import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner
} from "reactstrap";
import DataField from "./DataField";
import { getFields } from "../services/fieldsService";
import { getCollectionCount } from "../services/collectionService";
import { phraseToProperCase } from "../libs/case-utils";
import Moment from "react-moment";
import "moment-timezone";
import classnames from "classnames";
import "./DataFieldsList.css";
import GetPagination from "./GetPagination";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM-DD-YYYY HH:mm:ss";

const DataFieldsList = (props) => {
  const { user } = props;

  const userFileTypes = user?.prefs?.fileTypes ?? ["all"];

  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  const [fileType, setFileType] = useState("all");
  const [onlyEditableOption, setOnlyEditableOption] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [fields, setFields] = useState([]);

  const newSearch = () => {
    setIsLoading(false);
    setCurrentPage(1);
    setFields([]);
    toggleSearch();
  };

  // Control pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [fieldsCount, setFieldsCount] = useState(0);

  const totalItemsPerPage = 20;

  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setFields([]);

    listDataFields(pageNumber, totalItemsPerPage);
  };

  const incChange = (pageNumber, inc, isposOrneg) => {
    let finalPage;
    const residual = pageNumber % inc;
    const control = residual === 0 ? inc : residual;

    if (isposOrneg > 0) {
      finalPage = pageNumber + inc - control + 1;
    } else {
      finalPage = pageNumber - inc - control + 1;
    }

    setCurrentPage(finalPage);
    setFields([]);

    listDataFields(finalPage, totalItemsPerPage);
  };

  const firstChange = () => {
    let finalPage = 1;

    setCurrentPage(finalPage);
    setFields([]);

    listDataFields(finalPage, totalItemsPerPage);
  };

  const lastChange = (finalPage) => {
    setCurrentPage(finalPage);
    setFields([]);

    listDataFields(finalPage, totalItemsPerPage);
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  // Add "All" option to types
  const fileTypeOptions =
    userFileTypes.length > 1 ? ["all", ...userFileTypes] : userFileTypes;

  const listDataFields = async (page, count) => {
    let resItems = [];
    let countQuery = {};
    let fieldsQuery = {};

    if (fileType !== "all") {
      fieldsQuery.type = fileType; // To get fields data
      countQuery.fieldKeySuffixFor = fileType; // To get count
    }

    // Show only flexible fields
    if (onlyEditableOption) {
      countQuery.editable = true;
      fieldsQuery.editable = true;
    }

    try {
      const resCollectionCount = await getCollectionCount({
        collection: "Fields",
        ...countQuery
      });

      setFieldsCount(resCollectionCount.count);
    } catch (e) {}

    if (page) fieldsQuery.page = page;
    if (count) fieldsQuery.count = count;

    try {
      resItems = await getFields(fieldsQuery);
      setFields(resItems);
    } catch (e) {}

    return resItems.length;
  };

  const validateForm = () => {
    return fileType.length > 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const numRules = await listDataFields(1, totalItemsPerPage);
      if (numRules) {
        toggleSearch();
      } else {
        showCustomModal({
          title: "Search results",
          message: "No results were found following that criteria"
        });
      }
    } catch (e) {}

    setIsLoading(false);
  };

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const SearchResults = () => {
    let numberOfPages = 0;
    if (fieldsCount % totalItemsPerPage === 0) {
      numberOfPages = Math.floor(fieldsCount / totalItemsPerPage);
    } else {
      numberOfPages = Math.floor(fieldsCount / totalItemsPerPage) + 1;
    }

    return fields.length ? (
      <>
        <div className="NewSearch">
          <Button outline onClick={newSearch} color="secondary">
            New Search
          </Button>
        </div>
        <h5 className="card-title">Search results</h5>
        <GetPagination
          pages={numberOfPages}
          nextPage={nextPage}
          currentPage={currentPage}
          firstChange={firstChange}
          lastChange={lastChange}
          incChange={incChange}
        />
        <div className="RuleCards">
          {fields.map((f, i) => (
            <DataField key={i} field={f} user={user} />
          ))}
        </div>
      </>
    ) : (
      " "
    );
  };

  // Control tabs
  const [activeTab, setActiveTab] = useState("1");

  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="DataFieldsList">
      <Card>
        <CardHeader tag="h5">Manage Data Fields</CardHeader>
        <CardBody>
          <Nav tabs className="top-nav">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTabs("1");
                }}
                style={{ marginLeft: "0.5rem" }}
              >
                Search
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Collapse isOpen={isSearchOpen}>
                <Card className="search-engine">
                  <CardBody>
                    <Form onSubmit={handleSubmit} id="search-form">
                      <FormGroup tag="fieldset">
                        <h5 className="card-title">
                          Select the file type the data fields apply to
                        </h5>
                        {fileTypeOptions.map((el, i) => (
                          <FormGroup key={i} check>
                            <Label check>
                              <Input
                                type="radio"
                                name="filetype"
                                value={el}
                                checked={el === fileType}
                                onChange={(e) => setFileType(e.target.value)}
                              />{" "}
                              {phraseToProperCase(el)}
                            </Label>
                          </FormGroup>
                        ))}
                      </FormGroup>
                      <FormGroup tag="fieldset">
                        <h5 className="card-title">Other options</h5>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              value={true}
                              onChange={(e) =>
                                setOnlyEditableOption(e.target.checked)
                              }
                            />{" "}
                            Show only flexible fields
                          </Label>
                        </FormGroup>
                      </FormGroup>
                      <Button
                        className="btn-lg"
                        disabled={!validateForm() || isLoading}
                        color="primary"
                        block
                      >
                        Search {isLoading && <Spinner color="light" />}
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Collapse>
              {!isLoading && <SearchResults />}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
      <ErrorModal />
    </div>
  );
};

export default DataFieldsList;
