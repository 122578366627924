import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CustomInput,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner
} from "reactstrap";
import AdminGrantModal from "./AdminGrantModal";
import classnames from "classnames";
import { updateUser, deleteUser } from "../services/userService";
import { getUserRoles, getFieldsetPerRole } from "../models/UserRoles";
import { MailMessage } from "../models/Message";
import { phraseToProperCase } from "../libs/case-utils";
import Moment from "react-moment";
import "moment-timezone";
import "./User.css";

const APP_NAME = "DTE Data Delivery";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM/DD/YYYY HH:mm";

const roleOptions = getUserRoles();

const User = (props) => {
  const { user: userInfo, currentUser, sendEmail } = props;

  const [user, setUser] = useState(userInfo);
  const [isDeleted, setIsDeleted] = useState(false);

  const systemPrograms = ["PTM", "4.8 Hardening"];

  // Control tabs
  const [activeTab, setActiveTab] = useState("1");
  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const sendApprovalNotification = async (status) => {
    // Set mail delegate
    const fromRecipient = {
      name: currentUser.name,
      address: currentUser.email
    };

    // Set to recipients
    const toRecipients = [
      {
        name: user.name,
        address: user.email
      }
    ];

    // Set message subject
    const subject = `${APP_NAME}: Registration request`;

    // Set message according to status
    let statusMessage = `Dear ${user.name},\n\nYour request to use the ${APP_NAME} System was approved.\n\nThank you,\n${currentUser.name}`;
    if (status === "rejected")
      statusMessage = `Dear ${user.name},\n\nYour request to use the ${APP_NAME} System was rejected.\n\nThank you,\n${currentUser.name}`;

    // Set message body
    const body = {
      content: statusMessage
    };

    // Set one attachment
    const attachments = [];

    // Set Cc list
    const ccRecipients = [];

    // Set Bcc list
    const BccRecipients = [];

    const message = MailMessage(
      fromRecipient,
      toRecipients,
      subject,
      body,
      attachments,
      ccRecipients,
      BccRecipients,
      true
    );

    sendEmail(message);
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const UploadNotificationOption = (props) => {
    const { index, type, isChecked, isEditing, handleUpdate } = props;

    return (
      <CustomInput
        type="switch"
        id={`upload-${index}-${user.id}`}
        label={phraseToProperCase(type)}
        checked={isChecked}
        value={type}
        disabled={!isEditing}
        onChange={(e) => handleUpdate({ type, checked: e.target.checked })}
      />
    );
  };

  const RegNotificationOption = (props) => {
    const { isEditing, isChecked, handleUpdate } = props;

    return (
      <div className="checkBoxContainer">
        <CustomInput
          type="switch"
          id={`registration-${user.id}`}
          label="Receive user registration requests"
          checked={isChecked}
          disabled={!isEditing}
          onChange={(e) => handleUpdate(e.target.checked)}
        />
      </div>
    );
  };

  const RoleOption = (props) => {
    const { userId, role, isEditing, handleUpdate } = props;

    return isEditing ? (
      <Input
        className="Role"
        value={role}
        type="select"
        id={`role-${userId}`}
        onChange={(e) => handleUpdate(e.target.value)}
      >
        {roleOptions.map((el, i) => {
          return (
            <option key={i} value={el.role}>
              {el.label}
            </option>
          );
        })}
      </Input>
    ) : (
      <Badge color="primary">{phraseToProperCase(role)}</Badge>
    );
  };

  const RegistrationSelect = (props) => {
    const { userId, role, registration, handleUpdate } = props;

    const guestOptions = ["pending", "rejected"];
    let defOptions = ["approved", "revoked"];

    if (role === "guest") defOptions = guestOptions;

    let updatedRegistration = registration;
    if (!defOptions.includes(registration)) {
      updatedRegistration = defOptions[0];

      handleUpdate(updatedRegistration);
    }

    return (
      <Input
        className="text-capitalize Registration"
        value={updatedRegistration}
        type="select"
        id={`registration-${userId}`}
        onChange={(e) => handleUpdate(e.target.value)}
      >
        {defOptions.map((o, i) => (
          <option key={i} value={o}>
            {o}
          </option>
        ))}
      </Input>
    );
  };

  const RegistrationOption = (props) => {
    const { userId, role, registration, isEditing, handleUpdate } = props;

    const isActive = registration === "approved";
    const registrationClass = isActive ? "success" : "danger";

    return isEditing ? (
      <RegistrationSelect
        userId={userId}
        role={role}
        registration={registration}
        handleUpdate={handleUpdate}
      />
    ) : (
      <Badge color={registrationClass} className="text-uppercase">
        {registration}
      </Badge>
    );
  };

  const ProgramOption = (props) => {
    const { index, program, isChecked, isEditing, handleUpdate } = props;

    return (
      <CustomInput
        key={index}
        type="switch"
        id={`program-${index}-${user.id}`}
        label={program}
        checked={isChecked}
        value={program}
        disabled={!isEditing}
        onChange={(e) => handleUpdate({ program, checked: e.target.checked })}
      />
    );
  };

  const FileTypeOption = (props) => {
    const { index, type, isChecked, isEditing, handleUpdate } = props;

    return (
      <CustomInput
        key={index}
        type="switch"
        id={`fileType-${index}-${user.id}`}
        label={phraseToProperCase(type)}
        checked={isChecked}
        value={type}
        disabled={!isEditing}
        onChange={(e) => handleUpdate({ type, checked: e.target.checked })}
      />
    );
  };

  const OrganizationField = (props) => {
    const { userId, org, isEditing, handleUpdate, error } = props;

    return (
      <>
        <Input
          innerRef={(input) => input && input.focus()}
          className="Org"
          invalid={error ? true : false}
          type="text"
          name="organization"
          id={`organization-${userId}`}
          value={org}
          disabled={!isEditing}
          onChange={(e) => handleUpdate(e.target.value)}
        />
        {error && <FormFeedback className="Org">{error}</FormFeedback>}
      </>
    );
  };

  const EditButton = (props) => {
    const { handleEdit } = props;

    return (
      <Button
        outline
        color="primary"
        size="sm"
        className="editButton"
        onClick={() => handleEdit(true)}
      >
        Edit
      </Button>
    );
  };

  const DeleteButton = (props) => {
    const { handleDelete } = props;

    return (
      <Button
        outline
        color="danger"
        size="sm"
        className="deleteButton"
        onClick={() => handleDelete(true)}
      >
        Delete
      </Button>
    );
  };

  const SaveCancelButtons = (props) => {
    const { handleSave, handleCancel, isLoading } = props;

    return (
      <>
        <Button
          className="editButton"
          color="primary"
          size="sm"
          onClick={handleSave}
        >
          Save {isLoading && <Spinner size="sm" color="light" />}
        </Button>
        <Button
          className="editButton"
          color="default"
          size="sm"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </>
    );
  };

  const DeleteCancelButtons = (props) => {
    const { handleDelete, handleCancelDelete, isLoading } = props;

    return (
      <>
        <hr />
        Are you sure you want to delete this user?
        <br />
        <Button
          className="deleteButton"
          color="success"
          size="sm"
          onClick={handleCancelDelete}
        >
          Not sure
        </Button>
        <Button
          className="deleteButton"
          color="danger"
          size="sm"
          onClick={handleDelete}
        >
          Yes, I'm sure {isLoading && <Spinner size="sm" color="light" />}
        </Button>
      </>
    );
  };

  const GeneralTab = (props) => {
    const {
      userId,
      currentUserId,
      role: defRole,
      org: defOrganization,
      registration: defRegistration,
      prefs,
      handleUserUpdate
    } = props;

    const itsMe = userId === currentUserId;

    const defPrograms = prefs.programs;
    const defFileTypes = prefs.fileTypes;
    const defNotifications = prefs.notifications;

    const [showAdminGrant, setShowAdminGrant] = useState(false);

    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const [role, setRole] = useState(defRole);
    const [registration, setRegistration] = useState(defRegistration);
    const [programs, setPrograms] = useState(defPrograms);
    const [fileTypes, setFileTypes] = useState(defFileTypes);
    const [organization, setOrganization] = useState(defOrganization);

    const [orgError, setOrgError] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const validateInput = () => {
      if (!/^[A-Za-z0-9 ]*$/.test(organization.trim())) {
        setOrgError("Field can't be empty or last character is invalid");

        return false;
      } else {
        setOrgError(null);

        return true;
      }
    };

    const handleSave = async () => {
      if (!validateInput()) return false;

      let updatedPrefs = {};
      const newRegistration = defRole === "guest" && defRole !== role;
      const rejectedGuest = role === "guest" && registration === "rejected";
      const notify = newRegistration || rejectedGuest;

      const roleFileTypes = getFieldsetPerRole(role);
      const updatedFileTypes = fileTypes.filter((item) =>
        roleFileTypes.includes(item)
      );

      if (role === "admin") {
        const uploads = defNotifications.uploads.filter((x) =>
          updatedFileTypes.includes(x)
        );

        updatedPrefs = {
          programs,
          fileTypes: updatedFileTypes,
          notifications: { ...defNotifications, uploads }
        };
      } else {
        // Deactivate notification prefs for non-admin users
        updatedPrefs = {
          programs,
          fileTypes: updatedFileTypes,
          notifications: {
            ...defNotifications,
            registration: false,
            uploads: []
          }
        };
      }

      setIsLoading(true);

      await updateUser(userId, {
        org: organization,
        role,
        registration,
        prefs: updatedPrefs
      }).then((results) => {
        setIsEditing(false);

        setIsLoading(false);

        // Send notification when registration is approved
        if (notify) sendApprovalNotification(registration);

        handleUserUpdate({
          ...user,
          org: organization,
          role,
          registration,
          prefs: updatedPrefs
        });
      });
    };

    const handleCancel = () => {
      setOrganization(defOrganization);
      setRole(defRole);
      setRegistration(defRegistration);
      setPrograms(defPrograms);
      setFileTypes(defFileTypes);

      setIsEditing(false);
      setOrgError(null);
    };

    const handleDelete = async () => {
      setIsLoading(true);

      await deleteUser(userId).then((results) => {
        setIsDeleting(false);
        setIsLoading(false);

        if (results.deletedCount && results.deletedCount > 0) {
          setIsDeleted(true);
        } else {
          showCustomModal({
            title: "Error Deleting User",
            message:
              "Something wrong happened trying to delete user. Please contact the administrator."
          });
        }
      });
    };

    const handleCancelDelete = () => {
      setIsDeleting(false);
    };

    const handleRole = (role) => {
      if (role === "admin") {
        setShowAdminGrant(true);
      } else {
        setRole(role);
      }
    };

    const handleRegistration = (registration) => {
      setRegistration(registration);
    };

    const handlePrograms = (program) => {
      let updated = [];

      if (program.checked) {
        updated = [...programs, program.program].sort();
      } else {
        updated = programs.filter((item) => item !== program.program);
      }

      setPrograms(updated);
    };

    const handleFileTypes = (type) => {
      let updated = [];

      if (type.checked) {
        updated = [...fileTypes, type.type].sort();
      } else {
        updated = fileTypes.filter((item) => item !== type.type);
      }

      setFileTypes(updated);
    };

    const handleOrganization = (org) => {
      setOrganization(org);
    };

    return (
      <>
        <TabPane tabId="1">
          <CardBody>
            <CardText>
              <b>Name:</b> {user.name}
            </CardText>
            <CardText>
              <b>Email:</b> {user.email}
            </CardText>
            <CardText>
              <b>Registered since:</b> <Moment>{user.createdAt}</Moment>
            </CardText>
            <CardText>
              <b>Organization:</b> {!isEditing && organization}
            </CardText>
            {isEditing && (
              <OrganizationField
                userId={userId}
                org={organization}
                isEditing={isEditing}
                handleUpdate={handleOrganization}
                error={orgError}
              />
            )}
            <CardText>
              <b>Role:</b>{" "}
              <RoleOption
                userId={userId}
                role={role}
                isEditing={isEditing}
                handleUpdate={handleRole}
              />
            </CardText>
            <CardText>
              <b>Registration:</b>{" "}
              <RegistrationOption
                userId={userId}
                role={role}
                registration={registration}
                isEditing={isEditing}
                handleUpdate={handleRegistration}
              />
            </CardText>
            {role !== "guest" && (
              <>
                <CardText>
                  <b>Programs:</b>
                </CardText>
                <div className="checkBoxContainer">
                  {systemPrograms.map((program, i) => {
                    const isChecked = programs.includes(program);

                    return (
                      <ProgramOption
                        key={i}
                        index={i}
                        program={program}
                        isChecked={isChecked}
                        isEditing={isEditing}
                        handleUpdate={handlePrograms}
                      />
                    );
                  })}
                </div>
                <CardText>
                  <b>File types:</b>
                </CardText>
                <div className="checkBoxContainer">
                  {getFieldsetPerRole(role).map((type, i) => {
                    const isChecked = fileTypes.includes(type);

                    return (
                      <FileTypeOption
                        key={i}
                        index={i}
                        type={type}
                        isChecked={isChecked}
                        isEditing={isEditing}
                        handleUpdate={handleFileTypes}
                      />
                    );
                  })}
                </div>
              </>
            )}
            {!isEditing && !isDeleting && (
              <EditButton handleEdit={setIsEditing} />
            )}
            {isEditing && (
              <SaveCancelButtons
                handleSave={handleSave}
                handleCancel={handleCancel}
                isLoading={isLoading}
              />
            )}
            {!isDeleting && !itsMe && !isEditing && (
              <DeleteButton handleDelete={setIsDeleting} />
            )}
            {isDeleting && (
              <DeleteCancelButtons
                handleDelete={handleDelete}
                handleCancelDelete={handleCancelDelete}
                isLoading={isLoading}
              />
            )}
          </CardBody>
        </TabPane>
        <AdminGrantModal
          show={showAdminGrant}
          setShow={setShowAdminGrant}
          onYes={setRole}
        />
      </>
    );
  };

  const NotificationsTab = (props) => {
    const { userId, prefs, handleUserUpdate } = props;

    const defUploads = prefs.notifications.uploads;
    const defRegistration = prefs.notifications.registration;

    const [isEditing, setIsEditing] = useState(false);

    const [uploads, setUploads] = useState(defUploads);
    const [registration, setRegistration] = useState(defRegistration);
    const [isLoading, setIsLoading] = useState(false);

    const handleSave = async () => {
      const updatedPrefs = {
        ...prefs,
        notifications: { uploads, registration }
      };

      setIsLoading(true);

      await updateUser(userId, { prefs: updatedPrefs }).then((results) => {
        setIsEditing(false);

        setIsLoading(false);

        handleUserUpdate({
          ...user,
          prefs: updatedPrefs
        });
      });
    };

    const handleCancel = () => {
      setUploads(defUploads);
      setRegistration(defRegistration);

      setIsEditing(false);
    };

    const handleUploads = (upload) => {
      let updated = [];

      if (upload.checked) {
        updated = [...uploads, upload.type].sort();
      } else {
        updated = uploads.filter((item) => item !== upload.type);
      }

      setUploads(updated);
    };

    const handleRegistration = (registration) => {
      setRegistration(registration);
    };

    return (
      <TabPane tabId="2">
        <CardBody>
          <CardText>
            <b>Notify upon upload of the following data</b>
          </CardText>
          <div className="checkBoxContainer">
            {user.prefs.fileTypes.map((type, i) => {
              const isChecked = uploads.includes(type);

              return (
                <UploadNotificationOption
                  key={i}
                  index={i}
                  type={type}
                  isChecked={isChecked}
                  isEditing={isEditing}
                  handleUpdate={handleUploads}
                />
              );
            })}
          </div>
          <CardText>
            <b>Other:</b>
          </CardText>
          <RegNotificationOption
            isChecked={registration}
            isEditing={isEditing}
            handleUpdate={handleRegistration}
          />
          {!isEditing && <EditButton handleEdit={setIsEditing} />}
          {isEditing && (
            <SaveCancelButtons
              handleSave={handleSave}
              handleCancel={handleCancel}
              isLoading={isLoading}
            />
          )}
        </CardBody>
      </TabPane>
    );
  };

  return (
    !isDeleted && (
      <div className="User">
        <Card className="UserCard">
          <CardHeader tag="h5">{user.name}</CardHeader>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTabs("1");
                }}
              >
                General
              </NavLink>
            </NavItem>
            {user.role === "admin" && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggleTabs("2");
                  }}
                >
                  Notifications
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={activeTab}>
            <GeneralTab
              userId={user.id}
              currentUserId={currentUser.id}
              role={user.role}
              org={user.org}
              registration={user.registration}
              prefs={user.prefs}
              handleUserUpdate={setUser}
            />
            {user.role === "admin" && (
              <NotificationsTab
                userId={user.id}
                prefs={user.prefs}
                handleUserUpdate={setUser}
              />
            )}
          </TabContent>
        </Card>
        <ErrorModal />
      </div>
    )
  );
};

export default User;
