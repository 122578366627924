const Operators = {
  "===": {
    oper: "===",
    label: "=",
    type: ["string", "number"]
  },
  "!==": {
    oper: "!==",
    label: "<>",
    type: ["string", "number"]
  },
  ">": {
    oper: ">",
    label: ">",
    type: ["number"]
  },
  ">=": {
    oper: ">=",
    label: ">=",
    type: ["number"]
  },
  "<": {
    oper: "<",
    label: "<",
    type: ["number"]
  },
  "<=": {
    oper: "<=",
    label: "<=",
    type: ["number"]
  },
  in: {
    oper: "in",
    label: "IN",
    type: ["string", "number"]
  },
  "!in": {
    oper: "!in",
    label: "NOT IN",
    type: ["string", "number"]
  }
};

const getOperatorsObject = () => {
  return Operators;
};

const getOperatorsArray = () => {
  return Object.keys(Operators).map((o) => Operators[o]);
};

const getOperatorsList = () => {
  return Object.keys(Operators);
};

export { getOperatorsObject, getOperatorsArray, getOperatorsList };
