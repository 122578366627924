import React from "react";
import Rep1Comp from "../components/Rep1Comp";
import "./Rep1.css";

const Rep1 = (props) => {
  return (
    <div className="Rep1">
      <div className="container">
        <Rep1Comp {...props} />
      </div>
    </div>
  );
};

export default Rep1;
