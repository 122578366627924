import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Input,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Spinner,
  TabContent,
  TabPane,
  Collapse
} from "reactstrap";
import { updateCircuitAudit } from "../services/circuitAuditService";
import {
  runPipeline,
  CIRCUITSTATUS_INITIATED,
  CIRCUITSTATUS_STAGING,
  CIRCUITSTATUS_AUDITING,
  CIRCUITSTATUS_DELIVERING,
  CIRCUITSTATUS_DELIVERED,
  datasetAbbr
} from "../models/CircuitAuditPipeline";
import { downloadData, getUpload, getUploads } from "../services/filesService";
import {
  getDataFileEntries,
  createDataFileEntry
} from "../services/dataFileService";
import {
  getLastInspections,
  getNonApprovedPolesInCircuit,
  getRipInspectionsAndApproval
} from "../services/reinforcementInspectionsService";
import { getCollectionCount } from "../services/collectionService";
import { getSource } from "../services/sourceService";
import { getField, getFields } from "../services/fieldsService";
import {
  bulkGet,
  bulkGetAggregate,
  bulkUpdate
} from "../services/bulkServices";
import {
  getOperRecMd as getOperRecMdEda,
  getCsvMd as getCsvMdEda
} from "../models/MetadataEda";
import { getOperRecMd as getOperRecMdEsb } from "../models/MetadataEsb";
import {
  postToEsbInspRec,
  postToEsbInvGlobalId,
  postToEsbLocationData,
  postToEsbCheckLocationData,
  postToEsbSendReinforcementData,
  postDataToEda,
  postCsvToEda,
  postZipToEda,
  checkPoFake
} from "../services/dteService";
import {
  OverflowCardContainer,
  DuplicateGlnxyReport,
  MissingGlobalIdsReport,
  InvalidGlobalIdsReport,
  MismatchedLocationDataReport,
  FormatValidationReport,
  EngStandardsComplianceReport,
  getFieldDefinitionsBySource,
  getCircuitData,
  validateFormat,
  getEngStandardsBySource,
  runComplexRules,
  ManageRiaPoRequest,
  DisplayReinforcementInspections,
  DisplayReinforcementReinspections
} from "./CircuitAuditUtils";
import { ApiLoadTest } from "../models/ApiLoadTest";
import csv from "csvtojson/v2";
import { phraseToProperCase } from "../libs/case-utils";
import classnames from "classnames";
import moment from "moment";
import Moment from "react-moment";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "./CircuitAudit.css";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM/DD/YYYY HH:mm:ss";

const verbose = true;
const Json = ({ data }) => (
  <pre style={{ marginBottom: "0rem" }}>{JSON.stringify(data, null, 4)}</pre>
);
const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const delayBetCalls = 50;
const getTimestamp = () => new Date().getTime();

// Set load test headers
const loadtestHeaders = [
  "GLNX-GLNY",
  "Payload (bytes)",
  "Status code",
  "Response time (ms)",
  "Timestamp"
];

const getNewTimestamp = () => {
  return new Date().getTime();
};

const CUR_VERSION = "1.1.0";

const CircuitAudit = (props) => {
  const { circuitData, user, sendEmail } = props;

  const [circuit, setCircuit] = useState(circuitData);
  const [activeTaskGroup, setActiveTaskGroup] = useState(null);
  const [activeTab, setActiveTab] = useState("1");

  // Control tabs
  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const taskGroups = ["staging", "audit", "delivery"];

  const userInfo = {
    id: user.id,
    name: user.name,
    org: user.org,
    email: user.email
  };

  const overrideMailContacts = user.role === "dev" || user.role === "admin";

  const isObjectEmpty = (obj) => {
    const keys = Object.keys(obj);

    return keys.length === 0;
  };

  const findTaskIndex = (taskName, pipeline) => {
    return _.findIndex(pipeline, (t) => {
      return t.name === taskName;
    });
  };

  const CreatedOnField = (props) => {
    const { at } = props;

    return (
      <>
        <b>Created on:</b>
        <br />
        <Moment>{at}</Moment>
      </>
    );
  };

  const UpdatedByField = (props) => {
    const { by, at } = props;

    return (
      <>
        <b>Updated by:</b>
        <br /> {by} {" @ "}
        <Moment>{at}</Moment>
      </>
    );
  };

  const updateCircuit = async (newObj, group) => {
    setActiveTaskGroup(group);

    // Update pipeline
    let newCircuit = { ...newObj };

    newCircuit.pipeline = runPipeline(newObj.pipeline);
    newCircuit.updatedBy = userInfo;
    newCircuit.updatedAt = getNewTimestamp();

    delete newCircuit._id;

    try {
      // Update circuit in db
      const res = await updateCircuitAudit(circuit.circuit, newCircuit);

      if (res.modifiedCount) {
        // Update circuit state
        setCircuit(newCircuit);
      }
    } catch (e) {
      console.log("Error updating circuit: ", e);
    }
  };

  const Task = (props) => {
    const { taskData: task, handleUpdateCircuit, circuit } = props;

    const [transferProgress, setTransferProgress] = useState(0);
    const [transferProgressTitle, setTransferProgressTitle] = useState(null);
    const [transferTotalRecords, setTransferTotalRecords] = useState(0);
    const [isTransferring, setIsTransferring] = useState(false);

    const getChecksEnabledState = () => {
      let state = {};
      let disable = false;

      const taskKeys = Object.keys(task.checks);

      for (let i = 0; i < taskKeys.length; i++) {
        const check = task.checks[taskKeys[i]];

        if (!disable && check !== true) {
          state[taskKeys[i]] = true;

          disable = true;
        } else {
          if (disable) {
            state[taskKeys[i]] = false;
          }
        }
      }

      return state;
    };

    // Get check buttons initial state
    const checksEnabledState = getChecksEnabledState();

    const ActivateTaskButton = () => {
      return (
        <Button
          outline
          color="primary"
          size="sm"
          disabled={isTransferring}
          onClick={() => toggleRequiredTask(true)}
        >
          Set as required {isTransferring && <Spinner size="sm" color="dark" />}
        </Button>
      );
    };

    const DeactivateTaskButton = () => {
      return (
        <Button
          outline
          color="danger"
          size="sm"
          disabled={isTransferring}
          onClick={() => toggleRequiredTask(false)}
        >
          Set as not required{" "}
          {isTransferring && <Spinner size="sm" color="dark" />}
        </Button>
      );
    };

    const inspectCsvFile = async (fileName) => {
      // Get csv file from blob storage
      const csvData = await downloadData(fileName, "application/csv");

      // Convert csv to json array
      const jsonArray = await csv({
        noheader: false,
        output: "json"
      }).fromString(csvData);

      return jsonArray;
    };

    const didAllChecksPass = (checks) => {
      let passed = true;
      const checkKeys = Object.keys(checks);

      checkKeys.forEach((key) => {
        if (!checks[key]) {
          passed = false;
        }
      });

      return passed;
    };

    const toggleRequiredTask = async (flag) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set required to true
      const taskIndex = findTaskIndex(task.name, circuitCopy.pipeline);

      // Update task
      circuitCopy.pipeline[taskIndex].required = flag;

      // Reset checks
      if (!flag) {
        const checkProps = Object.keys(circuitCopy.pipeline[taskIndex].checks);
        if (checkProps.length) {
          checkProps.forEach(
            (check) => (circuitCopy.pipeline[taskIndex].checks[check] = null)
          );
        }
      }

      // Update status
      circuitCopy.pipeline[taskIndex].status = flag ? "pending" : null;

      // Update log
      circuitCopy.pipeline[taskIndex].logs["updated"] = {
        by: userInfo,
        at: getNewTimestamp()
      };

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateFileExistsCheck = async (taskName, flag) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "fileExists";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Disallow deactivation
      if (circuitCopy.pipeline[taskIndex].checks[checkName]) {
        if (circuitCopy.pipeline[taskIndex].settings.allowDeactivation) {
          circuitCopy.pipeline[taskIndex].settings.allowDeactivation = false;
        }
      }

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_STAGING;

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateDataExistsCheck = async (taskName, flag) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "dataExist";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_STAGING;

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateCheckDuplicateGlnxyCheck = async (
      taskName,
      flag,
      variables
    ) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "checkDuplicateGlnxy";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_STAGING;

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateCheckRestorableRejectCheck = async (
      taskName,
      flag,
      variables
    ) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "checkRestorableReject";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_STAGING;

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateCheckImageIntegrity = async (taskName, flag, variables) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "checkImageIntegrity";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_STAGING;

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateInvalidGlobalIdsCheck = async (taskName, flag, variables) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "invalidGlobalIds";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_STAGING;

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateMissingGlobalIdsCheck = async (taskName, flag, variables) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "missingGlobalIds";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_STAGING;

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updatePurchaseOrderExistsCheck = async (
      taskName,
      flag,
      variables
    ) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "purchaseOrderExists";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_STAGING;

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updatePullReinforcedPolesCheck = async (
      taskName,
      flag,
      variables
    ) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "pullReinforcedPoles";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_STAGING;

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateGetInspectionsCheck = async (taskName, flag, variables) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "loadInspections";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_STAGING;

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateApproveReleaseCheck = async (taskName, flag, variables) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "approveRelease";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_STAGING;

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        // Update pipeline status
        circuitCopy.status = CIRCUITSTATUS_AUDITING;

        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateFormatValidationCheck = async (taskName, flag, variables) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "formatValidation";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_AUDITING;

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateEngStandardsComplianceCheck = async (
      taskName,
      flag,
      variables
    ) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "engineeringStandardsCompliance";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_AUDITING;

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateRunAuditCheck = async (taskName, flag) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "runAudit";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_AUDITING;

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateCircuitAuditApprovalCheck = async (taskName, flag) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "circuitAuditApproval";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_AUDITING;

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        // Update pipeline status
        circuitCopy.status = CIRCUITSTATUS_DELIVERING;

        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateStageDataToDataLakeCheck = async (
      taskName,
      flag,
      variables
    ) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "stageDataToDataLake";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_DELIVERING;

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateTransferDataToDataLakeCheck = async (
      taskName,
      flag,
      variables
    ) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "transferDataToDataLake";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_DELIVERING;

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateTransferArtifactsToDataLakeCheck = async (
      taskName,
      flag,
      variables
    ) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "transferArtifactsToDataLake";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_DELIVERING;

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateTransferImagesToDataLakeCheck = async (
      taskName,
      flag,
      variables
    ) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "transferImagesToDataLake";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_DELIVERING;

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        // Update pipeline status
        circuitCopy.status = CIRCUITSTATUS_DELIVERED;

        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateMaximoLocationDataUpdateCheckCheck = async (
      taskName,
      flag,
      variables
    ) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "maximoLocationDataUpdateCheck";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_DELIVERING;

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateStageOperationalRecordForDataPowerMaximoCheck = async (
      taskName,
      flag,
      variables
    ) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "stageOperationalRecordForDataPowerMaximo";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_DELIVERING;

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateTransferLocationDataToDataPowerESRICheck = async (
      taskName,
      flag,
      variables
    ) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "transferLocationDataToDataPowerESRI";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_DELIVERING;

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updatePoleInspectionDataUpdateStateCheck = async (
      taskName,
      flag,
      variables
    ) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "poleInspectionDataUpdateState";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_DELIVERING;

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateTransferOperationalRecordToDataPowerMaximoCheck = async (
      taskName,
      flag,
      variables
    ) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "transferOperationalRecordToDataPowerMaximo";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_DELIVERING;

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const updateTransferReinforcementInspectionRecordToDataPowerCheck = async (
      taskName,
      flag,
      variables
    ) => {
      // Make copy of circuit data
      const circuitCopy = { ...circuit };

      // Set check name
      const checkName = "transferReinforcementInspectionRecordToDataPower";

      // Set required to true
      const taskIndex = findTaskIndex(taskName, circuitCopy.pipeline);

      // Update task
      if (circuitCopy.pipeline[taskIndex].checks[checkName] !== flag) {
        circuitCopy.pipeline[taskIndex].checks[checkName] = flag;

        // Update log
        circuitCopy.pipeline[taskIndex].logs["updated"] = {
          by: userInfo,
          at: getNewTimestamp()
        };
      }

      // Make copy of current variables
      const variablesCopy = circuitCopy.pipeline[taskIndex].variables;

      // Update variables
      circuitCopy.pipeline[taskIndex].variables = {
        ...variablesCopy,
        ...variables
      };

      // Update pipeline status
      circuitCopy.status = CIRCUITSTATUS_DELIVERING;

      // Check all checks to update status
      if (didAllChecksPass(circuitCopy.pipeline[taskIndex].checks)) {
        circuitCopy.pipeline[taskIndex].status = "passed";
      }

      await handleUpdateCircuit(circuitCopy, task.type);
    };

    const mapDoc = (doc, fields) => {
      let newDoc = {};

      fields.forEach((item) => {
        newDoc[item.alias] = doc[item.field] ? doc[item.field] : "";
      });

      return newDoc;
    };

    const getFieldMapping = (fields, dest) => {
      return fields.map((doc) => {
        const map = {};

        map["field"] = doc.fieldKey;
        map["alias"] = doc.alias[dest].field;

        if (doc.alias[dest].overrides) {
          map["overrides"] = doc.alias[dest].overrides;
        }
        return map;
      });
    };

    const applyOverrides = (records) => {
      // // Priority #1 (per client as no longer necessary)
      // const overrideRia = {
      //   ConditionOfPole_RIA: {
      //     overrides: "PoleStatus_RIP"
      //   }
      // };

      // Priority #2
      const overrideRip = {
        NumberOfFumigantHoles_RIP: {
          overrides: "NumberOfFumigantHoles_PIT"
        },
        PoleStatus_RIP: {
          overrides: "PoleStatus_PIT"
        },
        ReasonForPoleRejection_RIP: {
          overrides: "ReasonForPoleRejection_PIT"
        },
        RetreatmentForVoids_RIP: {
          overrides: "RetreatmentForVoids_PIT"
        },
        RetreatmentInternal_RIP: {
          overrides: "RetreatmentInternal_PIT"
        }
      };

      // Apply overrides
      // const riaKeys = Object.keys(overrideRia);
      const ripKeys = Object.keys(overrideRip);

      const recordsKeys = Object.keys(records);

      recordsKeys.forEach((k) => {
        const record = { ...records[k] };

        const recordKeys = Object.keys(record);

        let overrideKey = null;

        for (let i = 0; i < recordKeys.length; i++) {
          // RIP override
          if (ripKeys.includes(recordKeys[i])) {
            overrideKey = overrideRip[recordKeys[i]].overrides;

            if (record[overrideKey]) {
              records[k][overrideKey] = record[recordKeys[i]];
            }
          }

          // // RIA override
          // if (riaKeys.includes(recordKeys[i])) {
          //   overrideKey = overrideRia[recordKeys[i]].overrides;

          //   if (record[overrideKey]) {
          //     records[k][overrideKey] = record[recordKeys[i]];
          //   }
          // }
        }
      });

      return records;
    };

    const mergePoleData = (recs) => {
      let records = {};
      recs.forEach((doc) => {
        const dataPointId = doc.dataPointId;
        const suffix = doc.suffix;
        const data = doc.document;
        const keys = Object.keys(data);

        for (let i = 0; i < keys.length; i++) {
          if (!records[dataPointId]) records[dataPointId] = {};

          const fieldName = `${keys[i]}_${suffix}`;

          records[dataPointId][fieldName] = data[keys[i]];
        }
      });

      return applyOverrides(records);
    };

    const supportPoleData = (recs) => {
      let records = {};
      recs.forEach((doc) => {
        const dataPointId = doc.dataPointId;

        if (!records[dataPointId])
          records[dataPointId] = {
            "GLNX-GLNY": dataPointId,
            circuit: doc.circuit,
            map: doc.map,
            source: [],
            fieldset: [],
            suffix: []
          };

        records[dataPointId]["source"].push(doc.source);
        records[dataPointId]["fieldset"].push(doc.fieldset);
        records[dataPointId]["suffix"].push(doc.suffix);
      });

      return records;
    };

    const getFieldsetUpload = async (fieldset) => {
      const list = [];

      // Get pit upload for circuit
      let arr1 = await getUploads({
        page: 1,
        count: 100,
        circuit: circuit.circuit,
        type: fieldset
      });

      // Extract compressed data
      arr1 = arr1.map((upload) => {
        return upload?.support?.compressed ? upload.support.compressed : [];
      });

      for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr1[i].length; j++) {
          list.push(arr1[i][j]);
        }
      }

      return list;
    };

    const getUploadList = async () => {
      const list = [];

      // Get pit upload for circuit
      let arr1 = await getUploads({
        page: 1,
        count: 100,
        circuit: circuit.circuit,
        type: "poleInspection"
      });

      // Extract compressed data
      arr1 = arr1.map((upload) => {
        return upload?.support?.compressed ? upload.support.compressed : [];
      });

      for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr1[i].length; j++) {
          list.push(arr1[i][j]);
        }
      }

      // Get pia upload for circuit
      let arr2 = await getUploads({
        page: 1,
        count: 100,
        circuit: circuit.circuit,
        type: "poleInspectionAudit"
      });

      // Extract compressed data
      arr2 = arr2.map((upload) => {
        return upload?.support?.compressed ? upload.support.compressed : [];
      });

      for (let i = 0; i < arr2.length; i++) {
        for (let j = 0; j < arr2[i].length; j++) {
          list.push(arr2[i][j]);
        }
      }

      return list;
    };

    const getGlnxyGlobalId = async (fieldset, more = false) => {
      const retObj = {};

      // Get records for circuit
      const dataFiles = await getDataFileEntries({
        page: 1,
        count: 10000,
        circuit: circuit.circuit,
        fieldset
      });

      // PIT: AddRemoveUpdatePole = Remove -> image not required
      // PIT: ReasonPoleWasNotInspected <> Pole Was Inspected -> image not required
      // PIA: SkippedDueToAccess = Y -> image not required

      dataFiles.forEach((item) => {
        const { document: doc } = item;

        if (more) {
          retObj[doc["GLNX-GLNY"]] = {
            globalId: doc["GlobalID"],
            AddRemoveUpdatePole: doc["AddRemoveUpdatePole"],
            ReasonPoleWasNotInspected: doc["ReasonPoleWasNotInspected"],
            SkippedDueToAccess: doc["SkippedDueToAccess"]
          };
        } else {
          retObj[doc["GLNX-GLNY"]] = doc.GlobalID;
        }
      });

      return retObj;
    };

    const getLocationData = async (circuit) => {
      let docs = [];
      let query = null;

      query = {
        collection: "DataFiles",
        filter: {
          fieldset: "poleInspection",
          circuit: circuit
        },
        sort: {},
        project: {
          _id: 0,
          "document.GlobalID": 1,
          "document.InspectionDate": 1,
          "document.PoleStatus": 1,
          "document.PoleTagClass": 1,
          "document.PoleTagInstallationYear": 1,
          "document.PoleTagLength": 1,
          "document.PoleTagOwner": 1,
          "document.PoleTagSpeciesTreatmentCode": 1,
          "document.StructureType": 1
        },
        confirm: true
      };

      const pit = await bulkGet(query);

      if (pit.status === 200) {
        docs = pit.response;

        if (docs.length > 0) {
          query = {
            collection: "DataFiles",
            filter: {
              fieldset: "poleReinforcement",
              circuit: circuit
            },
            sort: {},
            project: {
              _id: 0,
              "document.GlobalID": 1,
              "document.PoleStatus": 1,
              "document.ReinforcementDate": 1
            },
            confirm: true
          };

          const rip = await bulkGet(query);

          if (rip.status === 200) {
            const ripDocs = rip.response;

            if (ripDocs.length > 0) {
              // Overwrite/update pit set
              for (let i = 0; i < ripDocs.length; i++) {
                const ripDoc = ripDocs[i].document;
                const index = docs.findIndex(
                  (item) => item.document.GlobalID === ripDoc.GlobalID
                );

                // Overwrite
                docs[index].document.PoleStatus = ripDoc.PoleStatus;
                docs[index].document.ReinforcementDate =
                  ripDoc.ReinforcementDate !== ""
                    ? ripDoc.ReinforcementDate
                    : null;
              }
            }
          }
        }
      }

      return { status: pit.status, response: docs };
    };

    const checkHandlers = {
      fileExists: async (taskName) => {
        let callStatus = false;

        const getFilename = await bulkGet({
          collection: "Uploads",
          filter: {
            type: taskName,
            circuit: circuit.circuit
          },
          project: {
            _id: 0,
            fileName: 1
          },
          confirm: true
        });

        if (getFilename.status === 200) {
          if (getFilename.response.length > 0) {
            const fileName = getFilename.response[0].fileName;

            const res = await inspectCsvFile(fileName);

            callStatus = res.length ? true : false;
          }

          await updateFileExistsCheck(taskName, callStatus);
        }

        return callStatus;
      },
      dataExist: async (taskName) => {
        let callStatus = false;

        const getFilename = await bulkGet({
          collection: "Uploads",
          filter: {
            type: taskName,
            circuit: circuit.circuit
          },
          project: {
            _id: 0,
            fileName: 1
          },
          confirm: true
        });

        if (getFilename.status === 200) {
          const fileName = getFilename.response[0].fileName;

          const res = await getUpload(fileName);

          callStatus =
            res !== null && res.status === "processed" && res.datapoints > 0
              ? true
              : false;

          await updateDataExistsCheck(taskName, callStatus);
        }

        return callStatus;
      },
      checkDuplicateGlnxy: async (taskName) => {
        let callStatus = true;
        const variables = {};

        const collection = "DataFiles";
        const fieldset = "poleInspection";

        let query = {
          collection,
          aggregate: [
            {
              $match: {
                fieldset
              }
            },
            {
              $sort: {
                "document.InspectionDate": 1
              }
            },
            {
              $group: {
                _id: "$dataPointId",
                circuits: {
                  $addToSet: "$circuit"
                },
                inspectionDates: {
                  $addToSet: "$document.InspectionDate"
                },
                circuitsCount: {
                  $sum: 1
                }
              }
            },
            {
              $match: {
                circuitsCount: {
                  $gt: 1
                },
                circuits: {
                  $elemMatch: {
                    $eq: circuit.circuit
                  }
                }
              }
            }
          ],
          confirm: true
        };

        try {
          const duplicatesRes = await bulkGetAggregate(query);

          if (duplicatesRes.status === 200) {
            const duplicates = duplicatesRes.response.filter((pole) => {
              const year1 = new Date(pole.inspectionDates[0]).getFullYear();
              const year2 = new Date(pole.inspectionDates[1]).getFullYear();

              return year1 === year2;
            });

            if (duplicates.length > 0) {
              for (let i = 0; i < duplicates.length; i++) {
                query = {
                  collection: "Uploads",
                  filter: {
                    type: "poleInspection",
                    circuit: duplicates[i].circuits[1]
                  },
                  sort: {},
                  project: {
                    _id: 0,
                    fileName: 1,
                    circuit: 1,
                    createdBy: 1,
                    createdAt: 1
                  },
                  confirm: true
                };

                const uploadsRes = await bulkGet(query);

                if (uploadsRes.status === 200) {
                  duplicates[i]["uploads"] = uploadsRes.response;
                }
              }

              variables["duplicateGlnxy"] = duplicates;

              callStatus = false;
            } else {
              variables["duplicateGlnxy"] = [];
            }

            await updateCheckDuplicateGlnxyCheck(
              taskName,
              callStatus,
              variables
            );
          }
        } catch (e) {
          callStatus = false;
        }

        return callStatus;
      },
      checkRestorableReject: async (taskName) => {
        let callStatus = true;
        let fileName = "";

        const getFilename = await bulkGet({
          collection: "Uploads",
          filter: {
            type: taskName,
            circuit: circuit.circuit
          },
          project: {
            _id: 0,
            fileName: 1
          },
          confirm: true
        });

        if (getFilename.status === 200) {
          fileName = getFilename.response[0].fileName;

          try {
            // Get count
            const countResponse = await getCollectionCount({
              collection: "DataFiles",
              source: fileName,
              poleStatus: "Restorable Reject"
            });

            const variables = {
              restorableReject: countResponse.count > 0
            };

            await updateCheckRestorableRejectCheck(
              taskName,
              callStatus,
              variables
            );
          } catch (e) {
            callStatus = false;
          }
        } else {
          callStatus = false;
        }

        return callStatus;
      },
      checkImageIntegrity: async (taskName) => {
        let callStatus = true;
        let variables = {};
        const fieldsetAbbr = datasetAbbr[taskName];

        // Rules
        // PIT: AddRemoveUpdatePole = Remove -> image not required
        // PIT: ReasonPoleWasNotInspected <> Pole Was Inspected -> image not required
        // PIA: SkippedDueToAccess = Y -> image not required

        // Checks
        // No match for total images and poles
        // File name format - done!
        // Photo does not match a global id - don't pass - done!
        // Photos not required due to filter - passes - done!
        // Need photos uploaded - don't pass - done!

        try {
          // Get globalId from glnx-y
          const glnxyToGlobalId = await getGlnxyGlobalId(taskName, true);

          // Get list of uploaded zip files
          const uploadList = await getFieldsetUpload(taskName);

          // Collect defects
          const unMatchedImages = [];
          const badFormat = [];
          const notRequired = [];
          const neededPhotos = [];

          // Collect good files
          const goodGlnxys = [];

          // Check images in upload list
          uploadList.forEach((upload) => {
            upload.list.forEach((image) => {
              // Check file name format / PIT-147780-285695-1.jpg
              const regexStr = `${fieldsetAbbr}-\\d{6}-\\d{6}-\\d{1,2}.(jpg|jpeg)`;
              const regex = new RegExp(regexStr);
              const checkFormat = regex.test(image);

              if (!checkFormat) {
                badFormat.push(image);

                return;
              }

              // Check if image matches a pole
              const glnxyMatches = image.match(/(\d{6}-\d{6})/);

              if (glnxyMatches !== null) {
                const glnxy = glnxyMatches[0];
                const globalId = glnxyToGlobalId[glnxy]?.globalId;

                // Store good glnxy
                goodGlnxys.push(glnxy);

                if (!globalId) {
                  unMatchedImages.push(image);
                  return;
                }

                // Filter from data: AddRemoveUpdatePole
                let checkField = "AddRemoveUpdatePole";

                if (glnxyToGlobalId[glnxy][checkField]) {
                  if (glnxyToGlobalId[glnxy][checkField] === "Remove") {
                    notRequired.push({
                      Photo: image,
                      AddRemoveUpdatePole: glnxyToGlobalId[glnxy][checkField]
                    });
                  }
                }

                // Filter from data: ReasonPoleWasNotInspected
                checkField = "ReasonPoleWasNotInspected";

                if (glnxyToGlobalId[glnxy][checkField]) {
                  if (
                    glnxyToGlobalId[glnxy][checkField] !== "Pole Was Inspected"
                  ) {
                    notRequired.push({
                      Photo: image,
                      ReasonPoleWasNotInspected:
                        glnxyToGlobalId[glnxy][checkField]
                    });
                  }
                }

                // Filter from data: SkippedDueToAccess
                checkField = "SkippedDueToAccess";

                if (glnxyToGlobalId[glnxy][checkField]) {
                  if (glnxyToGlobalId[glnxy][checkField] === "Y") {
                    notRequired.push({
                      Photo: image,
                      SkippedDueToAccess: glnxyToGlobalId[glnxy][checkField]
                    });
                  }
                }
              }
            });
          });

          // Check poles with no photos
          Object.keys(glnxyToGlobalId).forEach((glnxy) => {
            if (!goodGlnxys.includes(glnxy)) {
              neededPhotos.push(glnxy);
            }
          });

          // File name bad format halts the task
          if (badFormat.length > 0) callStatus = false;

          // Unmatched photos halt the task
          if (unMatchedImages.length > 0) callStatus = false;

          // // Needed photos halt the task
          // if (neededPhotos.length > 0) callStatus = false;

          variables = {
            imageIntegrity: {
              badFormat,
              unMatchedImages,
              neededPhotos,
              notRequired
            }
          };

          await updateCheckImageIntegrity(taskName, callStatus, variables);
        } catch (e) {
          callStatus = false;
        }

        return callStatus;
      },
      purchaseOrderExists: async (taskName) => {
        let callStatus = true;
        let fieldset = null;

        switch (taskName) {
          case "poleInspectionInvoices":
            fieldset = "poleInspection";
            break;
          case "poleReinforcementInvoices":
            fieldset = "poleReinforcement";
            break;
          case "poleInspectionAuditInvoices":
            fieldset = "poleInspectionAudit";
            break;
          default:
          // Do nothing
        }

        // Set required to true
        const taskIndex = findTaskIndex(taskName, circuit.pipeline);
        const prevFailed = circuit.pipeline[taskIndex].variables.failed;

        // Get poles
        try {
          const res = await getDataFileEntries({
            page: 1,
            count: 10000,
            circuit: circuit.circuit,
            fieldset
          });

          // Get all POs from doc
          let purchaseOrders = res.map((doc) => {
            return doc.document["PO"];
          });

          // Remove duplicate POs
          purchaseOrders = [...new Set(purchaseOrders)];

          setTransferTotalRecords(purchaseOrders.length);

          const curFailed = [];
          const curResponses = [];
          const errors = [];
          let foundPo = null;

          // // REMOVE AFTER UAT
          // // Alternate purchase orders
          // const altPos = {
          //   4701487501: { alt: "4701410696" },
          //   4701488157: { alt: "4701375738" },
          //   4701487504: { alt: "4701415329" },
          //   4701522622: { alt: "4701390631" },
          //   4701522639: { alt: "4701390628" },
          //   4701513704: { alt: "4701378662" },
          //   4701513751: { alt: "4701378612" },
          //   4701513705: { alt: "4701378667" },
          //   4701513706: { alt: "4701378655" },
          //   4701513707: { alt: "4701391080" },
          //   4701513703: { alt: "4701391084" },
          //   4701513750: { alt: "4701390638" },
          //   4701495943: { alt: "4701379158" },
          //   4701487502: { alt: "4701406309" },
          //   4701515798: { alt: "4701390624" },
          //   4701515797: { alt: "4701378656" },
          //   4701515795: { alt: "4701391071" },
          //   4701515796: { alt: "4701394731" },
          //   4701512404: { alt: "4701394731" }
          // };

          for (let i = 0; i < purchaseOrders.length; i++) {
            setTransferProgress(i + 1);

            const purchaseOrder = purchaseOrders[i];

            // // REMOVE AFTER UAT
            // const purchaseOrder = altPos[purchaseOrders[i]]
            //   ? altPos[purchaseOrders[i]].alt
            //   : "";

            // Set doc to post
            let doc = null;

            if (prevFailed && prevFailed.length) {
              if (prevFailed.includes(purchaseOrder)) {
                doc = { po: purchaseOrder };
              }
            } else {
              doc = { po: purchaseOrder };
            }

            if (doc !== null) {
              try {
                const ponum = doc.po;
                const remark = "";

                // Hit po check endpoint
                const checkPoRes = await checkPoFake({
                  ponum,
                  remark
                });

                // Save response
                curResponses.push(checkPoRes.response);

                // Check app interface response
                if (checkPoRes.status === 200) {
                  const memberArray = checkPoRes.response.data["member"];

                  if (memberArray !== null) {
                    if (memberArray.length > 0) {
                      foundPo = {
                        ponum,
                        data: memberArray
                      };

                      break;
                    } else {
                      // Save failed ids
                      curFailed.push(purchaseOrder);
                    }
                  } else {
                    if (checkPoRes.response.data["Error"]) {
                      // Save error responses
                      errors.push({
                        PO: purchaseOrder,
                        status: checkPoRes.response.data["Error"]["statusCode"],
                        message: checkPoRes.response.data["Error"]["message"]
                      });
                    }
                  }
                } else {
                  // Save failed ids
                  curFailed.push(purchaseOrder);

                  // Save error responses
                  if (checkPoRes.response.data["Error"]) {
                    errors.push({
                      PO: purchaseOrder,
                      status: checkPoRes.response.data["Error"]["statusCode"],
                      message: checkPoRes.response.data["Error"]["message"]
                    });
                  } else {
                    errors.push({
                      PO: purchaseOrder,
                      status: checkPoRes.status,
                      message: checkPoRes.response.data
                    });
                  }
                }
              } catch (e) {
                // Save failed ids
                curFailed.push(purchaseOrder);

                // Save error responses
                errors.push({
                  PO: purchaseOrder,
                  status: 500,
                  message: e.message
                });
              }
            }
          }

          callStatus = foundPo !== null ? true : false;

          // Clean up
          let variables = null;
          if (callStatus) {
            variables = {
              PO: foundPo,
              failed: [],
              errors: [],
              responses: []
            };
          } else {
            variables = {
              PO: foundPo,
              failed: curFailed,
              errors,
              responses: curResponses
            };
          }

          await updatePurchaseOrderExistsCheck(taskName, callStatus, variables);
        } catch (e) {
          console.log("Error gathering poles: ", e.message);

          callStatus = false;
        }

        return callStatus;
      },
      missingGlobalIds: async (taskName) => {
        let callStatus = true;
        const variables = {};

        try {
          const query = {
            collection: "DataFiles",
            filter: {
              fieldset: "poleInspection",
              circuit: circuit.circuit,
              "document.GlobalID": ""
            },
            sort: {},
            project: {
              _id: 0,
              dataPointId: 1,
              "document.PoleTagClass": 1,
              "document.PoleTagInstallationYear": 1,
              "document.PoleTagLength": 1,
              "document.PoleTagOwner": 1,
              "document.PoleTagSpeciesTreatmentCode": 1,
              "document.StructureType": 1
            },
            confirm: true
          };

          const res = await bulkGet(query);

          if (res.status === 200) {
            const poles = res.response;

            if (poles.length) {
              callStatus = false;

              variables["missingGlobalIds"] = poles;
            } else {
              callStatus = true;

              variables["missingGlobalIds"] = [];
            }

            await updateMissingGlobalIdsCheck(taskName, callStatus, variables);
          } else {
            callStatus = false;
          }
        } catch (e) {
          callStatus = false;
        }

        return callStatus;
      },
      invalidGlobalIds: async (taskName) => {
        // UC-1: Invalid Global Id Check
        let callStatus = true;

        const loadTestObj = new ApiLoadTest([
          "Global Id",
          "Payload (bytes)",
          "Status code",
          "Response time (ms)",
          "Timestamp"
        ]);

        // Find task in pipeline
        const taskIndex = findTaskIndex(taskName, circuit.pipeline);

        // Get previous state queues
        const prevRecords = circuit.pipeline[taskIndex].variables.records ?? 0;
        const prevSuccess = circuit.pipeline[taskIndex].variables.success ?? [];
        const prevInvalid = circuit.pipeline[taskIndex].variables.invalid ?? [];

        // Start progress bar
        setIsTransferring(true);
        setTransferProgressTitle("Requesting Global Id Validation");

        const variables = {
          records: prevRecords,
          invalid: prevInvalid,
          success: prevSuccess,
          failed: [],
          errors: [],
          responses: [],
          payloads: [],
          loadtest: []
        };

        const removeFromQueue = (queue, item) => {
          let arr = [];

          for (let i = 0; i < queue.length; i++) {
            if (item !== queue[i]) arr.push(queue[i]);
          }

          return arr;
        };

        // Check if there are more records to try
        if (prevSuccess.length === 0 || prevRecords > prevSuccess.length) {
          try {
            const query = {
              collection: "DataFiles",
              filter: {
                fieldset: "poleInspection",
                circuit: circuit.circuit
              },
              sort: {},
              project: {
                _id: 0,
                "document.GlobalID": 1,
                "document.GLNX-GLNY": 1
              },
              confirm: true
            };

            const res = await bulkGet(query);

            if (res.status === 200) {
              const poles = res.response;

              // Save total number of records
              variables.records = poles.length;

              // Calculate total records to try
              const recordsToTry = poles.length - prevSuccess.length;

              // Reset progress
              setTransferProgress(0);
              setTransferTotalRecords(recordsToTry);

              // Set progress counter
              let counter = 0;

              if (poles.length) {
                for (let i = 0; i < poles.length; i++) {
                  const pole = poles[i];
                  const globalId = pole.document["GlobalID"];
                  const [GLNX, GLNY] = pole.document["GLNX-GLNY"].split("-");

                  // Set doc to post
                  let doc = {
                    GlobalId: globalId,
                    GLNX: parseInt(GLNX),
                    GLNY: parseInt(GLNY)
                  };

                  // Check for pending requests to try
                  if (prevSuccess && prevSuccess.length) {
                    if (prevSuccess.includes(globalId)) doc = null;
                  }

                  if (doc !== null) {
                    counter += 1;

                    setTransferProgress(counter);

                    try {
                      // Delay between calls
                      // await delay(delayBetCalls);
                      await delay(0);

                      // Remove later
                      variables.payloads.push(doc);

                      // Get payload size
                      const payloadSize = new Blob([JSON.stringify(doc)]).size;

                      // Set load test benchmark
                      loadTestObj.setBenchmark(getTimestamp());

                      const esbRes = await postToEsbInvGlobalId(doc);

                      // Remove later
                      variables.responses.push(esbRes.response);

                      // Push load test entry
                      loadTestObj.pushEntry({
                        key: globalId,
                        payload: payloadSize,
                        status: esbRes.status,
                        timestamp: getTimestamp()
                      });

                      // Save failed record
                      if (esbRes.status !== 202) {
                        // Save failed request
                        variables.failed.push(globalId);

                        // Save response errors
                        variables.errors.push({
                          status: esbRes.status,
                          ...esbRes.response
                        });

                        callStatus = false;

                        // Exit on 401
                        if (esbRes.status === 401) {
                          break;
                        }

                        // Exit on 400
                        if (esbRes.status === 400) {
                          break;
                        }

                        // Exit on 500
                        if (esbRes.status === 500) {
                          break;
                        }
                      } else {
                        // Manage invalid queue
                        if (!esbRes.response.valid) {
                          if (!variables.invalid.includes(globalId)) {
                            // Add to invalid queue
                            variables.invalid.push(globalId);

                            // Log error
                            variables.errors.push({
                              globalId,
                              payload: doc,
                              response: esbRes.response
                            });
                          }
                        } else {
                          // Save success request
                          variables.success.push(globalId);

                          // Remove from invalid queue
                          if (variables.invalid.includes(globalId)) {
                            variables.invalid = removeFromQueue(
                              variables.invalid,
                              globalId
                            );
                          }
                        }
                      }
                    } catch (e) {
                      // Save failed ids
                      variables.failed.push(globalId);

                      // Save response errors
                      variables.errors.push(e.message);

                      callStatus = false;
                    }
                  }
                }

                // Print report in console
                variables.loadtest = loadTestObj.report();

                // Reset progress
                setTransferProgress(0);
                setTransferTotalRecords(0);

                // Check passed or not
                if (variables.records === variables.success.length) {
                  callStatus = true;
                } else {
                  callStatus = false;
                }

                await updateInvalidGlobalIdsCheck(
                  taskName,
                  callStatus,
                  variables
                );
              } else {
                callStatus = true;
              }
            } else {
              callStatus = false;
            }
          } catch (e) {
            callStatus = false;
          }
        } else {
          callStatus = false;
        }

        // Stop progress bar
        setIsTransferring(false);

        return callStatus;
      },
      pullReinforcedPoles: async (taskName) => {
        let callStatus = true;
        let successfulInserts = 0;

        const taskIndex = findTaskIndex(taskName, circuit.pipeline);

        const prevFailed = circuit.pipeline[taskIndex].variables.errors;
        const curFailed = [];
        const errors = [];

        const extractYear = (date) => {
          let parts = [];

          // Try dashes
          parts = date.split("-");
          if (parts.length === 3) return parts[0];

          // Try forward slashes
          parts = date.split("/");
          if (parts.length === 3) return parts[2];

          return "";
        };

        try {
          // Get poles
          const resSource = await getSource("poleReinforcementAudit");

          // Filter fields
          const fields = resSource.fields.map((field) => {
            return field.replace("_RIA", "");
          });

          // Get poles from pole inspection
          const resPit = await getDataFileEntries({
            page: 1,
            count: 10000,
            circuit: circuit.circuit,
            fieldset: "poleInspection"
          });

          // Collect pit data for overrides
          const pitOverride = {};
          resPit.forEach((doc) => {
            pitOverride[doc.dataPointId] = {
              InspectionTagType: doc.document.InspectionTagType,
              InspectionTagYear: extractYear(doc.document.InspectionDate)
            };
          });

          // Get poles from pole reinforcement
          const resRip = await bulkGet({
            collection: "DataFiles",
            filter: {
              circuit: circuit.circuit,
              fieldset: "poleReinforcement",
              "document.TrussInstalledSize": {
                $ne: "Truss Not Installed"
              }
            },
            sort: {},
            project: {
              _id: 0
            },
            confirm: true
          });

          // Get existing reinforcement audit docs
          const resRia = await bulkGet({
            collection: "DataFiles",
            filter: {
              circuit: circuit.circuit,
              fieldset: "poleReinforcementAudit"
            },
            sort: {},
            project: {
              _id: 0,
              dataPointId: 1
            },
            confirm: true
          });

          // Gather existing dataPointIds
          let existingDataPointIds = [];
          if (resRia.status === 200) {
            existingDataPointIds = resRia.response.map(
              (doc) => doc.dataPointId
            );
          }

          let poles = [];

          if (resRip.status === 200) {
            // Build pole data
            for (let i = 0; i < resRip.response.length; i++) {
              const doc = resRip.response[i];

              const defValues = {};

              // If existing inspection: update approved and inspections props
              const updateRiaRes = await getRipInspectionsAndApproval(
                doc.dataPointId,
                doc.circuit
              );

              // Flag ignore pole if reinforcement date is empty
              const reinforcementDate = doc.document["ReinforcementDate"];

              // Buid RIA record
              const record = {
                dataPointId: doc.dataPointId,
                status: updateRiaRes.status,
                source: doc.source.replace("_RIP_", "_RIA_"),
                fieldset: "poleReinforcementAudit",
                suffix: "RIA",
                contentType: "text/csv",
                map: doc.map,
                circuit: doc.circuit,
                GLNX: doc.GLNX,
                GLNY: doc.GLNY,
                document: updateRiaRes.document,
                parsedBy: userInfo,
                parsedAt: getTimestamp(),
                inspections: updateRiaRes.inspections,
                media: null,
                approved: updateRiaRes.approved,
                ignore: reinforcementDate === "",
                notifiedReinspection: updateRiaRes.notifiedReinspection,
                version: CUR_VERSION
              };

              // RIP default values not in RIA
              const ripDefFields = [
                {
                  find: "RetreatmentForVoids",
                  replace: "VoidTreatmentTagAttached"
                },
                {
                  find: "PO",
                  replace: "PO"
                },
                {
                  find: "LINENUM",
                  replace: "LINENUM"
                }
              ];
              ripDefFields.forEach(
                (field) =>
                  (defValues[field.replace] = doc.document[field.find] ?? null)
              );

              // Define key fields to populate
              // These are values that are not provided
              // by the inspector during the survey
              const keyFields = [
                "GlobalID",
                "GLNX-GLNY",
                "OHPrimaryCircuitNumber"
              ];

              // Reproduce document if empty
              if (isObjectEmpty(record.document)) {
                fields.forEach((field) => {
                  record.document[field] = null;

                  if (doc.document[field]) {
                    defValues[field] = doc.document[field];
                  }

                  if (pitOverride[doc.dataPointId][field]) {
                    defValues[field] = pitOverride[doc.dataPointId][field];
                  }

                  // Populate key fields
                  if (keyFields.includes(field) && defValues[field]) {
                    record.document[field] = defValues[field];
                  }
                });

                record.document["default"] = defValues;
              }

              poles.push(record);
            }
          } else {
            // Save error responses
            errors.push({
              status: resRip.status,
              message: "Failed gathering RIP data..."
            });
          }

          // Transfer pole data to db
          setTransferTotalRecords(poles.length);

          for (let i = 0; i < poles.length; i++) {
            const pole = poles[i];

            // Set new document to post
            let newDoc = null;

            if (prevFailed && prevFailed.length > 0) {
              if (prevFailed.includes(pole.dataPointId)) {
                newDoc = pole;
              }
            } else {
              newDoc = pole;
            }

            // Prevent inserting doc again
            if (existingDataPointIds.includes(newDoc.dataPointId)) {
              newDoc = null;
            }

            if (newDoc !== null) {
              try {
                const res = await createDataFileEntry(newDoc);

                // Save failed record
                if (res.status === 200) {
                  successfulInserts += 1;

                  setTransferProgress(successfulInserts);
                } else {
                  // Save failed ids
                  curFailed.push(newDoc.dataPointId);

                  // Save error responses
                  errors.push({
                    pole: newDoc.dataPointId,
                    status: res.status,
                    message: res.response
                  });
                }
              } catch (e) {
                // Save failed ids
                curFailed.push(newDoc.dataPointId);

                // Save error responses
                errors.push({
                  pole: newDoc.dataPointId,
                  status: 500,
                  message: e.message
                });

                // Wait for 1 second
                await delay(1000);
              }

              await delay(100);
            }
          }

          // Check success
          callStatus = curFailed.length > 0 ? false : true;

          let variables = {};

          if (callStatus) {
            variables = { errors: [], failed: [] };
          } else {
            variables = { errors, failed: curFailed };
          }

          await updatePullReinforcedPolesCheck(taskName, callStatus, variables);
        } catch (e) {
          callStatus = false;
        }

        return callStatus;
      },
      loadInspections: async (taskName) => {
        let callStatus = true;

        const variables = {};
        const errors = [];

        // Control variables
        let polesToInspect = [];
        let polesToReinspect = {};
        let inspectionsToApprove = 0;

        const res = [];

        try {
          // Check for non-approved ria poles
          res["getPoles"] = await getNonApprovedPolesInCircuit(circuit.circuit);

          if (res["getPoles"].status === 200) {
            res["getPoles"].response.forEach((pole) => {
              const inspections = Array.isArray(pole.inspections)
                ? pole.inspections.length
                : pole.inspections;

              if (inspections === 0) {
                polesToInspect.push(pole.dataPointId);
              } else {
                if (pole.status === "reinspect") {
                  polesToReinspect[pole.dataPointId] = {
                    notified: pole.notifiedReinspection
                  };
                }
              }
            });

            if (
              polesToInspect.length > 0 ||
              Object.keys(polesToReinspect).length > 0
            ) {
              callStatus = false;
            }
          } else {
            // Save error responses
            errors.push({
              circuit: circuit.circuit,
              status: res["getInspections"].status,
              message: res["getInspections"].response
            });

            callStatus = false;
          }

          // Check for non-approved inspections
          res["getInspections"] = await getLastInspections(circuit.circuit);

          if (res["getInspections"].status === 200) {
            variables["inspections"] = res["getInspections"].response.filter(
              (item) => item.status !== "reinspect"
            );

            inspectionsToApprove = variables["inspections"].length;
          } else {
            // Save error responses
            errors.push({
              circuit: circuit.circuit,
              status: res["getInspections"].status,
              message: res["getInspections"].response
            });

            callStatus = false;
          }
        } catch (e) {
          // Save error responses
          errors.push({
            circuit: circuit.circuit,
            status: 500,
            message: e.message
          });

          callStatus = false;
        }

        // Carry over errors
        if (errors.length) variables["errors"] = errors;

        // Carry over control variables
        variables["polesToInspect"] = polesToInspect;
        variables["polesToReinspect"] = polesToReinspect;
        variables["inspectionsToApprove"] = inspectionsToApprove;
        variables["lastUpdate"] = getNewTimestamp();

        await updateGetInspectionsCheck(taskName, callStatus, variables);

        return callStatus;
      },
      approveRelease: async (taskName) => {
        let callStatus = true;

        const variables = { errors: [] };

        try {
          const query = {
            collection: "DataFiles",
            filter: {
              circuit: circuit.circuit,
              fieldset: "poleReinforcementAudit"
            },
            update: {
              approvedBy: userInfo,
              approvedAt: getNewTimestamp()
            },
            confirm: true
          };

          const res = await bulkUpdate(query);

          if (res.status !== 200) {
            // Save error responses
            variables.errors.push({
              status: res.status,
              message: "Error while updating database..."
            });

            callStatus = false;
          }
        } catch (e) {
          // Save error responses
          variables.errors.push({
            status: 500,
            message: e.message
          });

          callStatus = false;
        }

        await updateApproveReleaseCheck(taskName, callStatus, variables);

        return callStatus;
      },
      formatValidation: async (taskName) => {
        let callStatus = true;
        const variables = {
          sources: [],
          errorsFormat: []
        };
        const errors = [];
        const statsKeys = {};

        const reduceArray = (arr) => {
          const uniqueSet = new Set(arr);

          return [...uniqueSet];
        };

        const findDuplicatesInArray = (arr) => {
          return arr.reduce((acc, el, i, arr) => {
            if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el);
            return acc;
          }, []);
        };

        const collectKeysValues = (fieldset, collection) => {
          if (!statsKeys[fieldset])
            statsKeys[fieldset] = {
              keys: {},
              errors: [],
              count: 0
            };

          // Count data points
          statsKeys[fieldset]["count"] += 1;

          for (const key in collection) {
            if (statsKeys[fieldset]["keys"][key]) {
              statsKeys[fieldset]["keys"][key].push(collection[key]);
            } else {
              statsKeys[fieldset]["keys"][key] = [collection[key]];
            }
          }
        };

        // Start progress bar
        setIsTransferring(true);

        const fieldDefinitions = await getFieldDefinitionsBySource(
          circuit.circuit,
          setTransferProgressTitle,
          setTransferTotalRecords,
          setTransferProgress
        );

        const circuitData = await getCircuitData(
          circuit.circuit,
          setTransferProgressTitle,
          setTransferTotalRecords,
          setTransferProgress
        );

        await delay(1000);

        // Stop progress bar
        setIsTransferring(false);

        for (const rowIndex in circuitData) {
          const row = circuitData[rowIndex];
          const rowDefinitions = fieldDefinitions[row.fieldset];
          const toValidate = {
            circuit: row.document["OHPrimaryCircuitNumber"],
            fieldset: row.fieldset,
            glnxy: row.document["GLNX-GLNY"],
            data: row.document,
            definitions: rowDefinitions
          };

          const results = validateFormat(toValidate);

          // Collect errors
          if (results.errors.length > 0) errors.push(...results.errors);

          // Collect unique key values
          collectKeysValues(row.fieldset, results.statsKeys);
        }

        // Calculate stats
        for (const fieldset in statsKeys) {
          for (const statsKey in statsKeys[fieldset]["keys"]) {
            switch (statsKey) {
              case "GLNX-GLNY":
                // Find duplicates
                statsKeys[fieldset]["keys"][statsKey] = findDuplicatesInArray(
                  statsKeys[fieldset]["keys"][statsKey]
                );

                // Log error
                if (statsKeys[fieldset]["keys"][statsKey].length > 0) {
                  errors.push({
                    type: "semantic",
                    msg: `${statsKey} values appear more than once in ${phraseToProperCase(
                      fieldset
                    )} data`,
                    data: statsKeys[fieldset]["keys"][statsKey]
                  });
                }
                break;
              case "GlobalID":
                // Find duplicates
                statsKeys[fieldset]["keys"][statsKey] = findDuplicatesInArray(
                  statsKeys[fieldset]["keys"][statsKey]
                );

                // Log error
                if (statsKeys[fieldset]["keys"][statsKey].length > 0) {
                  errors.push({
                    type: "semantic",
                    msg: `${statsKey} values appear more than once in ${phraseToProperCase(
                      fieldset
                    )} data`,
                    data: statsKeys[fieldset]["keys"][statsKey]
                  });
                }
                break;
              case "OHPrimaryCircuitNumber":
                // Get distinct values
                statsKeys[fieldset]["keys"][statsKey] = reduceArray(
                  statsKeys[fieldset]["keys"][statsKey]
                );

                // Log error
                if (statsKeys[fieldset]["keys"][statsKey].length > 1) {
                  errors.push({
                    type: "semantic",
                    msg: `More ${statsKey} distinct values than expected in ${phraseToProperCase(
                      fieldset
                    )} data`,
                    data: statsKeys[fieldset]["keys"][statsKey]
                  });
                }
                break;
              case "PO":
                // Get distinct values
                statsKeys[fieldset]["keys"][statsKey] = reduceArray(
                  statsKeys[fieldset]["keys"][statsKey]
                );

                // Log error
                if (statsKeys[fieldset]["keys"][statsKey].length > 1) {
                  errors.push({
                    type: "semantic",
                    msg: `More ${statsKey} distinct values than expected in ${phraseToProperCase(
                      fieldset
                    )} data`,
                    data: statsKeys[fieldset]["keys"][statsKey]
                  });
                }
                break;
              default:
              // do nothing
            }
          }
        }

        // Check data point counts
        const pitCount = statsKeys?.poleInspection?.count;
        const ptpCount = statsKeys?.poletopInspection?.count;
        if (ptpCount) {
          if (pitCount !== ptpCount) {
            errors.push({
              type: "semantic",
              msg: "Data point counts don't match",
              data: {
                "Pole Inspection": pitCount,
                "Poletop Inspection": ptpCount
              }
            });
          }
        }

        variables["sources"] = Object.keys(fieldDefinitions);
        variables["errorsFormat"] = errors;
        variables["stats"] = statsKeys;

        if (errors.length > 0) callStatus = false;

        await updateFormatValidationCheck(taskName, callStatus, variables);

        return callStatus;
      },
      engineeringStandardsCompliance: async (taskName) => {
        let callStatus = true;

        const variables = { errorsStandards: [] };
        const errors = [];
        const groupedErrors = {};

        const sources = task?.variables?.sources;

        // Check for sources from previous check
        if (Array.isArray(sources) && sources.length > 0) {
          // Start progress bar
          setIsTransferring(true);

          const engStandards = await getEngStandardsBySource(
            sources,
            setTransferProgressTitle,
            setTransferTotalRecords,
            setTransferProgress
          );

          const circuitData = await getCircuitData(
            circuit.circuit,
            setTransferProgressTitle,
            setTransferTotalRecords,
            setTransferProgress
          );

          await delay(1000);

          // Stop progress bar
          setIsTransferring(false);

          // Test compliance
          for (const index in circuitData) {
            const doc = circuitData[index];
            const fieldset = doc.fieldset;
            const data = doc.document;
            const rules = engStandards[fieldset];

            const test = runComplexRules(data, rules, fieldset);

            if (!test.passed)
              errors.push({
                pole: test.pole,
                errors: test.errors
              });
          }

          for (let index in errors) {
            const error = errors[index];
            const fieldset = error.pole.fieldset;

            if (!groupedErrors[error.pole.GlobalID])
              groupedErrors[error.pole.GlobalID] = {
                GlobalID: error.pole.GlobalID,
                "GLNX-GLNY": error.pole["GLNX-GLNY"],
                errors: {}
              };

            if (!groupedErrors[error.pole.GlobalID].errors[fieldset])
              groupedErrors[error.pole.GlobalID].errors[fieldset] =
                error.errors;
          }
        } else {
          errors.push({
            type: "semantic",
            msg: "No sources found from previous check",
            data: null
          });

          callStatus = false;
        }

        variables["errorsStandards"] = groupedErrors;

        // // Remove later
        // callStatus = false;

        await updateEngStandardsComplianceCheck(
          taskName,
          callStatus,
          variables
        );

        return callStatus;
      },
      runAudit: async (taskName) => {
        const callStatus = true;

        await updateRunAuditCheck(taskName, callStatus);

        return callStatus;
      },
      circuitAuditApproval: async (taskName) => {
        const callStatus = true;

        await updateCircuitAuditApprovalCheck(taskName, callStatus);

        return callStatus;
      },
      stageDataToDataLake: async (taskName) => {
        let callStatus = true;

        const aliasIndex = "EDA";

        try {
          // Get poles
          const resFields = await getFields({
            page: 1,
            count: 10000,
            alias: aliasIndex
          });

          const fields = getFieldMapping(resFields, aliasIndex);

          const variables = { fields };

          await updateStageDataToDataLakeCheck(taskName, callStatus, variables);
        } catch (e) {
          callStatus = false;
        }

        return callStatus;
      },
      transferDataToDataLake: async (taskName) => {
        let callStatus = true;

        // Set required to true
        const taskIndex = findTaskIndex(taskName, circuit.pipeline);

        const fields = circuit.pipeline[taskIndex].variables.fields;
        const prevFailed = circuit.pipeline[taskIndex].variables.errors;

        // Set load test array
        const doLoadTest = true;
        let loadtest = [];
        let benchmarkStart = 0;
        let responseTime = 0;
        let loadtestEntry = "";

        try {
          // Get records for circuit
          const sourceDocs = await getDataFileEntries({
            page: 1,
            count: 10000,
            circuit: circuit.circuit
          });

          // Merge pole data
          const records = mergePoleData(sourceDocs);

          // Get support data for metadata
          const supportRecords = supportPoleData(sourceDocs);

          const dataPointIds = Object.keys(records);

          setTransferTotalRecords(dataPointIds.length);

          const curFailed = [];
          const curResponses = []; // Remove later
          const postedDocs = []; // Remove later
          const errors = [];
          const primaryKey = "GLNX-GLNY";

          let dynWaitTime = delayBetCalls;

          // Show load test entry in console
          if (doLoadTest) {
            console.log(`Test start: ${circuit.circuit}`, getTimestamp());

            loadtest.push(loadtestHeaders.join(","));
          }

          for (let i = 0; i < dataPointIds.length; i++) {
            setTransferProgress(i + 1);

            const dataPointId = dataPointIds[i];

            // Set doc to post
            let doc = null;
            let supportDoc = null;

            if (prevFailed && prevFailed.length) {
              if (prevFailed.includes(dataPointId)) {
                doc = records[dataPointId];
                supportDoc = supportRecords[dataPointId];
              }
            } else {
              doc = records[dataPointId];
              supportDoc = supportRecords[dataPointId];
            }

            if (doc !== null) {
              // Custom doc
              const newDoc = mapDoc(doc, fields);

              // Get payload size
              const payloadSize = new Blob([JSON.stringify(newDoc)]).size;

              if (newDoc[primaryKey] !== "") {
                // Produce metadata
                const metadata = getOperRecMdEda(doc, supportDoc);

                try {
                  // Delay between calls
                  await delay(dynWaitTime);

                  // Start benchmark
                  if (doLoadTest) benchmarkStart = getTimestamp();

                  // Hit ESB endpoint
                  const edaRes = await postDataToEda({
                    data: newDoc,
                    metadata
                  });

                  // Finish benchmark
                  if (doLoadTest)
                    responseTime = getTimestamp() - benchmarkStart;

                  // Remove later
                  postedDocs.push({ data: newDoc, metadata });

                  // Remove later
                  curResponses.push(edaRes.response);

                  // Show load test entry in console
                  if (doLoadTest) {
                    loadtestEntry = `"${dataPointId}",${payloadSize},${edaRes.status},${responseTime},${benchmarkStart}`;

                    loadtest.push(loadtestEntry);

                    console.log(circuit.circuit, loadtestEntry);
                  }

                  // Save failed record
                  if (edaRes.status !== 200) {
                    // Save failed ids
                    curFailed.push(dataPointId);

                    // Save error responses
                    errors.push({
                      file: dataPointId,
                      status: edaRes.status,
                      message: edaRes.response.data
                    });
                  }
                } catch (e) {
                  // Show load test entry in console
                  if (doLoadTest) {
                    responseTime = getTimestamp() - benchmarkStart;

                    // Add request data to load test
                    const loadtestEntry = `"${dataPointId}",${payloadSize},500,${responseTime},${benchmarkStart}`;

                    loadtest.push(loadtestEntry);

                    console.log(loadtestEntry);
                  }

                  // Save failed ids
                  curFailed.push(dataPointId);

                  // Save error responses
                  errors.push({
                    file: dataPointId,
                    status: 500,
                    message: e.message
                  });
                }
              }
            }
          }

          // Show load test entry in console
          if (doLoadTest)
            console.log(`Test finish: ${circuit.circuit}`, getTimestamp());

          // Check success
          callStatus = curFailed.length > 0 ? false : true;

          // Store or clean up results
          let variables = null;
          if (callStatus) {
            variables = {
              failed: [],
              errors: [],
              responses: []
            };
          } else {
            variables = {
              failed: curFailed,
              errors: errors,
              responses: curResponses
            };
          }

          // // Remove later
          // if (postedDocs.length > 0) variables["docs"] = postedDocs;

          // Store load test
          if (doLoadTest && loadtest.length > 0)
            variables["loadtest"] = loadtest;

          // Unstage fields when finished
          if (callStatus) variables["fields"] = [];

          await updateTransferDataToDataLakeCheck(
            taskName,
            callStatus,
            variables
          );
        } catch (e) {
          callStatus = false;
        }

        return callStatus;
      },
      transferArtifactsToDataLake: async (taskName) => {
        let callStatus = true;

        // Set required to true
        const taskIndex = findTaskIndex(taskName, circuit.pipeline);

        const prevFailed = circuit.pipeline[taskIndex].variables.errors;

        // Set load test array
        const doLoadTest = true;
        let loadtest = [];
        let benchmarkStart = 0;
        let responseTime = 0;
        let loadtestEntry = "";

        try {
          // Get uploads for circuit
          const uploadList = await getUploads({
            page: 1,
            count: 100,
            circuit: circuit.circuit
          });

          const uploads = uploadList.length;

          setTransferTotalRecords(uploads);

          const curFailed = [];
          const curResponses = []; // Remove later
          const postedDocs = []; // Remove later
          const errors = [];

          let dynWaitTime = delayBetCalls;

          // Show load test entry in console
          if (doLoadTest) {
            console.log(`Test start: ${circuit.circuit}`, getTimestamp());

            loadtest.push(loadtestHeaders.join(","));
          }

          for (let i = 0; i < uploads; i++) {
            setTransferProgress(i + 1);

            const upload = uploadList[i];

            // Set doc to post
            let doc = null;

            if (prevFailed && prevFailed.length) {
              if (prevFailed.includes(upload.fileName)) {
                doc = upload;
              }
            } else {
              doc = upload;
            }

            if (doc !== null) {
              // Get payload
              const metadata = getCsvMdEda(doc);

              // Get payload size
              const payloadSize = new Blob([JSON.stringify(metadata)]).size;

              try {
                // Delay between calls
                await delay(dynWaitTime);

                // Start benchmark
                if (doLoadTest) benchmarkStart = getTimestamp();

                // Hit ESB endpoint
                const edaRes = await postCsvToEda(doc.fileName, metadata);

                // Finish benchmark
                if (doLoadTest) responseTime = getTimestamp() - benchmarkStart;

                // Remove later
                postedDocs.push(metadata);

                // Remove later
                curResponses.push(edaRes.response);

                // Show load test entry in console
                if (doLoadTest) {
                  loadtestEntry = `"${doc.fileName}",${payloadSize},${edaRes.status},${responseTime},${benchmarkStart}`;

                  loadtest.push(loadtestEntry);

                  console.log(circuit.circuit, loadtestEntry);
                }

                // Save failed record
                if (edaRes.status !== 201) {
                  // Save failed ids
                  curFailed.push(doc.fileName);

                  // Save error responses
                  errors.push({
                    file: doc.fileName,
                    status: edaRes.status,
                    message: edaRes.response.data
                  });
                }
              } catch (e) {
                // Show load test entry in console
                if (doLoadTest) {
                  responseTime = getTimestamp() - benchmarkStart;

                  // Add request data to load test
                  const loadtestEntry = `"${doc.fileName}",${payloadSize},500,${responseTime},${benchmarkStart}`;

                  loadtest.push(loadtestEntry);

                  console.log(loadtestEntry);
                }

                // Save failed ids
                curFailed.push(doc.fileName);

                // Save error responses
                errors.push({
                  file: doc.fileName,
                  status: 500,
                  message: e.message
                });
              }
            }
          }

          // Show load test entry in console
          if (doLoadTest)
            console.log(`Test finish: ${circuit.circuit}`, getTimestamp());

          // Check success
          callStatus = curFailed.length > 0 ? false : true;

          // Store or clean up results
          let variables = null;
          if (callStatus) {
            variables = {
              failed: [],
              errors: [],
              responses: []
            };
          } else {
            variables = {
              failed: curFailed,
              errors: errors,
              responses: curResponses
            };
          }

          // // Remove later
          // if (postedDocs.length > 0) variables["docs"] = postedDocs;

          // Store load test
          if (doLoadTest && loadtest.length > 0)
            variables["loadtest"] = loadtest;

          await updateTransferArtifactsToDataLakeCheck(
            taskName,
            callStatus,
            variables
          );
        } catch (e) {
          callStatus = false;

          console.log(e.message);
        }

        return callStatus;
      },
      transferImagesToDataLake: async (taskName) => {
        let callStatus = true;

        // Set required to true
        const taskIndex = findTaskIndex(taskName, circuit.pipeline);

        const prevFailed = circuit.pipeline[taskIndex].variables.errors;

        // Set load test array
        const doLoadTest = true;
        let loadtest = [];
        let benchmarkStart = 0;
        let responseTime = 0;
        let loadtestEntry = "";

        try {
          // Get globalId from glnx-y
          const glnxyToGlobalId = await getGlnxyGlobalId("poleInspection");

          // Get list of uploaded zip files
          const uploadList = await getUploadList();

          // Collect missing global ids
          const missingGlobalIds = {};

          // Generate metadata
          const uploadMetadata = uploadList.map((upload) => {
            const missing = [];

            const contents = upload.list.map((item) => {
              const glnxy = item.match(/(\d{6}-\d{6})/)[0];
              const globalId = glnxyToGlobalId[glnxy];

              if (!globalId) missing.push(glnxy);

              return {
                PhotoFilename: item,
                GlobalID: globalId ?? "",
                "GLNX-GLNY": glnxy,
                OHPrimaryCircuitNumber: circuit.circuit
              };
            });

            if (missing.length > 0)
              missingGlobalIds[upload.fileName] = [...new Set(missing)];

            return {
              ZipFilename: upload.fileName,
              Contents: contents
            };
          });

          // Set important counts
          let uploads = uploadMetadata.length;
          const filesWithMissingGlobalIds = Object.keys(missingGlobalIds);

          // Halt if missing global ids
          if (filesWithMissingGlobalIds.length > 0) uploads = 0;

          setTransferTotalRecords(uploads);

          const curFailed = [];
          const curResponses = []; // Remove later
          const postedDocs = []; // Remove later
          const errors = [];

          let dynWaitTime = delayBetCalls;

          // Show load test entry in console
          if (doLoadTest) {
            console.log(`Test start: ${circuit.circuit}`, getTimestamp());

            loadtest.push(loadtestHeaders.join(","));
          }

          for (let i = 0; i < uploads; i++) {
            setTransferProgress(i + 1);

            const upload = uploadMetadata[i];

            // Set doc to post
            let doc = null;

            // Check to retry failed calls
            if (prevFailed && prevFailed.length) {
              if (prevFailed.includes(upload.ZipFilename)) {
                doc = upload;
              }
            } else {
              doc = upload;
            }

            if (doc !== null) {
              // Get payload size
              const payloadSize = new Blob([JSON.stringify(doc)]).size;

              try {
                // Delay between calls
                await delay(dynWaitTime);

                // Start benchmark
                if (doLoadTest) benchmarkStart = getTimestamp();

                // Hit ESB endpoint
                const edaRes = await postZipToEda(doc.ZipFilename, doc);

                // Finish benchmark
                if (doLoadTest) responseTime = getTimestamp() - benchmarkStart;

                // Remove later
                postedDocs.push(doc);

                // Remove later
                curResponses.push(edaRes.response);

                // Show load test entry in console
                if (doLoadTest) {
                  loadtestEntry = `"${doc.ZipFilename}",${payloadSize},${edaRes.status},${responseTime},${benchmarkStart}`;

                  loadtest.push(loadtestEntry);

                  console.log(circuit.circuit, loadtestEntry);
                }

                // Save failed record
                if (edaRes.status !== 201) {
                  // Save failed ids
                  curFailed.push(doc.ZipFilename);

                  // Save error responses
                  errors.push({
                    file: doc.ZipFilename,
                    status: edaRes.status,
                    message: edaRes.response.data
                  });
                }
              } catch (e) {
                // Show load test entry in console
                if (doLoadTest) {
                  responseTime = getTimestamp() - benchmarkStart;

                  // Add request data to load test
                  const loadtestEntry = `"${doc.ZipFilename}",${payloadSize},500,${responseTime},${benchmarkStart}`;

                  loadtest.push(loadtestEntry);

                  console.log(loadtestEntry);
                }

                // Save failed ids
                curFailed.push(doc.ZipFilename);

                // Save error responses
                errors.push({
                  file: doc.ZipFilename,
                  status: 500,
                  message: e.message
                });
              }
            }
          }

          // Show load test entry in console
          if (doLoadTest)
            console.log(`Test finish: ${circuit.circuit}`, getTimestamp());

          // Check success
          callStatus =
            curFailed.length > 0 || filesWithMissingGlobalIds.length > 0
              ? false
              : true;

          // Store or clean up results
          let variables = null;
          if (callStatus) {
            variables = {
              failed: [],
              errors: [],
              responses: [],
              filesWithMissingGlobalIds: []
            };
          } else {
            variables = {
              failed: curFailed,
              errors: errors,
              responses: curResponses,
              filesWithMissingGlobalIds: filesWithMissingGlobalIds
            };
          }

          // // Remove later
          // if (postedDocs.length > 0) variables["docs"] = postedDocs;

          // Store load test
          if (doLoadTest && loadtest.length > 0)
            variables["loadtest"] = loadtest;

          await updateTransferImagesToDataLakeCheck(
            taskName,
            callStatus,
            variables
          );
        } catch (e) {
          callStatus = false;

          console.log(e.message);
        }

        return callStatus;
      },
      maximoLocationDataUpdateCheck: async (taskName) => {
        let callStatus = true;

        const loadTestObj = new ApiLoadTest([
          "Global Id",
          "Payload (bytes)",
          "Status code",
          "Response time (ms)",
          "Timestamp"
        ]);

        // Find task in pipeline
        const taskIndex = findTaskIndex(taskName, circuit.pipeline);

        // Get previous state queues
        const prevRecords = circuit.pipeline[taskIndex].variables.records ?? 0;
        const prevSuccess = circuit.pipeline[taskIndex].variables.success ?? [];
        const prevMismatches =
          circuit.pipeline[taskIndex].variables.mismatches ?? [];

        // Start progress bar
        setIsTransferring(true);
        setTransferProgressTitle("Requesting location data...");

        const variables = {
          records: prevRecords,
          success: prevSuccess,
          mismatches: prevMismatches,
          failed: [],
          errors: [],
          responses: [],
          payloads: [],
          loadtest: []
        };

        const removeFromQueue = (queue, item) => {
          let arr = [];

          for (let i = 0; i < queue.length; i++) {
            if (item !== queue[i]) arr.push(queue[i]);
          }

          return arr;
        };

        const compareDoc = (sent, list, tagOwnerMap) => {
          const other = list[0] ? list[0] : {};
          const diffs = [];

          Object.keys(sent).forEach((key) => {
            let sentVal = sent[key];
            let recVal = other[key];

            // Transform or translate
            switch (key) {
              case "PoleTagInstallationYearAttribute":
                // Reduce to year
                recVal = recVal.slice(0, 4);
                break;
              case "PoleTagOwnerAttribute":
                recVal = tagOwnerMap[recVal]?.option ?? recVal;
                break;
              default:
              // Do nothing
            }

            if (sentVal !== recVal) {
              const obj = {};

              // Build diff info
              obj[key] = {
                expected: sentVal,
                received: recVal
              };

              diffs.push(obj);
            }
          });

          if (diffs.length > 0) {
            variables.errors.push({
              PoleGlobalId: sent.PoleGlobalId,
              differences: diffs
            });
          }

          return diffs.length === 0;
        };

        const translatePoleTagOwnerForMaximo = async () => {
          let map = {};

          const res = await getField({ fieldKey: "PoleTagOwner_PIT" });

          if (res.status === 200) {
            const validation = res.data.validation;

            if (validation.useTransformation) {
              validation.options.forEach((option, key) => {
                const [SAPOwner, SAPCompany, SAPCode] =
                  validation.transformation[key];

                map[SAPOwner] = { option, SAPCompany, SAPCode };
              });
            }
          }

          return map;
        };

        // Check if there are more records to try
        if (prevSuccess.length === 0 || prevRecords > prevSuccess.length) {
          try {
            const res = await getLocationData(circuit.circuit);

            if (res.status === 200) {
              const poles = res.response;

              // Save total number of records
              variables.records = poles.length;

              // Calculate total records to try
              const recordsToTry = poles.length - prevSuccess.length;

              // Reset progress
              setTransferProgress(0);
              setTransferTotalRecords(recordsToTry);

              // Set progress counter
              let counter = 0;

              if (poles.length) {
                // Get pole tag owner map
                const ptOwner = await translatePoleTagOwnerForMaximo();

                for (let i = 0; i < poles.length; i++) {
                  const pole = poles[i];
                  const globalId = pole.document["GlobalID"];
                  const poleTagClassAttribute = pole.document["PoleTagClass"];
                  const poleTagInstallationYearAttribute =
                    pole.document["PoleTagInstallationYear"];
                  const poleTagLengthAttribute = pole.document["PoleTagLength"];
                  const poleTagOwnerAttribute = pole.document["PoleTagOwner"];
                  const poleTagSpeciesTreatmentCodeAttribute =
                    pole.document["PoleTagSpeciesTreatmentCode"];

                  // Set doc to post
                  let doc = {
                    PoleGlobalId: [globalId]
                  };

                  // Set doc sent
                  let docSent = {
                    PoleGlobalId: globalId,
                    PoleTagClassAttribute: poleTagClassAttribute,
                    PoleTagLengthAttribute: poleTagLengthAttribute,
                    PoleTagInstallationYearAttribute:
                      poleTagInstallationYearAttribute,
                    PoleTagOwnerAttribute: poleTagOwnerAttribute,
                    PoleTagSpeciesTreatmentCodeAttribute:
                      poleTagSpeciesTreatmentCodeAttribute
                  };

                  // Check for pending requests to try
                  if (prevSuccess && prevSuccess.length) {
                    if (prevSuccess.includes(globalId)) doc = null;
                  }

                  if (doc !== null) {
                    counter += 1;

                    setTransferProgress(counter);

                    try {
                      // Delay between calls
                      // await delay(delayBetCalls);
                      await delay(50);

                      // Remove later
                      variables.payloads.push(doc);

                      // Get payload size
                      const payloadSize = new Blob([JSON.stringify(doc)]).size;

                      // Set load test benchmark
                      loadTestObj.setBenchmark(getTimestamp());

                      const esbRes = await postToEsbCheckLocationData(doc);

                      // Remove later
                      variables.responses.push(esbRes.response);

                      // Push load test entry
                      loadTestObj.pushEntry({
                        key: globalId,
                        payload: payloadSize,
                        status: esbRes.status,
                        timestamp: getTimestamp()
                      });

                      // Save failed record
                      if (esbRes.status !== 200) {
                        // Save failed request
                        variables.failed.push(globalId);

                        // Save response errors
                        variables.errors.push({
                          status: esbRes.status,
                          ...esbRes.response
                        });

                        callStatus = false;

                        // Exit on 401
                        if (esbRes.status === 401) {
                          break;
                        }

                        // Exit on 500
                        if (esbRes.status === 500) {
                          break;
                        }
                      } else {
                        // Compare record
                        if (
                          compareDoc(docSent, esbRes.response.list, ptOwner)
                        ) {
                          // Save success request
                          variables.success.push(globalId);

                          // Remove from mismatches queue
                          if (variables.mismatches.includes(globalId)) {
                            variables.mismatches = removeFromQueue(
                              variables.mismatches,
                              globalId
                            );
                          }
                        } else {
                          // Save mismatch request
                          variables.mismatches.push(globalId);
                        }
                      }
                    } catch (e) {
                      // Save failed ids
                      variables.failed.push(globalId);

                      // Save response errors
                      variables.errors.push(e.message);

                      callStatus = false;
                    }
                  }
                }

                // Print report in console
                variables.loadtest = loadTestObj.report();

                // Reset progress
                setTransferProgress(0);
                setTransferTotalRecords(0);

                // Check passed or not
                if (variables.records === variables.success.length) {
                  callStatus = true;
                } else {
                  callStatus = false;
                }

                await updateMaximoLocationDataUpdateCheckCheck(
                  taskName,
                  callStatus,
                  variables
                );
              } else {
                callStatus = true;
              }
            } else {
              callStatus = false;
            }
          } catch (e) {
            callStatus = false;
          }
        } else {
          callStatus = false;
        }

        // Stop progress bar
        setIsTransferring(false);

        return callStatus;
      },
      stageOperationalRecordForDataPowerMaximo: async (taskName) => {
        let callStatus = true;

        const aliasIndex = "ESB";

        try {
          // Get poles
          const res = await getFields({
            page: 1,
            count: 10000,
            alias: aliasIndex
          });

          const fields = getFieldMapping(res, aliasIndex);

          const variables = { fields };

          await updateStageOperationalRecordForDataPowerMaximoCheck(
            taskName,
            callStatus,
            variables
          );
        } catch (e) {
          callStatus = false;
        }

        return callStatus;
      },
      transferLocationDataToDataPowerESRI: async (taskName) => {
        let callStatus = true;

        const loadTestObj = new ApiLoadTest([
          "Global Id",
          "Payload (bytes)",
          "Status code",
          "Response time (ms)",
          "Timestamp"
        ]);

        // Find task in pipeline
        const taskIndex = findTaskIndex(taskName, circuit.pipeline);

        // Get previous state queues
        const prevRecords = circuit.pipeline[taskIndex].variables.records ?? 0;
        const prevSuccess = circuit.pipeline[taskIndex].variables.success ?? [];

        // Start progress bar
        setIsTransferring(true);
        setTransferProgressTitle("Transferring Location Data to ESRI");

        const variables = {
          records: prevRecords,
          success: prevSuccess,
          failed: [],
          errors: [],
          responses: [],
          payloads: [],
          loadtest: []
        };

        const transformPoleTagOwnerForEsri = async () => {
          let map = {};

          const res = await getField({ fieldKey: "PoleTagOwner_PIT" });

          if (res.status === 200) {
            const validation = res.data.validation;

            if (validation.useTransformation) {
              validation.options.forEach((option, key) => {
                const [SAPOwner, SAPCompany, SAPCode] =
                  validation.transformation[key];

                map[option] = { SAPOwner, SAPCompany, SAPCode };
              });
            }
          }

          return map;
        };

        const getStructureTypeMap = async () => {
          let map = {};

          const res = await getField({ fieldKey: "StructureType_PIT" });

          if (res.status === 200) {
            const validation = res.data.validation;

            if (validation.useTransformation) {
              validation.options.forEach((option, key) => {
                const [SubTypeCd] = validation.transformation[key];

                map[option] = { SubTypeCd };
              });
            }
          }

          return map;
        };

        // Check if there are more records to try
        if (prevSuccess.length === 0 || prevRecords > prevSuccess.length) {
          try {
            const res = await getLocationData(circuit.circuit);

            if (res.status === 200) {
              const poles = res.response;

              // Save total number of records
              variables.records = poles.length;

              // Calculate total records to try
              const recordsToTry = poles.length - prevSuccess.length;

              // Reset progress
              setTransferProgress(0);
              setTransferTotalRecords(recordsToTry);

              // Set progress counter
              let counter = 0;

              if (poles.length) {
                // Get pole tag owner map
                const ptOwner = await transformPoleTagOwnerForEsri();
                const structType = await getStructureTypeMap();

                for (let i = 0; i < poles.length; i++) {
                  const pole = poles[i];
                  const globalId = pole.document["GlobalID"];
                  const inspectionDate = pole.document["InspectionDate"];
                  const poleCondition = pole.document["PoleStatus"];
                  const poleTagClassAttribute = pole.document["PoleTagClass"];
                  const poleTagInstallationYearAttribute =
                    pole.document["PoleTagInstallationYear"];
                  const poleTagLengthAttribute = pole.document["PoleTagLength"];
                  const poleTagOwnerAttribute = pole.document["PoleTagOwner"];
                  const poleTagSpeciesTreatmentCodeAttribute =
                    pole.document["PoleTagSpeciesTreatmentCode"];
                  const reinforcementDate =
                    pole.document["ReinforcementDate"] === undefined
                      ? null
                      : pole.document["ReinforcementDate"];
                  const structureType = pole.document["StructureType"];

                  // Set doc to post
                  let doc = {
                    PoleGlobalId: globalId,
                    InspectionDate: inspectionDate,
                    PoleCondition: poleCondition,
                    PoleTagClassAttribute: poleTagClassAttribute,
                    PoleTagInstallationYearAttribute:
                      poleTagInstallationYearAttribute,
                    PoleTagLengthAttribute: poleTagLengthAttribute,
                    PoleTagOwnerAttribute:
                      ptOwner[poleTagOwnerAttribute].SAPOwner ?? "",
                    PoleTagSpeciesTreatmentCodeAttribute:
                      poleTagSpeciesTreatmentCodeAttribute,
                    ReinforcementDate: reinforcementDate,
                    SAPCompany: ptOwner[poleTagOwnerAttribute].SAPCompany ?? "",
                    SAPCode: ptOwner[poleTagOwnerAttribute].SAPCode ?? "",
                    SubTypeCd: structType[structureType].SubTypeCd ?? ""
                  };

                  // Check for pending requests to try
                  if (prevSuccess && prevSuccess.length) {
                    if (prevSuccess.includes(globalId)) doc = null;
                  }

                  if (doc !== null) {
                    counter += 1;

                    setTransferProgress(counter);

                    try {
                      // Delay between calls
                      // await delay(delayBetCalls);
                      await delay(0);

                      // Remove later
                      variables.payloads.push(doc);

                      // Get payload size
                      const payloadSize = new Blob([JSON.stringify(doc)]).size;

                      // Set load test benchmark
                      loadTestObj.setBenchmark(getTimestamp());

                      const esbRes = await postToEsbLocationData(doc);

                      // Remove later
                      variables.responses.push(esbRes.response);

                      // Push load test entry
                      loadTestObj.pushEntry({
                        key: globalId,
                        payload: payloadSize,
                        status: esbRes.status,
                        timestamp: getTimestamp()
                      });

                      // Save failed record
                      if (esbRes.status !== 202) {
                        // Save failed request
                        variables.failed.push(globalId);

                        // Save response errors
                        variables.errors.push({
                          status: esbRes.status,
                          ...esbRes.response
                        });

                        callStatus = false;

                        // Exit on 401
                        if (esbRes.status === 401) {
                          break;
                        }

                        // Exit on 500
                        if (esbRes.status === 500) {
                          break;
                        }
                      } else {
                        // Save success request
                        variables.success.push(globalId);
                      }
                    } catch (e) {
                      // Save failed ids
                      variables.failed.push(globalId);

                      // Save response errors
                      variables.errors.push(e.message);

                      callStatus = false;
                    }
                  }
                }

                // Print report in console
                variables.loadtest = loadTestObj.report();

                // Reset progress
                setTransferProgress(0);
                setTransferTotalRecords(0);

                // Check passed or not
                if (variables.records === variables.success.length) {
                  callStatus = true;
                } else {
                  callStatus = false;
                }

                await updateTransferLocationDataToDataPowerESRICheck(
                  taskName,
                  callStatus,
                  variables
                );
              } else {
                callStatus = true;
              }
            } else {
              callStatus = false;
            }
          } catch (e) {
            callStatus = false;
          }
        } else {
          callStatus = false;
        }

        // Stop progress bar
        setIsTransferring(false);

        return callStatus;
      },
      poleInspectionDataUpdateState: async (taskName) => {
        let callStatus = true;

        // Start progress bar
        setIsTransferring(true);
        setTransferProgressTitle("Processing messages from ESRI");

        const variables = {
          errors: []
        };

        // Get unread messaages from queue
        const getMessages = async () => {
          const mq = [];

          const query = {
            collection: "MessageQueue",
            filter: {
              scope: "AuditPipeline",
              queue: "ESB",
              topic: "esriPoleInspectionDataUpdateState",
              read: false
            },
            sort: {
              createdAt: 1
            },
            project: {
              _id: 0,
              messageId: 1,
              message: 1
            },
            confirm: true
          };

          const mqRes = await bulkGet(query);

          if (mqRes.status === 200) {
            const list = mqRes.response;

            if (list.length > 0) {
              for (let i = 0; i < list.length; i++) {
                const messageId = list[i].messageId;
                const updateState = list[i].message;

                mq.push({
                  messageId,
                  updateState
                });
              }
            }
          }

          return mq;
        };

        const processMessages = async (items) => {
          const messageIds = [];
          let query = null;

          for (let i = 0; i < items.length; i++) {
            const messageId = items[i].messageId;
            const list = items[i].updateState;

            // Reset progress
            setTransferProgress(0);
            setTransferTotalRecords(list.length);

            // Set progress counter
            let counter = 0;

            for (let j = 0; j < list.length; j++) {
              const poleGlobalId = list[j].PoleGlobalId;
              const posted = list[j].Posted;

              query = {
                collection: "DataFiles",
                filter: {
                  suffix: "PIT",
                  "document.GlobalID": poleGlobalId
                },
                update: {
                  inEsri: posted
                },
                confirm: true
              };

              await bulkUpdate(query);

              counter += 1;

              setTransferProgress(counter);
            }

            messageIds.push(messageId);
          }

          // Mark messages as read
          if (messageIds.length > 0) {
            query = {
              collection: "MessageQueue",
              filter: {
                messageId: {
                  $in: messageIds
                }
              },
              update: {
                read: true
              },
              confirm: true
            };
            await bulkUpdate(query);
          }

          // Reset progress
          setTransferProgress(0);
          setTransferTotalRecords(0);
        };

        const checkEsriStatus = async () => {
          const query = {
            collection: "DataFiles",
            filter: {
              suffix: "PIT",
              circuit: circuit.circuit,
              inEsri: {
                $ne: true
              }
            },
            sort: {},
            project: {
              _id: 0,
              "document.GlobalID": 1,
              inEsri: 1
            },
            confirm: true
          };

          const bg = await bulkGet(query);

          if (bg.status === 200) {
            if (bg.response.length > 0) {
              bg.response.forEach((item) => {
                variables.errors.push({
                  globalId: item.document.GlobalID,
                  posted: item.inEsri
                });
              });
            }
          }
        };

        const messages = await getMessages();

        if (messages.length > 0) {
          // Process all pending messages from ESRI
          await processMessages(messages);
        }

        // Check ESRI update status in PIT
        await checkEsriStatus();

        callStatus = variables.errors.length === 0;

        await updatePoleInspectionDataUpdateStateCheck(
          taskName,
          callStatus,
          variables
        );

        // Stop progress bar
        setIsTransferring(false);

        return callStatus;
      },
      transferOperationalRecordToDataPowerMaximo: async (taskName) => {
        let callStatus = true;

        // Set required to true
        const taskIndex = findTaskIndex(taskName, circuit.pipeline);

        const fields = circuit.pipeline[taskIndex].variables.fields;
        const prevFailed = circuit.pipeline[taskIndex].variables.failed;

        // Set load test array
        const doLoadTest = true;
        let loadtest = [];
        let benchmarkStart = 0;
        let responseTime = 0;
        let loadtestEntry = "";

        const transformPoleTagOwnerForMaximo = async () => {
          let map = {};

          const res = await getField({ fieldKey: "PoleTagOwner_PIT" });

          if (res.status === 200) {
            const validation = res.data.validation;

            if (validation.useTransformation) {
              validation.options.forEach((option, key) => {
                const [SAPOwner, SAPCompany, SAPCode] =
                  validation.transformation[key];

                map[option] = { SAPOwner, SAPCompany, SAPCode };
              });
            }
          }

          return map;
        };

        try {
          // Get records for circuit
          const sourceDocs = await getDataFileEntries({
            page: 1,
            count: 10000,
            circuit: circuit.circuit
          });

          // Merge pole data
          const records = mergePoleData(sourceDocs);

          // Get support data for metadata
          const supportRecords = supportPoleData(sourceDocs);

          const dataPointIds = Object.keys(records);

          setTransferTotalRecords(dataPointIds.length);

          const curFailed = [];
          const curResponses = []; // Remove later
          const postedDocs = []; // Remove later
          const errors = [];
          const primaryKey = "PoleGlobalId";

          let dynWaitTime = delayBetCalls;

          // Show load test entry in console
          if (doLoadTest) {
            console.log(`Test start: ${circuit.circuit}`, getTimestamp());

            loadtest.push(loadtestHeaders.join(","));
          }

          // Get pole tag owner map
          const ptOwner = await transformPoleTagOwnerForMaximo();

          for (let i = 0; i < dataPointIds.length; i++) {
            setTransferProgress(i + 1);

            const dataPointId = dataPointIds[i];

            // Set doc to post
            let doc = null;
            let supportDoc = null;

            if (prevFailed && prevFailed.length) {
              if (prevFailed.includes(dataPointId)) {
                doc = records[dataPointId];
                supportDoc = supportRecords[dataPointId];
              }
            } else {
              doc = records[dataPointId];
              supportDoc = supportRecords[dataPointId];
            }

            if (doc !== null) {
              // Custom doc
              const newDoc = mapDoc(doc, fields);

              // Get payload size
              const payloadSize = new Blob([JSON.stringify(newDoc)]).size;

              if (newDoc[primaryKey] !== "") {
                // Produce metadata
                const metadata = getOperRecMdEsb(doc, supportDoc);

                try {
                  // Delay between calls
                  await delay(dynWaitTime);

                  // Start benchmark
                  if (doLoadTest) benchmarkStart = getTimestamp();

                  // Transform pole tag owner
                  newDoc.PoleTagOwnerAttribute =
                    ptOwner[newDoc.PoleTagOwnerAttribute]?.SAPOwner ??
                    newDoc.PoleTagOwnerAttribute;

                  // Hit ESB endpoint
                  const esbRes = await postToEsbInspRec(newDoc);

                  // Finish benchmark
                  if (doLoadTest)
                    responseTime = getTimestamp() - benchmarkStart;

                  // Remove later
                  postedDocs.push({ data: newDoc, support: metadata });

                  // Remove later
                  curResponses.push(esbRes.response);

                  // Show load test entry in console
                  if (doLoadTest) {
                    loadtestEntry = `"${dataPointId}",${payloadSize},${esbRes.status},${responseTime},${benchmarkStart}`;

                    loadtest.push(loadtestEntry);

                    console.log(circuit.circuit, loadtestEntry);
                  }

                  // Save failed record
                  if (esbRes.status !== 201) {
                    // Save failed ids
                    curFailed.push(dataPointId);

                    // Save error responses
                    if (esbRes.response.data && esbRes.response.data["Error"]) {
                      errors.push({
                        "GLNX-GLNY": dataPointId,
                        status: +esbRes.response.data["Error"]["statusCode"],
                        message: esbRes.response.data["Error"]["message"],
                        scope: {
                          InspectionRecordType: newDoc["InspectionRecordType"]
                        }
                      });
                    } else {
                      errors.push({
                        "GLNX-GLNY": dataPointId,
                        status: esbRes.status,
                        message: esbRes.response.data,
                        scope: {
                          InspectionRecordType: newDoc["InspectionRecordType"]
                        }
                      });
                    }
                  }
                } catch (e) {
                  // Show load test entry in console
                  if (doLoadTest) {
                    responseTime = getTimestamp() - benchmarkStart;

                    // Add request data to load test
                    const loadtestEntry = `"${dataPointId}",${payloadSize},500,${responseTime},${benchmarkStart}`;

                    loadtest.push(loadtestEntry);

                    console.log(loadtestEntry);
                  }

                  // Save failed ids
                  curFailed.push(dataPointId);

                  // Save error responses
                  errors.push({
                    "GLNX-GLNY": dataPointId,
                    status: 500,
                    message: e.message,
                    scope: {
                      InspectionRecordType: newDoc["InspectionRecordType"],
                      "try/catch": true
                    }
                  });
                }
              }
            }
          }

          // Show load test entry in console
          if (doLoadTest)
            console.log(`Test finish: ${circuit.circuit}`, getTimestamp());

          callStatus = curFailed.length > 0 ? false : true;

          let variables = null;
          if (callStatus) {
            variables = {
              failed: [],
              errors: [],
              responses: []
            };
          } else {
            variables = {
              failed: curFailed,
              errors,
              responses: curResponses
            };
          }

          // Remove later
          if (postedDocs.length > 0) variables["payloads"] = postedDocs;

          // Store load test
          if (doLoadTest && loadtest.length > 0)
            variables["loadtest"] = loadtest;

          // Unstage fields when finished
          if (callStatus) variables["fields"] = [];

          await updateTransferOperationalRecordToDataPowerMaximoCheck(
            taskName,
            callStatus,
            variables
          );
        } catch (e) {
          callStatus = false;
        }

        return callStatus;
      },
      transferReinforcementInspectionRecordToDataPower: async (taskName) => {
        let callStatus = true;

        const loadTestObj = new ApiLoadTest([
          "Global Id",
          "Payload (bytes)",
          "Status code",
          "Response time (ms)",
          "Timestamp"
        ]);

        // Set required to true
        const taskIndex = findTaskIndex(taskName, circuit.pipeline);

        // Get previous state queues
        // Set check name
        const checkName = "transferReinforcementInspectionRecordToDataPower";
        const pipe = circuit.pipeline[taskIndex];
        const thisCheck = pipe.checks[checkName];
        let prevRecords = pipe.variables.records ?? 0;
        let prevSuccess = pipe.variables.success ?? [];

        // Is first time? Clean stats
        if (thisCheck === null) {
          prevRecords = 0;
          prevSuccess = [];
        }

        // Start progress bar
        setIsTransferring(true);
        setTransferProgressTitle("Requesting RIA data...");

        const variables = {
          records: prevRecords,
          success: prevSuccess,
          failed: [],
          errors: [],
          prompt: [],
          responses: [],
          payloads: [],
          loadtest: []
        };

        const removeFromPrompt = (item) => {
          let arr = [];

          for (let i = 0; i < variables.prompt.length; i++) {
            if (item !== variables.prompt[i]["globalId"])
              arr.push(variables.prompt[i]);
          }

          return arr;
        };

        const handle400 = (res, data) => {
          const WO_MULTIPLE = /^(BMXZZ7180E|BMXZZ7135E)/;
          const DOC_DUPLICATE = /^BMXAA4211E/;
          // const TASKWO_MISSING = "BMXZZ7142E";

          const { dataPointId, globalId, PO, LINENUM, inspectionId } = data;

          const source = res.source;
          const msg = res.msg;

          // Check ESB response
          if (source === "ESB") {
            // Check Maximo message
            if (msg.source === "Maximo") {
              // Multiple work orders for PO
              if (msg.message.search(WO_MULTIPLE) === 0) {
                variables.prompt.push({
                  circuit: circuit.circuit,
                  dataPointId,
                  globalId,
                  PO,
                  LINENUM,
                  inspectionId
                });

                return true;
              }

              // Duplicate record: mark it as successful
              if (msg.message.search(DOC_DUPLICATE) === 0) {
                variables.success.push(globalId);

                return true;
              }

              // if (msg.message.search(TASKWO_MISSING) === 0) {
              //   return true;
              // }

              // If error not handled
              return false;
            }
          }
        };

        const transformRiaDoc = (doc) => {
          const { source, inspections, billing, approvedBy } = doc;

          try {
            const inspection = inspections[inspections.length - 1];
            const inspectionId = inspection.id;
            const inspectionDateTime = inspection.submittedAt;
            const inspector = inspection.user;
            const document = inspection.document;
            const globalId = document.GlobalID;
            const circuit = document.OHPrimaryCircuitNumber;
            const comments = document.ReinforcementQAComments ?? "NO COMMENT";
            const po = document.default.PO;
            const vendor = source.match(/RIA_(.*).csv$/)[1];
            const isBilling = billing !== undefined;
            let lineNumber = null;

            let reinspection = false;

            if (isBilling) {
              if (billing.reinspect) {
                reinspection = billing.reinspect.length > 0;
              }
            }

            // Try to get line number
            if (isBilling) {
              if (billing.PO) {
                if (billing.PO[inspectionId]) {
                  lineNumber = billing.PO[inspectionId].LINENUM;
                }
              }
            }

            // Produce violations
            let violations = [];
            const defects = inspection.results.defects;

            if (defects.list.length > 0) {
              let result;

              if (reinspection) {
                result = billing.reinspect.filter(
                  (item) => item.inspectionId === inspectionId
                )[0];
              } else {
                result = billing.approve.filter(
                  (item) => item.inspectionId === inspectionId
                )[0];
              }

              violations = defects.list.map((defect) => {
                const md = defects.metadata[defect];
                const isSpecViolation = md.answer !== md.positiveAnswer;
                let approvedBy = "";

                if (reinspection && result.reinspections.includes(defect)) {
                  approvedBy = result.orderedBy.email;
                }

                if (!reinspection && result.approvals.includes(defect)) {
                  approvedBy = result.approvedBy.email;
                }

                return {
                  VIOLATIONID: uuidv4(),
                  INSPECTIONID: inspectionId,
                  DTE_VIOLATION: md.question,
                  DTE_SPECVIOLATION: isSpecViolation ? 1 : 0,
                  DTE_SIGNEDOFFVIOLATIONBY_EMAIL: approvedBy
                };
              });
            }

            const model = {
              INSPECTIONID: inspectionId,
              OHPRIMARYCIRCUITNUMBER: circuit,
              GLOBALID: globalId,
              REINSPECTION: reinspection ? 1 : 0,
              DTE_INSPECTIONDATE: moment(inspectionDateTime).format(),
              INSPECTOR_EMAIL: inspector.email,
              DTE_SIGNEDOFFBY_EMAIL: approvedBy.email,
              DTE_BUILTBY: vendor,
              DTE_COMMENTS: comments,
              PO: {
                PONUM: po,
                LINENUM: lineNumber
              },
              VIOLATIONS: violations
            };

            return model;
          } catch (e) {
            console.log(e);
          }
        };

        // Check if there are more records to try
        if (prevSuccess.length === 0 || prevRecords > prevSuccess.length) {
          try {
            // Get records for circuit
            const query = {
              collection: "DataFiles",
              filter: {
                fieldset: "poleReinforcementAudit",
                circuit: circuit.circuit
              },
              sort: {},
              project: {
                _id: 0
              },
              confirm: true
            };

            const riaRes = await bulkGet(query);

            if (riaRes.status === 200) {
              const poles = riaRes.response;

              // Save total number of records
              variables.records = poles.length;

              // Calculate total records to try
              const recordsToTry = poles.length - prevSuccess.length;

              // Reset progress
              setTransferProgress(0);
              setTransferTotalRecords(recordsToTry);

              // Set progress counter
              let counter = 0;

              if (poles.length) {
                for (let i = 0; i < poles.length; i++) {
                  const pole = poles[i];
                  const dataPointId = pole.dataPointId;
                  const globalId = pole.document["GlobalID"];
                  const PO = pole.document.default.PO;

                  // Skip if inspections were not approved
                  if (!pole.approved) {
                    // Save failed request
                    variables.failed.push(globalId);

                    // Save response errors
                    variables.errors.push({
                      globalId,
                      msg: "Unresolved defects"
                    });

                    callStatus = false;

                    continue;
                  }

                  // Do transformation
                  let doc = transformRiaDoc(pole);

                  // Check for pending requests to try
                  if (prevSuccess && prevSuccess.length) {
                    if (prevSuccess.includes(globalId)) doc = null;
                  }

                  if (doc !== null) {
                    counter += 1;

                    setTransferProgress(counter);

                    try {
                      // Remove later
                      variables.payloads.push(doc);

                      // Get payload size
                      const payloadSize = new Blob([JSON.stringify(doc)]).size;

                      // Set load test benchmark
                      loadTestObj.setBenchmark(getTimestamp());

                      // Hit ESB endpoint
                      const esbRes = await postToEsbSendReinforcementData(doc);

                      // Remove later
                      variables.responses.push(esbRes.response);

                      // Push load test entry
                      loadTestObj.pushEntry({
                        key: globalId,
                        payload: payloadSize,
                        status: esbRes.status,
                        timestamp: getTimestamp()
                      });

                      // Save failed record
                      if (esbRes.status !== 202) {
                        // Save failed request
                        variables.failed.push(globalId);

                        // Save response errors
                        variables.errors.push({
                          status: esbRes.status,
                          ...esbRes.response
                        });

                        callStatus = false;

                        // Hanlde 400
                        if (esbRes.status === 400) {
                          // Exit if not handled
                          if (
                            !handle400(esbRes.response, {
                              dataPointId,
                              globalId,
                              PO,
                              LINENUM: doc.PO.LINENUM,
                              inspectionId: doc.INSPECTIONID
                            })
                          )
                            break;
                        }

                        // Exit on 401
                        if (esbRes.status === 401) {
                          break;
                        }

                        // Exit on 500
                        if (esbRes.status === 500) {
                          break;
                        }
                      } else {
                        // Save success request
                        variables.success.push(globalId);

                        // Remove prompt list
                        variables.prompt = removeFromPrompt(globalId);
                      }
                    } catch (e) {
                      // Save failed ids
                      variables.failed.push(globalId);

                      // Save response errors
                      variables.errors.push({ globalId, msg: e.message });

                      callStatus = false;
                    }
                  }
                }

                // Print report in console
                variables.loadtest = loadTestObj.report();

                // Reset progress
                setTransferProgress(0);
                setTransferTotalRecords(0);

                // Check passed or not
                if (variables.records === variables.success.length) {
                  callStatus = true;
                } else {
                  callStatus = false;
                }

                await updateTransferReinforcementInspectionRecordToDataPowerCheck(
                  taskName,
                  callStatus,
                  variables
                );
              } else {
                callStatus = true;
              }
            } else {
              callStatus = false;
            }
          } catch (e) {
            callStatus = false;
          }
        } else {
          callStatus = false;
        }

        // Stop progress bar
        setIsTransferring(false);

        return callStatus;
      }
    };

    const doCheck = async (taskName, checkName) => {
      if (checkHandlers[checkName]) {
        setIsTransferring(true);

        await checkHandlers[checkName](taskName);

        setIsTransferring(false);
      }

      return true;
    };

    const CheckButton = (props) => {
      const { taskName, checkName } = props;
      const check = task.checks[checkName];
      const status = check ? "Passed" : check === false ? "Retry" : "Try";
      const color = check ? "success" : check === false ? "danger" : "warning";
      const caption = `${status}: ${phraseToProperCase(checkName)}`;

      return (
        <>
          <Button
            className="mr-05rem mt-1rem"
            outline
            color={color}
            size="sm"
            disabled={!checksEnabledState[checkName] || isTransferring}
            onClick={() => doCheck(taskName, checkName)}
          >
            {caption}{" "}
            {checksEnabledState[checkName] && isTransferring && (
              <Spinner size="sm" color="dark" />
            )}
          </Button>
          <br />
        </>
      );
    };

    let headerClass = "";
    let activateButton = false;
    let deactivateButton = false;

    // Set activation buttons
    if (task.required && task.settings.allowDeactivation) {
      deactivateButton = true;
    }
    if (!task.required && task.settings.allowActivation) {
      activateButton = true;
    }

    // Define header class
    switch (task.status) {
      case "pending":
        headerClass = "card-header-warning";
        break;
      case "failed":
        headerClass = "card-header-danger";
        break;
      case "passed":
        headerClass = "card-header-success";
        break;
      default:
      // Do nothing
    }

    const TaskLog = (props) => {
      const { log } = props;

      return (
        <Card
          body
          inverse
          style={{
            backgroundColor: "#f7f7f7",
            marginTop: "1.5rem"
          }}
        >
          <CardText>
            <UpdatedByField by={log.by.name} at={log.at} />
          </CardText>
        </Card>
      );
    };

    const TransferProgressBar = () => {
      const isPlural = transferTotalRecords > 1;

      return (
        <Card body>
          <CardText className="text-center">
            {transferProgress} of {transferTotalRecords} record
            {isPlural ? "s" : ""}
          </CardText>
          <Progress
            color="success"
            value={transferProgress}
            max={transferTotalRecords}
          />
        </Card>
      );
    };

    const TransferProgressBarWithTitle = () => {
      const isPlural = transferTotalRecords > 1;

      return (
        <Card body>
          {transferProgressTitle && (
            <CardText className="text-center">{transferProgressTitle}</CardText>
          )}
          <CardText className="text-center">
            {transferProgress} of {transferTotalRecords} record
            {isPlural ? "s" : ""}
          </CardText>
          <Progress
            color="success"
            value={transferProgress}
            max={transferTotalRecords}
          />
        </Card>
      );
    };

    const DisplayHandlers = {
      displayCheckDuplicateGlnxy: () => {
        if (!task.variables.duplicateGlnxy) return null;
        if (task.variables.duplicateGlnxy.length === 0) return null;

        const handleUpdate = (data) => {
          const TASK_NAME = "poleInspection";

          updateCheckDuplicateGlnxyCheck(TASK_NAME, false, data);
        };

        return (
          <DuplicateGlnxyReport
            variables={task.variables}
            sendEmail={sendEmail}
            userInfo={userInfo}
            curCircuit={circuit.circuit}
            handleUpdate={handleUpdate}
          />
        );
      },
      displayImageIntegrityReport: () => {
        if (!task.variables.imageIntegrity) return null;
        if (task.variables.imageIntegrity.length === 0) return null;

        const badFormat = task.variables.imageIntegrity.badFormat;
        const unMatchedImages = task.variables.imageIntegrity.unMatchedImages;
        const neededPhotos = task.variables.imageIntegrity.neededPhotos;
        const notRequired = task.variables.imageIntegrity.notRequired;

        if (badFormat.length === 0) {
          if (unMatchedImages.length === 0) {
            if (neededPhotos.length === 0) {
              if (notRequired.length === 0) return null;
            }
          }
        }

        return (
          <Card body className="display-container">
            {badFormat.length > 0 && (
              <Card className="mb-1rem">
                <CardHeader>Bad File Names</CardHeader>
                <CardBody className="overflow-500">
                  {badFormat.join(", ")}
                </CardBody>
              </Card>
            )}
            {unMatchedImages.length > 0 && (
              <Card className="mb-1rem">
                <CardHeader>No Pole Found for Image</CardHeader>
                <CardBody className="overflow-500">
                  {unMatchedImages.join(", ")}
                </CardBody>
              </Card>
            )}
            {neededPhotos.length > 0 && (
              <Card className="mb-1rem">
                <CardHeader>Poles Needing Photos</CardHeader>
                <CardBody className="overflow-500">
                  {neededPhotos.join(", ")}
                </CardBody>
              </Card>
            )}
            {notRequired.length > 0 && (
              <Card className="mb-1rem">
                <CardHeader>Photos Not Required by Filter</CardHeader>
                <CardBody className="overflow-500">
                  {notRequired.map((item, i) => {
                    return (
                      <Card className="mb-05rem" body key={i}>
                        {Object.keys(item).map((p, i) => {
                          return (
                            <CardText className="mb-05rem" key={i}>
                              <b>{p}</b>: {item[p]}
                            </CardText>
                          );
                        })}
                      </Card>
                    );
                  })}
                </CardBody>
              </Card>
            )}
          </Card>
        );
      },
      displayGlobalIdValidationProgress: () => {
        if (!isTransferring) return null;

        return <TransferProgressBarWithTitle />;
      },
      displayGlobalIdValidationResponses: () => {
        if (!task.variables.responses) return null;
        if (task.variables.responses.length === 0) return null;

        return <OverflowCardContainer arr={task.variables.responses} />;
      },
      displayGlobalIdValidationPayloads: () => {
        if (!task.variables.payloads) return null;
        if (task.variables.payloads.length === 0) return null;

        return <OverflowCardContainer arr={task.variables.payloads} />;
      },
      displayGlobalIdValidationErrors: () => {
        if (!task.variables.errors) return null;
        if (task.variables.errors.length === 0) return null;

        return <OverflowCardContainer arr={task.variables.errors} />;
      },
      displayGlobalIdValidationLoadTest: () => {
        if (!task.variables.loadtest) return null;
        if (task.variables.loadtest.length <= 1) return null;

        const loadtest = task.variables.loadtest;

        return (
          <Card body className="display-container">
            <Input
              type="textarea"
              id="loadtest"
              rows="5"
              defaultValue={loadtest}
            />
          </Card>
        );
      },
      displayMissingGlobalIds: () => {
        if (!task.variables.missingGlobalIds) return null;
        if (task.variables.missingGlobalIds.length === 0) return null;

        return (
          <MissingGlobalIdsReport
            poles={task.variables.missingGlobalIds}
            circuit={circuit.circuit}
          />
        );
      },
      displayInvalidGlobalIds: () => {
        if (!task.variables.invalid) return null;
        if (task.variables.invalid.length === 0) return null;

        return <InvalidGlobalIdsReport poles={task.variables.invalid} />;
      },
      displayMismatchedLocationData: () => {
        if (!task.variables.mismatches) return null;
        if (task.variables.mismatches.length === 0) return null;

        return (
          <MismatchedLocationDataReport poles={task.variables.mismatches} />
        );
      },
      displayPoCheckProgress: () => {
        if (!isTransferring) return null;

        return <TransferProgressBar />;
      },
      displayPoCheckPOFound: () => {
        const check = task.checks.purchaseOrderExists;
        if (check === null) return null;

        return check === true ? (
          <Card body className="display-container">
            <CardText>
              <b>Found PO:</b> {task.variables.PO.ponum}
            </CardText>
          </Card>
        ) : (
          <Card body className="display-container">
            <CardText>No purchase order was found...</CardText>
          </Card>
        );
      },
      displayPoCheckErrors: () => {
        if (!task.variables.errors) return null;
        if (task.variables.errors.length === 0) return null;

        return (
          <Card body className="display-container">
            {task.variables.errors.map((err, i) => (
              <Card body className="display-card" key={i}>
                <CardText>PO: {err.PO}</CardText>
                <CardText>Status: {err.status}</CardText>
                {err.message && <CardText>Message: {err.message}</CardText>}
              </Card>
            ))}
          </Card>
        );
      },
      displayPoCheckResponses: () => {
        if (!task.variables.responses) return null;
        if (task.variables.responses.length === 0) return null;

        return (
          <Card body className="display-container">
            {task.variables.responses.map((res, i) => (
              <Card body className="display-card" key={i}>
                <Json data={res} />
              </Card>
            ))}
          </Card>
        );
      },
      displayReinforcementReinspections: () => {
        const polesToReinspect = task.variables?.polesToReinspect ?? {};
        const lastUpdate = task.variables?.lastUpdate ?? null;

        if (Object.keys(polesToReinspect).length === 0) return null;

        return (
          <DisplayReinforcementReinspections
            circuit={circuit.circuit}
            polesToReinspect={polesToReinspect}
            sendEmail={sendEmail}
            handleLoadInspections={() =>
              checkHandlers.loadInspections("poleReinforcementAudit")
            }
            overrideMailContacts={overrideMailContacts}
            user={user}
            userInfo={userInfo}
            lastUpdate={lastUpdate}
          />
        );
      },
      displayReinforcementInspections: () => {
        const polesToInspect = task.variables?.polesToInspect ?? [];
        const inspectionsToApprove = task.variables?.inspectionsToApprove ?? 0;
        const lastUpdate = task.variables?.lastUpdate ?? null;

        if (polesToInspect.length === 0 && inspectionsToApprove === 0)
          return null;

        const inspections = task.variables.inspections;

        return (
          <DisplayReinforcementInspections
            polesToInspect={polesToInspect}
            inspectionsToApprove={inspectionsToApprove}
            inspections={inspections}
            sendEmail={sendEmail}
            handleLoadInspections={() =>
              checkHandlers.loadInspections("poleReinforcementAudit")
            }
            overrideMailContacts={overrideMailContacts}
            user={user}
            userInfo={userInfo}
            lastUpdate={lastUpdate}
          />
        );
      },
      displayFormatValidationProgress: () => {
        if (!isTransferring) return null;

        return <TransferProgressBarWithTitle />;
      },
      displayFormatValidationResults: () => {
        const errors = task.variables?.errorsFormat;

        if (!errors) return null;
        if (errors.length === 0) return null;

        return <FormatValidationReport errors={errors} />;
      },
      displayEngineeringStandardsCompliance: () => {
        const errors = task.variables?.errorsStandards;

        if (!errors) return null;
        if (errors.length === 0) return null;

        return <EngStandardsComplianceReport errors={errors} />;
      },
      displayDataLakeTransferProgress: () => {
        if (!isTransferring) return null;

        return <TransferProgressBar />;
      },
      displayDataLakeTransferMissingGlobalIds: () => {
        if (!task.variables.filesWithMissingGlobalIds) return null;
        if (task.variables.filesWithMissingGlobalIds.length === 0) return null;

        return (
          <Card body className="display-container">
            <Card body className="display-card">
              {task.variables.filesWithMissingGlobalIds.map((file, i) => (
                <CardText key={i}>{file}</CardText>
              ))}
            </Card>
          </Card>
        );
      },
      displayDataLakeTransferErrors: () => {
        if (!task.variables.errors) return null;
        if (task.variables.errors.length === 0) return null;

        return (
          <Card body className="display-container">
            {task.variables.errors.map((err, i) => (
              <Card body className="display-card" key={i}>
                <CardText>File name: {err.file}</CardText>
                <CardText>Status: {err.status}</CardText>
                {err.message && <CardText>Message: {err.message}</CardText>}
              </Card>
            ))}
          </Card>
        );
      },
      displayDataLakeTransferResponses: () => {
        if (!task.variables.responses) return null;
        if (task.variables.responses.length === 0) return null;

        return (
          <Card body className="display-container">
            {task.variables.responses.map((res, i) => (
              <Card body className="display-card" key={i}>
                <Json data={res} />
              </Card>
            ))}
          </Card>
        );
      },
      displayDataLakeTransferDocs: () => {
        if (!task.variables.docs) return null;
        if (task.variables.docs.length === 0) return null;

        return (
          <Card body className="display-container">
            {task.variables.docs.map((doc, i) => (
              <Card body className="display-card" key={i}>
                <Json data={doc} />
              </Card>
            ))}
          </Card>
        );
      },
      displayDataLakeLoadTest: () => {
        if (!task.variables.loadtest) return null;
        if (task.variables.loadtest.length <= 1) return null;

        const loadtest = task.variables.loadtest.join("\n");

        return (
          <Card body className="display-container">
            <Input
              type="textarea"
              id="loadtest"
              rows="5"
              defaultValue={loadtest}
            />
          </Card>
        );
      },
      displayDataPowerESRITransferProgress: () => {
        if (!isTransferring) return null;

        return <TransferProgressBarWithTitle />;
      },
      displayDataPowerESRITransferResponses: () => {
        if (!task.variables.responses) return null;
        if (task.variables.responses.length === 0) return null;

        return <OverflowCardContainer arr={task.variables.responses} />;
      },
      displayDataPowerESRITransferErrors: () => {
        if (!task.variables.errors) return null;
        if (task.variables.errors.length === 0) return null;

        return <OverflowCardContainer arr={task.variables.errors} />;
      },
      displayDataPowerESRITransferPayloads: () => {
        if (!task.variables.payloads) return null;
        if (task.variables.payloads.length === 0) return null;

        return <OverflowCardContainer arr={task.variables.payloads} />;
      },
      displayDataPowerESRILoadTest: () => {
        if (!task.variables.loadtest) return null;
        if (task.variables.loadtest.length <= 1) return null;

        const loadtest = task.variables.loadtest;

        return (
          <Card body className="display-container">
            <Input
              type="textarea"
              id="loadtest"
              rows="5"
              defaultValue={loadtest}
            />
          </Card>
        );
      },
      manageRiaPoRequest: () => {
        if (!task.variables.prompt) return null;
        if (task.variables.prompt.length === 0) return null;

        return <ManageRiaPoRequest prompt={task.variables.prompt} />;
      },
      displayDataPowerMaximoTransferProgress: () => {
        if (!isTransferring) return null;

        return <TransferProgressBar />;
      },
      displayDataPowerMaximoTransferResponses: () => {
        if (!task.variables.responses) return null;
        if (task.variables.responses.length === 0) return null;

        return <OverflowCardContainer arr={task.variables.responses} />;
      },
      displayDataPowerMaximoTransferErrors: () => {
        if (!task.variables.errors) return null;
        if (task.variables.errors.length === 0) return null;

        return <OverflowCardContainer arr={task.variables.errors} />;
      },
      displayDataPowerMaximoTransferPayloads: () => {
        if (!task.variables.payloads) return null;
        if (task.variables.payloads.length === 0) return null;

        return <OverflowCardContainer arr={task.variables.payloads} />;
      },
      displayDataPowerMaximoLoadTest: () => {
        if (!task.variables.loadtest) return null;
        if (task.variables.loadtest.length <= 1) return null;

        const loadtest = task.variables.loadtest;

        return (
          <Card body className="display-container">
            <Input
              type="textarea"
              id="loadtest"
              rows="5"
              defaultValue={loadtest}
            />
          </Card>
        );
      },
      displayReinforcedPolesProgress: () => {
        if (!isTransferring) return null;

        return <TransferProgressBar />;
      }
    };

    const DisplayStateReport = (props) => {
      const { item, rendering } = props;

      const [open, setOpen] = useState(false);
      const [collapseIcon, setCollapseIcon] = useState(faChevronDown);
      const toggle = () => {
        const isOpen = !open;
        const icon = isOpen ? faChevronUp : faChevronDown;

        setCollapseIcon(icon);
        setOpen(isOpen);
      };
      const status = task.status;
      const color = status === "passed" ? "success" : "warning";

      return (
        <div className="mt-1rem">
          <Button outline size="sm" color={color} onClick={toggle}>
            {item.caption} <FontAwesomeIcon icon={collapseIcon} />
          </Button>
          {open && rendering}
        </div>
      );
    };

    const DisplayStateReportCollapse = (props) => {
      const { item, rendering } = props;

      return (
        <div className="mt-1rem">
          {item.caption && (
            <CardText>
              <b>{item.caption}</b>
            </CardText>
          )}
          {rendering}
        </div>
      );
    };

    const Display = (props) => {
      const { item } = props;

      const rendering = DisplayHandlers[item.handler]();

      const notCollapse = [
        "displayPoCheckProgress",
        "displayPoCheckPOFound",
        "displayDataPowerMaximoTransferProgress",
        "displayDataPowerESRITransferProgress",
        "displayDataLakeTransferProgress",
        "displayCheckDuplicateGlnxy",
        "displayGlobalIdValidationProgress",
        "displayMissingGlobalIds",
        "displayInvalidGlobalIds",
        "displayMismatchedLocationData",
        "displayImageIntegrityReport",
        "displayReinforcedPolesProgress",
        "displayReinforcedPoles",
        "displayReinforcementReinspections",
        "displayReinforcementInspections",
        "displayFormatValidationProgress",
        "displayFormatValidationResults",
        "displayEngineeringStandardsCompliance",
        "manageRiaPoRequest"
      ];

      if (rendering && notCollapse.includes(item.handler))
        return <DisplayStateReportCollapse item={item} rendering={rendering} />;

      if (rendering) {
        return <DisplayStateReport item={item} rendering={rendering} />;
      } else {
        return null;
      }
    };

    const InspectObject = () => {
      const [open, setOpen] = useState(false);
      const [collapseIcon, setCollapseIcon] = useState(faChevronDown);
      const toggle = () => {
        const isOpen = !open;
        const icon = isOpen ? faChevronUp : faChevronDown;

        setCollapseIcon(icon);
        setOpen(isOpen);
      };

      return (
        <div className="mt-1rem">
          <Button outline size="sm" color="secondary" onClick={toggle}>
            Inspect Object <FontAwesomeIcon icon={collapseIcon} />
          </Button>
          {open && (
            <Card body className="inspect-obj-card">
              <Json data={task} />
            </Card>
          )}
        </div>
      );
    };

    return (
      <Card className="mb-1rem">
        <CardHeader className={headerClass}>{task.label}</CardHeader>
        <CardBody>
          <CardText>
            <b>Task:</b> {task.label}
          </CardText>
          <CardText>
            <b>Description:</b>
            <br />
            {task.description}
          </CardText>
          {task.required && Object.keys(task.checks).length > 0 && (
            <CardText>
              <b>Checks:</b>
              <br />
              {Object.keys(task.checks).map((check, i) => (
                <CheckButton key={i} taskName={task.name} checkName={check} />
              ))}
            </CardText>
          )}
          {task.status && (
            <CardText>
              <b>Status:</b> {task.status}
            </CardText>
          )}
          {task.display.length > 0 &&
            task.display.map((item, i) => <Display key={i} item={item} />)}
          {activateButton && <ActivateTaskButton />}
          {deactivateButton && <DeactivateTaskButton />}
          {task.logs.updated && <TaskLog log={task.logs.updated} />}
          {verbose && <InspectObject />}
        </CardBody>
      </Card>
    );
  };

  const getCircuitHeaderClass = () => {
    let classStr = "";

    switch (circuit.status) {
      case CIRCUITSTATUS_INITIATED:
        classStr = "card-header-warning";
        break;
      case CIRCUITSTATUS_STAGING:
        classStr = "card-header-warning";
        break;
      case CIRCUITSTATUS_AUDITING:
        classStr = "card-header-warning";
        break;
      case CIRCUITSTATUS_DELIVERING:
        classStr = "card-header-warning";
        break;
      case CIRCUITSTATUS_DELIVERED:
        classStr = "card-header-success";
        break;
      default:
        classStr = "card-header-danger";
    }

    return classStr;
  };

  const getTaskByType = (type) => {
    return circuit.pipeline.filter((task) => task.type === type);
  };

  const TaskGroup = (props) => {
    const { type, handleUpdateCircuit, activeTaskGroup, circuit } = props;

    const isCollapsed = type === activeTaskGroup;
    const [collapse, setCollapse] = useState(isCollapsed);
    const [collapseIcon, setCollapseIcon] = useState(faChevronDown);

    const toggle = () => setCollapse(!collapse);
    const onEntering = () => setCollapseIcon(faChevronUp);
    const onExiting = () => setCollapseIcon(faChevronDown);

    let headerTitle = "";
    const taskList = getTaskByType(type);
    const required = taskList
      .map((task) => (task.required ? 1 : 0))
      .reduce((a, b) => a + b, 0);
    const passed = taskList
      .map((task) => (task.status === "passed" ? 1 : 0))
      .reduce((a, b) => a + b, 0);
    const progress = required
      ? `${passed ? passed : "None"} of ${required} tasks completed`
      : "No tasks required";

    // Set card title
    switch (type) {
      case "staging":
        headerTitle = "Staging Tasks";
        break;
      case "audit":
        headerTitle = "Audit Tasks";
        break;
      case "delivery":
        headerTitle = "Delivery Tasks";
        break;
      default:
      // No nothing
    }

    // Set group class name
    let groupClassName = "";
    let badgeAndButtonClassName = "";

    if (passed === required) {
      groupClassName = "card-header-success";
      badgeAndButtonClassName = "success";
    } else {
      groupClassName = "card-header-warning";
      badgeAndButtonClassName = "warning";
    }

    return (
      <Card className="task-group">
        <CardHeader className={groupClassName}>
          {headerTitle}{" "}
          <Badge color={badgeAndButtonClassName} pill>
            {progress}
          </Badge>
          <Button
            color={badgeAndButtonClassName}
            size="sm"
            onClick={toggle}
            className="collapse-button"
          >
            <FontAwesomeIcon icon={collapseIcon} />
          </Button>
        </CardHeader>
        <Collapse
          isOpen={collapse}
          onEntering={onEntering}
          onExiting={onExiting}
        >
          <CardBody>
            {taskList.map((task, i) => (
              <Task
                key={i}
                taskData={task}
                circuit={circuit}
                handleUpdateCircuit={handleUpdateCircuit}
              />
            ))}
          </CardBody>
        </Collapse>
      </Card>
    );
  };

  const CircuitLog = () => {
    return (
      <Card
        body
        inverse
        style={{
          backgroundColor: "#f7f7f7",
          marginTop: "1.5rem"
        }}
      >
        <CardText>
          <CreatedOnField at={circuit.createdAt} />
        </CardText>
        {circuit.updatedBy && (
          <CardText>
            <UpdatedByField
              by={circuit.updatedBy.name}
              at={circuit.updatedAt}
            />
          </CardText>
        )}
      </Card>
    );
  };

  return (
    <div className="CircuitAudit">
      <Card className="mb-1rem">
        <CardHeader className={getCircuitHeaderClass()}>
          {circuit.circuit}
        </CardHeader>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggleTabs("1");
              }}
            >
              Audit
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggleTabs("2");
              }}
            >
              Pipeline
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <CardBody>
              <CardText>
                <b>Circuit:</b> {circuit.circuit}
              </CardText>
              <CardText>
                <b>Status:</b> {circuit.status}
              </CardText>
              {circuit.version && (
                <CardText>
                  <b>Version:</b> {circuit.version}
                </CardText>
              )}
              <CircuitLog />
            </CardBody>
          </TabPane>
          <TabPane tabId="2">
            <CardBody>
              {taskGroups.map((tg, i) => (
                <TaskGroup
                  key={i}
                  type={tg}
                  circuit={circuit}
                  handleUpdateCircuit={updateCircuit}
                  activeTaskGroup={activeTaskGroup}
                />
              ))}
            </CardBody>
          </TabPane>
        </TabContent>
      </Card>
    </div>
  );
};

export default CircuitAudit;
