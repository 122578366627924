import { useEffect, useState } from "react";
import { defer } from "rxjs";
import { getSomeFieldsObj } from "../services/fieldsService";

export const useGetBuilderFields = (someFields) => {
  const [fields, setFields] = useState(null);

  useEffect(() => {
    if (!someFields) {
      return;
    }

    const fetchFields = async (someFields) => {
      return await getSomeFieldsObj(someFields);
    };

    const subscription = defer(() => fetchFields(someFields)).subscribe(
      setFields
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [someFields]);

  return fields;
};
