import React from "react";
import AzUsageDetails from "../components/AzUsageDetails";
import "./AzCostManagement.css";

const AzCostManagement = (props) => {
  return (
    <div className="AzCostManagement">
      <div className="container">
        <AzUsageDetails {...props} />
      </div>
    </div>
  );
};

export default AzCostManagement;
