import axios from "axios";
import { config } from "./config";

const stage = config.stage;
const settings = config[stage].appService;

const signalRNegotiate = async (hubName) => {
  const url = `${settings.baseUrl}/api/negotiate?hubName=${hubName}&code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: null,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

export { signalRNegotiate };
