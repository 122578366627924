import React from "react";
import ReinforcementInspectionList from "../components/ReinforcementInspectionList";
import "./ReinforcementInspection.css";

const ReinforcementInspection = (props) => {
  return (
    <div className="ReinforcementInspection">
      <div className="container">
        <ReinforcementInspectionList {...props} />
      </div>
    </div>
  );
};

export default ReinforcementInspection;
