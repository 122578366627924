import { bulkGetAggregate } from "./bulkServices";

const getRep1 = async (circuit) => {
  const query = {
    collection: "DataFiles",
    aggregate: [
      {
        $match: {
          circuit,
          suffix: "PIT"
        }
      },
      {
        $sort: {
          "document.PoleTagClass": 1
        }
      },
      {
        $group: {
          _id: {
            PoleTagClass: "$document.PoleTagClass",
            PoleStatus: "$document.PoleStatus",
            InspectionTagType: "$document.InspectionTagType"
          },
          poles: {
            $sum: 1
          }
        }
      },
      {
        $project: {
          _id: 1,
          poles: 1
        }
      }
    ],
    confirm: true
  };

  return await bulkGetAggregate(query);
};

const getRep2 = async (circuit) => {
  const query = {
    collection: "DataFiles",
    aggregate: [
      {
        $match: {
          circuit,
          suffix: "PIT"
        }
      },
      {
        $sort: {
          "document.PoleTagClass": 1
        }
      },
      {
        $group: {
          _id: {
            PoleTagClass: "$document.PoleTagClass",
            PoleStatus: "$document.PoleStatus",
            PoleTagSpeciesTreatmentCode: "$document.PoleTagSpeciesTreatmentCode"
          },
          poles: {
            $sum: 1
          }
        }
      },
      {
        $project: {
          _id: 1,
          poles: 1
        }
      }
    ],
    confirm: true
  };

  const res = await bulkGetAggregate(query);

  return res;
};

export { getRep1, getRep2 };
