import axios from "axios";
import { config } from "./config";

const stage = config.stage;
const settings = config[stage].appService;

const bulkGet = async (query) => {
  const url = `${settings.baseUrl}/api/bulkGet?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: query,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const bulkGetAggregate = async (query) => {
  const url = `${settings.baseUrl}/api/bulkGetAggregate?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: query,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const bulkUpdate = async (query) => {
  const url = `${settings.baseUrl}/api/bulkUpdate?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: query,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const bulkUpdateGen = async (query) => {
  const url = `${settings.baseUrl}/api/bulkUpdateGen?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: query,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const bulkInsert = async (query) => {
  const url = `${settings.baseUrl}/api/bulkInsert?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: query,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

export { bulkGet, bulkGetAggregate, bulkUpdate, bulkUpdateGen, bulkInsert };
