import axios from "axios";
import { config } from "./config";

const stage = config.stage;
const settings = config[stage].appService;

const getSetting = async (settingId) => {
  const url = `${settings.baseUrl}/api/settingGet?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: { settingId }
  });

  return data;
};

const getSettings = async (query) => {
  const url = `${settings.baseUrl}/api/settingsList?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: query
  });

  return data;
};

const createSetting = async (doc) => {
  const url = `${settings.baseUrl}/api/settingInsert?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "POST",
    url: url,
    data: doc
  });

  return data;
};

const updateSetting = async (settingId, doc) => {
  const url = `${settings.baseUrl}/api/settingUpdate?code=${settings.hostKey}`;
  const { data } = await axios({
    method: "PUT",
    url: url,
    params: { settingId },
    data: doc
  });

  return data;
};

const deleteSetting = async (settingId) => {
  const url = `${settings.baseUrl}/api/settingDelete?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "DELETE",
    url: url,
    params: { settingId }
  });

  return data;
};

export { getSetting, getSettings, createSetting, updateSetting, deleteSetting };
