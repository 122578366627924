import axios from "axios";
import { config } from "./config";

const stage = config.stage;
const settings = config[stage].appService;

const getCircuitsByStatus = async (poleStatus) => {
  const url = `${settings.baseUrl}/api/bulkGetAggregate?code=${settings.hostKey}`;
  const collection = "DataFiles";
  const fieldset = "poleReinforcementAudit";
  const ignore = false;

  const aggregate = [
    {
      $match: {
        fieldset,
        ignore
      }
    },
    {
      $group: {
        _id: "$circuit"
      }
    },
    {
      $project: {
        _id: 1
      }
    }
  ];

  if (poleStatus) aggregate[0]["$match"]["status"] = poleStatus;

  const doc = {
    collection,
    aggregate,
    confirm: true
  };

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const getNonApprovedPolesInCircuit = async (circuit) => {
  const url = `${settings.baseUrl}/api/bulkGet?code=${settings.hostKey}`;

  const query = {
    collection: "DataFiles",
    filter: {
      circuit,
      fieldset: "poleReinforcementAudit",
      approved: false,
      ignore: false
    },
    sort: {
      parsedAt: 1
    },
    project: {
      _id: 0,
      dataPointId: 1,
      status: 1,
      inspections: 1,
      notifiedReinspection: 1
    },
    confirm: true
  };

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: query,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const getNonApprovedInspectionsInCircuit = async (circuit) => {
  const url = `${settings.baseUrl}/api/bulkGet?code=${settings.hostKey}`;

  const query = {
    collection: "PoleReinforcementInspections",
    filter: {
      circuit,
      approved: false,
      "results.billing": null
    },
    sort: {
      submittedAt: 1
    },
    project: {
      _id: 0,
      dataPointId: 1,
      status: 1,
      submittedAt: 1,
      "results.notifiedReinspection": 1
    },
    confirm: true
  };

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: query,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const getLastInspections = async (circuit) => {
  const url = `${settings.baseUrl}/api/bulkGetAggregate?code=${settings.hostKey}`;

  const doc = {
    collection: "PoleReinforcementInspections",
    aggregate: [
      {
        $match: {
          circuit,
          approved: false
        }
      },
      {
        $sort: {
          submittedAt: 1
        }
      },
      {
        $group: {
          _id: {
            circuit: "$circuit",
            dataPointId: "$dataPointId"
          },
          doc: {
            $last: "$$ROOT"
          }
        }
      },
      {
        $replaceRoot: {
          newRoot: "$doc"
        }
      },
      {
        $project: {
          _id: 0,
          inspectionId: 1,
          dataPointId: 1,
          circuit: 1,
          status: 1,
          approved: 1,
          submittedAt: 1,
          user: 1,
          results: 1,
          "document.ReinforcementQAComments": 1,
          notifiedReinspection: 1
        }
      }
    ],
    confirm: true
  };

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const getPoleListInCircuit = async (circuit, poleStatus = null) => {
  const url = `${settings.baseUrl}/api/bulkGet?code=${settings.hostKey}`;

  const collection = "DataFiles";
  const fieldset = "poleReinforcementAudit";
  const ignore = false;
  const filter = {
    circuit,
    fieldset,
    ignore
  };

  if (poleStatus) filter["status"] = poleStatus;

  const doc = {
    collection,
    filter,
    project: {
      _id: 0,
      dataPointId: 1
    },
    confirm: true
  };

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const getRipInspectionsAndApproval = async (dataPointId, circuit) => {
  const url = `${settings.baseUrl}/api/bulkGet?code=${settings.hostKey}`;

  const collection = "PoleReinforcementInspections";

  const doc = {
    collection,
    filter: {
      dataPointId,
      circuit
    },
    sort: {
      submittedAt: 1
    },
    project: {
      _id: 0,
      status: 1,
      notifiedReinspection: 1,
      approved: 1,
      document: 1
    },
    confirm: true
  };

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  const ret = {
    inspections: 0,
    approved: false,
    status: "inspect",
    notifiedReinspection: null,
    document: {}
  };

  if (status === 200) {
    const len = data.length;

    if (len > 0) {
      const lastInspection = data[len - 1];

      ret.inspections = len;
      ret.approved = lastInspection.approved;
      ret.status = lastInspection.status;
      ret.notifiedReinspection = lastInspection?.notifiedReinspection ?? null;
      ret.document = lastInspection.document;
    }
  }

  return ret;
};

export {
  getLastInspections,
  getPoleListInCircuit,
  getCircuitsByStatus,
  getNonApprovedPolesInCircuit,
  getNonApprovedInspectionsInCircuit,
  getRipInspectionsAndApproval
};
