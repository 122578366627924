import axios from "axios";
import { config } from "./config";

const stage = config.stage;
const settings = config[stage].appService;

const getDataFileEntry = async (dataPointId, fieldset) => {
  const url = `${settings.baseUrl}/api/dataFileGet?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: { dataPointId, fieldset }
  });

  return data;
};

const getDataFileEntries = async (query) => {
  const url = `${settings.baseUrl}/api/dataFilesList?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: query
  });

  return data;
};

const createDataFileEntry = async (doc) => {
  const url = `${settings.baseUrl}/api/dataFileInsert?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const updateDataFileEntry = async (dataPointId, fieldset, doc) => {
  const url = `${settings.baseUrl}/api/dataFileUpdate?code=${settings.hostKey}`;
  // const url = `${settings.baseUrl}/api/dataFileUpdate?code=123${settings.hostKey}`;
  const { data, status } = await axios({
    method: "PUT",
    url: url,
    params: { dataPointId, fieldset },
    data: doc
  });

  return { status, response: data };
};

const deleteDataFileEntry = async (dataPointId, source) => {
  const url = `${settings.baseUrl}/api/dataFileDelete?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "DELETE",
    url: url,
    params: { dataPointId, source }
  });

  return data;
};

const getCircuitList = async (query) => {
  const url = `${settings.baseUrl}/api/circuitList?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: { query }
  });

  return data;
};

const getCircuitListCount = async (query) => {
  const url = `${settings.baseUrl}/api/circuitListCount?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: { query }
  });

  return data;
};

export {
  getDataFileEntry,
  getDataFileEntries,
  createDataFileEntry,
  updateDataFileEntry,
  deleteDataFileEntry,
  getCircuitList,
  getCircuitListCount
};
