const emailBillingCategories = ["truss", "treatment"];
const categories = ["critical", "treatment", "groundWire", "tags", "truss"];

const questions = [
  {
    field: "LocationNotAlongOrInWater",
    caption: "Pole set 100+ ft from water?",
    description:
      "Is the pole set back from a large body of water such as river, stream, or pond by 100ft or more?",
    category: "critical",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: "Not Acceptable"
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "If you see this message, contact the Project Manager. An error occurred in the defect resolution process"
        },
        vendor: {
          poleInspection: {
            fieldwork: [
              "Replace yellow band with red and retake photo of completed job"
            ],
            data: [
              "Update record to Non-Restorable Reject for PoleStatus_PIT, reupload csv, and upload new photo|pitPoleStatusNonRestorableReject"
            ],
            invoices: [
              "Remove the cost of below ground treatment for full excavations"
            ]
          },
          poleReinforcement: {
            fieldwork: null,
            data: ["Remove pole from dataset and reupload csv|ripRemovePole"],
            invoices: ["Update for cost of visual inspection"]
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    seq: 1
  },
  {
    field: "LocationNotUsedForFood",
    caption: "Pole set away from garden/farm?",
    description: "Is the pole set outside of soil used to grow food?",
    category: "critical",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: "Not Acceptable"
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "If you see this message, contact the Project Manager. An error occurred in the defect resolution process"
        },
        vendor: {
          poleInspection: {
            fieldwork: [
              "Replace yellow band with red and retake photo of completed job"
            ],
            data: [
              "Update record to Non-Restorable Reject for PoleStatus_PIT, reupload csv, and upload new photo|pitPoleStatusNonRestorableReject"
            ],
            invoices: [
              "Remove the cost of below ground treatment for full excavations"
            ]
          },
          poleReinforcement: {
            fieldwork: null,
            data: ["Remove pole from dataset and reupload csv|ripRemovePole"],
            invoices: ["Update for cost of visual inspection"]
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    seq: 2
  },
  {
    field: "LocationNotOnSchoolGround",
    caption: "Pole set away from school property?",
    description:
      "Is the pole set outside of school property (Day care and Grades K-12)",
    category: "critical",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: "Not Acceptable"
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "If you see this message, contact the Project Manager. An error occurred in the defect resolution process"
        },
        vendor: {
          poleInspection: {
            fieldwork: [
              "Replace yellow band with red and retake photo of completed job"
            ],
            data: [
              "Update record to Non-Restorable Reject for PoleStatus_PIT, reupload csv, and upload new photo|pitPoleStatusNonRestorableReject"
            ],
            invoices: [
              "Remove the cost of below ground treatment for full excavations"
            ]
          },
          poleReinforcement: {
            fieldwork: null,
            data: ["Remove pole from dataset and reupload csv|ripRemovePole"],
            invoices: ["Update for cost of visual inspection"]
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    seq: 3
  },
  {
    field: "LocationWiresNotCrossingOverHighway",
    caption: "OH wires avoid crossing highway?",
    description: "OH wires from pole avoid crossing over highway?",
    category: "critical",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: "Not Acceptable"
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "If you see this message, contact the Project Manager. An error occurred in the defect resolution process"
        },
        vendor: {
          poleInspection: {
            fieldwork: [
              "Replace yellow band with red and retake photo of completed job"
            ],
            data: [
              "Update record to Non-Restorable Reject for PoleStatus_PIT, reupload csv, and upload new photo|pitPoleStatusNonRestorableReject"
            ],
            invoices: [
              "Remove the cost of below ground treatment for full excavations"
            ]
          },
          poleReinforcement: {
            fieldwork: null,
            data: ["Remove pole from dataset and reupload csv|ripRemovePole"],
            invoices: ["Update for cost of visual inspection"]
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    seq: 4
  },
  {
    field: "LocationWiresNotCrossingOverRailroad",
    caption: "OH wires avoid crossing Rail Rd?",
    description: "OH wires from pole avoid crossing over railroad tracks?",
    category: "critical",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: "Not Acceptable"
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "If you see this message, contact the Project Manager. An error occurred in the defect resolution process",
          reinspection: "TBD"
        },
        vendor: {
          poleInspection: {
            fieldwork: [
              "Replace yellow band with red and retake photo of completed job"
            ],
            data: [
              "Update record to Non-Restorable Reject for PoleStatus_PIT, reupload csv, and upload new photo|pitPoleStatusNonRestorableReject"
            ],
            invoices: [
              "Remove the cost of below ground treatment for full excavations"
            ]
          },
          poleReinforcement: {
            fieldwork: null,
            data: ["Remove pole from dataset and reupload csv|ripRemovePole"],
            invoices: ["Update for cost of visual inspection"]
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    seq: 5
  },
  {
    field: "LocationNotOnPropertyWithPrivateWell",
    caption: "Pole set 50+ ft from well?",
    description: "Pole set at least 50 feet away from well or ground water?",
    category: "critical",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: "Not Acceptable"
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "If you see this message, contact the Project Manager. An error occurred in the defect resolution process"
        },
        vendor: {
          poleInspection: {
            fieldwork: [
              "Replace yellow band with red and retake photo of completed job"
            ],
            data: [
              "Update record to Non-Restorable Reject for PoleStatus_PIT, reupload csv, and upload new photo|pitPoleStatusNonRestorableReject"
            ],
            invoices: [
              "Remove the cost of below ground treatment for full excavations"
            ]
          },
          poleReinforcement: {
            fieldwork: null,
            data: ["Remove pole from dataset and reupload csv|ripRemovePole"],
            invoices: ["Update for cost of visual inspection"]
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    seq: 6
  },
  {
    field: "NonRiserCablePole",
    caption: "Pole lacks DTE riser / UG cable?",
    description:
      "Is the pole free of a DTE riser and/or UG cable? Joint Use riser is considered non-riser pole in this case.",
    category: "critical",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: "Not Acceptable"
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "If you see this message, contact the Project Manager. An error occurred in the defect resolution process"
        },
        vendor: {
          poleInspection: {
            fieldwork: [
              "Replace yellow band with red and retake photo of completed job"
            ],
            data: [
              "Update record to Non-Restorable Reject for PoleStatus_PIT, reupload csv, and upload new photo|pitPoleStatusNonRestorableReject"
            ],
            invoices: [
              "Remove the cost of below ground treatment for full excavations"
            ]
          },
          poleReinforcement: {
            fieldwork: null,
            data: ["Remove pole from dataset and reupload csv|ripRemovePole"],
            invoices: ["Update for cost of visual inspection"]
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    seq: 7
  },
  {
    field: "OHWireOnPole",
    caption: "Pole has OH wire?",
    description: "Is there OH electrical conductor on the pole?",
    category: "critical",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: "Not Acceptable"
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "If you see this message, contact the Project Manager. An error occurred in the defect resolution process"
        },
        vendor: {
          poleInspection: {
            fieldwork: [
              "Replace yellow band with red and retake photo of completed job"
            ],
            data: [
              "Update record to Non-Restorable Reject for PoleStatus_PIT, reupload csv, and upload new photo|pitPoleStatusNonRestorableReject"
            ],
            invoices: [
              "Remove the cost of below ground treatment for full excavations"
            ]
          },
          poleReinforcement: {
            fieldwork: null,
            data: ["Remove pole from dataset and reupload csv|ripRemovePole"],
            invoices: ["Update for cost of visual inspection"]
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    seq: 8
  },
  {
    field: "PoleStatus",
    caption: "Is pole free of visual defects?",
    description:
      "Is the pole free of visual defects requiring replacement?  Determining lean may require level and measuring tape.",
    category: "critical",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: {
          caption: "If no, select defect",
          inputElement: "select",
          values: [
            {
              caption: "Select defect",
              value: ""
            },
            {
              caption: "Carpenter Ant Infestation",
              value: "Carpenter Ant Infestation"
            },
            {
              caption: "Decay Affects Hardware",
              value: "Decay Affects Hardware"
            },
            {
              caption: "Deep Large Checks/Splits Running Top to Bottom",
              value: "Deep Large Checks/Splits Running Top to Bottom"
            },
            {
              caption: "Excessive Lean",
              value: "Excessive Lean"
            },
            {
              caption: "Extensive Shell Rot",
              value: "Extensive Shell Rot"
            },
            {
              caption: "Extensive Surface Decay",
              value: "Extensive Surface Decay"
            },
            {
              caption: "Fire Damage",
              value: "Fire Damage"
            },
            {
              caption: "Mechanical Damage",
              value: "Mechanical Damage"
            },
            {
              caption: "Severe Compression",
              value: "Severe Compression"
            },
            {
              caption: "Severe Spur Damage",
              value: "Severe Spur Damage"
            }
          ]
        }
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "If you see this message, contact the Project Manager. An error occurred in the defect resolution process"
        },
        vendor: {
          poleInspection: {
            fieldwork: [
              "Replace yellow band with red and retake photo of completed job"
            ],
            data: [
              "Update record to Non-Restorable Reject for PoleStatus_PIT, reupload csv, and upload new photo|pitPoleStatusNonRestorableReject"
            ],
            invoices: [
              "Remove the cost of below ground treatment for full excavations"
            ]
          },
          poleReinforcement: {
            fieldwork: null,
            data: ["Remove pole from dataset and reupload csv|ripRemovePole"],
            invoices: ["Update for cost of visual inspection"]
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    pullMedia: [
      {
        caption: "Pole Lean Calculation",
        container: "mydrive",
        fileName: "pole-lean-calculation.pdf",
        contentType: "application/pdf"
      }
    ],
    seq: 9
  },
  {
    field: "PoleTagOwner",
    caption: "Is the pole owner {defValue}?",
    description:
      "Does the pole tag match the reinforcement record's owner? Respond yes if tag doesn't exist.",
    category: "critical",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: {
          caption: "If no, select owner",
          inputElement: "select",
          values: [
            { caption: "Select owner", value: "" },
            { caption: "AT&T", value: "AT&T" },
            { caption: "City", value: "City" },
            { caption: "Comcast", value: "Comcast" },
            { caption: "PLD", value: "PLD" },
            { caption: "Private", value: "Private" },
            { caption: "Verizon", value: "Verizon" }
          ]
        }
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "If you see this message, contact the Project Manager. An error occurred in the defect resolution process"
        },
        vendor: {
          poleInspection: {
            fieldwork: [
              "Replace yellow band with red and retake photo of completed job"
            ],
            data: [
              "Update record to Non-Restorable Reject for PoleStatus_PIT, reupload csv, and upload new photo|pitPoleStatusNonRestorableReject"
            ],
            invoices: [
              "Remove the cost of below ground treatment for full excavations"
            ]
          },
          poleReinforcement: {
            fieldwork: null,
            data: ["Remove pole from dataset and reupload csv|ripRemovePole"],
            invoices: ["Update for cost of visual inspection"]
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    seq: 10
  },
  {
    field: "PoleTagSpeciesTreatmentCode",
    caption: "Is the species/treat code {defValue}?",
    description:
      "Does the pole tag match the reinforcement record's species/treat code? Respond yes if tag doesn't exist.",
    category: "critical",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: {
          caption: "If no, select code",
          inputElement: "select",
          values: [
            { caption: "Select code", value: "" },
            { caption: "A", value: "A" },
            { caption: "B", value: "B" },
            { caption: "BB", value: "BB" },
            { caption: "D", value: "D" },
            { caption: "DD", value: "DD" },
            { caption: "E", value: "E" },
            { caption: "G", value: "G" },
            { caption: "GC", value: "GC" },
            { caption: "GD", value: "GD" },
            { caption: "H", value: "H" },
            { caption: "K", value: "K" },
            { caption: "L", value: "L" },
            { caption: "M", value: "M" },
            { caption: "NA", value: "NA" },
            { caption: "NB", value: "NB" },
            { caption: "NC", value: "NC" },
            { caption: "ND", value: "ND" },
            { caption: "S", value: "S" },
            { caption: "T", value: "T" },
            { caption: "V", value: "V" },
            { caption: "WC", value: "WC" },
            { caption: "Z", value: "Z" }
          ]
        }
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["GC", "NC", "WC"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection: "Update inspection record"
        },
        vendor: {
          poleInspection: {
            fieldwork: [
              "For GC, NC, and WC, replace yellow band with red and retake photo of completed job"
            ],
            data: [
              "Update record with correct PoleTagSpeciesTreatmentCode_PIT value and reupload csv",
              "For only GC, NC, and WC, in addition, change PoleStatus_PIT to Non-Restorable Reject, change InspectionTagType_PIT to Visual, make any other related data changes, reupload csv, and upload new photo"
            ],
            invoices: [
              "For GC, NC, and WC, update invoice for visual inspection",
              "Remove the cost of below ground treatment for full excavations"
            ]
          },
          poleReinforcement: {
            fieldwork: null,
            data: [
              "For poles with a GC, NC, or WC value in PoleTagSpeciesTreatmentCode_RIP, remove pole from dataset. For all other values, correct the record. Reupload the csv."
            ],
            invoices: [
              "For poles with a GC, NC, or WC value in PoleTagSpeciesTreatmentCode_RIP, update for cost of visual inspection. No action for all other values"
            ]
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    seq: 11
  },
  {
    field: "RetreatmentInternalHoles",
    caption: "Are there fumigant holes or N/A?",
    description:
      "Were holes bored for use of fumigant/rods? Respond yes if fumigants/rods not used according to tags and data.",
    category: "treatment",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: "Not Acceptable"
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "Reinspect for fumigant/rod holes. Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: [
              "Add fumigants/rods to pole to align with data and/or tags"
            ],
            data: [
              "Update record, if needed, for the correct number of fumigant holes and reupload csv"
            ],
            invoices: ["Update invoice, if needed, to match fumigant holes"]
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "treatment"
            }
          },
          onRejected: {
            invoices: {
              category: "treatment"
            }
          }
        }
      }
    },
    seq: 1
  },
  {
    field: "InspectionHolesPlugged",
    caption: "Are holes plugged or N/A?",
    description:
      "Are the holes used for investigative boring, fumigant, and void treatment plugged? Respond yes if pole was not bored.",
    category: "treatment",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: {
          caption: "If no, select defect",
          inputElement: "select",
          values: [
            { caption: "Select defect", value: "" },
            {
              caption: "Fumigant Holes Not Plugged",
              value: "Fumigant Holes Not Plugged"
            },
            {
              caption: "Investigative Holes Not Plugged",
              value: "Investigative Holes Not Plugged"
            }
          ]
        }
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "Verify bored holes are plugged. Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: ["Add plugs to bored holes"],
            data: null,
            invoices: null
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "treatment"
            }
          },
          onRejected: {
            invoices: {
              category: "treatment"
            }
          }
        }
      }
    },
    seq: 2
  },
  {
    field: "GroundlineTreatmentBarrierAttached",
    caption: "Is wrap below ground or N/A?",
    description:
      "Is retreatment wrap covering at least 50% of the pole below ground or not applicable because full excavation not performed?",
    category: "treatment",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: "Not Acceptable"
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection: "Verify wrap was fixed. Update inspection record"
        },
        vendor: {
          poleInspection: {
            fieldwork: [
              "Redo below ground retreatment wrap to cover 50% or more of the pole"
            ],
            data: null,
            invoices: null
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    seq: 3
  },
  {
    field: "GroundWireAttachedWithStaples",
    caption: "Is ground wire stapled or N/A?",
    description:
      "Is the ground wire secured to the pole with steel staples? Respond yes if not applicable.",
    category: "groundWire",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: "Not Acceptable"
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection: "Verify staples were added. Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: [
              "Attach staples to ground wire up to height of the truss"
            ],
            data: null,
            invoices: null
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "truss"
            }
          },
          onRejected: {
            invoices: {
              category: "truss"
            }
          }
        }
      }
    },
    seq: 1
  },
  {
    field: "GroundWireCondition",
    caption: "Is ground wire intact or N/A?",
    description:
      "Is the ground wire complete and undamaged? Respond yes if not applicable.",
    category: "groundWire",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: "Not Acceptable"
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: false,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection: "Verify ground wire repaired. Update inspection record"
        },
        vendor: {}
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "truss"
            }
          },
          onRejected: {
            invoices: {
              category: "truss"
            }
          }
        }
      }
    },
    seq: 2
  },
  {
    field: "StrapsTrussInstalledUnderGroundWire",
    caption: "Straps under ground wire or N/A?",
    description:
      "Are the truss and straps under the ground wire? Respond yes if not applicable.",
    category: "groundWire",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: "Not Acceptable"
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "Verify straps are under the ground wire. Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: [
              "Adjust truss straps so they are under the ground wire"
            ],
            data: null,
            invoices: null
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "truss"
            }
          },
          onRejected: {
            invoices: {
              category: "truss"
            }
          }
        }
      }
    },
    seq: 3
  },
  {
    field: "InsulatorInstalledCorrectly",
    caption: "Is insulator b/t wire and bands?",
    description:
      "Is the insulator material separating the ground wire from the steel truss bands? Respond yes if not applicable.",
    category: "groundWire",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: "Not Acceptable"
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "Verify insulator was added between ground wire and straps. Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: ["Fix insulator between ground wire and straps"],
            data: null,
            invoices: null
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "truss"
            }
          },
          onRejected: {
            invoices: {
              category: "truss"
            }
          }
        }
      }
    },
    seq: 4
  },
  {
    field: "InspectionTagType",
    caption: "Is inspection tag {defValue}?",
    description:
      "Does the inspection tag type match the pole inspection record?",
    category: "tags",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: {
          caption: "If no, select defect",
          inputElement: "select",
          values: [
            {
              caption: "Select defect",
              value: ""
            },
            {
              caption: "Visual",
              value: "Visual"
            },
            {
              caption: "Sound & Bore",
              value: "Sound & Bore"
            },
            {
              caption: "Partial Excavation",
              value: "Partial Excavation"
            },
            {
              caption: "Full Excavation",
              value: "Full Excavation"
            }
          ]
        }
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "Verify pole reinspection was performed. Update inspection record"
        },
        vendor: {
          poleInspection: {
            fieldwork: [
              "If visual or partial excavation performed, reinspect pole per standards"
            ],
            data: [
              "Update InspectionTagType_PIT to align with inspection performed and reupload csv"
            ],
            invoices: ["Update if needed"]
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    seq: 1
  },
  {
    field: "InspectionTagYear",
    caption: "Is the inspection tag year {defValue}?",
    description: "Does the pole inspection year match the inspection record?",
    category: "tags",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: {
          caption: "If no, select year",
          inputElement: "selectint",
          options: {
            first: {
              caption: "Select year",
              value: ""
            },
            range: {
              min: { func: "xYearsAgo", args: [20] },
              max: { func: "currentYear" }
            }
          }
        }
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "Verify pole was reinspected and/or inspection tag attached. Update inspection record"
        },
        vendor: {
          poleInspection: {
            fieldwork: ["Inspect pole and/or attach missing inspection tag"],
            data: [
              "Update InspectionDate_PIT value to align with date pole was inspected and reupload csv"
            ],
            invoices: ["Update if needed"]
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    seq: 2
  },
  {
    field: "ReinforcementTagAttached",
    caption: "Reinforcement tag attached firm?",
    description: "Is the reinforcement tag attached firm with nail?",
    category: "tags",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: {
          caption: "If no, select defect",
          inputElement: "select",
          values: [
            { caption: "Select defect", value: "" },
            { caption: "Tag Loose", value: "Tag Loose" },
            { caption: "Tag Missing", value: "Tag Missing" }
          ]
        }
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection: "Verify tag was fixed. Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: ["Fix missing or loose reinforcment tag"],
            data: null,
            invoices: null
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "truss"
            }
          },
          onRejected: {
            invoices: {
              category: "truss"
            }
          }
        }
      }
    },
    seq: 3
  },
  {
    field: "PoleTagInstallationYear",
    caption: "Was the pole installed in {defValue}?",
    description:
      "Does the pole tag match the reinforcement record's pole installation year? Respond yes if tag doesn't exist.",
    category: "tags",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: {
          caption: "If no, select year",
          inputElement: "selectint",
          options: {
            first: {
              caption: "Select year",
              value: ""
            },
            range: {
              min: { func: "xYearsAgo", args: [100] },
              max: { func: "currentYear" }
            }
          }
        }
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection: "Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: null,
            data: [
              "Update record with correct installation year and reupload csv"
            ],
            invoices: null
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    seq: 4
  },
  {
    field: "PoleTagClass",
    caption: "Is the pole class {defValue}?",
    description:
      "Does the pole tag match the reinforcement record's pole tag class? Respond yes if tag doesn't exist.",
    category: "tags",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: {
          caption: "If no, select pole class",
          inputElement: "select",
          values: [
            { caption: "Select pole class", value: "" },
            { caption: "1", value: "1" },
            { caption: "2", value: "2" },
            { caption: "3", value: "3" },
            { caption: "4", value: "4" },
            { caption: "5", value: "5" },
            { caption: "6", value: "6" },
            { caption: "7", value: "7" },
            { caption: "8", value: "8" },
            { caption: "9", value: "9" },
            { caption: "H1", value: "H1" },
            { caption: "H2", value: "H2" }
          ]
        }
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection: "Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: null,
            data: ["Update record with correct class and reupload csv"],
            invoices: null
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    seq: 5
  },
  {
    field: "PoleTagLength",
    caption: "Is the pole length {defValue}?",
    description:
      "Does the pole tag match the reinforcement record's length? Respond yes if tag doesn't exist.",
    category: "tags",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: {
          caption: "If no, select pole length",
          inputElement: "select",
          values: [
            { caption: "Select pole length", value: "" },
            { caption: 20, value: 20 },
            { caption: 25, value: 25 },
            { caption: 30, value: 30 },
            { caption: 35, value: 35 },
            { caption: 40, value: 40 },
            { caption: 45, value: 45 },
            { caption: 50, value: 50 },
            { caption: 55, value: 55 },
            { caption: 60, value: 60 },
            { caption: 65, value: 65 },
            { caption: 70, value: 70 },
            { caption: 75, value: 75 },
            { caption: 80, value: 80 },
            { caption: 85, value: 85 },
            { caption: 90, value: 90 },
            { caption: 95, value: 95 },
            { caption: 100, value: 100 },
            { caption: 105, value: 105 },
            { caption: 110, value: 110 },
            { caption: 115, value: 115 },
            { caption: 120, value: 120 },
            { caption: 125, value: 125 }
          ]
        }
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection: "Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: null,
            data: ["Update record with correct length and reupload csv"],
            invoices: null
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    seq: 6
  },
  {
    field: "TreatmentTagsAttached",
    caption: "Are ext/int trt tag(s) attached?",
    description:
      "Are all external and internal (fumigant) retreatment tags attached? Respond yes if not applicable.",
    category: "tags",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: {
          caption: "If no, select missing tag(s)",
          inputElement: "select",
          values: [
            { caption: "Select missing tag(s)", value: "" },
            {
              caption: "DuraFume II Missing",
              value: "DuraFume II Missing"
            },
            {
              caption: "MP500-EXT Missing",
              value: "MP500-EXT Missing"
            },
            {
              caption: "DuraFume II & MP500-EXT Missing",
              value: "DuraFume II & MP500-EXT Missing"
            }
          ]
        }
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "Verify retreatment tag(s) attached. Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: ["Add missing retreatment tags"],
            data: null,
            invoices: null
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "treatment"
            }
          },
          onRejected: {
            invoices: {
              category: "treatment"
            }
          }
        }
      }
    },
    seq: 7
  },
  {
    field: "ReinforcementBandRemoved",
    caption: "Is the yellow band removed?",
    description:
      "Has the yellow reinforcement band been removed from the pole?",
    category: "tags",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: "Not Acceptable"
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection: "Verify yellow band removed. Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: ["Remove yellow reinforcement band"],
            data: null,
            invoices: null
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    seq: 8
  },
  {
    field: "VoidTreatmentTagAttached",
    caption: "Void retreatment tag attached?",
    description:
      "Is there a tag attached to the pole with the name of the void retreatment? Respond yes if tag not applicable.",
    category: "tags",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: "Not Acceptable"
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "Verify void retreatment tag was attached. Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: ["Add missing void retreatment tag"],
            data: null,
            invoices: null
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "treatment"
            }
          },
          onRejected: {
            invoices: {
              category: "treatment"
            }
          }
        }
      }
    },
    seq: 9
  },
  {
    field: "TrussInstalledSize",
    caption: "Is truss installed {defValue}?",
    description:
      "Does the truss size installed match the reinforcement record?",
    category: "truss",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: {
          caption: "If no, select truss",
          inputElement: "select",
          values: [
            { caption: "Select truss", value: "" },
            { caption: "5100x10", value: "5100x10" },
            { caption: "C2-3610", value: "C2-3610" },
            { caption: "C2-3613", value: "C2-3613" },
            { caption: "C2-4910", value: "C2-4910" },
            { caption: "C2-4913", value: "C2-4913" },
            { caption: "C2-5610", value: "C2-5610" },
            { caption: "C2-5613", value: "C2-5613" },
            { caption: "C2-7110", value: "C2-7110" },
            { caption: "C2-7113", value: "C2-7113" },
            { caption: "980x10", value: "980x10" },
            { caption: "1080x11", value: "1080x11" },
            { caption: "1080x13", value: "1080x13" },
            { caption: "1180x11", value: "1180x11" },
            { caption: "1180x13", value: "1180x13" },
            { caption: "1280x11", value: "1280x11" },
            { caption: "1280x13", value: "1280x13" },
            { caption: "1380x11", value: "1380x11" },
            { caption: "1480x13", value: "1480x13" },
            { caption: "1580x13", value: "1580x13" },
            { caption: "1680x13", value: "1680x13" },
            { caption: "2-5100x10", value: "2-5100x10" },
            { caption: "C2-3610 D", value: "C2-3610 D" },
            { caption: "C2-4910 D", value: "C2-4910 D" },
            { caption: "C2-5610 D", value: "C2-5610 D" },
            { caption: "C2-7110 D", value: "C2-7110 D" },
            { caption: "2-980x10", value: "2-980x10" },
            { caption: "2-1080x11", value: "2-1080x11" },
            { caption: "2-1180x11", value: "2-1180x11" },
            { caption: "2-1280x11", value: "2-1280x11" },
            { caption: "2-1280x13", value: "2-1280x13" },
            { caption: "2-1380x11", value: "2-1380x11" },
            { caption: "2-1480x13", value: "2-1480x13" },
            { caption: "2-1580x13", value: "2-1580x13" },
            { caption: "2-1680x13", value: "2-1680x13" }
          ]
        }
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection: "Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: null,
            data: [
              "Update record with correct truss size that was installed and reupload csv"
            ],
            invoices: ["Update if needed"]
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    pullMedia: [
      {
        caption: "Truss Identification",
        container: "mydrive",
        fileName: "Truss-Identification.pdf",
        contentType: "application/pdf"
      }
    ],
    seq: 1
  },
  {
    field: "TrussMatchChart",
    caption: "Does truss used match chart?",
    description:
      "Does the truss attached match the manufacturer's truss selection chart?",
    category: "truss",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: {
          caption: "If no, select truss",
          inputElement: "select",
          values: [
            { caption: "Select truss", value: "" },
            { caption: "5100x10", value: "5100x10" },
            { caption: "C2-3610", value: "C2-3610" },
            { caption: "C2-4910", value: "C2-4910" },
            { caption: "C2-5610", value: "C2-5610" },
            { caption: "C2-7110", value: "C2-7110" },
            { caption: "980x10", value: "980x10" },
            { caption: "1080x11", value: "1080x11" },
            { caption: "1180x11", value: "1180x11" },
            { caption: "1280x11", value: "1280x11" },
            { caption: "1380x11", value: "1380x11" },
            { caption: "1480x13", value: "1480x13" },
            { caption: "1580x13", value: "1580x13" },
            { caption: "1680x13", value: "1680x13" },
            { caption: "2-5100x10", value: "2-5100x10" },
            { caption: "C2-3610 D", value: "C2-3610 D" },
            { caption: "C2-4910 D", value: "C2-4910 D" },
            { caption: "C2-5610 D", value: "C2-5610 D" },
            { caption: "C2-7110 D", value: "C2-7110 D" },
            { caption: "2-980x10", value: "2-980x10" },
            { caption: "2-1080x11", value: "2-1080x11" },
            { caption: "2-1180x11", value: "2-1180x11" },
            { caption: "2-1280x11", value: "2-1280x11" },
            { caption: "2-1380x11", value: "2-1380x11" },
            { caption: "2-1480x13", value: "2-1480x13" },
            { caption: "2-1580x13", value: "2-1580x13" },
            { caption: "2-1680x13", value: "2-1680x13" }
          ]
        }
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection: "Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: null,
            data: [
              "Discuss truss installed with Project Manager.  If necessary, update record and reupload"
            ],
            invoices: [
              "Update, if needed, for cost of correct truss or truss installed, whichever is lower"
            ]
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "all"
            }
          },
          onRejected: {
            invoices: {
              category: "all"
            }
          }
        }
      }
    },
    pullMedia: [
      {
        caption: "Truss Matrix 1 of 2",
        container: "mydrive",
        fileName: "Truss-Matrix-1-of-2.pdf",
        contentType: "application/pdf"
      },
      {
        caption: "Truss Matrix 2 of 2",
        container: "mydrive",
        fileName: "Truss-Matrix-2-of-2.pdf",
        contentType: "application/pdf"
      }
    ],
    seq: 2
  },
  {
    field: "ReinforcementCapAttached",
    caption: "Is the truss cap attached?",
    description: "Is the truss cap attached to the top of the truss?",
    category: "truss",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: "Not Acceptable"
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "Verify truss cap was attached. Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: ["Attach missing truss cap"],
            data: null,
            invoices: null
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "truss"
            }
          },
          onRejected: {
            invoices: {
              category: "truss"
            }
          }
        }
      }
    },
    seq: 3
  },
  {
    field: "TrussBandsCapMaterial",
    caption: "Truss, cap, bands galvanized/SS?",
    description:
      "Is the truss galvanized and cap/bands galvanized or stainless steel? The material should not be completely coated in paint. Minor paint used to cover rust is acceptable.",
    category: "truss",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: {
          caption: "If no, select defect",
          inputElement: "select",
          values: [
            { caption: "Select defect", value: "" },
            {
              caption: "Truss Not Galvanized",
              value: "Truss Not Galvanized"
            },
            {
              caption: "Cap Not Galvanized or Stainless Steel",
              value: "Cap Not Galvanized or Stainless Steel"
            },
            {
              caption: "Bands Not Galvanized or Stainless Steel",
              value: "Bands Not Galvanized or Stainless Steel"
            },
            {
              caption:
                "Truss Not Galvanized and Cap Not Galvanized or Stainless Steel",
              value:
                "Truss Not Galvanized and Cap Not Galvanized or Stainless Steel"
            },
            {
              caption:
                "Truss Not Galvanized and Bands Not Galvanized or Stainless Steel",
              value:
                "Truss Not Galvanized and Bands Not Galvanized or Stainless Steel"
            },
            {
              caption:
                "Cap Not Galvanized or Stainless Steel and Bands Not Galvanized or Stainless Steel",
              value:
                "Cap Not Galvanized or Stainless Steel and Bands Not Galvanized or Stainless Steel"
            },
            {
              caption: "None of the materials are acceptable",
              value: "None of the materials are acceptable"
            }
          ]
        }
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "Verify approved material was installed. Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: [
              "Replace painted and/or non-galvanized truss hardware with approved material"
            ],
            data: null,
            invoices: null
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "truss"
            }
          },
          onRejected: {
            invoices: {
              category: "truss"
            }
          }
        }
      }
    },
    seq: 4
  },
  {
    field: "TrussSnug",
    caption: "Is truss firmly attached?",
    description:
      "Is the truss firmly attached with bands wrapped tight around the pole?",
    category: "truss",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: "Not Acceptable"
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "Verify bands are wrapped tightly around pole. Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: ["Tighten bands on truss"],
            data: null,
            invoices: null
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "truss"
            }
          },
          onRejected: {
            invoices: {
              category: "truss"
            }
          }
        }
      }
    },
    seq: 5
  },
  {
    field: "NumberOfTrussBandsInstalled",
    caption: "Are all bands attached?",
    description:
      "Are all bands attached according to manufacturer recommendations?",
    category: "truss",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: {
          caption: "If no, select number of bands attached",
          inputElement: "select",
          values: [
            { caption: "Select number of bands attached", value: "" },
            { caption: 0, value: 0 },
            { caption: 1, value: 1 },
            { caption: 2, value: 2 },
            { caption: 3, value: 3 },
            { caption: 4, value: 4 },
            { caption: 5, value: 5 },
            { caption: 6, value: 6 },
            { caption: 7, value: 7 },
            { caption: 8, value: 8 },
            { caption: 9, value: 9 },
            { caption: 10, value: 10 }
          ]
        }
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection:
            "Verify correct number of bands are attached. Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: ["Add missing truss bands"],
            data: null,
            invoices: null
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "truss"
            }
          },
          onRejected: {
            invoices: {
              category: "truss"
            }
          }
        }
      }
    },
    pullMedia: [
      {
        caption: "Truss Band Guide",
        container: "mydrive",
        fileName: "Truss-Band-Guide.pdf",
        contentType: "application/pdf"
      }
    ],
    seq: 6
  },
  {
    field: "SealsCrimpedFlat",
    caption: "Are seals crimped & flat?",
    description: "Are the band seals crimped and set flat against the pole?",
    category: "truss",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: {
          caption: "If no, select defect",
          inputElement: "select",
          values: [
            { caption: "Select defect", value: "" },
            { caption: "Seals Not Crimped", value: "Seals Not Crimped" },
            { caption: "Seals Not Flat", value: "Seals Not Flat" },
            {
              caption: "Seals Not Crimped and Not Flat",
              value: "Seals Not Crimped and Not Flat"
            }
          ]
        }
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection: "Verify seals were repaired. Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: ["Crimp truss band seals"],
            data: null,
            invoices: null
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "truss"
            }
          },
          onRejected: {
            invoices: {
              category: "truss"
            }
          }
        }
      }
    },
    seq: 7
  },
  {
    field: "SealsPerBand",
    caption: "Are there two band seals?",
    description: "Are there two seals on each band?",
    category: "truss",
    state: true,
    inputElement: "radio",
    positiveAnswer: "Acceptable or Not Applicable",
    values: [
      {
        caption: "Yes",
        value: "Acceptable or Not Applicable"
      },
      {
        caption: "No",
        value: {
          caption: "If no, select number of band seals attached",
          inputElement: "select",
          values: [
            { caption: "Select number of band seals attached", value: "" },
            { caption: 0, value: 0 },
            { caption: 1, value: 1 }
          ]
        }
      }
    ],
    defValue: null,
    onDefect: {
      state: true,
      fixable: true,
      trigger: { notIn: ["Acceptable or Not Applicable"] },
      actions: {
        reinforcementInspector: {
          submission:
            "Wait for the Project Manager's email before processing invoices",
          reinspection: "Verify seals were added. Update inspection record"
        },
        vendor: {
          poleReinforcement: {
            fieldwork: ["Add missing seals to truss bands"],
            data: null,
            invoices: null
          }
        }
      }
    },
    onResolved: {
      state: true,
      actions: {
        reinforcementInspector: {
          onApproved: {
            invoices: {
              category: "truss"
            }
          },
          onRejected: {
            invoices: {
              category: "truss"
            }
          }
        }
      }
    },
    seq: 8
  }
];

const findQuestionsByCategory = (cat) => {
  return questions.filter((q) => q.category === cat && q.state);
};

const getSurveyCategories = () => {
  return categories;
};

const getQuestionsByCategory = (cat) => {
  return findQuestionsByCategory(cat);
};

const getAllQuestions = () => questions;

export {
  emailBillingCategories,
  getSurveyCategories,
  getQuestionsByCategory,
  getAllQuestions
};
