import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { getLastInspections } from "../services/reinforcementInspectionsService";
import PoleReinforcementInspectionResolve from "../components/PoleReinforcementInspectionResolve";
import "./PoleReinforcementInspection.css";

const circuit = "HEMLK1995";

const PoleReinforcementInspection = (props) => {
  const { user, sendEmail } = props;

  const userInfo = {
    id: user.id,
    name: user.name,
    org: user.org,
    email: user.email
  };

  const [inspections, setInspections] = useState([]);

  const loadInspections = async () => {
    const res = await getLastInspections(circuit);

    if (res.status === 200) {
      const inspections = res.response.filter(
        (item) => item.status !== "reinspect"
      );

      setInspections(inspections);
    }
  };

  return (
    <div className="PoleReinforcementInspection">
      <div className="container">
        <Card className="cards-container">
          <CardHeader>
            <div style={{ overflow: "hidden" }}>
              <b>Defects and pending inspections</b>
              <Button
                color="primary"
                size="sm"
                style={{ float: "right" }}
                onClick={() => loadInspections("poleReinforcementAudit")}
              >
                Update list
              </Button>
            </div>
          </CardHeader>
          <CardBody className="overflow-500">
            {inspections.map((inspection, i) => (
              <PoleReinforcementInspectionResolve
                key={i}
                user={user}
                userInfo={userInfo}
                inspection={inspection}
                handleLoadInspections={loadInspections}
                sendEmail={sendEmail}
              />
            ))}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default PoleReinforcementInspection;
