import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane
} from "reactstrap";
import ReinforcementInspectionPole from "./ReinforcementInspectionPole";
import { getDataFileEntries } from "../services/dataFileService";
import { getCollectionCount } from "../services/collectionService";
import {
  getPoleListInCircuit,
  getCircuitsByStatus
} from "../services/reinforcementInspectionsService";
import classnames from "classnames";
import "./ReinforcementInspectionList.css";
import GetPagination from "./GetPagination";
import CardText from "reactstrap/lib/CardText";

const ReinforcementInspectionList = (props) => {
  const { user } = props;

  const userInfo = {
    id: user.id,
    name: user.name,
    org: user.org,
    email: user.email
  };

  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);
  const [isLoading, setIsLoading] = useState(false);

  const [dataFileStatus, setDataFileStatus] = useState("");
  const [circuitContains, setCircuitContains] = useState("");
  const [poleContains, setPoleContains] = useState("");
  const [circuitsToSearch, setCircuitsToSearch] = useState(null);
  const [polesToSearch, setPolesToSearch] = useState(null);

  const statiOptions = [
    { caption: "All", value: "all" },
    { caption: "Ready for inspection", value: "inspect" },
    { caption: "Ready for reinspection", value: "reinspect" },
    { caption: "Submitted", value: "submitted" },
    { caption: "Submitted with defects", value: "submitted with defects" },
    { caption: "Submitted with warnings", value: "submitted with warnings" }
  ];

  const [dataFiles, setDataFiles] = useState([]);

  const newSearch = () => {
    setIsLoading(false);
    setDataFiles([]);
    toggleSearch();
  };

  // Control pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [dataFilesCount, setDataFilesCount] = useState(0);

  const totalDataFilesPerPage = 20;

  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setDataFiles([]);

    listDataFiles(pageNumber, totalDataFilesPerPage);
  };

  const incChange = (pageNumber, inc, isposOrneg) => {
    let finalPage;
    const residual = pageNumber % inc;
    const control = residual === 0 ? inc : residual;

    if (isposOrneg > 0) {
      finalPage = pageNumber + inc - control + 1;
    } else {
      finalPage = pageNumber - inc - control + 1;
    }

    setCurrentPage(finalPage);
    setDataFiles([]);

    listDataFiles(finalPage, totalDataFilesPerPage);
  };

  const firstChange = () => {
    let finalPage = 1;

    setCurrentPage(finalPage);
    setDataFiles([]);

    listDataFiles(finalPage, totalDataFilesPerPage);
  };

  const lastChange = (finalPage) => {
    setCurrentPage(finalPage);
    setDataFiles([]);

    listDataFiles(finalPage, totalDataFilesPerPage);
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const validateForm = () => {
    let valid = true;

    if (dataFileStatus === "") valid = false;

    if (circuitContains) {
      valid = poleContains !== "";
    }

    return valid;
  };

  const listDataFiles = async (page, count) => {
    let resDataFiles = [];
    let query = { fieldset: "poleReinforcementAudit", ignore: false };

    if (dataFileStatus !== "all") query.status = dataFileStatus;
    if (circuitContains !== "") query.circuit = circuitContains;
    if (poleContains !== "") query.pole = poleContains;

    try {
      const resDataFilesCount = await getCollectionCount({
        collection: "DataFiles",
        ...query
      });

      setDataFilesCount(resDataFilesCount.count);
    } catch (e) {}

    if (page) query.page = page;
    if (count) query.count = count;

    try {
      resDataFiles = await getDataFileEntries(query);
      setDataFiles(resDataFiles);
    } catch (e) {}

    return resDataFiles.length;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const numDataFiles = await listDataFiles(1, totalDataFilesPerPage);
      if (numDataFiles) {
        toggleSearch();
      } else {
        showCustomModal({
          title: "Search results",
          message: "No results were found following that criteria"
        });
      }
    } catch (e) {}

    setIsLoading(false);
  };

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const SearchResults = () => {
    let numberOfPages = 0;
    if (dataFilesCount % totalDataFilesPerPage === 0) {
      numberOfPages = Math.floor(dataFilesCount / totalDataFilesPerPage);
    } else {
      numberOfPages = Math.floor(dataFilesCount / totalDataFilesPerPage) + 1;
    }

    return dataFiles.length ? (
      <>
        <div className="NewSearch">
          <Button outline onClick={newSearch} color="secondary">
            New Search
          </Button>
        </div>
        <h5 className="card-title">Search results</h5>
        <GetPagination
          pages={numberOfPages}
          nextPage={nextPage}
          currentPage={currentPage}
          firstChange={firstChange}
          lastChange={lastChange}
          incChange={incChange}
        />
        <div className="mt-1rem">
          {dataFiles.map((dataFile, i) => (
            <ReinforcementInspectionPole
              key={i}
              dataFileDef={dataFile}
              userInfo={userInfo}
            />
          ))}
        </div>
      </>
    ) : (
      ""
    );
  };

  const handleDataSetStatus = async (status) => {
    setDataFileStatus(status);
    setCircuitContains("");
    setPolesToSearch(null);
    setPoleContains("");

    // Get list of circuits
    const poleStatus = status !== "all" ? status : null;
    const res = await getCircuitsByStatus(poleStatus);

    if (res.status === 200) {
      const circuits = res.response.map((item) => item._id);

      setCircuitsToSearch(circuits);
    }
  };

  const handleCircuitContains = async (circuit) => {
    setCircuitContains(circuit);

    const status = dataFileStatus !== "all" ? dataFileStatus : null;

    // Reset pole selection
    setPolesToSearch(null);
    setPoleContains("");

    if (circuit) {
      const res = await getPoleListInCircuit(circuit, status);

      if (res.status === 200) {
        setPolesToSearch(res.response.map((pole) => pole.dataPointId));
      }
    }
  };

  // Control tabs
  const [activeTab, setActiveTab] = useState("1");

  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="ReinforcementInspectionList">
      <Card>
        <CardHeader tag="h5">Search Reinforcement Inspections</CardHeader>
        <CardBody>
          <Nav tabs className="top-nav">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTabs("1");
                }}
                style={{ marginLeft: "0.5rem" }}
              >
                Search
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Collapse isOpen={isSearchOpen}>
                <Card className="search-engine">
                  <CardBody>
                    <Form onSubmit={handleSubmit} id="search-form">
                      <FormGroup tag="fieldset">
                        <h5 className="card-title">Select a status</h5>
                        {statiOptions.map((el, i) => (
                          <FormGroup key={i} check>
                            <Label check>
                              <Input
                                type="radio"
                                name="dataFileStatus"
                                value={el.value}
                                // defaultChecked={el.value === dataFileStatus}
                                onChange={(e) =>
                                  handleDataSetStatus(e.target.value)
                                }
                              />{" "}
                              {el.caption}
                            </Label>
                          </FormGroup>
                        ))}
                      </FormGroup>
                      {circuitsToSearch && (
                        <FormGroup tag="fieldset">
                          <h5 className="card-title">Circuit (optional)</h5>
                          {circuitsToSearch.length > 0 ? (
                            <FormGroup>
                              <Input
                                type="select"
                                name="circuits"
                                id="circuits"
                                value={circuitContains}
                                onChange={(e) =>
                                  handleCircuitContains(e.target.value)
                                }
                              >
                                <>
                                  <option value="">
                                    Select a circuit from the list
                                  </option>
                                  {circuitsToSearch.map((circuit, i) => (
                                    <option key={i} value={circuit}>
                                      {circuit}
                                    </option>
                                  ))}
                                </>
                              </Input>
                            </FormGroup>
                          ) : (
                            <CardText>
                              No circuits found matching status
                            </CardText>
                          )}
                        </FormGroup>
                      )}
                      {polesToSearch && (
                        <FormGroup tag="fieldset">
                          <h5 className="card-title">GLNX-GLNY</h5>
                          {polesToSearch.length > 0 ? (
                            <FormGroup>
                              <Input
                                type="select"
                                name="poleContains"
                                id="poleContains"
                                value={poleContains}
                                onChange={(e) =>
                                  setPoleContains(e.target.value)
                                }
                              >
                                <>
                                  <option value="">
                                    Select a pole from the list
                                  </option>
                                  {polesToSearch.map((pole, i) => (
                                    <option key={i} value={pole}>
                                      {pole}
                                    </option>
                                  ))}
                                </>
                              </Input>
                            </FormGroup>
                          ) : (
                            <CardText>
                              No poles found in circuit matching status
                            </CardText>
                          )}
                        </FormGroup>
                      )}
                      <Button
                        className="btn-lg"
                        disabled={!validateForm() || isLoading}
                        color="primary"
                        block
                      >
                        Search {isLoading && <Spinner color="light" />}
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Collapse>
              <SearchResults />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
      <ErrorModal />
    </div>
  );
};

export default ReinforcementInspectionList;
