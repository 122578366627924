import React from "react";
import DoAssocTableTest from "../components/DoAssocTableTest";
import "./AssocTableTest.css";

const AssocTableTest = (props) => {
  return (
    <div className="AssocTableTest">
      <div className="container">
        <DoAssocTableTest {...props} />
      </div>
    </div>
  );
};

export default AssocTableTest;
