import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardTitle,
  Spinner
} from "reactstrap";
import { createUser } from "../services/userService";
import { MailMessage } from "../models/Message";

import "./Guest.css";

const APP_NAME = "DTE Data Delivery";

const getRegRecipients = (adminMailPrefs) => {
  const admins = adminMailPrefs.users.map((u) => {
    if (u.notifications.registration) return { name: u.name, address: u.email };

    return null;
  });

  return admins.filter((u) => u);
};

const Guest = (props) => {
  const { user, adminMailPrefs, sendEmail } = props;

  const registration = user.registration ?? null;
  const [requested, setRequested] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState(null);

  const [error, setError] = useState(false);

  const regRecipients = getRegRecipients(adminMailPrefs);

  const adminGreet = () => {
    return regRecipients.length === 1
      ? regRecipients[0].name
      : "Administrators";
  };

  const sendRequest = async () => {
    // Set mail delegate
    const fromRecipient = {
      name: user.name,
      address: user.email
    };

    // Set to recipients
    const toRecipients = regRecipients;

    // Set message subject
    const subject = `${APP_NAME}: Registration request`;

    // Set message body
    const body = {
      content: `Dear ${adminGreet()},\n\nI'm requesting to be a registered user of the ${APP_NAME} System.\n\nThank you,\n${
        user.name
      }`
    };

    // Set one attachment
    const attachments = [];

    // Set Cc list
    const ccRecipients = [];

    // Set Bcc list
    const BccRecipients = [];

    const message = MailMessage(
      fromRecipient,
      toRecipients,
      subject,
      body,
      attachments,
      ccRecipients,
      BccRecipients,
      true
    );

    // Send email request
    sendEmail(message, setResults);
  };

  const handleRequest = async () => {
    const guest = {
      id: user.id,
      name: user.name,
      org: "",
      email: user.email,
      role: user.role,
      prefs: {
        programs: [],
        fileTypes: [],
        notifications: {
          registration: false,
          uploads: []
        }
      },
      registration: "pending"
    };

    setIsLoading(true);

    await createUser(guest)
      .then((results) => {
        sendRequest();

        setIsLoading(false);
        setRequested(true);
      })
      .catch((e) => {
        setError(new Error("Asynchronous error"));
      });
  };

  const RequestRegistration = () => {
    return (
      <Card>
        <CardHeader tag="h5" className="bg-light text-dark font-weight-bold">
          User registration
        </CardHeader>
        <CardBody>
          <CardTitle>
            {user.name}, you are currently not registered to use this
            application.
          </CardTitle>
          <CardText>
            If you wish to request your registration, please click on the button
            below.
          </CardText>
          {error && (
            <Alert color="danger">
              Having trouble contacting the server. Please contact the
              administrator or try again later.
            </Alert>
          )}
          {!results && (
            <Button
              color="primary"
              disabled={isLoading}
              onClick={handleRequest}
            >
              Request registration{" "}
              {isLoading && <Spinner size="sm" color="primary" />}
            </Button>
          )}
        </CardBody>
      </Card>
    );
  };

  const PendingRegistration = () => {
    return (
      <Card>
        <CardHeader tag="h5" className="bg-light text-dark font-weight-bold">
          User registration
        </CardHeader>
        <CardBody>
          <CardTitle>
            {user.name}, your request for registration is currently being
            reviewed by the administrator.
          </CardTitle>
          <hr />
          <Alert color="danger">
            <h5 className="alert-heading">Important!</h5>
            <p className="mb-0">
              Please <u>log out now</u> and come back after you receive the
              welcome email from the administrator.
            </p>
          </Alert>
        </CardBody>
      </Card>
    );
  };

  const RejectedRegistration = () => {
    return (
      <Card>
        <CardHeader tag="h5" className="bg-light text-dark font-weight-bold">
          User registration
        </CardHeader>
        <CardBody>
          <CardTitle>
            {user.name}, your request for registration was rejected by the
            administrator.
          </CardTitle>
        </CardBody>
      </Card>
    );
  };

  const SuspendedRegistration = () => {
    return (
      <Card>
        <CardHeader tag="h5" className="bg-light text-dark font-weight-bold">
          User registration
        </CardHeader>
        <CardBody>
          <CardTitle>
            {user.name}, registration to this application is currently suspended
            until further notice.
          </CardTitle>
        </CardBody>
      </Card>
    );
  };

  return (
    <div className="Guest">
      {!regRecipients && <SuspendedRegistration />}
      {!registration && regRecipients && !requested && <RequestRegistration />}
      {(registration === "pending" || requested) && <PendingRegistration />}
      {registration === "rejected" && <RejectedRegistration />}
    </div>
  );
};

export default Guest;
