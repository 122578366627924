import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  Table,
  Spinner
} from "reactstrap";
import { useGetUserSources } from "./useGetUserSources";
import { useGetFields } from "./useGetFields";
import { useGetFieldDefinitionReleases } from "./useGetFieldDefinitionReleases";
import { phraseToProperCase, camelcaseToDashes } from "../libs/case-utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import "./DownloadTemplates.css";

import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const DEF_VERSION = "1.0.0";

const DownloadTemplates = (props) => {
  const { params } = props;
  const { role, fileTypes } = params;
  const sources = useGetUserSources(fileTypes);
  const fields = useGetFields();
  const settings = useGetFieldDefinitionReleases();
  const isVendor = role === "vendor" || role === "audit";

  const makeHtml = (type, version) => {
    const sourceFields = sources[type].fields;

    const header = `
    <div>
        <h1>DTE Data Delivery Templates</h1>
        <hr />
        <h3>${phraseToProperCase(type)} Field Definitions</h3>
        <h5>Version ${version}</h5>
    </div>`;

    let fieldsMarkup = [];

    sourceFields.map((fieldKey, i) => {
      const field = fields[fieldKey];
      const index = i + 1;

      const valueLength = () => {
        let markup = "";

        const charPlural = (val) => {
          return val === 1 ? "character" : "characters";
        };

        if (field.validation["length"])
          markup = `<p><strong>Length:</strong> ${field.validation["length"]} characters</p>`;

        if (field.validation.minLength && field.validation.maxLength) {
          markup = `<p><strong>Minimum length:</strong> ${
            field.validation.minLength
          } ${charPlural(field.validation.minLength)}</p>`;
          markup += `<p><strong>Maximum length:</strong> ${
            field.validation.maxLength
          } ${charPlural(field.validation.maxLength)}</p>`;
        }

        return markup;
      };

      const possibleValues = () => {
        return `<p><strong>Acceptable values:</strong> ${field.validation.options.join(
          ", "
        )}</p>`;
      };

      const patternMessage = () => {
        return `<p><strong>Format/pattern:</strong> ${field.validation.patternMsg}</p>`;
      };

      const sampleData = () => {
        return `<p><strong>Sample values:</strong> ${field.validation.test.join(
          ", "
        )}</p>`;
      };

      const aliasMapping = () => {
        const sources = Object.keys(field.alias);

        if (sources.length === 0) return "";

        let sourceTags = [];
        for (let i = 0; i < sources.length; i++) {
          const source = sources[i];
          const alias = field.alias[source];

          if (alias) {
            if (alias.field) {
              sourceTags.push(`<p><strong>${source}</strong><br />`);
              sourceTags.push(`<i>Field alias: ${alias.field}</i><br />`);

              if (alias.overrides && alias.overrides.length) {
                sourceTags.push(
                  `<i>Overrides: ${alias.overrides.join(", ")}</i>`
                );
              }

              sourceTags.push("</p>");
            }
          }
        }

        return sourceTags.length
          ? `<p><strong>Systems mapping</strong> ${sourceTags.join("")}</p>`
          : "";
      };
      
      return fieldsMarkup.push(`
        <br />
        <div>
            <h5>${index}. ${field.fieldKey}</h5>
            <p><strong>Description:</strong> ${
              field.description.length
                ? field.description
                : "No description available"
            }</p>
            <p><strong>Required:</strong> ${field.required ? "Yes" : "No"}</p>
            <p><strong>Type:</strong> ${field.validation.type}</p>
            ${valueLength()}
            ${field.validation.options ? possibleValues() : ""}
            ${field.validation.patternMsg ? patternMessage() : ""}
            ${field.validation.test ? sampleData() : ""}
            ${!isVendor ? aliasMapping() : ""}
        </div>
      `);
    });

    return header + fieldsMarkup.join("");
  };

  const makeDoc = (type) =>
    new Promise((resolve) => {
      const settingsObj = generateSettingsObj();
      const version = getVersion(type, settingsObj);
      const html = htmlToPdfmake(makeHtml(type, version));

      const docDefinition = {
        content: [html],
        pageOrientation: "portrait",
        pageMargins: [40, 60, 40, 60],
        header: () => {
          return {
            columns: [
              {
                text: "DTE Data Delivery",
                alignment: "left"
              },
              {
                text: `${phraseToProperCase(type)} v. ${version}`,
                alignment: "right"
              }
            ],
            margin: [10, 10]
          };
        },
        footer: (currentPage, pageCount, pageSize) => {
          return {
            columns: [
              "",
              {
                text: `${currentPage.toString()} of ${pageCount}`,
                alignment: "right"
              }
            ],
            margin: [10, 0]
          };
        },
        pageBreakBefore: function (currentNode) {
          return (
            currentNode.style &&
            currentNode.style.indexOf("pdf-pagebreak-before") > -1
          );
        }
      };

      const pdfDocGenerator = pdfMake.createPdf(docDefinition);

      pdfDocGenerator.getBuffer((buffer) => {
        resolve(buffer);
      });
    });

  const getVersion = (type, obj) => {
    return obj[type] ? obj[type].version : DEF_VERSION;
  };

  const generateSettingsObj = () => {
    const obj = {};

    settings.map((k) => {
      obj[k.object] = { ...k.setting };

      return false;
    });

    return obj;
  };

  const RenderTable = () => {
    const [docs, setDocs] = useState({});
    const settingsObj = generateSettingsObj();

    useEffect(() => {
      const fetchDocs = async () => {
        let results = {};

        for (let type in fileTypes) {
          results[fileTypes[type]] = await makeDoc(fileTypes[type]);
        }

        setDocs(results);
      };

      fetchDocs();
    }, []);

    return (
      <div className="table-responsive">
        <Table>
          <thead>
            <tr>
              <th>Template name</th>
              <th className="download-cell">CSV</th>
              <th className="download-cell">PDF</th>
            </tr>
          </thead>
          <tbody>
            {fileTypes.map((type, i) => {
              const header = sources[type].fields;
              const version = getVersion(type, settingsObj);

              // Create URL for CSV data
              const fileCsv = new Blob([header.join(",")], {
                type: "text/csv"
              });
              const hrefCsv = URL.createObjectURL(fileCsv);

              // Create URL for documentation
              const fileDoc = new Blob([docs[type]], {
                type: "application/pdf"
              });
              const hrefDoc = URL.createObjectURL(fileDoc);

              return (
                <tr key={i}>
                  <td>
                    {phraseToProperCase(type)}{" "}
                    <small>{`(v. ${version})`}</small>
                  </td>
                  <td className="download-cell">
                    <a
                      href={hrefCsv}
                      download={`${camelcaseToDashes(
                        type
                      )}-template-${version}.csv`}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </a>
                  </td>
                  <td className="download-cell">
                    <a
                      href={hrefDoc}
                      download={`${camelcaseToDashes(type)}-doc-${version}.pdf`}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <div className="DownloadTemplates">
      <Card className="card-margins">
        <CardHeader tag="h5" className="bg-light text-dark font-weight-bold">
          Data Templates
        </CardHeader>
        <CardBody>
          <CardText>
            The CSV template(s) listed below contain the standard fields
            required for data uploads. The PDF document contains the data
            dictionary associated with the template.
          </CardText>
          {settings && sources && fields && fileTypes.length ? (
            <RenderTable />
          ) : (
            <>
              <Spinner size="sm" color="primary" /> Gathering templates...
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default DownloadTemplates;
