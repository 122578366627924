import React from "react";
import { Card, CardHeader, CardBody, CardText } from "reactstrap";
import ShowUserInquiries from "./ShowUserInquiries";
import "./Developer.css";
import DownloadTemplates from "./DownloadTemplates";

const Developer = (props) => {
  const { user, sendEmail } = props;

  return (
    <div className="Developer">
      <Card>
        <CardHeader tag="h5" className="bg-light text-dark font-weight-bold">
          Developer
        </CardHeader>
        <CardBody>
          <Card body>
            <CardText>{user.name}, you are logged on as developer.</CardText>
          </Card>
          <ShowUserInquiries user={user} sendEmail={sendEmail} />
        </CardBody>
      </Card>
      {user.prefs.fileTypes && user.prefs.fileTypes.length ? (
        <DownloadTemplates
          params={{ role: user.role, fileTypes: user.prefs.fileTypes }}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Developer;
