import React, { useState } from "react";
import { Button, Card, CardHeader, CardBody } from "reactstrap";
import PhotoUpload from "./PhotoUpload";
import PhotoPreview from "./PhotoPreview";
import { useGetDataFile } from "./useGetDataFile";
import "./DoUploadPicturesTest.css";

const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

const DoUploadPicturesTest = () => {
  const [media, setMedia] = useState(null);
  const [inspectionMedia, setInspectionMedia] = useState(null);

  const docInfo = {
    dataPointId: "155210-277701",
    fieldset: "poleReinforcementAudit"
  };

  const doc = useGetDataFile(docInfo.dataPointId, docInfo.fieldset);

  const handleMedia = () => {
    setMedia(doc ? doc.media : null);
  };

  const handleInspectionMedia = () => {
    setInspectionMedia(doc ? doc.inspections[0].media : null);
  };

  return (
    <div className="DoUploadPicturesTest">
      {!media && (
        <Button
          color="primary"
          size="sm"
          style={{ marginBottom: "1rem" }}
          onClick={handleMedia}
        >
          Load previous media
        </Button>
      )}
      <PhotoUpload curMedia={media} updateMedia={setMedia} docInfo={docInfo} />
      {media && (
        <Card className="mt-05rem">
          <CardHeader>Debug</CardHeader>
          <CardBody>{media && <Json data={media} />}</CardBody>
        </Card>
      )}
      <hr />
      {!inspectionMedia && (
        <Button
          color="primary"
          size="sm"
          style={{ marginTop: "1rem" }}
          onClick={handleInspectionMedia}
        >
          Load inspection media
        </Button>
      )}
      {inspectionMedia && <PhotoPreview curMedia={inspectionMedia} />}
    </div>
  );
};

export default DoUploadPicturesTest;
