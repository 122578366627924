const getOperRecMd = (doc, supportDoc) => {
  const globalID = doc.GlobalID_PIT;
  const GLNX_GLNY = supportDoc["GLNX-GLNY"];
  const circuit = supportDoc.circuit;
  const map = supportDoc.map;
  const year = doc.InspectionDate_PIT.split("-")[0];

  return {
    JsonFilename: `${GLNX_GLNY}-${year}.json`,
    GlobalID: globalID,
    "GLNX-GLNY": GLNX_GLNY,
    OHPrimaryCircuitNumber: circuit,
    Map: map,
    Year: year
  };
};

const getCsvMd = (doc) => {
  const circuit = doc.circuit;
  const fileName = doc.fileName;
  const org = doc.createdBy.org;
  const createdAt = doc.createdAt;
  const year = new Date(createdAt).getFullYear();
  const [base, ext] = fileName.split(".");

  return {
    CsvFilename: `${base}-${year}.${ext}`,
    OHPrimaryCircuitNumber: circuit,
    Vendor: org,
    Year: year.toString(),
    TimeStampOfUpload: createdAt
  };
};

export { getOperRecMd, getCsvMd };
