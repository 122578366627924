import React, { useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Spinner,
  Table
} from "reactstrap";
import { MailMessage } from "../models/Message";
import {
  Email,
  Item,
  Span,
  Box,
  renderEmail,
  configStyleValidator
} from "react-html-email";
import { getInspectorsInfo } from "../services/poleReinfInspCustomServices";
import PoleReinforcementInspectionResolve from "./PoleReinforcementInspectionResolve";
import {
  bulkGet,
  bulkGetAggregate,
  bulkUpdate
} from "../services/bulkServices";
import { phraseToProperCase } from "../libs/case-utils";
import jsonLogic from "json-logic-js";
import { JsonLogicMethods, getNameSpace } from "../models/JsonLogicMethods";
import moment from "moment";
import Moment from "react-moment";
import "moment-timezone";
import prettyBytes from "pretty-bytes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import "./CircuitAuditUtils.css";

// Load JsonLogicMethods into Json Logic
const methodNamespace = getNameSpace();
jsonLogic.add_operation(methodNamespace, JsonLogicMethods);

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM/DD/YYYY HH:mm:ss";

const APP_NAME = "DTE Data Delivery";

configStyleValidator({
  strict: true,
  warn: true,
  platforms: [
    "gmail",
    "gmail-android",
    "apple-mail",
    "apple-ios",
    "yahoo-mail",
    "outlook",
    "outlook-legacy",
    "outlook-web"
  ]
});
const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const getNewTimestamp = () => new Date().getTime();
const Json = ({ data }) => (
  <pre className="mb-0rem">{JSON.stringify(data, null, 4)}</pre>
);

const OverflowCardContainer = (props) => {
  const { arr } = props;

  return (
    <div className="CircuitAuditUtils">
      <Card className="mt-05rem">
        <CardBody className="overflow-500">
          {arr.map((res, i) => (
            <Card body className="display-card" key={i}>
              <Json data={res} />
            </Card>
          ))}
        </CardBody>
      </Card>
    </div>
  );
};

const getCircuitData = async (
  circuit,
  updateProgressTitle,
  updateProgressMax,
  updateProgress
) => {
  let count = 0;
  const batch = 75;

  const queryGetCount = (circuit) => {
    return {
      collection: "DataFiles",
      aggregate: [
        {
          $match: {
            circuit
          }
        },
        {
          $group: {
            _id: null,
            count: { $sum: 1 }
          }
        },
        {
          $project: {
            _id: 0,
            count: 1
          }
        }
      ],
      confirm: true
    };
  };

  const dataCount = await bulkGetAggregate(queryGetCount(circuit, 0, 2));

  if (dataCount.status === 200) {
    count = dataCount.response[0].count;

    if (count === 0) return [];
  } else {
    return null;
  }

  const queryGetData = (circuit, skip, limit) => {
    return {
      collection: "DataFiles",
      aggregate: [
        {
          $match: {
            circuit: circuit
          }
        },
        {
          $sort: {
            dataPointId: 1
          }
        },
        {
          $skip: skip
        },
        {
          $limit: limit
        },
        {
          $project: {
            _id: 0,
            fieldset: 1,
            document: 1
          }
        }
      ],
      confirm: true
    };
  };

  const residual = count % batch;
  const iterations = (count - residual) / batch + (residual > 0 ? 1 : 0);
  let ret = [];

  // Set progress
  updateProgressTitle("Downloading circuit data...");
  updateProgressMax(count);

  for (let i = 0; i < iterations; i++) {
    const dataFiles = await bulkGetAggregate(
      queryGetData(circuit, i * batch, batch)
    );

    if (dataFiles.status === 200) {
      ret = [...ret, ...dataFiles.response];

      updateProgress(ret.length);
    } else {
      return ret;
    }

    if (i + 1 < iterations) await delay(100);
  }

  return ret;
};

const getFieldDefinitionsBySource = async (
  circuit,
  updateProgressTitle,
  updateProgressMax,
  updateProgress
) => {
  let query = {
    collection: "DataFiles",
    aggregate: [
      {
        $match: {
          circuit: circuit
        }
      },
      {
        $group: {
          _id: "$fieldset"
        }
      }
    ],
    confirm: true
  };

  // Set progress
  updateProgressTitle("Pulling available fieldsets...");
  updateProgressMax(1);

  const dataFiles = await bulkGetAggregate(query);

  updateProgress(1);

  if (dataFiles.status === 200) {
    const fieldsets = dataFiles.response.map((item) => item._id);

    query = {
      collection: "Sources",
      filter: {
        sourceKey: {
          $in: fieldsets
        }
      },
      sort: {},
      project: {
        _id: 0,
        sourceKey: 1,
        fields: 1
      },
      confirm: true
    };

    // Set progress
    updateProgressTitle("Pulling fields per fieldset...");
    updateProgressMax(1);

    const sources = await bulkGet(query);

    updateProgress(1);

    if (sources.status === 200) {
      const fieldsBySource = {};

      sources.response.forEach(
        (item) => (fieldsBySource[item.sourceKey] = item.fields)
      );

      const fieldDefinitions = {};

      // Set progress
      updateProgressTitle("Downloading field definitions...");
      updateProgressMax(fieldsets.length);

      for (let i = 0; i < fieldsets.length; i++) {
        query = {
          collection: "Fields",
          filter: {
            fieldKey: {
              $in: fieldsBySource[fieldsets[i]]
            }
          },
          sort: {},
          project: {
            _id: 0,
            fieldKey: 1,
            description: 1,
            required: 1,
            validation: 1,
            overrides: 1
          },
          confirm: true
        };

        const fields = await bulkGet(query);

        updateProgress(i + 1);

        if (fields.status === 200) {
          const fieldArr = fields.response.map((field) => {
            const [key, suffix] = field.fieldKey.split("_");

            return [key, { suffix, ...field, fieldKey: key }];
          });
          const map = new Map(fieldArr);

          fieldDefinitions[fieldsets[i]] = Object.fromEntries(map);
        }
      }

      return fieldDefinitions;
    }
  } else {
    return null;
  }
};

const getEngStandardsBySource = async (
  sources,
  updateProgressTitle,
  updateProgressMax,
  updateProgress
) => {
  let query = {
    collection: "ComplexRules",
    filter: {
      applyTo: {
        $in: sources
      }
    },
    sort: {
      name: 1
    },
    project: {
      _id: 0,
      ruleId: 1,
      name: 1,
      description: 1,
      applyTo: 1,
      rules: 1
    },
    confirm: true
  };

  // Set progress
  updateProgressTitle("Pulling engineering standards per fieldset...");
  updateProgressMax(1);

  const engStandards = await bulkGet(query);

  updateProgress(1);

  if (engStandards.status === 200) {
    if (engStandards.response.length > 0) {
      const engStandardsSets = {};

      // Organize in sets by fieldset
      engStandards.response.forEach((item) => {
        const fieldset = item.applyTo;

        if (!engStandardsSets[fieldset]) engStandardsSets[fieldset] = [];

        engStandardsSets[fieldset].push(item);
      });

      return engStandardsSets;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const runComplexRules = (doc, rules, fieldset) => {
  const errors = [];
  const pole = {
    fieldset,
    GlobalID: doc["GlobalID"],
    "GLNX-GLNY": doc["GLNX-GLNY"]
  };

  // Pass if no rules
  if (!rules) return { passed: true, pole, errors };

  const populateDataObj = (data) => {
    let sample = {};
    const keys = Object.keys(data).map((k) => k.replace(/_.*$/, ""));

    for (let i = 0; i < keys.length; i++) {
      sample[keys[i]] = doc[keys[i]];
    }

    return sample;
  };

  const execRule = (rule) => {
    const sample = populateDataObj(rule.rules.data);
    const res = jsonLogic.apply(rule.rules.logic, sample);

    if (!res) {
      errors.push({
        name: rule.name,
        description: rule.description,
        values: sample
      });
    }
  };

  rules.map((r) => execRule(r));

  return { passed: errors.length === 0, pole, errors };
};

const validateFormat = (doc) => {
  const { data, definitions, circuit, fieldset, glnxy } = doc;

  const parseMomentCount = (countObj) => {
    const total = countObj.map((expr) => {
      let count = 0;

      switch (expr.oper) {
        case "plus":
          count = expr.func ? parseMomentFunc(expr) : expr.count;
          break;
        case "minus":
          count = -1 * (expr.func ? parseMomentFunc(expr) : expr.count);
          break;
        default:
          count = expr.func ? parseMomentFunc(expr) : expr.count;
      }

      return count;
    });

    return total.reduce((a, b) => a + b, 0);
  };

  const parseMomentFunc = (m) => {
    let hold = null;
    let holdCount = 0;

    switch (m.func) {
      case "subtract":
        holdCount = Number(m.count) ? m.count : parseMomentCount(m.count);
        hold = moment().subtract(holdCount, m.unit);
        break;
      case "add":
        holdCount = Number(m.count) ? m.count : parseMomentCount(m.count);
        hold = moment().add(holdCount, m.unit);
        break;
      case "currentDate":
        hold = moment();
        break;
      case "dayOfYear":
        hold = moment().dayOfYear();
        break;
      default:
        hold = null;
    }

    return hold;
  };

  const setValidation = (definition) => {
    let validation = definition.validation;
    const overrides = definition?.overrides?.validation;

    if (overrides) {
      validation = { ...validation, ...overrides };
    }

    return validation;
  };

  const errors = [];
  const statsKeys = {};

  // Skip poleReinforcementAudit
  if (fieldset !== "poleReinforcementAudit") {
    // Loop through data fields
    for (const field in data) {
      // Skip "default" prop in RIA
      if (field === "default") continue;

      const value = data[field];
      const definition = definitions[field];
      let isRequired = definition.required;
      const validation = setValidation(definition);

      // Override required
      if (definition?.overrides?.required !== undefined) {
        isRequired = definition.overrides.required;
      }

      const isNull = value === null;
      const valueToStr = isNull ? "" : value.toString();
      const isNumeric = isNull ? false : !isNaN(Number(valueToStr));
      const isEmpty = valueToStr.trim().length === 0;
      const castValue = isNumeric ? Number(value) : value;

      // Collect circuit numbers
      if (field === "OHPrimaryCircuitNumber") {
        statsKeys["OHPrimaryCircuitNumber"] = value;
      }

      // Collect POs
      if (field === "PO") {
        statsKeys["PO"] = value;
      }

      // Collect GLNX-GLNYs
      if (field === "GLNX-GLNY") {
        statsKeys["GLNX-GLNY"] = value;
      }

      // Collect Global Ids
      if (field === "GlobalID") {
        statsKeys["GlobalID"] = value;
      }

      // Set minimum error info
      const formatErrorHeader = {
        type: "format",
        circuit,
        fieldset,
        "GLNX-GLNY": glnxy,
        field,
        value
      };

      // Ignore if not required and is empty
      if (!isRequired && isEmpty) continue;

      // Ignore if not required and is null
      if (!isRequired && isNull) continue;

      // Check empty and required
      if (isRequired && isEmpty) {
        // Log error
        errors.push({
          ...formatErrorHeader,
          msg: `A value is required`
        });

        // Continue with next
        continue;
      }

      // Check type
      if (validation.type) {
        if (validation.type === "number" && !isNumeric) {
          if (validation.typeException) {
            if (!validation.typeException.includes(value)) {
              errors.push({
                ...formatErrorHeader,
                msg: `Value must be of type ${validation.type}`
              });

              // Continue with next
              continue;
            }
          } else {
            errors.push({
              ...formatErrorHeader,
              msg: `Value must be of type ${validation.type}`
            });

            // Continue with next
            continue;
          }
        }
      }

      // Check options
      if (validation.options) {
        if (
          !validation.options.includes(castValue) &&
          !validation.options.includes(valueToStr)
        )
          errors.push({
            ...formatErrorHeader,
            msg: `"${value}" is not an option`
          });

        // Continue with next
        continue;
      }

      // Check fixed length
      if (validation.length) {
        if (valueToStr.length !== validation.length) {
          errors.push({
            ...formatErrorHeader,
            msg: `String should be ${validation.length} characters in length`
          });

          // Continue with next
          continue;
        }
      }

      // Check minimum length
      if (validation.minLength) {
        if (value.length < validation.minLength) {
          errors.push({
            ...formatErrorHeader,
            msg: `String should be greater than or equal to ${validation.minLength} characters in length`
          });

          // Continue with next
          continue;
        }
      }

      // Check maximum length
      if (validation.maxLength) {
        if (value.length > validation.maxLength) {
          errors.push({
            ...formatErrorHeader,
            msg: `String should be less than or equal to ${validation.maxLength} characters in length`
          });

          // Continue with next
          continue;
        }
      }

      // Check patterns
      if (validation.pattern) {
        const regex = RegExp(validation.pattern.replace(/\\/g, "\\"));

        if (!regex.test(value)) {
          // Check options
          if (validation.orOptions) {
            if (!validation.orOptions.includes(value))
              errors.push({
                ...formatErrorHeader,
                msg: `String doesn't match format pattern (${validation.patternMsg})`
              });

            // Continue with next
            continue;
          } else {
            errors.push({
              ...formatErrorHeader,
              msg: `String doesn't match format pattern (${validation.patternMsg})`
            });

            // Continue with next
            continue;
          }
        }
      }

      // Check date (evaluate date only if passes the pattern rules)
      if (validation.date) {
        const inputDate = moment(value);

        // Check if date is valid
        if (inputDate.isValid()) {
          // Validate from starting date
          if (validation.date.start) {
            let startCompareDate = null;

            if (validation.date.start.moment) {
              startCompareDate = parseMomentFunc(validation.date.start.moment);
            } else {
              startCompareDate = moment({
                year: validation.date.start.year,
                month: validation.date.start.month - 1,
                day: validation.date.start.day
              });
            }

            // Check if given date happens before set date
            if (inputDate.isBefore(startCompareDate)) {
              const startingDate = startCompareDate.format(
                validation.date.format
              );

              errors.push({
                ...formatErrorHeader,
                msg: `Date should be later than or equal to ${startingDate}`
              });
            }
          }

          // Validate from ending date
          if (validation.date.end) {
            let endCompareDate = null;

            if (validation.date.end.moment) {
              endCompareDate = parseMomentFunc(validation.date.end.moment);
            } else {
              endCompareDate = moment({
                year: validation.date.end.year,
                month: validation.date.end.month - 1,
                day: validation.date.end.day
              });
            }

            // Check if given date happens after set date
            if (inputDate.isAfter(endCompareDate)) {
              const endingDate = endCompareDate.format(validation.date.format);

              errors.push({
                ...formatErrorHeader,
                msg: `Date should be before or equal to ${endingDate}`
              });
            }
          }
        } else {
          errors.push({
            ...formatErrorHeader,
            msg: `"${value}" is an invalid date`
          });
        }
      }

      // Check numeric range
      if (validation.range) {
        if (validation.range.start) {
          if (Math.abs(value) < Math.abs(validation.range.start)) {
            errors.push({
              ...formatErrorHeader,
              msg: `Number outside range: [${validation.range.start}, ${validation.range.end}]`
            });
          }

          if (Math.abs(value) > Math.abs(validation.range.end)) {
            errors.push({
              ...formatErrorHeader,
              msg: `Number outside range: [${validation.range.start}, ${validation.range.end}]`
            });
          }
        }
      }
    }
  }

  return { errors, statsKeys, doc };
};

const FormatValidationCard = (props) => {
  const { children, header } = props;

  return (
    <Card className="mb-05rem">
      <CardHeader>{header}</CardHeader>
      <CardBody className="overflow-500">{children}</CardBody>
    </Card>
  );
};

const FormatValidationReport = (props) => {
  const { errors } = props;

  const formatData = (data) => {
    const isArray = Array.isArray(data);

    return isArray ? (
      <CardText>
        <b>Entrie(s): </b>
        {data.join(", ")}
      </CardText>
    ) : (
      <>
        {Object.keys(data).map((k, i) => {
          return (
            <CardText key={i}>
              <b>{k}: </b>
              {data[k]}
            </CardText>
          );
        })}
      </>
    );
  };

  return (
    <div className="CircuitAuditUtils">
      <Card body className="display-container">
        <FormatValidationCard header="Validation errors">
          {errors.map((res, i) => {
            const isFormat = res.type === "format";

            return isFormat ? (
              <Card body className="display-card" key={i}>
                <CardText>
                  <b>Circuit: </b>
                  {res.circuit}
                </CardText>
                <CardText>
                  <b>Fieldset: </b>
                  {res.fieldset}
                </CardText>
                <CardText>
                  <b>GLNX-GLNY: </b>
                  {res["GLNX-GLNY"]}
                </CardText>
                <CardText>
                  <b>Field: </b>
                  {res.field}
                </CardText>
                <CardText>
                  <b>Value: </b>
                  {res.value}
                </CardText>
                <CardText>
                  <b>Error: </b>
                  {res.msg}
                </CardText>
              </Card>
            ) : (
              <Card body className="display-card" key={i}>
                <CardText>
                  <b>Error: </b>
                  {res.msg}
                </CardText>
                {formatData(res.data)}
                {/* <Json data={res.data} /> */}
              </Card>
            );
          })}
        </FormatValidationCard>
      </Card>
    </div>
  );
};

const EngStandardsComplianceReport = (props) => {
  const { errors } = props;

  return (
    <div className="CircuitAuditUtils">
      <Card body className="display-container">
        <FormatValidationCard header="Defects per pole">
          {Object.keys(errors).map((globalId, i) => {
            const error = errors[globalId];
            const defects = error.errors;

            const DefectCollapse = (props) => {
              const { fieldset, defects } = props;

              const [open, setOpen] = useState(false);
              const [collapseIcon, setCollapseIcon] = useState(faChevronDown);
              const toggle = () => {
                const isOpen = !open;
                const icon = isOpen ? faChevronUp : faChevronDown;

                setCollapseIcon(icon);
                setOpen(isOpen);
              };

              return (
                <Card className="mb-05rem">
                  <CardHeader>
                    {phraseToProperCase(fieldset)}
                    <Button
                      className="collapse-button"
                      size="sm"
                      color="light"
                      onClick={toggle}
                    >
                      <FontAwesomeIcon icon={collapseIcon} />
                    </Button>
                  </CardHeader>
                  {open && (
                    <CardBody>
                      {defects.map((defect, k) => {
                        return (
                          <Card body className="display-card" key={k}>
                            <CardText>
                              <b>Defect:</b> {defect.description}
                            </CardText>
                            <CardText>
                              <b>Rule:</b> {defect.name}
                            </CardText>
                            <CardText>
                              <b>Current values:</b>
                            </CardText>
                            {Object.keys(defect.values).map((field, l) => {
                              return (
                                <CardText key={l}>
                                  {field} = {defect.values[field]}
                                </CardText>
                              );
                            })}
                          </Card>
                        );
                      })}
                    </CardBody>
                  )}
                </Card>
              );
            };

            return (
              <Card body className="display-card" key={i}>
                <CardText>
                  <b>Global Id: </b>
                  {error.GlobalID}
                </CardText>
                <CardText>
                  <b>GLNX-GLNY: </b>
                  {error["GLNX-GLNY"]}
                </CardText>
                {Object.keys(defects).map((fieldset, j) => {
                  return (
                    <DefectCollapse
                      key={j}
                      fieldset={fieldset}
                      defects={defects[fieldset]}
                    />
                  );
                })}
              </Card>
            );
          })}
        </FormatValidationCard>
      </Card>
    </div>
  );
};

const InvalidGlobalIdsReport = (props) => {
  const { poles } = props;

  return (
    <div className="CircuitAuditUtils">
      <Card body className="display-container">
        <Alert color="danger">
          Please review vendor provided global Id(s) below to ensure the pole
          existed in the original ESRI extract and adheres to the Data
          Dictionary standards. This error may require contacting Mapping
          Technology to resolve.
        </Alert>
        <Card body className="overflow-500">
          {poles.map((pole, i) => (
            <CardText key={i} style={{ marginBottom: "0.25rem", color: "red" }}>
              {pole}
            </CardText>
          ))}
        </Card>
      </Card>
    </div>
  );
};

const UpdatePo = (props) => {
  const { data, updateItems } = props;
  const { circuit, dataPointId, globalId, PO, LINENUM, inspectionId } = data;

  const [lineNumber, setLineNumber] = useState(LINENUM ?? "");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const handleLineNum = (item) => {
    const isNumeric = !isNaN(Number(item));
    const isEmpty = item.trim() === "";
    const castValue = isNumeric && !isEmpty ? Number(item) : item;

    if (isNumeric && !isEmpty) {
      if (castValue <= 0) {
        setIsError(true);
        setErrMsg("Line number must be greater than zero");
      } else {
        setIsError(false);
        setErrMsg(null);
      }
    } else {
      setIsError(true);

      if (isEmpty) {
        setErrMsg("Line number cannot be empty");
      } else {
        setErrMsg("Line number should be a number");
      }
    }

    setLineNumber(castValue);
  };

  const handleSave = async () => {
    setIsLoading(true);

    const collection = "DataFiles";
    const suffix = "RIA";

    const doc = {
      collection,
      filter: {
        circuit,
        suffix,
        dataPointId
      },
      update: {},
      confirm: true
    };

    doc.update[`billing.PO.${inspectionId}`] = {
      PO,
      LINENUM: lineNumber
    };

    const res = await bulkUpdate(doc);

    if (res.status === 200) {
      setIsEditing(false);

      updateItems({ ...data, LINENUM: lineNumber });
    } else {
      setIsError(true);
      setErrMsg("Could not update line number...");
    }

    setIsLoading(false);
  };

  const handleCancel = () => {
    setLineNumber(LINENUM ?? "");
    setIsEditing(false);
    setIsError(false);
    setErrMsg(null);
  };

  return (
    <Card className="mb-1rem">
      <CardHeader>{globalId}</CardHeader>
      <CardBody>
        <CardText>
          <b>PO: </b>
          {PO}
        </CardText>
        {!isEditing && (
          <CardText>
            <b>LINENUM: </b>
            {lineNumber === "" ? "<NOT SET>" : lineNumber}
          </CardText>
        )}
        {isEditing && (
          <FormGroup>
            <Label for="exampleEmail">
              <b>LINENUM:</b>
            </Label>
            <Input
              invalid={isError}
              name="line-number"
              value={lineNumber}
              onChange={(e) => handleLineNum(e.target.value)}
            />
            <FormFeedback>{errMsg}</FormFeedback>
          </FormGroup>
        )}
      </CardBody>
      <CardFooter>
        {!isEditing && (
          <Button color="primary" size="sm" onClick={() => setIsEditing(true)}>
            Enter line number
          </Button>
        )}
        {isEditing && (
          <>
            <Button
              color="primary"
              size="sm"
              disabled={isLoading || isError}
              onClick={() => handleSave()}
            >
              Save {isLoading && <Spinner size="sm" color="light" />}
            </Button>
            <Button
              outline
              className="ml-025rem"
              color="danger"
              size="sm"
              disabled={isLoading}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
          </>
        )}
      </CardFooter>
    </Card>
  );
};

const ManageRiaPoRequest = (props) => {
  const { prompt } = props;
  const [items, setItems] = useState(prompt);

  const handleItem = (item, index) => {
    let copyItems = [...items];

    copyItems[index] = item;

    setItems(copyItems);
  };

  return (
    <div className="CircuitAuditUtils">
      <Card className="mb-05rem">
        <CardHeader>Add correct PO line number from Maximo</CardHeader>
        <CardBody className="overflow-500">
          {items.map((item, i) => {
            return (
              <UpdatePo
                key={i}
                data={item}
                updateItems={(item) => handleItem(item, i)}
              />
            );
          })}
        </CardBody>
      </Card>
    </div>
  );
};

const MismatchedLocationDataReport = (props) => {
  const { poles } = props;

  return (
    <div className="CircuitAuditUtils">
      <Card body className="display-container">
        <Card body className="overflow-500">
          {poles.map((pole, i) => (
            <CardText key={i} style={{ marginBottom: "0.25rem", color: "red" }}>
              {pole}
            </CardText>
          ))}
        </Card>
      </Card>
    </div>
  );
};

const MissingGlobalIdsReport = (props) => {
  const { poles, circuit } = props;

  const [hrefCsv, setHrefCsv] = useState(null);
  const [csvSize, setCsvSize] = useState(null);

  const PoleOptions = (props) => {
    const { pole, circuit } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isError, setIsError] = useState(false);

    const formFeedbackInit = {
      globalId: {
        invalid: false,
        msg: ""
      }
    };
    const [formFeedback, setFormFeedback] = useState(formFeedbackInit);

    const [globalId, setGlobalId] = useState("");

    const showError = (field, invalid, msg) => {
      const copy = { ...formFeedback };

      copy[field].invalid = invalid;
      copy[field].msg = msg;

      setFormFeedback(copy);
      setIsError(invalid);
    };

    const handleGlobalId = (str) => {
      if (str === "") {
        showError("globalId", true, "Global Id cannot be empty...");
      } else {
        showError("globalId", false, "");

        const regex = new RegExp(
          /^\{[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}\}$/
        );

        if (!regex.test(str)) {
          showError("globalId", true, "Format error...");
        } else {
          showError("globalId", false, "");
        }
      }

      setGlobalId(str);
    };

    const validateForm = () => {
      if (globalId === "") {
        showError("globalId", true, "Global Id cannot be empty...");
        return false;
      }

      const regex = new RegExp(
        /^\{[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}\}$/
      );

      if (!regex.test(globalId)) {
        showError("globalId", true, "Format error...");
        return false;
      }

      return true;
    };

    const handleSave = async () => {
      if (!validateForm()) {
        return false;
      }

      setIsLoading(true);

      try {
        const query = {
          collection: "DataFiles",
          filter: {
            dataPointId: pole.dataPointId,
            circuit,
            fieldset: "poleInspection"
          },
          update: {
            "document.GlobalID": globalId
          },
          confirm: true
        };

        const res = await bulkUpdate(query);

        if (res.status === 200) {
          setIsEditing(false);
        }

        setIsLoading(false);
      } catch (e) {}
    };

    const handleCancel = () => {
      setIsEditing(false);
      setGlobalId("");
      setFormFeedback(formFeedbackInit);
      setIsError(false);
    };

    const handleAddGlobalId = () => {
      setIsEditing(true);
    };

    return (
      <Card body className="mb-05rem" style={{ padding: "0.5rem" }}>
        <FormGroup className="mb-0rem">
          <CardText className="mb-05rem">
            <b>GLNX-GLNY: </b>
            {pole.dataPointId}
          </CardText>
          {globalId !== "" && !isEditing && (
            <CardText className="mb-05rem">
              <b>Global Id: </b>
              {globalId}
            </CardText>
          )}
          {isEditing && (
            <>
              <FormGroup>
                <Label for="exampleEmail">
                  <b>Global Id:</b>
                </Label>
                <Input
                  invalid={formFeedback.globalId.invalid}
                  name="global-id"
                  value={globalId}
                  onChange={(e) => handleGlobalId(e.target.value)}
                />
                <FormFeedback>{formFeedback.globalId.msg}</FormFeedback>
                <FormText>
                  {"{XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX}"} X is a HEX number.
                </FormText>
              </FormGroup>
              <Button
                color="primary"
                size="sm"
                disabled={isLoading || isError}
                onClick={() => handleSave()}
              >
                Save {isLoading && <Spinner size="sm" color="light" />}
              </Button>
              <Button
                outline
                className="ml-025rem"
                color="danger"
                size="sm"
                disabled={isLoading}
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
            </>
          )}
          {!isEditing && globalId === "" && (
            <Button
              color="link"
              size="sm"
              disabled={isLoading}
              onClick={() => handleAddGlobalId()}
            >
              Add Global Id
            </Button>
          )}
        </FormGroup>
      </Card>
    );
  };

  const convertToCsv = (docs) => {
    const headers = [
      "GlobalID",
      "GLNX-GLNY",
      "PoleTagClass",
      "PoleTagInstallationYear",
      "PoleTagLength",
      "PoleTagOwner",
      "PoleTagSpeciesTreatmentCode",
      "StructureType"
    ];

    const rows = docs.map((row, i) => {
      const arr = [
        "",
        `"${row.dataPointId}"`,
        `"${row.document.PoleTagClass}"`,
        `"${row.document.PoleTagInstallationYear}"`,
        row.document.PoleTagLength,
        `"${row.document.PoleTagOwner}"`,
        `"${row.document.PoleTagSpeciesTreatmentCode}"`,
        `"${row.document.StructureType}"`
      ];

      return arr.join(",");
    });

    return [headers.join(","), ...rows].join("\n");
  };

  const handleDownload = () => {
    const csvData = convertToCsv(poles);

    // Create URL for CSV data
    const fileCsv = new Blob([csvData], {
      type: "text/csv"
    });

    setHrefCsv(URL.createObjectURL(fileCsv));
    setCsvSize(fileCsv.size);
  };

  return (
    <div className="CircuitAuditUtils">
      <Card body className="display-container">
        {hrefCsv && (
          <Card body className="mb-05rem">
            <CardText>
              Click on the link below to download the CSV file
            </CardText>
            <CardText>
              <a href={hrefCsv} download="esri-poles-missing-global-ids.csv">
                <FontAwesomeIcon icon={faDownload} /> Poles Missing Global Ids (
                {prettyBytes(csvSize ? csvSize : 0)})
              </a>
            </CardText>
          </Card>
        )}
        {!hrefCsv && (
          <Card className="mb-05rem">
            <CardHeader>Download List for ESRI</CardHeader>
            <CardBody>
              <FormGroup className="mb-0rem">
                <Button
                  outline
                  color="primary"
                  size="sm"
                  onClick={handleDownload}
                >
                  Prepare download
                </Button>
              </FormGroup>
            </CardBody>
          </Card>
        )}
        <Card>
          <CardHeader>Update Pole GlobalIds</CardHeader>
          <CardBody>
            {poles.map((pole, i) => {
              return <PoleOptions key={i} pole={pole} circuit={circuit} />;
            })}
          </CardBody>
        </Card>
      </Card>
    </div>
  );
};

const DuplicateGlnxyReport = (props) => {
  const { variables, sendEmail, userInfo, curCircuit, handleUpdate } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [notifiedOfDuplicates, setNotifiedOfDuplicates] = useState(
    variables.notifiedOfDuplicates ?? []
  );
  const [isNotified, setIsNotified] = useState(notifiedOfDuplicates.length > 0);

  const processDuplicates = () => {
    const ret = {};

    variables.duplicateGlnxy.map((duplicate, i) => {
      const uploads = duplicate.uploads;

      uploads.forEach((upload, i) => {
        const vendor = upload.createdBy.org;
        const circuit = upload.circuit;

        if (!ret[vendor]) ret[vendor] = {};
        if (!ret[vendor][circuit])
          ret[vendor][circuit] = {
            fileName: upload.fileName,
            createdAt: upload.createdAt,
            contact: {
              id: upload.createdBy.id,
              name: upload.createdBy.name,
              email: upload.createdBy.email
            },
            poles: []
          };

        ret[vendor][circuit].poles.push(duplicate._id);
      });

      return null;
    });

    return ret;
  };

  const duplicatesByVendor = processDuplicates();

  const callback = (response) => {
    if (response.status === 202) setIsLoading(false);
  };

  const sendRequest = async (circuit, circuitObj) => {
    setIsLoading(true);

    const contact = circuitObj.contact;

    // Set mail delegate
    const fromRecipient = {
      name: userInfo.name,
      address: userInfo.email
    };

    const toRecipients = [
      {
        name: contact.name,
        address: contact.email
      }
    ];

    // Set message subject
    const subject = `${APP_NAME}: Duplicate pole inspection data...`;

    // Set message body
    const body = {
      contentType: "HTML",
      content: genHtml(circuit, circuitObj)
    };

    // Set one attachment
    const attachments = [];

    // Set Cc list
    const ccRecipients = [];

    // Set Bcc list
    const BccRecipients = [];

    const message = MailMessage(
      fromRecipient,
      toRecipients,
      subject,
      body,
      attachments,
      ccRecipients,
      BccRecipients,
      true
    );

    // Send email request
    sendEmail(message, callback);
  };

  const sendRequests = async () => {
    const notifications = [...notifiedOfDuplicates];
    const vendorList = Object.keys(duplicatesByVendor);

    for (let i = 0; i < vendorList.length; i++) {
      const circuitList = Object.keys(duplicatesByVendor[vendorList[i]]);
      for (let j = 0; j < circuitList.length; j++) {
        const circuitObj = duplicatesByVendor[vendorList[i]][circuitList[j]];
        const contact = circuitObj.contact;

        await sendRequest(circuitList[j], circuitObj);

        notifications.push({
          contact: {
            name: contact.name,
            address: contact.email,
            org: vendorList[i]
          },
          timestamp: getNewTimestamp()
        });
      }
    }

    // Add to list of notified contacts
    setNotifiedOfDuplicates(notifications);
    setIsNotified(true);

    const newVariables = {
      ...variables,
      notifiedOfDuplicates: notifications
    };

    // Update task in pipeline
    await handleUpdate(newVariables);
  };

  const genHtml = (circuit, circuitObj) => {
    const comp = renderEmail(
      <Email title={`${APP_NAME}`}>
        <Box cellSpacing={20} width="100%">
          <Item>
            <Span fontSize={18}>
              The following poles were inspected this year on a different
              circuit, please remove the poles and re-upload the CSV file.
              <br />
            </Span>
          </Item>
        </Box>
        <Box cellSpacing={20} width="100%" style={{ border: "1px solid gray" }}>
          <Item>
            <Span fontSize={18}>
              <b>Circuit:</b> {circuit}
            </Span>
          </Item>
          <Item>
            <Span fontSize={18}>
              <b>
                Pole(s) <small>(GLNX-GLNY)</small>:
              </b>{" "}
              {circuitObj.poles.join(", ")}
            </Span>
          </Item>
        </Box>
      </Email>
    );

    return comp;
  };

  return (
    <div className="CircuitAuditUtils">
      <Card body className="display-container">
        <CardText>
          This check determines if the pole (GLNX-GLNY) was already inspected in
          the current year but on a different circuit.
        </CardText>
        <Table size="sm" responsive bordered>
          <thead>
            <tr>
              <th>Vendor</th>
              <th>In circuit</th>
              <th>
                Duplicate poles <small>(GLNX-GLNY)</small>
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(duplicatesByVendor).map((vendor, i) => {
              const circuits = duplicatesByVendor[vendor];

              return Object.keys(circuits).map((circuit, j) => {
                const poles = duplicatesByVendor[vendor][circuit].poles;
                const contact = duplicatesByVendor[vendor][circuit].contact;
                const isCurCircuit = circuit === curCircuit;

                return (
                  <tr key={`${j}-${i}`}>
                    <td>
                      {contact.name} <small>({vendor})</small>
                    </td>
                    <td>{isCurCircuit ? `${circuit} (this)` : circuit}</td>
                    <td>{poles.join(", ")}</td>
                  </tr>
                );
              });
            })}
          </tbody>
        </Table>
        {isNotified && (
          <Card>
            <CardHeader>Notifications</CardHeader>
            <CardBody className="overflow-500">
              {notifiedOfDuplicates.map((item, i) => {
                return (
                  <Card key={i} className="mb-05rem">
                    <CardBody className="notification-padding">
                      <CardText>
                        {item.contact.name} <small>({item.contact.org})</small>{" "}
                        on <Moment>{item.timestamp}</Moment>
                      </CardText>
                    </CardBody>
                  </Card>
                );
              })}
            </CardBody>
            <CardFooter>
              <Button
                color="warning"
                size="sm"
                disabled={isLoading}
                onClick={() => sendRequests()}
              >
                Resend notification to vendor(s){" "}
                {isLoading && <Spinner size="sm" color="light" />}
              </Button>
              <CardText className="mt-05rem">
                <small>
                  (The notification is sent to the vendor that introduced a
                  duplicate pole. The pole with the later inspection date is
                  considered invalid and must be replaced.)
                </small>
              </CardText>
            </CardFooter>
          </Card>
        )}
        {!isNotified && (
          <FormGroup>
            <Button
              color="danger"
              size="sm"
              disabled={isLoading}
              onClick={() => sendRequests()}
            >
              Send notification to vendor(s){" "}
              {isLoading && <Spinner size="sm" color="light" />}
            </Button>
            <CardText className="mt-05rem">
              <small>
                (The notification is sent to the vendor that introduced a
                duplicate pole. The pole with the later inspection date is
                considered invalid and must be replaced.)
              </small>
            </CardText>
          </FormGroup>
        )}
      </Card>
    </div>
  );
};

const DisplayReinforcementInspections = (props) => {
  const {
    polesToInspect,
    inspectionsToApprove,
    inspections,
    sendEmail,
    handleLoadInspections,
    overrideMailContacts,
    user,
    userInfo,
    lastUpdate
  } = props;

  const now = getNewTimestamp();
  const contentExpiration = 10; // In seconds
  const [locked, setLocked] = useState(
    now > lastUpdate + contentExpiration * 1000
  );

  const handleUnlock = () => {
    setLocked(false);

    handleLoadInspections();
  };

  return (
    <div className="CircuitAuditUtils">
      {polesToInspect.length > 0 && (
        <Card className="cards-container">
          <CardHeader className="bg-warning text-white">
            Poles yet to inspect <sup>*</sup>
          </CardHeader>
          {locked && (
            <CardBody>
              <CardText>
                Content expired. Please re-load inspection data or hit the
                "Update view" button below.
              </CardText>
              <Button color="warning" outline size="sm" onClick={handleUnlock}>
                Update view
              </Button>
            </CardBody>
          )}
          {!locked && (
            <>
              <CardBody className="overflow-500">
                {polesToInspect.join(", ")}
              </CardBody>
              <CardFooter>
                <small>
                  <i>
                    <sup>*</sup> Defect resolution is available to the project
                    manager when all the poles in the circuit have been
                    inspected.
                  </i>
                </small>
              </CardFooter>
            </>
          )}
        </Card>
      )}
      {inspectionsToApprove > 0 && polesToInspect.length === 0 && (
        <Card className="cards-container">
          <CardHeader className="bg-danger text-white">
            Defects and pending inspections
          </CardHeader>
          {locked && (
            <CardBody>
              <CardText>
                Content expired. Please re-load inspection data or hit the
                "Update view" button below.
              </CardText>
              <Button color="danger" outline size="sm" onClick={handleUnlock}>
                Update view
              </Button>
            </CardBody>
          )}
          {!locked && (
            <>
              <CardBody className="overflow-500">
                {inspections.map((inspection, i) => (
                  <PoleReinforcementInspectionResolve
                    key={i}
                    user={user}
                    userInfo={userInfo}
                    overrideMailContacts={overrideMailContacts}
                    inspection={inspection}
                    handleLoadInspections={handleLoadInspections}
                    sendEmail={sendEmail}
                  />
                ))}
                {inspections.length === 0 && (
                  <CardText>
                    Unabled to display defect information. There might be
                    outdated inspection documents.
                  </CardText>
                )}
              </CardBody>
            </>
          )}
        </Card>
      )}
    </div>
  );
};

const DisplayReinforcementReinspections = (props) => {
  const {
    circuit,
    polesToReinspect,
    sendEmail,
    handleLoadInspections,
    overrideMailContacts,
    user,
    userInfo,
    lastUpdate
  } = props;

  const now = getNewTimestamp();
  const contentExpiration = 10; // In seconds
  const [locked, setLocked] = useState(
    now > lastUpdate + contentExpiration * 1000
  );
  const [isLoading, setIsLoading] = useState(false);

  const polesToNotify = Object.keys(polesToReinspect).filter(
    (item) => !polesToReinspect[item].notified
  );
  const isPolesToReinspectPlural = Object.keys(polesToReinspect).length > 1;
  const isPolesToNotifyPlural = polesToNotify.length > 1;

  const overrideContacts = (contacts) => {
    if (overrideMailContacts) {
      return [
        {
          name: user.name,
          address: user.email
        }
      ];
    } else {
      return contacts;
    }
  };

  const getLeadInspectorsInfo = async () => {
    const res = await getInspectorsInfo(true);

    if (res.status === 200) {
      return res.response;
    } else {
      return null;
    }
  };

  const callback = async (response) => {
    if (response.status === 202) {
      setIsLoading(true);

      let res = null;

      // Update notified poles
      const query = {
        collection: "DataFiles",
        filter: {
          circuit,
          fieldset: "poleReinforcementAudit",
          dataPointId: { $in: polesToNotify }
        },
        update: {
          notifiedReinspection: true
        },
        confirm: true
      };

      res = await bulkUpdate(query);

      if (res.status === 200) {
        // Update notified poles
        const query = {
          collection: "PoleReinforcementInspections",
          filter: {
            circuit,
            dataPointId: { $in: polesToNotify }
          },
          update: {
            notifiedReinspection: true
          },
          confirm: true
        };

        res = await bulkUpdate(query);

        if (res.status === 200) {
          handleLoadInspections();
        }
      }

      setIsLoading(false);
    }
  };

  const genHtmlToLeadInspector = (info) => {
    const isPlural = info.poles.length > 1;

    const comp = renderEmail(
      <Email title={`${APP_NAME}`}>
        <Box cellSpacing={10} width="100%">
          <Item>
            <Span fontSize={14}>
              To the Inspection Leader,
              <br />
              <br />
              Assign re-inspection task for the following pole
              {isPlural ? "s" : ""}.
              <br />
              <br />
            </Span>
          </Item>
        </Box>
        <Box cellSpacing={10} width="100%">
          <Item>
            <Span fontSize={14}>
              <b>Request details:</b>
            </Span>
          </Item>
        </Box>
        <Box cellSpacing={10} width="100%" style={{ border: "1px solid gray" }}>
          <Item>
            <Span fontSize={14}>
              <b>Circuit:</b> {info["circuit"]}
            </Span>
          </Item>
          <Item>
            <Span fontSize={14}>
              <b>
                Pole{isPlural ? "s" : ""} <small>(GLNX-GLNY)</small>:
              </b>
              <br />
              {info.poles.join(", ")}
            </Span>
          </Item>
          <Item>
            <Span fontSize={14}>
              <b>Requested by:</b>
              <br />
              {info.requestedBy.name} @{" "}
              {moment(info.requestedAt).format("MM/DD/YYYY HH:mm:s")}
            </Span>
          </Item>
        </Box>
      </Email>
    );

    return comp;
  };

  const sendEmailNotificationToLeadInspector = async (request) => {
    const contacts = request.contacts;

    if (contacts.length === 0) return false;

    const info = request.info;

    // Set mail delegate
    const fromRecipient = {
      name: userInfo.name,
      address: userInfo.email
    };

    const toRecipients = contacts;

    // Set message subject
    const subject = `${APP_NAME}: Reinf. Reinspection: ${info["circuit"]}`;

    // Set message body
    const body = {
      contentType: "HTML",
      content: genHtmlToLeadInspector(info)
    };

    // Set one attachment
    const attachments = [];

    // Set Cc list
    const ccRecipients = [];

    // Set Bcc list
    const BccRecipients = [];

    const message = MailMessage(
      fromRecipient,
      toRecipients,
      subject,
      body,
      attachments,
      ccRecipients,
      BccRecipients,
      true
    );

    // Send email request
    sendEmail(message, callback);
  };

  const handleNotify = async () => {
    setIsLoading(true);

    const res = await getLeadInspectorsInfo();

    const contacts = res.map((c) => {
      return { name: c.name, address: c.email };
    });

    if (contacts.length > 0) {
      const request = {
        contacts: overrideContacts(contacts),
        info: {
          circuit,
          poles: polesToNotify,
          requestedBy: userInfo,
          requestedAt: getNewTimestamp()
        }
      };

      await sendEmailNotificationToLeadInspector(request);
    } else {
      // No lead inspectors found
    }

    setIsLoading(false);
  };

  const handleUnlock = () => {
    setLocked(false);

    handleLoadInspections();
  };

  return (
    <div className="CircuitAuditUtils">
      <Card>
        <CardHeader className="bg-warning text-white">
          Poles to re-inspect
        </CardHeader>
        {locked && (
          <CardBody>
            <CardText>
              Content expired. Please re-load inspection data or hit the "Update
              view" button below.
            </CardText>
            <Button color="warning" outline size="sm" onClick={handleUnlock}>
              Update view
            </Button>
          </CardBody>
        )}
        {!locked && (
          <>
            <CardBody className="overflow-500">
              <div className="mb-05rem" style={{ padding: "0.5rem" }}>
                <CardText>
                  The pole
                  {isPolesToReinspectPlural ? "s below are" : " below is"}{" "}
                  awaiting re-inspection.
                </CardText>
                {polesToNotify.length > 0 && (
                  <CardText>
                    <Badge color="warning">Important!</Badge>
                    <br />
                    The pole
                    {isPolesToNotifyPlural ? "s" : ""} in red below, need
                    {!isPolesToNotifyPlural ? "s" : ""} to be communicated to
                    the Lead Inspector to order{" "}
                    {isPolesToNotifyPlural ? "their" : "its"} re-inspection.
                  </CardText>
                )}
              </div>
              <Card body className="display-card">
                <FormGroup className="mb-0rem">
                  {Object.keys(polesToReinspect).map((pole, i) => {
                    const color = polesToReinspect[pole].notified
                      ? "warning"
                      : "danger";

                    return (
                      <Badge key={i} color={color} className="mr-025rem">
                        {pole}
                      </Badge>
                    );
                  })}
                </FormGroup>
              </Card>
              {/* <Json data={polesToReinspect} /> */}
            </CardBody>
            {polesToNotify.length > 0 && (
              <CardFooter>
                <Button
                  color="warning"
                  size="sm"
                  disabled={isLoading}
                  onClick={handleNotify}
                >
                  Notify Lead Inspector{" "}
                  {isLoading && <Spinner size="sm" color="light" />}
                </Button>
              </CardFooter>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

export {
  OverflowCardContainer,
  DuplicateGlnxyReport,
  MissingGlobalIdsReport,
  InvalidGlobalIdsReport,
  MismatchedLocationDataReport,
  FormatValidationReport,
  EngStandardsComplianceReport,
  getFieldDefinitionsBySource,
  getCircuitData,
  validateFormat,
  getEngStandardsBySource,
  runComplexRules,
  ManageRiaPoRequest,
  DisplayReinforcementInspections,
  DisplayReinforcementReinspections
};
