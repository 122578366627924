import _ from "lodash";

// Set header
let header = [
  "Line #",
  "Map",
  "Circuit",
  "GLNX",
  "GLNY",
  "Length",
  "Class",
  "Trt",
  "Install year",
  "Truck access",
  "Pole Replacement"
];

const invalidRipGlns = [];

const removeNullsFromArray = (arr) => {
  return arr.filter((el) => {
    return el != null;
  });
};

const removeEmptyColsFromArray = (arr, header, offset) => {
  let transposed = _.zip.apply(_, [header, ...arr]);

  const cleanArray = transposed.map((row, i) => {
    if (i >= offset) {
      if (row.slice(1).join("")) {
        return row;
      } else {
        return null;
      }
    } else {
      return row;
    }
  });

  return _.zip.apply(_, cleanArray);
};

const genSummaryRows = (rows, sources, totalInspections) => {
  let summary = [];
  let totalNumOfDefects = 0;
  const colCountOffset = 10;

  // Add sources to summary
  summary.push("Sources");
  summary.push(sources.join("+"));

  const padOffset = summary.length;

  // Insert empty cells
  for (let i = 0; i < colCountOffset - padOffset; i++) {
    summary.push("");
  }

  // Count
  const maxCols = rows[0].length;
  const count = Array(maxCols - colCountOffset).fill(0);
  rows.forEach((row, i) => {
    if (i > 0) {
      // Start counting cell values
      row.forEach((cell, i) => {
        if (i >= colCountOffset) {
          const cellValue = parseInt(cell);
          const defect = isNaN(cellValue) ? 0 : cellValue;

          // Add to column count
          count[i - colCountOffset] += defect;

          // Add to total count of defects
          totalNumOfDefects += defect;
        }
      });
    }
  });

  // Calculate defect rate
  const perc = ((totalNumOfDefects / totalInspections) * 100).toFixed(0);

  // Set top rows
  const topRows = [];

  // Report invalid glns in rip data
  if (invalidRipGlns.length) {
    topRows.push(["Invalid RIP GLNX-GLNY", `"${invalidRipGlns.join(",")}"`]);
  }

  // Set other rows
  topRows.push(["Pole Inspections", totalInspections]);
  topRows.push(["Defect rate", `${perc}%`]);

  return [...topRows, [...summary, ...count]];
};

const sortByMap = (source) => {
  source.sort((x, y) => x.map.localeCompare(y.map));

  return source;
};

const rewriteLineNumber = (rows) => {
  return rows.map((row, i) => {
    // Skip header
    if (i > 0) {
      row[0] = i;
    }

    return row;
  });
};

const processRipData = (source, ripEntries) => {
  // Set defect status
  const defectStatus = "Non-Restorable Reject";

  // Loop through RIP data
  ripEntries.forEach((ripEntry) => {
    const sourceIndex = _.findIndex(source, (sourceEntry) => {
      return sourceEntry.dataPointId === ripEntry.dataPointId;
    });

    // If RIP entry is found in source data
    if (sourceIndex !== -1) {
      // Overwrite pole status in source
      if (ripEntry.document.PoleStatus === defectStatus) {
        source[sourceIndex].document.PoleStatus = ripEntry.document.PoleStatus;
      }
    } else {
      // If not found: add new doc to source
      if (ripEntry.document.PoleStatus === defectStatus) {
        // Add defect to oh package
        source.push(ripEntry);

        // Collect invalid gln coords in rip data
        invalidRipGlns.push(ripEntry.dataPointId);
      }
    }
  });

  return source;
};

const aggregateData = (
  source,
  ptpEntries,
  ptpAcceptableValues,
  ripEntries,
  sources
) => {
  // Add PTP header
  header = [...header, ...Object.keys(ptpAcceptableValues)];

  // Process RIP data
  if (ripEntries.length > 0) source = processRipData(source, ripEntries);

  // Index merge data using dataPointId
  let mergeIndex = {};
  ptpEntries.map((row, i) => {
    mergeIndex[row.dataPointId] = i;

    return null;
  });

  // Sort by map
  const sortedSource = sortByMap(source);

  // Generate CSV source data
  const sourceCsvRows = sortedSource.map((entry, i) => {
    const lineNumber = i + 1;
    const { dataPointId, map, circuit, GLNX, GLNY, document } = entry;
    // Transform pole status
    const poleReplacement =
      document.PoleStatus === "Non-Restorable Reject" ? 1 : "";

    const row1 = [
      lineNumber,
      map,
      circuit,
      GLNX,
      GLNY,
      document.PoleTagLength,
      document.PoleTagClass,
      document.PoleTagSpeciesTreatmentCode,
      document.PoleTagInstallationYear,
      document.TruckAccessible,
      poleReplacement
    ];

    // Locate the same row in merge data
    let row2 = [];
    let numOfDefects = 0;

    if (Object.keys(mergeIndex).length && ptpEntries[mergeIndex[dataPointId]]) {
      const mergeRow = ptpEntries[mergeIndex[dataPointId]];

      row2 = Object.keys(ptpAcceptableValues).map((key) => {
        const isDefect = mergeRow.document[key] !== ptpAcceptableValues[key];

        if (isDefect) numOfDefects += 1;

        return isDefect ? 1 : "";
      });
    }

    return poleReplacement || numOfDefects > 0 ? [...row1, ...row2] : null;
  });

  // Remove nulls from array
  const cleanRows = removeNullsFromArray(sourceCsvRows);

  // Set header offset (number of fixed columns)
  const headerOffset = 11;

  // Remove empty columns from array
  let rowsArray = removeEmptyColsFromArray(cleanRows, header, headerOffset);

  // Rewrite line number
  rowsArray = rewriteLineNumber(rowsArray);

  // Generate summary row
  const summaryRows = genSummaryRows(rowsArray, sources, source.length);

  // Convert rows into csv strings
  const csvArray = [...summaryRows, ...rowsArray].map((row) => row.join(","));

  return csvArray;
};

export { aggregateData };
