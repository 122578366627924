import { useEffect, useState } from "react";
import { defer } from "rxjs";
import { getSource } from "../services/sourceService";

export const useGetBuilderSource = (fileType) => {
  const [source, setSource] = useState(null);

  useEffect(() => {
    if (!fileType) {
      return;
    }

    const fetchSources = async (fileType) => {
      return await getSource(fileType);
    };

    const subscription = defer(() => fetchSources(fileType)).subscribe(
      setSource
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [fileType]);

  return source;
};
