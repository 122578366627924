import axios from "axios";
import { config } from "./config";

const stage = config.stage;
const settings = config[stage].appService;

const getCollectionCount = async (query) => {
  const url = `${settings.baseUrl}/api/getCollectionCount?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: query
  });

  return data;
};

export { getCollectionCount };
