import React from "react";
import { Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import "./ReportsComp.css";

const DEBUG = false;

// Utils
const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;
// const delay = (ms) => new Promise((res) => setTimeout(res, ms));
// const cleanUpArr = (arr) => {
//   return [...new Set(arr)].sort().map((i) => +i);
// };

const ReportsComp = (props) => {
  const { user } = props;

  const Debug = () => {
    return (
      <Card className="mt-1rem">
        <CardHeader>Debug</CardHeader>
        <CardBody className="overflow-600">
          <Json data={user} />
        </CardBody>
      </Card>
    );
  };

  return (
    <div className="ReportsComp">
      <Card>
        <CardHeader tag="h5">Reports</CardHeader>
        <CardBody className="overflow-1200">Reports here...</CardBody>
        <CardFooter>Footer here...</CardFooter>
      </Card>
      {DEBUG && <Debug />}
    </div>
  );
};

export default ReportsComp;
