import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import {
  Button,
  Collapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from "reactstrap";
import { getLinks } from "../models/RoleAccess";

const Links = getLinks();

const MainMenu = (props) => {
  const { user, location } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const { instance, accounts } = useMsal();

  const handleLogout = async () => {
    const homeAccountId = accounts[0]?.homeAccountId;
    const currentAccount = instance.getAccountByHomeId(homeAccountId);

    await instance.logoutRedirect({
      account: currentAccount,
      postLogoutRedirectUri: "/"
    });
  };

  const CustomLink = (props) => {
    const { path, label } = props.link;
    const active = location.pathname === path;

    return (
      <NavItem>
        <NavLink active={active} href={path}>
          {label}
        </NavLink>
      </NavItem>
    );
  };

  const CustomDropdownLink = (props) => {
    const { path, label } = props.link;
    const active = location.pathname === path;

    return (
      <DropdownItem active={active} tag="a" href={path}>
        {label}
      </DropdownItem>
    );
  };

  const LinkOptions = () => {
    return user.registration !== "revoked" ? (
      <Nav className="mr-auto" navbar>
        {Links.map((link, i) => {
          return link.links
            ? link.roles.includes(user.role) && (
                <DropdownOptions key={i} link={link} />
              )
            : link.roles.includes(user.role) && (
                <CustomLink key={i} link={link} />
              );
        })}
      </Nav>
    ) : (
      <Nav className="mr-auto" navbar>
        <CustomLink link={{ path: "/", label: "Home" }} />
      </Nav>
    );
  };

  const DropdownOptions = (props) => {
    const { link } = props;

    return (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          {link.label}
        </DropdownToggle>
        <DropdownMenu right>
          {link.links.map((link, i) => {
            return (
              link.roles.includes(user.role) && (
                <CustomDropdownLink key={i} link={link} />
              )
            );
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <div>
      <Navbar color="dark" dark expand="md">
        <NavbarBrand href="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 105 39"
            height="26"
            width="75"
          >
            <path
              d="M62.3844 7.78728H53.4123V32H41.7655V7.78728H32.7755V0.0179513H62.3844V7.78728ZM93.2247 7.66162V0H66.8219V24.3133C66.8219 28.5103 70.2255 31.9102 74.4189 31.9102H79.3627H93.3611V24.2127H79.8079C78.8278 24.2127 78.0307 23.4157 78.0307 22.4355V19.2761H92.6144V12.1853H78.0307V7.66162H93.2247ZM19.7177 31.9749H0V0.0502637H19.6531C24.8482 0.0502637 29.056 4.26164 29.056 9.45316V22.6366C29.056 27.7922 24.8769 31.9749 19.7177 31.9749ZM18.2888 21.8826V10.0061C18.2888 8.65253 17.1902 7.5575 15.8402 7.5575H11.5283V24.3312H15.8402C17.1902 24.3312 18.2888 23.2362 18.2888 21.8826Z"
              fill="white"
            ></path>
          </svg>{" "}
          Data Delivery
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <LinkOptions />
          <Button outline size="sm" color="primary" onClick={handleLogout}>
            Log Out
          </Button>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default MainMenu;
