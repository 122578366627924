const config = {
  stage: "prod",
  test: {
    appRegistration: {
      clientId: "d3377bb8-0d01-4cec-a6ed-e99ff8f32665",
      tenantId: "8e61d5fe-7749-4e76-88ee-6d8799ae8143",
      redirectUri: "https://poletop-test.dteenergy.com",
      logoutUrl: "https://poletop-test.dteenergy.com"
    },
    staticWebsite: {
      url: "https://poletop-test.dteenergy.com"
    },
    appService: {
      baseUrl: "https://poletop-test.dteenergy.com",
      hostKey: "euwYRirezkMyqw2YiVhsujDQUpDfI6IDhW3lfw7SI5e8XFGneCvfcw=="
    }
  },
  prod: {
    appRegistration: {
      clientId: "da03947f-7482-4137-b54d-0e769bcc5890",
      tenantId: "8e61d5fe-7749-4e76-88ee-6d8799ae8143",
      redirectUri: "https://poletop.dteenergy.com",
      logoutUrl: "https://poletop.dteenergy.com"
    },
    staticWebsite: {
      url: "https://poletop.dteenergy.com"
    },
    appService: {
      baseUrl: "https://poletop.dteenergy.com",
      hostKey: "EntMHGEOXfoYL36bCevwj3T5vVfQfl6ceSafghVox11XQu5Ia4Nkkg=="
    }
  }
};

export { config };
