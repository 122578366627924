import { useEffect, useState } from "react";
import { defer } from "rxjs";
import { getUserInquiryByInspectionId } from "../services/poleReinfInspCustomServices";

export const useGetUserInquiryByInspId = (inspectionId, answered) => {
  const [inquiry, setInquiry] = useState(null);

  useEffect(() => {
    const fetchDataFile = async () => {
      const res = await getUserInquiryByInspectionId(inspectionId, answered);

      if (res.status === 200) {
        return res.response;
      } else {
        return null;
      }
    };

    const subscription = defer(() => fetchDataFile()).subscribe(setInquiry);

    return () => {
      subscription.unsubscribe();
    };
  }, [inspectionId, answered]);

  return inquiry;
};
