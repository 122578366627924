import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardText,
  Form,
  FormFeedback,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  FormGroup
} from "reactstrap";
import { createComplexRule } from "../services/complexRuleService";
import { v4 as uuidv4 } from "uuid";
import { phraseToProperCase } from "../libs/case-utils";
import "./ComplexRuleAdd.css";

const ComplexRuleAdd = (props) => {
  const { userInfo, fileTypes } = props;

  const [ruleName, setRuleName] = useState("");
  const [ruleDescription, setRuleDescription] = useState("");
  const [applyTo, setApplyTo] = useState(fileTypes[0]);

  const [ruleNameError, setRuleNameError] = useState(null);
  const [ruleDescriptionError, setRuleDescriptionError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const validateInput = () => {
    // Check rule name format
    if (!/^[A-Za-z0-9 #]*$/.test(ruleName.trim())) {
      setRuleNameError("Field can't be empty or last character is invalid");

      return false;
    }

    // Check rule name length
    if (ruleName.length > 64) {
      setRuleNameError("Value can't be longer than 64 characters");

      return false;
    }

    // Check rule description format
    if (!/^[A-Za-z0-9 .,]*$/.test(ruleDescription.trim())) {
      setRuleDescriptionError(
        "Field can't be empty or last character is invalid"
      );

      return false;
    }

    // Check rule description length
    if (ruleDescription.length > 256) {
      setRuleDescriptionError("Value can't be longer than 256 characters");

      return false;
    }

    setRuleNameError(null);
    setRuleDescriptionError(null);

    return true;
  };

  const handleSave = async () => {
    if (!validateInput()) return false;

    setIsLoading(true);
    setIsSaving(true);

    await createComplexRule({
      ruleId: uuidv4(),
      name: ruleName,
      description: ruleDescription,
      applyTo,
      display: false,
      active: false,
      rules: { logic: { and: [] }, data: {} },
      updatedBy: userInfo
    }).then((results) => {
      setIsLoading(false);
      setIsSaving(false);

      handleCancel();

      showCustomModal({
        title: "New Complex Rule",
        message: `The complex rule, "${ruleName}" was successfully created.`
      });
    });
  };

  const handleCancel = () => {
    setRuleName("");
    setRuleDescription("");
    setApplyTo(fileTypes[0]);
  };

  const handleRuleName = (option) => {
    setRuleName(option);
  };

  const handleRuleDescription = (option) => {
    setRuleDescription(option);
  };

  const handleApplyTo = (option) => {
    setApplyTo(option);
  };

  const ApplyToSelect = (props) => {
    const { applyTo, isSaving, handleUpdate } = props;

    return (
      <Input
        className="text-capitalize applyTo"
        value={applyTo}
        type="select"
        id="apply-to-add"
        onChange={(e) => handleUpdate(e.target.value)}
        disabled={isSaving}
      >
        {fileTypes.map((o, i) => (
          <option key={i} value={o}>
            {phraseToProperCase(o)}
          </option>
        ))}
      </Input>
    );
  };

  const SaveCancelButtons = (props) => {
    const { handleSave, handleCancel, isLoading, isSaving } = props;

    return (
      <>
        <Button
          className="editButton"
          color="primary"
          size="sm"
          onClick={handleSave}
          disabled={isSaving}
        >
          Save {isLoading && <Spinner size="sm" color="light" />}
        </Button>
        <Button
          className="editButton"
          color="default"
          size="sm"
          onClick={handleCancel}
          disabled={isSaving}
        >
          Reset
        </Button>
      </>
    );
  };

  const CreationWarning = () => {
    return (
      <div className="creation-warning">
        <Card body inverse>
          <h5 className="card-title">Important!</h5>
          <CardText>
            The new standard will be created as inactive and no logic will be
            set at its creation. To set the standard's logic, please edit it
            after it has been created.
          </CardText>
        </Card>
      </div>
    );
  };

  return (
    <div className="ComplexRuleAdd">
      <CardBody className="add-card-body">
        <Form id="form-add-complex-rule">
          <CreationWarning />
          <Card body style={{ marginBottom: "1rem" }}>
            <CardText>
              <b>Name:</b>
            </CardText>
            <FormGroup>
              <Input
                className="ruleName"
                invalid={ruleNameError ? true : false}
                type="text"
                name="rule-name"
                id="rule-name-add"
                placeholder="Max. length: 64 characters"
                value={ruleName}
                disabled={isSaving}
                onChange={(e) => handleRuleName(e.target.value)}
              />
              {ruleNameError && (
                <FormFeedback className="ruleName">
                  {ruleNameError}
                </FormFeedback>
              )}
            </FormGroup>
            <CardText>
              <b>Description:</b>
            </CardText>
            <FormGroup>
              <Input
                className="ruleDescription"
                invalid={ruleDescriptionError ? true : false}
                type="textarea"
                name="description"
                id="rule-description-add"
                rows="3"
                placeholder="Max. length: 256 characters"
                defaultValue={ruleDescription}
                disabled={isSaving}
                onChange={(e) => handleRuleDescription(e.target.value)}
              />
              {ruleDescriptionError && (
                <FormFeedback className="ruleDescription">
                  {ruleDescriptionError}
                </FormFeedback>
              )}
            </FormGroup>
            <CardText>
              <b>Apply to:</b>{" "}
              <ApplyToSelect
                applyTo={applyTo}
                isSaving={isSaving}
                handleUpdate={handleApplyTo}
              />
            </CardText>
          </Card>
          <SaveCancelButtons
            handleSave={handleSave}
            handleCancel={handleCancel}
            isLoading={isLoading}
            isSaving={isSaving}
          />
        </Form>
      </CardBody>
      <ErrorModal />
    </div>
  );
};

export default ComplexRuleAdd;
