import React from "react";
import DoUploadPicturesTest from "../components/DoUploadPicturesTest";
import "./UploadPicturesTest.css";

const UploadPicturesTest = (props) => {
  return (
    <div className="UploadPicturesTest">
      <div className="container">
        <DoUploadPicturesTest {...props} />
      </div>
    </div>
  );
};

export default UploadPicturesTest;
