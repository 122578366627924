import axios from "axios";
import { config } from "./config";

const stage = config.stage;
const settings = config[stage].appService;

const objToHeaders = (obj, prefix, append = {}) => {
  const headers = {};

  Object.keys(obj).forEach((key) => {
    headers[`${prefix}${key}`] = obj[key];
  });

  Object.keys(append).forEach((key) => {
    headers[`${key}`] = append[key];
  });

  return headers;
};

export const getUploadUrl = async (fileName, token = null) => {
  const url = `${settings.baseUrl}/api/getUploadUrl?code=${settings.hostKey}`;
  const { data, status } = await axios({
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      fileName,
      container: "uploads"
    }
  });
  return { status, response: data };
};

export const getBlockListUrl = async (fileName, token = null) => {
  const url = `${settings.baseUrl}/api/getBlockListUrl?code=${settings.hostKey}`;
  const { data, status } = await axios({
    method: "GET",
    url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      fileName,
      container: "uploads"
    }
  });
  return { status, response: data };
};

export const uploadZip = (
  url,
  file,
  headers = {},
  onUploadProgress = () => {},
  token = ""
) => {
  const formData = new FormData();
  formData.append("upload", file);
  return axios({
    method: "PUT",
    url,
    headers: {
      // Authorization: `Bearer ${token}`,
      "x-ms-blob-type": "BlockBlob",
      ...headers
    },
    data: formData,
    onUploadProgress
  });
};

export const postUploadMd = async (url, md) => {
  const prefix = "x-ms-meta-";
  const headers = objToHeaders(md, prefix);

  return axios({
    method: "PUT",
    url,
    headers,
    params: {
      comp: "metadata"
    }
  });
};

export const uploadZipChunk = (url, file, blockid, token = null) => {
  return axios({
    method: "PUT",
    url,
    headers: {
      // Authorization: `Bearer ${token}`,
      "x-ms-date": new Date()
    },
    data: file,
    params: {
      comp: "block",
      blockid
    }
  });
};

export const commitUpload = (url, blockIds, token = null) => {
  return axios({
    method: "PUT",
    url,
    headers: {
      // Authorization: `Bearer ${token}`
    },
    data: `<?xml version="1.0" encoding="utf-8"?><BlockList>${blockIds
      .map((id) => `<Latest>${id}</Latest>`)
      .join("")}</BlockList>`,
    params: {
      comp: "blocklist"
    }
  });
};

export const getUploadedBlocks = async (url, token = null) => {
  const { data, status } = await axios({
    url,
    method: "GET",
    headers: {
      // Authorization: `Bearer: ${token}`
    },
    params: {
      comp: "blocklist",
      blocklisttype: "uncommitted"
    },
    validateStatus: (status) => {
      return status < 500;
    }
  });

  let uploadedBlocks = [];

  if (status === 200) {
    const xmlDom = new DOMParser().parseFromString(data, "text/xml");
    const blocks = xmlDom.querySelectorAll("Block");
    uploadedBlocks = Array.from(blocks).reduce((carry, current) => {
      carry[current.querySelector("Name").innerHTML] =
        +current.querySelector("Size").innerHTML;

      return carry;
    }, {});
  }

  return uploadedBlocks;
};
