import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane
} from "reactstrap";
import { useGetVendors } from "./useGetVendors";
import MyDriveUpload from "./MyDriveUpload";
import { getMyDriveFiles } from "../services/vendorDriveService";
import { getCollectionCount } from "../services/collectionService";
import GetPagination from "./GetPagination";
import MyDriveFile from "./MyDriveFile";
import { phraseToProperCase } from "../libs/case-utils";
import classnames from "classnames";
import { getContentTypes } from "../models/ContentTypes";
import "./MyDriveSearch.css";

const defFileContentTypes = getContentTypes();

// Additional owners
const addOwners = ["DTE"];

const MyDriveSearch = (props) => {
  const { user, adminMailPrefs, sendEmail } = props;

  const isVendor = user.role === "vendor" || user.role === "audit";

  const vendors = useGetVendors();

  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  const [isLoading, setIsLoading] = useState(false);

  const [files, setFiles] = useState([]);
  const [fileContentTypes, setFileContentTypes] = useState([]);
  const [fileCategory, setFileCategory] = useState("all");
  const [orgs, setOrgs] = useState(isVendor ? [user.org] : []);
  const [sharedWith, setSharedWith] = useState(null);
  const [deletedFiles, setDeletedFiles] = useState([]);

  const updateDeletedFiles = (fileId) => {
    setDeletedFiles([...deletedFiles, fileId]);

    if (deletedFiles.length + 1 === files.length) {
      newSearch();
    }
  };

  const handleFileVendor = (checked, value) => {
    let temp = orgs;

    if (checked) {
      temp = [...orgs, value];
    } else {
      temp.splice(temp.indexOf(value), 1);
    }

    setOrgs(temp);
  };

  const handleFileContentType = (checked, value) => {
    let temp = fileContentTypes;

    if (checked) {
      temp = [...fileContentTypes, value];
    } else {
      temp.splice(temp.indexOf(value), 1);
    }

    setFileContentTypes(temp);
  };

  const handleSharedWith = (checked, value) => {
    if (checked) {
      setSharedWith(value);
    } else {
      setSharedWith(null);
    }
  };

  const newSearch = () => {
    setIsLoading(false);

    setCurrentPage(1);
    setFiles([]);
    toggleSearch();
  };

  // Control pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [filesCount, setFilesCount] = useState(0);

  const totalFilesPerPage = 20;

  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setFiles([]);

    listUserFiles(pageNumber, totalFilesPerPage);
  };

  const incChange = (pageNumber, inc, isposOrneg) => {
    let finalPage;
    const residual = pageNumber % inc;
    const control = residual === 0 ? inc : residual;

    if (isposOrneg > 0) {
      finalPage = pageNumber + inc - control + 1;
    } else {
      finalPage = pageNumber - inc - control + 1;
    }

    setCurrentPage(finalPage);
    setFiles([]);

    listUserFiles(finalPage, totalFilesPerPage);
  };

  const firstChange = () => {
    let finalPage = 1;

    setCurrentPage(finalPage);
    setFiles([]);

    listUserFiles(finalPage, totalFilesPerPage);
  };

  const lastChange = (finalPage) => {
    setCurrentPage(finalPage);
    setFiles([]);

    listUserFiles(finalPage, totalFilesPerPage);
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const validateForm = () => {
    return fileCategory.length > 0;
  };

  // Add "All" option to categories
  const fileCatOptions =
    user.prefs.programs.length > 1
      ? ["all", ...user.prefs.programs]
      : user.prefs.programs;

  const listUserFiles = async (page, count) => {
    let resFiles = [];
    let query = {};

    if (fileCategory !== "all") query.category = fileCategory;
    if (orgs.length) query.org = orgs;
    if (fileContentTypes.length) query.contentType = fileContentTypes;
    if (sharedWith) query.sharedWith = sharedWith;

    try {
      const resCollectionCount = await getCollectionCount({
        collection: "MyDrive",
        ...query
      });

      setFilesCount(resCollectionCount.count);
    } catch (e) {}

    if (page) query.page = page;
    if (count) query.count = count;

    try {
      resFiles = await getMyDriveFiles(query);
      setFiles(resFiles);
    } catch (e) {}

    return resFiles.length;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const numUploads = await listUserFiles(1, totalFilesPerPage);
      if (numUploads) {
        toggleSearch();
      } else {
        showCustomModal({
          title: "Search results",
          message: "No results were found following that criteria"
        });
      }
    } catch (e) {}

    setIsLoading(false);
  };

  const SearchResults = () => {
    let numberOfPages = 0;
    if (filesCount % totalFilesPerPage === 0) {
      numberOfPages = Math.floor(filesCount / totalFilesPerPage);
    } else {
      numberOfPages = Math.floor(filesCount / totalFilesPerPage) + 1;
    }

    return files.length ? (
      <>
        <div className="NewSearch">
          <Button outline onClick={newSearch} color="secondary">
            New Search
          </Button>
        </div>
        <h5 className="card-title">Search results</h5>
        <GetPagination
          pages={numberOfPages}
          nextPage={nextPage}
          currentPage={currentPage}
          firstChange={firstChange}
          lastChange={lastChange}
          incChange={incChange}
        />
        <div className="fileCards">
          {files.map((f, i) => {
            return !deletedFiles.includes(f.fileId) ? (
              <MyDriveFile
                key={i}
                file={f}
                user={user}
                vendors={vendors}
                sendEmail={sendEmail}
                updateDeletedFiles={updateDeletedFiles}
              />
            ) : (
              ""
            );
          })}
        </div>
      </>
    ) : (
      " "
    );
  };

  // Control tabs
  const [activeTab, setActiveTab] = useState("1");

  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="MyDriveSearch">
      <Card>
        <CardHeader tag="h5">Search My Drive</CardHeader>
        <CardBody>
          <Nav tabs className="top-nav">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1"
                })}
                onClick={() => {
                  toggleTabs("1");
                }}
                style={{
                  marginLeft: "0.5rem"
                }}
              >
                Search
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2"
                })}
                onClick={() => {
                  toggleTabs("2");
                }}
              >
                Upload files
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Collapse isOpen={isSearchOpen}>
                <Card className="search-engine">
                  <CardBody>
                    {vendors ? (
                      <Form onSubmit={handleSubmit} id="search-form">
                        <FormGroup tag="fieldset">
                          <h5 className="card-title">Select the program</h5>
                          {fileCatOptions.map((el, i) => (
                            <FormGroup key={i} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="fileCategory"
                                  value={el}
                                  checked={fileCategory === el}
                                  onChange={(e) =>
                                    setFileCategory(e.target.value)
                                  }
                                />{" "}
                                {el === "all" ? phraseToProperCase(el) : el}
                              </Label>
                            </FormGroup>
                          ))}
                        </FormGroup>
                        {!isVendor && (
                          <FormGroup tag="fieldset">
                            <h5 className="card-title">
                              Select the owner (optional)
                            </h5>
                            {[...vendors, ...addOwners].map((v, i) => (
                              <FormGroup key={i} check>
                                <Input
                                  type="checkbox"
                                  name="check"
                                  value={v}
                                  id="file-vendor"
                                  defaultChecked={false}
                                  onChange={(e) =>
                                    handleFileVendor(
                                      e.target.checked,
                                      e.target.value
                                    )
                                  }
                                />
                                <Label for="file-vendor" check>
                                  {v}
                                </Label>
                              </FormGroup>
                            ))}
                          </FormGroup>
                        )}
                        <FormGroup tag="fieldset">
                          <h5 className="card-title">
                            Select the file type (optional)
                          </h5>
                          {defFileContentTypes.map((c, i) => (
                            <FormGroup key={i} check>
                              <Input
                                type="checkbox"
                                name="check"
                                value={c.contentType}
                                id="file-content-type"
                                defaultChecked={false}
                                onChange={(e) =>
                                  handleFileContentType(
                                    e.target.checked,
                                    e.target.value
                                  )
                                }
                              />
                              <Label for="file-content-type" check>
                                {c.label}
                              </Label>
                            </FormGroup>
                          ))}
                        </FormGroup>
                        {isVendor && (
                          <FormGroup tag="fieldset">
                            <h5 className="card-title">Other options</h5>
                            <FormGroup check>
                              <Input
                                type="checkbox"
                                name="check"
                                value={user.org}
                                id="file-shared-with"
                                defaultChecked={false}
                                onChange={(e) =>
                                  handleSharedWith(
                                    e.target.checked,
                                    e.target.value
                                  )
                                }
                              />
                              <Label for="file-shared-with" check>
                                Show only files shared with me
                              </Label>
                            </FormGroup>
                          </FormGroup>
                        )}
                        <Button
                          className="btn-lg"
                          disabled={!validateForm() || isLoading}
                          color="primary"
                          block
                        >
                          Search {isLoading && <Spinner color="light" />}
                        </Button>
                      </Form>
                    ) : (
                      <div>
                        <Spinner type="grow" color="primary" /> Gathering vendor
                        list...
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Collapse>
              <SearchResults />
            </TabPane>
            <TabPane tabId="2">
              <MyDriveUpload
                user={user}
                adminMailPrefs={adminMailPrefs}
                sendEmail={sendEmail}
              />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
      <ErrorModal />
    </div>
  );
};

export default MyDriveSearch;
