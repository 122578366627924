class ApiLoadTest {
  headers = [];
  start = null;
  finish = null;
  entries = [];
  benchmark = 0;

  constructor(headers) {
    this.headers = headers;
  }

  setBenchmark(timestamp) {
    this.benchmark = timestamp;

    if (!this.start) this.start = timestamp;
  }

  pushEntry(entry) {
    this.responseTime = entry.timestamp - this.benchmark;

    this.entries.push({
      key: entry.key,
      payload: entry.payload,
      status: entry.status,
      responseTime: this.responseTime,
      timestamp: this.benchmark
    });

    this.finish = entry.timestamp;
  }

  report() {
    this.rows = [];

    for (let i = 0; i < this.entries.length; i++) {
      this.rows.push(Object.values(this.entries[i]).join(","));
    }

    return `${this.headers.join(",")}\n${this.rows.join("\n")}`;
  }
}

export { ApiLoadTest };
