
const FileTypeAbbr = {
    poleInspection: "PIT",
    poleInspectionAudit: "PIA",
    poleReinforcement: "RIP",
    poleReinforcementAudit: "RIA",
    poletopInspection: "PTP",
    poleTopMaintenance: "PTM"
  };

export {
    FileTypeAbbr
}