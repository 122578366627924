import React from "react";
import ReleasesList from "../components/ReleasesList";
import "./Releases.css";

const Releases = (props) => {
  return (
    <div className="Releases">
      <div className="container">
        <ReleasesList {...props} />
      </div>
    </div>
  );
};

export default Releases;
