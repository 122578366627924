import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import { getSources } from "../services/sourceService";
import { getSomeFieldsObj } from "../services/fieldsService";
import "./RunIntegrityChecks.css";

const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

const ShowSourceFieldIntegrity = props => {
  const { source } = props;

  const [fields, setFields] = useState(null);

  useEffect(() => {
    const pullSourceFields = async fields => {
      return await getSomeFieldsObj(fields);
    };
    pullSourceFields(source.fields).then(results => {
      setFields(results);
    });
  }, [source.fields]);

  const findFieldsInSource = () => {
    let sourceObj = { name: source.sourceKey, fields: {} };
    source.fields.map(field => {
      sourceObj.fields[field] = false;

      return true;
    });

    Object.keys(fields).map(field => {
      sourceObj.fields[field] = true;

      return true;
    });

    return sourceObj;
  };

  return fields ? (
    <Json data={findFieldsInSource()} />
  ) : (
    <pre>Waiting for {source.sourceKey}</pre>
  );
};

const RunIntegrityChecks = props => {
  const [sources, setSources] = useState(null);

  useEffect(() => {
    const pullSources = async () => {
      return await getSources();
    };
    pullSources().then(results => {
      setSources(results);
    });
  }, []);

  return (
    <div className="RunIntegrityChecks">
      <div className="container">
        <Card>
          <CardHeader tag="h5">Run Data Integrity Checks</CardHeader>
          <CardBody>
            {sources &&
              sources.map((source, i) => {
                return <ShowSourceFieldIntegrity key={i} source={source} />;
              })}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default RunIntegrityChecks;
