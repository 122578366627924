import React from "react";
import { withRouter } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from "@azure/msal-react";
import { sendMsGraphMail } from "./graph";
import { sendMailRequest } from "./authConfig";
import Routes from "./Routes";
import "./App.css";
import MainMenu from "./components/MainMenu";
import Login from "./containers/Login";
import { useGetUser } from "./components/useGetUser";
import { useGetAdminPrefs } from "./components/useGetAdminPrefs";
import { getAccessToken } from "./services/userService";

const App = (props) => {
  const AppContent = () => {
    const { instance, accounts } = useMsal();

    const sendEmail = (message, callback = null) => {
      instance
        .acquireTokenSilent({
          ...sendMailRequest,
          account: accounts[0]
        })
        .then((response) => {
          sendMsGraphMail(response.accessToken, message).then((response) => {
            if (callback) callback(response);
          });
        });
    };

    const sendMail = async (message, token, callback = null) => {
      const res = await sendMsGraphMail(token, message);

      if (callback) {
        callback(res);
      } else {
        return res;
      }
    };

    let user = {};
    const account = accounts[0] ?? null;
    const userId = account ? account.localAccountId : null;

    user = useGetUser(userId);

    if (!user) {
      const name = accounts[0].name;
      const email = accounts[0].username;

      const guest = {
        id: userId,
        name,
        email,
        role: "guest",
        prefs: {
          fileTypes: []
        }
      };

      user = { ...guest, token: getAccessToken(guest) };
    }

    let adminMailPrefs = {};
    adminMailPrefs = useGetAdminPrefs();

    if (!adminMailPrefs) {
      adminMailPrefs = { users: [] };
    }

    return (
      <div>
        <MainMenu location={props.location} user={user} />
        <Routes appProps={{ user, adminMailPrefs, sendEmail, sendMail }} />
      </div>
    );
  };

  return (
    <div className="App">
      <AuthenticatedTemplate>
        <AppContent />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </div>
  );
};

export default withRouter(App);
