import React from "react";
import DataFieldsList from "../components/DataFieldsList";
import "./DataFields.css";

const DataFields = (props) => {
  return (
    <div className="DataFields">
      <div className="container">
        <DataFieldsList {...props} />
      </div>
    </div>
  );
};

export default DataFields;
