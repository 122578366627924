import React from "react";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";

const PhotoViewer = (props) => {
  const { user } = props;
  return (
    <div className="PhotoViewer">
      <Card>
        <CardHeader tag="h5" className="bg-light text-dark font-weight-bold">
          Photo Viewer
        </CardHeader>
        <CardBody>
          <CardTitle>{user.name}, you are logged on as photo viewer.</CardTitle>
        </CardBody>
      </Card>
    </div>
  );
};

export default PhotoViewer;
