import axios from "axios";
import { config } from "./config";

const stage = config.stage;
const settings = config[stage].appService;

const getSource = async (sourceKey) => {
  const url = `${settings.baseUrl}/api/sourceGet?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: {
      sourceKey
    }
  });

  return data;
};

const getSources = async () => {
  const url = `${settings.baseUrl}/api/sourcesList?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url
  });

  return data;
};

const getSourceKeys = async () => {
  const url = `${settings.baseUrl}/api/sourcesList?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: { onlyKeys: "true" }
  });

  return data.map((s) => s.sourceKey);
};

export { getSource, getSources, getSourceKeys };
