import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  Form,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import AssocTable from "./AssocTable";
import "./DoAssocTableTest.css";

const Json = ({ data }) => (
  <pre className="mt-1rem">{JSON.stringify(data, null, 4)}</pre>
);

const DoAssocTableTest = () => {
  const defOptions = [
    "AT&T",
    "City",
    "Comcast",
    "County",
    "Deerfield",
    "DTE",
    "ITC",
    "MDOT",
    "PLD",
    "Private",
    "Verizon"
  ];
  const defUseTransformation = false;
  const defTransformation = [];

  //   const defOptions = ["o1", "o2"];
  //   const defUseTransformation = true;
  //   const defTransformation = [
  //     ["1", "2", "3"],
  //     ["4", "5", "6"]
  //   ];

  const [options, setOptions] = useState(defOptions);
  const [transformation, setTransformation] = useState(defTransformation);
  const [useTransformation, setUseTransformation] =
    useState(defUseTransformation);

  const isOptions = options.length > 0;

  const handleOptions = (value) => {
    const isEmpty = value.length === 0;
    const newOptions = isEmpty ? [] : value.split(",").map((o) => o.trim());

    setOptions(newOptions);
    if (isEmpty) handleUseTransformation(false);
  };

  const handleUseTransformation = (flag) => {
    if (!flag) {
      setTransformation([]);
    }

    setUseTransformation(flag);
  };

  return (
    <div className="DoAssocTableTest">
      <Card>
        <CardHeader tag="h5">Association Table Test</CardHeader>
        <CardBody>
          <Form>
            <FormGroup>
              <CardText>
                <b>Field Options</b> <sup>* &dagger;</sup>
              </CardText>
              <Input
                type="textarea"
                id="field-options"
                rows="2"
                defaultValue={options}
                onChange={(e) => handleOptions(e.target.value)}
              />
            </FormGroup>
            {isOptions && (
              <FormGroup check className="mb-1rem">
                <Input
                  type="checkbox"
                  id="use-transformation"
                  defaultChecked={useTransformation}
                  onChange={(e) => handleUseTransformation(e.target.checked)}
                />{" "}
                <Label check>Use transformation</Label>
              </FormGroup>
            )}
            {useTransformation && (
              <AssocTable
                options={options}
                transformation={transformation}
                updateTransformation={setTransformation}
              />
            )}
          </Form>
          <Json data={{ options, useTransformation, transformation }} />
        </CardBody>
      </Card>
    </div>
  );
};

export default DoAssocTableTest;
