export const env = () => process.env.NODE_ENV;
export const isDevEnv = () => env() === "development";
export const ifIsDevEnv = (fn) => (isDevEnv() ? fn() : undefined);
export const logMessageInDevEnv = (msg) => ifIsDevEnv(() => console.log(msg));

export const useFileSignatures = () => {
  // use the first 512mb of the file to sign.
  const fileSizeForSignature = 1024 * 1024 * 512;

  const jwk = {
    alg: "HS512",
    ext: true,
    k: "0bDD0aLbF82DFhJBo0Zzhd7eXXU0SC3fcZL8ASOb0h6U-A6vy90GPTIB8cBc_546gmYIguovIf6kndl7Rcg_rlRq7Htap3BA7WkdqKHUtHo8pg3PSzN9WOgFr2ilEu7AUaCC08axOMnrviUVfCHMBho6W9tTCcMS1FvQhJceKLE",
    key_ops: ["sign", "verify"],
    kty: "oct"
  };

  const getKey = async () =>
    await crypto.subtle.importKey(
      "jwk",
      jwk,
      {
        name: "HMAC",
        hash: { name: "SHA-512" }
      },
      true,
      ["sign", "verify"]
    );

  const getFileBuffer = async (file) => {
    let buffer = new ArrayBuffer();
    try {
      buffer = file.slice(0, fileSizeForSignature, file.type).arrayBuffer();
    } catch (err) {}
    return buffer;
  };

  const signFile = async (file) => {
    const key = await getKey();
    const encoded = await getFileBuffer(file);
    const signResult = await window.crypto.subtle.sign("HMAC", key, encoded);
    const encodableArray = new Uint8Array(signResult, 0, signResult.byteLength);
    const signature = btoa(encodableArray);
    return signature;
  };

  const verifyFile = async (file, signature) => {
    const key = await getKey();
    let fileBuffer = await getFileBuffer(file);
    let decodedBuffer = [];
    try {
      decodedBuffer = atob(signature)
        .split(",")
        .map((d) => +d);
    } catch (err) {}

    let decodedSignature = Uint8Array.from(decodedBuffer);
    return await window.crypto.subtle.verify(
      "HMAC",
      key,
      decodedSignature,
      fileBuffer
    );
  };

  return [signFile, verifyFile];
};
