import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  CardText,
  Col,
  Row
} from "reactstrap";
import { getDownloadUrl, followDownloadUrl } from "../services/filesService";
import prettyBytes from "pretty-bytes";
import "./PhotoPreview.css";

// const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;
// const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const PhotoPreview = (props) => {
  const { curMedia } = props;
  const [media, setMedia] = useState(curMedia);
  const [showYesToAll, setShowYesToAll] = useState(media.length > 1);

  const handlePreviewPhoto = async (photoIndex) => {
    const { storedAs, type } = media[photoIndex];

    // Get download url
    const blobUrl = await getDownloadUrl(storedAs, "uploads");

    // Create local blob and url
    const content = await followDownloadUrl(blobUrl);
    const blob = new Blob([content], {
      type
    });
    const url = URL.createObjectURL(blob);

    const copy = [...media];
    copy[photoIndex].url = url;
    setMedia(copy);
  };

  const UploadedPhoto = (props) => {
    const { file, index } = props;

    return (
      <Card className="mb-05rem">
        {file.url && (
          <CardImg alt={file.name} src={file.url} top width="100%" />
        )}
        {!file.url && (
          <CardHeader className="align-preview">
            <Button
              color="primary"
              outline
              size="sm"
              onClick={() => handlePreviewPhoto(index)}
            >
              Preview
            </Button>
          </CardHeader>
        )}
        <CardBody>
          <CardText className="photo-des">
            <b>Name:</b> {file.name}
          </CardText>
          <CardText className="photo-des">
            <b>Size:</b> {prettyBytes(file.size)}
          </CardText>
        </CardBody>
      </Card>
    );
  };

  const YesToAllButton = () => {
    const [yesAll, setYesAll] = useState(false);

    const yesToAll = () => {
      setYesAll(true);

      for (let i = 0; i < media.length; i++) {
        handlePreviewPhoto(i);
      }

      setYesAll(false);
      setShowYesToAll(false);
    };

    return (
      <div style={{ overflow: "hidden" }}>
        <Button
          color="link"
          className="float-right"
          disabled={yesAll}
          onClick={() => yesToAll()}
        >
          Preview all
        </Button>
      </div>
    );
  };

  return (
    <div className="PhotoPreview">
      {showYesToAll && <YesToAllButton />}
      <Row>
        {media.map((file, i) => {
          return (
            <Col sm="3" key={i}>
              <UploadedPhoto file={file} index={i} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default PhotoPreview;
