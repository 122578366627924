import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  FormGroup,
  Input,
  Spinner
} from "reactstrap";
import ComplexRules from "../models/seeding/ComplexRules.json";
import Fields from "../models/seeding/Fields.json";
import {
  createComplexRule,
  updateComplexRule
} from "../services/complexRuleService";
import { createField } from "../services/fieldsService";
import "./SeedManager.css";

// const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

const SEEDING_COMPLEXRULES = true;
const SEEDING_FIELDS = true;
const FIELD_DEFINITIONS = false;

const sortedFields = Fields.sort((x, y) =>
  x.fieldKey.localeCompare(y.fieldKey)
);

const SeedManager = () => {
  const genComplexRuleDocs = () => {
    // Generate inserts
    const inserts = ComplexRules.map((rule) => {
      return { doc: { ...rule, rules: {} } };
    });

    // Generate updates
    const updates = ComplexRules.map((rule) => {
      return {
        ruleId: rule.ruleId,
        doc: {
          rules: rule.rules,
          updatedBy: { ...rule.createdBy }
        }
      };
    });

    return { inserts, updates };
  };

  const SeedComplexRules = () => {
    const docs = genComplexRuleDocs();

    const RunButton = (props) => {
      const { action, data } = props;

      const [isLoading, setIsLoading] = useState(false);
      const [isDone, setIsDone] = useState(false);

      const handleClick = () => {
        setIsLoading(true);

        if (action === "insert") {
          createComplexRule(data.doc).then((result) => {
            console.log(`${action}: `, result);

            setIsDone(true);
            setIsLoading(false);
          });
        }

        if (action === "update") {
          updateComplexRule(data.ruleId, data.doc).then((result) => {
            console.log(`${action}: `, result);

            setIsDone(true);
            setIsLoading(false);
          });
        }
      };

      return (
        <div>
          <Button
            id="createButton"
            className="action-button"
            color="primary"
            size="sm"
            onClick={handleClick}
            disabled={isLoading || isDone}
          >
            {action} {isLoading && <Spinner size="sm" color="light" />}
          </Button>
        </div>
      );
    };
    return (
      <Card>
        <CardHeader>Engineering Standards</CardHeader>
        <CardBody>
          <CardText>
            <b>Inserts</b>
          </CardText>
          {docs.inserts.map((insert, i) => {
            return <RunButton key={i} action="insert" data={insert} />;
          })}
          <CardText>
            <b>Updates</b>
          </CardText>
          {docs.updates.map((update, i) => {
            return <RunButton key={i} action="update" data={update} />;
          })}
        </CardBody>
      </Card>
    );
  };

  const genFieldDocs = () => {
    // Generate inserts
    const inserts = Fields.map((field) => {
      return { doc: { ...field } };
    });

    return { inserts };
  };

  const SeedFields = () => {
    const docs = genFieldDocs();

    const RunButton = (props) => {
      const { action, data } = props;

      const [isLoading, setIsLoading] = useState(false);
      const [isDone, setIsDone] = useState(false);

      const handleClick = () => {
        setIsLoading(true);

        if (action === "insert") {
          createField(data.doc).then((result) => {
            console.log(`${action}: `, result);

            setIsDone(true);
            setIsLoading(false);
          });
        }
      };

      return (
        <div>
          <Button
            id="createButton"
            className="action-button"
            color="primary"
            size="sm"
            onClick={handleClick}
            disabled={isLoading || isDone}
          >
            {action}: {data.doc.fieldKey}{" "}
            {isLoading && <Spinner size="sm" color="light" />}
          </Button>
        </div>
      );
    };

    return (
      <Card>
        <CardHeader>Fields</CardHeader>
        <CardBody>
          <CardText>
            <b>Inserts</b>
          </CardText>
          {docs.inserts.map((insert, i) => {
            return <RunButton key={i} action="insert" data={insert} />;
          })}
        </CardBody>
      </Card>
    );
  };

  const longest = (arr) => {
    return arr.reduce((a, b) => {
      const aStr = a.toString();
      const bStr = b.toString();

      return aStr.length > bStr.length ? aStr : bStr;
    });
  };

  const FieldDefinitions = () => {
    const rows = sortedFields.map((field, i) => {
      const typeException = field.validation.typeException
        ? field.validation.typeException.join(", ")
        : field.validation.orOptions
        ? field.validation.orOptions.join(",")
        : "NA";
      const options = field.validation.options
        ? field.validation.options
        : field.validation.orOptions;
      const length = field.validation.length ? field.validation.length : "NA";
      const minLength = field.validation.minLength
        ? field.validation.minLength
        : "NA";
      const maxLength = field.validation.maxLength
        ? field.validation.maxLength
        : options !== undefined
        ? longest(options).length
        : "NA";
      const date = field.validation.date ? field.validation.date.format : "No";
      const pattern = field.validation.patternMsg
        ? field.validation.patternMsg
        : "NA";

      // const definition = {
      //   fieldKey: field.fieldKey,
      //   description: field.description,
      //   type: field.validation.type,
      //   typeException,
      //   date,
      //   length,
      //   minLength,
      //   maxLength,
      //   pattern
      //   // validation: field.validation
      // };

      const esb = field.alias.ESB.field ? field.alias.ESB.field : "";
      const esbOverrides = field.alias.ESB.overrides
        ? `"${field.alias.ESB.overrides.join(",")}"`
        : "";
      const eda = field.alias.EDA.field ? field.alias.EDA.field : "";
      const edaOverrides = field.alias.EDA.overrides
        ? `"${field.alias.EDA.overrides.join(",")}"`
        : "";

      const definition = [
        field.fieldKey,
        esb,
        esbOverrides,
        eda,
        edaOverrides,
        `"${field.description}"`,
        field.validation.type,
        `"${typeException}"`,
        `"${date}"`,
        length,
        minLength,
        maxLength,
        `"${pattern}"`
      ];

      return definition.join(",");
    });

    const header = [
      "Data Delivery Field",
      "ESB",
      "Overrides",
      "EDA",
      "Overrides",
      "Description",
      "Type",
      "Type Exception",
      "Date",
      "Length",
      "Min Length",
      "Max Length",
      "Pattern"
    ].join(",");

    // Merge header and rows
    const dump = [header, ...rows].join("\n");

    return (
      <Card>
        <CardHeader>Field Definitions</CardHeader>
        <CardBody>
          <CardText>
            <b>Fields</b>
          </CardText>
          {/* <Json data={rows} />
           */}
          <FormGroup>
            <Input type="textarea" name="text" rows={10} defaultValue={dump} />
          </FormGroup>
        </CardBody>
      </Card>
    );
  };

  return (
    <div className="SeedManager">
      <Card>
        <CardHeader tag="h5">Seeding Collections</CardHeader>
        <CardBody>
          {SEEDING_COMPLEXRULES && <SeedComplexRules />}
          {SEEDING_FIELDS && <SeedFields />}
          {FIELD_DEFINITIONS && <FieldDefinitions />}
        </CardBody>
      </Card>
    </div>
  );
};

export default SeedManager;
