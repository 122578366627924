import { bulkGet } from "./bulkServices";

const getAllReinfData = async (circuit) => {
  const query = {
    collection: "DataFiles",
    filter: {
      circuit,
      $or: [{ suffix: "RIP" }, { suffix: "RIA" }]
    },
    sort: {},
    project: {
      _id: 0
    },
    confirm: true
  };

  const res = await bulkGet(query);

  return res;
};

const getDataByFileName = async (source) => {
  const query = {
    collection: "DataFiles",
    filter: {
      source
    },
    sort: {},
    project: {
      _id: 0
    },
    confirm: true
  };

  const res = await bulkGet(query);

  return res;
};

const getDuplicatePoles = async (
  circuit,
  type,
  poles,
  earliestInspectionYear
) => {
  const query = {
    collection: "DataFiles",
    filter: {
      dataPointId: {
        $in: poles
      },
      fieldset: type,
      circuit: {
        $ne: circuit
      }
    },
    sort: {},
    project: {
      _id: 0,
      dataPointId: 1,
      circuit: 1,
      source: 1,
      "document.InspectionDate": 1,
      parsedBy: 1,
      parsedAt: 1
    },
    confirm: true
  };

  if (earliestInspectionYear)
    query.filter["document.InspectionDate"] = {
      $gte: `${earliestInspectionYear}-01-01`
    };

  const res = await bulkGet(query);

  return res;
};

const checkPoleInspectionDataTransfer = async (circuit) => {
  const query = {
    collection: "Uploads",
    filter: {
      circuit,
      type: "poleInspection"
    },
    sort: {},
    project: {
      _id: 0,
      status: 1,
      datapoints: 1,
      transferred: 1
    },
    confirm: true
  };

  const res = await bulkGet(query);

  return res;
};

export {
  getAllReinfData,
  getDataByFileName,
  getDuplicatePoles,
  checkPoleInspectionDataTransfer
};
