import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardText,
  Spinner
} from "reactstrap";
import dataModel from "../models/BreakFixData.json";
import { bulkUpdate } from "../services/bulkServices";
import "./DoBreakFixData.css";

// const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

const DoBreakFixData = () => {
  const [isBreaking, setIsBreaking] = useState(false);
  const [isFixing, setIsFixing] = useState(false);
  const [updated, setUpdated] = useState(null);

  const updateDoc = async (doc) => {
    try {
      const query = {
        collection: "DataFiles",
        filter: {
          dataPointId: doc.dataPointId,
          fieldset: doc.fieldset,
          circuit: doc.circuit
        },
        update: doc.update,
        confirm: true
      };

      console.log(query);

      const res = await bulkUpdate(query);

      if (res.status === 200) {
        return res.response.result.modifiedCount;
      } else {
        return 0;
      }
    } catch (e) {
      console.log(e);

      return 0;
    }
  };

  const handleBreak = async () => {
    setIsBreaking(true);

    let count = 0;

    const breakBranch = dataModel["break"];

    const breakThese = breakBranch.map((doc) => {
      const { dataPointId, fieldset, circuit, ...update } = doc;

      return {
        dataPointId,
        fieldset,
        circuit,
        update
      };
    });

    for (let i = 0; i < breakThese.length; i++) {
      count += await updateDoc(breakThese[i]);
    }

    setUpdated(count);

    setIsBreaking(false);
  };

  const handleFix = async () => {
    setIsFixing(true);

    let count = 0;

    const fixBranch = dataModel["fix"];

    const fixThese = fixBranch.map((doc) => {
      const { dataPointId, fieldset, circuit, ...update } = doc;

      return {
        dataPointId,
        fieldset,
        circuit,
        update
      };
    });

    for (let i = 0; i < fixThese.length; i++) {
      count += await updateDoc(fixThese[i]);
    }

    setUpdated(count);

    setIsFixing(false);
  };

  return (
    <div className="DoBreakFixData">
      <div className="container">
        <Card>
          <CardHeader tag="h5">Break/Fix Data</CardHeader>
          <CardBody>
            {updated !== null && (
              <CardText>Updated records: {updated}</CardText>
            )}
            <Button
              style={{ marginBottom: "1rem" }}
              color="danger"
              size="sm"
              disabled={isBreaking || isFixing}
              onClick={handleBreak}
            >
              <b>Break</b> {isBreaking && <Spinner size="sm" color="light" />}
            </Button>
            <Button
              style={{ marginLeft: "0.25rem", marginBottom: "1rem" }}
              color="success"
              size="sm"
              disabled={isFixing || isBreaking}
              onClick={handleFix}
            >
              Fix {isFixing && <Spinner size="sm" color="light" />}
            </Button>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default DoBreakFixData;
