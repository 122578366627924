import React from "react";
import ComplexRulesList from "../components/ComplexRulesList";
import "./ComplexRules.css";

const ComplexRules = (props) => {
  return (
    <div className="ComplexRules">
      <div className="container">
        <ComplexRulesList {...props} />
      </div>
    </div>
  );
};

export default ComplexRules;
