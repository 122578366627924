import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  Table
} from "reactstrap";
import CardText from "reactstrap/lib/CardText";
import "./AssocTable.css";

// const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

const AssocTable = (props) => {
  const {
    options,
    transformation: defTransformation,
    updateTransformation
  } = props;

  const [transformation, setTransformation] = useState(defTransformation);

  const startEditing = options.length !== defTransformation.length;

  const [isEditing, setIsEditing] = useState(startEditing);

  const checkTransformation = (value, index) => {
    const transformationLen = transformation.length;
    const isTransformation = transformationLen > 0;
    const optionsLen = options.length;
    let copy = null;

    const isString = typeof value === "string";
    const isListEmpty = !isString && value.join() === "";

    if (!isTransformation) {
      copy = Array(optionsLen).fill("");

      copy[index] = !isString && !isListEmpty ? value : [];

      return copy;
    } else {
      const countDiff = optionsLen - transformationLen;
      copy = [...transformation];

      if (countDiff > 0) {
        for (let i = 0; i < countDiff; i++) {
          copy.push([]);
        }
      } else {
        for (let i = 0; i < Math.abs(countDiff); i++) {
          copy.pop();
        }
      }

      copy[index] = !isString && !isListEmpty ? value : [];

      return copy;
    }
  };

  const EditTable = () => {
    const TransformationItem = (props) => {
      const { index, value } = props;

      const [item, setItem] = useState(value);

      const handleItem = (value) => {
        const newItem = value.split(",").map((o) => o.trim());

        setItem(newItem);
      };

      return (
        <Input
          id={`transformation-value-${index}`}
          placeholder="Comma-delimited text list"
          defaultValue={item}
          onChange={(e) => handleItem(e.target.value)}
          onBlur={() => setTransformation(checkTransformation(item, index))}
        />
      );
    };

    return (
      <Table bordered>
        <thead>
          <tr>
            <th>Lookup option</th>
            <th>Transformation</th>
          </tr>
        </thead>
        <tbody>
          {options.map((option, i) => {
            return (
              <tr key={i}>
                <td>{option}</td>
                <td>
                  <TransformationItem index={i} value={transformation[i]} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const ViewTable = () => {
    const genBadges = (items) => {
      let checkedItems = items.length > 0 ? items : [""];

      return (
        <div>
          {checkedItems.map((item, i) => {
            let caption = item;
            let styleClass = "primary";

            if (caption === "") {
              styleClass = "warning";
              caption = "<empty list>";
            }

            return (
              <Badge key={i} color={styleClass} className="mr-025rem">
                {caption}
              </Badge>
            );
          })}
        </div>
      );
    };

    return (
      <Table bordered>
        <thead>
          <tr>
            <th>Lookup option</th>
            <th>Transformation</th>
          </tr>
        </thead>
        <tbody>
          {options.map((option, i) => {
            const items = transformation[i] ? transformation[i] : [""];

            return (
              <tr key={i}>
                <td>{option}</td>
                <td>{genBadges(items)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const validate = () => {
    const copy = [...transformation];
    const updated = [];

    for (let i = 0; i < options.length; i++) {
      const value = copy[i] && Array.isArray(copy[i]) ? copy[i] : [];

      setTransformation(checkTransformation(value, i));

      updated.push(value);
    }

    updateTransformation(updated);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setTransformation(defTransformation);
    setIsEditing(false);
  };

  const checkStartEditing = () => {
    return startEditing || isEditing;
  };

  return (
    <div className="AssocTable">
      <Card>
        <CardHeader>Options Transformation</CardHeader>
        <CardBody>
          <CardText>Add instructions...</CardText>
          {checkStartEditing() && <EditTable />}
          {!checkStartEditing() && <ViewTable />}
        </CardBody>
        <CardFooter>
          {checkStartEditing() && (
            <>
              <Button
                className="mr-05rem"
                color="primary"
                outline
                size="sm"
                onClick={validate}
              >
                Apply changes
              </Button>
              <Button
                color="danger"
                outline
                size="sm"
                disabled={startEditing}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </>
          )}
          {!checkStartEditing() && (
            <Button
              color="primary"
              outline
              size="sm"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </Button>
          )}
        </CardFooter>
      </Card>
    </div>
  );
};

export default AssocTable;
