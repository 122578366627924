import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  FormText,
  Input,
  Label,
  Spinner,
  Table
} from "reactstrap";
import { getRep2 } from "../services/reportServices";
import prettyBytes from "pretty-bytes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import "./Rep2Comp.css";

const DEBUG = false;

// Utils
const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;
// const delay = (ms) => new Promise((res) => setTimeout(res, ms));
// const cleanUpArr = (arr) => {
//   return [...new Set(arr)].sort().map((i) => +i);
// };

const Rep2Comp = () => {
  const [matrix, setMatrix] = useState(null);
  const [hrefCsv, setHrefCsv] = useState(null);
  const [csvSize, setCsvSize] = useState(null);
  const [showDownload, setShowDownload] = useState(false);
  const [circuit, setCircuit] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const Debug = () => {
    return (
      <Card className="mt-1rem">
        <CardHeader>Debug</CardHeader>
        <CardBody className="overflow-600">
          <Json data={matrix} />
        </CardBody>
      </Card>
    );
  };

  const getMatrix = (model) => {
    // Exit if no data
    if (model.length === 0) return [];

    const summary = {};
    let classes = [];
    let stati = [];
    let tagTypes = [];

    model.forEach((item) => {
      const prop1 = `Class ${item._id.PoleTagClass}`;
      const prop2 = item._id.PoleStatus;
      const prop3 = item._id.PoleTagSpeciesTreatmentCode;
      const poles = item.poles;

      if (!summary[prop1]) {
        summary[prop1] = {};

        classes.push(prop1);
      }
      if (!summary[prop1][prop2]) {
        summary[prop1][prop2] = {};

        stati.push(prop2);
      }
      if (!summary[prop1][prop2][prop3]) {
        summary[prop1][prop2][prop3] = {
          poles
        };

        tagTypes.push(prop3);
      }
    });

    classes = [...new Set(classes)].sort();
    stati = [...new Set(stati)].sort();
    tagTypes = [...new Set(tagTypes)].sort();

    const headerArr = [...classes, ...stati, ...tagTypes];

    const summaryObj = Object.fromEntries(headerArr.map((i) => [i, []]));

    // Fill out summary obj

    // Loop through all possible classes
    for (let i = 0; i < classes.length; i++) {
      // Set new row
      Object.keys(summaryObj).forEach((item) => summaryObj[item].push(0));

      // Set current index
      const index = summaryObj[classes[0]].length - 1;

      // Set current class
      const thisClass = classes[i];

      // Pull class object
      const thisClassObj = summary[thisClass];

      // Start counting poles in class
      let thisClassPoles = 0;

      // Loop through all possible stati
      for (let j = 0; j < stati.length; j++) {
        // Set current status
        const thisStatus = stati[j];

        // Pull status object
        if (thisClassObj[thisStatus]) {
          const thisStatusObj = thisClassObj[thisStatus];

          // Start counting poles in status
          let thisStatusPoles = 0;

          // Loop through all possible tag types
          for (let k = 0; k < tagTypes.length; k++) {
            // Set current tag type
            const thisTagType = tagTypes[k];

            // Pull tag type object
            if (thisStatusObj[thisTagType]) {
              const thisTagTypeObj = thisStatusObj[thisTagType];

              // Pull # of poles
              const poles = thisTagTypeObj.poles;

              // Increment poles in class
              thisClassPoles += poles;

              // Increment poles in class
              thisStatusPoles += poles;

              // Update tag type pole total
              summaryObj[thisTagType][index] += poles;
            }
          }

          // Update status pole total
          summaryObj[thisStatus][index] = thisStatusPoles;
        }
      }

      // Update class pole total
      summaryObj[thisClass][index] = thisClassPoles;
    }

    // Produce csv data
    const headers = Object.keys(summaryObj);
    const firstCol = headers[0];
    const totalRows = summaryObj[firstCol].length;

    // Set rows
    let matrix = [];
    matrix.push(headers);

    for (let i = 0; i < totalRows; i++) {
      let row = [];

      for (let j = 0; j < headers.length; j++) {
        const header = headers[j];

        row.push(summaryObj[header][i]);
      }

      matrix.push(row);
    }

    return matrix;
  };

  const BuildTable = () => {
    if (matrix.length === 0)
      return <CardText>No results were found for {circuit}.</CardText>;

    const header = matrix[0];
    const body = matrix.slice(1);
    let totals = new Array(header.length).fill(0);

    return (
      <Table size="sm" responsive bordered>
        <thead>
          <tr>
            {header.map((cell, i) => (
              <th key={i}>{cell}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {body.map((row, i) => {
            return (
              <tr key={i}>
                {row.map((cell, i) => {
                  // Increment total
                  totals[i] += cell;

                  return <td key={i}>{cell}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            {totals.map((total, i) => (
              <th className="btw-2px" key={i}>
                {total}
              </th>
            ))}
          </tr>
        </tfoot>
      </Table>
    );
  };

  const handleResults = async () => {
    setIsLoading(true);

    // Call report
    const res = await getRep2(circuit);

    // Check response
    if (res.status === 200) {
      // Set results
      const model = res.response;

      setMatrix(getMatrix(model));
    }

    setIsLoading(false);
  };

  const handleDownload = async () => {
    const header = matrix[0].join(",");
    const body = matrix.slice(1);
    const rowsCsv = body.map((row) => Object.values(row).join(","));

    // Create URL for CSV data
    const fileCsv = new Blob([`${header}\n${rowsCsv.join("\n")}`], {
      type: "text/csv"
    });

    setHrefCsv(URL.createObjectURL(fileCsv));
    setCsvSize(fileCsv.size);
    setShowDownload(true);
  };

  const handleReset = () => {
    setMatrix(null);
    setHrefCsv(null);
    setCsvSize(null);
  };

  const ShowDownloadLink = () => {
    return (
      <>
        <CardText>Click on the link below to download the CSV file</CardText>
        <CardText>
          <a
            href={hrefCsv}
            download="pole-treatment-report-pit.csv"
            onClick={() => setShowDownload(false)}
          >
            <FontAwesomeIcon icon={faDownload} /> Pole Treatment Report (PIT) (
            {prettyBytes(csvSize ? csvSize : 0)})
          </a>
        </CardText>
      </>
    );
  };

  return (
    <div className="Rep2Comp">
      <Card>
        <CardHeader tag="h5">Pole Treatment Report (PIT)</CardHeader>
        <CardBody className="overflow-1200">
          {matrix && <BuildTable />}
          {!matrix && (
            <>
              <FormGroup>
                <Label>
                  <b>Report description:</b>
                </Label>
                <CardText>Consolidate pole treatment codes.</CardText>
              </FormGroup>
              <FormGroup>
                <Label for="circuitId">
                  <b>
                    Circuit <sup>1</sup>
                  </b>
                </Label>
                <Input
                  className="mb-1rem"
                  type="text"
                  name="circuitId"
                  id="circuitId"
                  value={circuit}
                  disabled={isLoading}
                  onChange={(e) => setCircuit(e.target.value)}
                />
                <FormText>
                  <sup>(1)</sup> Required. Enter the circuit Id.
                </FormText>
              </FormGroup>
            </>
          )}
        </CardBody>
        <CardFooter>
          {!matrix && (
            <Button
              size="sm"
              color="primary"
              disabled={isLoading || circuit === ""}
              onClick={handleResults}
            >
              Pull report {isLoading && <Spinner size="sm" color="light" />}
            </Button>
          )}
          {matrix && (
            <>
              {!showDownload && (
                <>
                  {matrix.length > 0 && (
                    <Button
                      size="sm"
                      className="mr-025rem"
                      color="success"
                      onClick={handleDownload}
                    >
                      Export to CSV file
                    </Button>
                  )}
                  <Button size="sm" color="danger" onClick={handleReset}>
                    Reset
                  </Button>
                </>
              )}
              {showDownload && <ShowDownloadLink />}
            </>
          )}
        </CardFooter>
      </Card>
      {DEBUG && <Debug />}
    </div>
  );
};

export default Rep2Comp;
