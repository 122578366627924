import axios from "axios";
import { config } from "./config";

const stage = config.stage;
const settings = config[stage].appService;

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
// const getRanNumber = () => Math.floor(Math.random() * 5);

const checkPo = async (params) => {
  const url = `${settings.baseUrl}/api/esb-receipt-record?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "GET",
    url: url,
    params,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const checkPoFake = async (params) => {
  const status = 200;
  const data = {
    data: { member: [params.ponum] }
  };

  await delay(100);

  return { status, response: data };
};

const postToEsbInspRec = async (doc) => {
  const url = `${settings.baseUrl}/api/esb-inspection-record?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: [doc],
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const postToEsbInspRecFake = async (doc) => {
  const status = 201;
  const data = {
    data: { doc, fake: true }
  };

  await delay(100);

  return { status, response: data };
};

const postToEsbInvGlobalId = async (doc) => {
  const url = `${settings.baseUrl}/api/esb-ph2c-uc1?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const postToEsbLocationData = async (doc) => {
  const url = `${settings.baseUrl}/api/esb-ph2c-uc2?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const postToEsbLocationDataFake = async (doc) => {
  const status = 202;
  const data = {
    source: "ESRI",
    PoleGlobalId: doc.PoleGlobalId,
    success: true,
    message: "Record updated"
  };

  await delay(100);

  return { status, response: data };
};

const postToEsbCheckLocationData = async (doc) => {
  const url = `${settings.baseUrl}/api/esb-ph2c-uc3?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const postToEsbCheckLocationDataFake = async (doc) => {
  const status = 200;
  const data = { list: [doc] };

  await delay(100);

  return { status, response: data };
};

const postToEsbSendReinforcementData = async (doc) => {
  const url = `${settings.baseUrl}/api/esb-ph2c-uc4?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const postToEsbSendReinforcementDataFake = async (doc) => {
  let status = 400;
  let data = {
    source: "ESB",
    msg: {
      error: 400,
      source: "Maximo",
      PoleGlobalId: doc.GLOBALID,
      message: `BMXZZ7180E - more than 1 Workorder exists for the PO Combination, please pass a valid POLineNum for PoNum: <${doc.GLOBALID}>`
    }
  };

  if (doc.PO.LINENUM && doc.PO.LINENUM === 6) {
    status = 202;

    data = {
      source: "ESB",
      msg: {}
    };
  }

  await delay(100);

  return { status, response: data };
};

const postDataToEda = async (doc) => {
  const url = `${settings.baseUrl}/api/eda-oper-record?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const postCsvToEda = async (fileName, doc) => {
  const url = `${settings.baseUrl}/api/eda-csv-meta?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    params: { fileName },
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const postZipToEda = async (fileName, doc) => {
  const url = `${settings.baseUrl}/api/eda-zip-meta?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    params: { fileName },
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

export {
  checkPo,
  checkPoFake,
  postToEsbInspRec,
  postToEsbInspRecFake,
  postToEsbInvGlobalId,
  postToEsbLocationData,
  postToEsbLocationDataFake,
  postToEsbCheckLocationData,
  postToEsbCheckLocationDataFake,
  postToEsbSendReinforcementData,
  postToEsbSendReinforcementDataFake,
  postDataToEda,
  postCsvToEda,
  postZipToEda
};
