import { useEffect, useState } from "react";
import { defer } from "rxjs";
import { getAdminMailPrefs } from "../services/mail";

export const useGetAdminPrefs = () => {
  const [prefs, setPrefs] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      return await getAdminMailPrefs();
    };

    const subscription = defer(() => fetchData()).subscribe(setPrefs);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return prefs;
};
