const Access = {
  containers: {
    upload: {
      roles: ["admin", "vendor", "audit", "dev"]
    },
    uploads: {
      roles: ["admin", "vendor", "analyst", "dev"]
    },
    "my-drive": {
      roles: ["admin", "vendor", "audit", "analyst", "dev"]
    },
    "data-fields": {
      roles: ["admin", "dev"]
    },
    users: {
      roles: ["admin", "dev"]
    },
    "comple-rules": {
      roles: ["admin", "dev"]
    },
    releases: {
      roles: ["admin", "dev"]
    },
    "circuit-audits": {
      roles: ["admin", "dev"]
    },
    "az-cost-management": {
      roles: ["admin", "dev"]
    },
    "reinforcement-inspection": {
      roles: [
        "admin",
        "reinforcementInspector",
        "leadReinforcementInspector",
        "dev"
      ]
    },
    reports: {
      roles: ["admin", "dev"]
    },
    rep1: {
      roles: ["admin", "analyst", "dev"]
    },
    rep2: {
      roles: ["admin", "analyst", "dev"]
    },
    testdata: {
      roles: ["dev"]
    },
    integrity: {
      roles: ["dev"]
    },
    seeding: {
      roles: ["dev"]
    },
    "break-fix-data": {
      roles: ["dev"]
    },
    mail: {
      roles: ["dev"]
    },
    "upload-pictures-test": {
      roles: ["dev"]
    },
    "assoc-table-test": {
      roles: ["dev"]
    },
    "circuit-photos": {
      roles: ["admin", "vendor", "analyst", "dev", "audit", "photo-viewer"]
    }
  },
  links: [
    {
      path: "/",
      label: "Home",
      roles: [
        "admin",
        "reinforcementInspector",
        "leadReinforcementInspector",
        "audit",
        "vendor",
        "analyst",
        "dev",
        "photo-viewer"
      ]
    },
    {
      label: "Files",
      roles: ["admin", "vendor", "audit", "analyst", "dev"],
      links: [
        {
          path: "/upload",
          label: "Upload",
          roles: ["admin", "vendor", "audit", "dev"]
        },
        {
          path: "/uploads",
          label: "Submitted Files",
          roles: ["admin", "vendor", "analyst", "dev"]
        },
        {
          path: "/my-drive",
          label: "My Drive",
          roles: ["admin", "vendor", "audit", "analyst", "dev"]
        }
      ]
    },
    {
      label: "Inspections",
      roles: [
        "admin",
        "reinforcementInspector",
        "leadReinforcementInspector",
        "dev"
      ],
      links: [
        {
          path: "/reinforcement-inspection",
          label: "Pole Reinforcement",
          roles: [
            "admin",
            "reinforcementInspector",
            "leadReinforcementInspector",
            "dev"
          ]
        }
      ]
    },
    {
      label: "Reports",
      roles: ["admin", "analyst", "dev"],
      links: [
        {
          path: "/rep1",
          label: "Pole Inspection Status (PIT)",
          roles: ["admin", "analyst", "dev"]
        },
        {
          path: "/rep2",
          label: "Pole Treatment (PIT)",
          roles: ["admin", "analyst", "dev"]
        }
      ]
    },
    {
      label: "Admin",
      roles: ["admin", "dev"],
      links: [
        {
          path: "/data-fields",
          label: "Manage Data Fields",
          roles: ["admin", "dev"]
        },
        {
          path: "/users",
          label: "User Management",
          roles: ["admin", "dev"]
        },
        {
          path: "/complex-rules",
          label: "Engineering Standards",
          roles: ["admin", "dev"]
        },
        {
          path: "/releases",
          label: "Data Changes",
          roles: ["admin", "dev"]
        },
        {
          path: "/circuit-audits",
          label: "Circuit Audits",
          roles: ["admin", "dev"]
        },
        {
          path: "/az-cost-management",
          label: "Azure Cost Management",
          roles: ["admin", "dev"]
        }
      ]
    },
    {
      label: "Dev",
      roles: ["dev"],
      links: [
        {
          path: "/testdata",
          label: "Test Data",
          roles: ["dev"]
        },
        {
          path: "/integrity",
          label: "Data Integrity",
          roles: ["dev"]
        },
        {
          path: "/seeding",
          label: "Seeding Collections",
          roles: ["dev"]
        },
        {
          path: "/break-fix-data",
          label: "Break/Fix Data",
          roles: ["dev"]
        },
        {
          path: "/mail",
          label: "Mail Test",
          roles: ["dev"]
        },
        {
          path: "/upload-pictures-test",
          label: "Upload Pictures Test",
          roles: ["dev"]
        },
        {
          path: "/assoc-table-test",
          label: "Association Table Test",
          roles: ["dev"]
        },
        {
          path: "/pole-reinforcement-inspection",
          label: "Pole Reinforcement Inspection",
          roles: ["dev"]
        }
      ]
    },
    {
      path: "/circuit-photos",
      label: "Circuit photos",
      roles: ["admin", "vendor", "audit", "analyst", "dev", "photo-viewer"]
    }
  ]
};

const getContainerAccessRoles = (c) => {
  const container = c.replace("/", "");

  return Access.containers[container]
    ? Access.containers[container].roles
    : [
        "admin",
        "reinforcementInspector",
        "leadReinforcementInspector",
        "vendor",
        "audit",
        "analyst",
        "dev",
        "guest",
        "photo-viewer"
      ];
};

const getLinks = () => {
  return Access.links;
};

export { getContainerAccessRoles, getLinks };
