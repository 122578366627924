import React, {useEffect, useState} from "react";
import {Alert, Button, Card, CardBody, CardHeader, CardText, Collapse, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Tooltip} from "reactstrap";
import {copyForExtraction, downloadCsvData, getPhotoLists, getPictureBytesBase64, getPitAndPtmUploads} from "../services/circuitPhotosServices";
import {faChevronDown, faChevronUp, faDownload, faExclamationCircle, faExpand} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {signalRNegotiate} from "../services/signalrServices";
import * as signalR from "@microsoft/signalr";
import csv from "csvtojson";
import Moment from "react-moment";
import "moment-timezone";
import {json2csv} from "json-2-csv";

import "./CircuitPhotosComponent.css";
import {FileTypeAbbr} from "../utils/appUtils";


// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM-DD-YYYY HH:mm:ss";

const CircuitPhotosComponent = (props) => {
  const { user } = props;
  const isAdmin = ["admin", "dev"].includes(user.role);
  const nonViewer = "photo-viewer" !== user.role;
  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [uploads, setUploads] = useState([]);
  const [selectedCircuit, setSelectedCircuit] = useState("");
  const [selectedPhoto, setSelectedPhoto] = useState("");
  const [selectedPhotoUrl, setSelectedPhotoUrl] = useState("");
  const [glnInfo, setGlnInfo] = useState({});
  const [collapseIcon, setCollapseIcon] = useState(faChevronDown);
  const [photos, setPhotos] = useState([]);
  const [filter, setFilter] = useState("");
  const [mismatchFilterEnabled, setMismatchFilterEnabled] = useState(false);
  const [photosFiltered, setPhotosFiltered] = useState([]);
  const [extractionFileInfos, setExtractionFileInfos] = useState([]);
  const [connection, setConnection] = useState(null);
  const [mismatchGlns, setMismatchGlns] = useState([]);
  const [dataset, setDataset] = useState({});

  const SIGNALR_HUBNAME = "circuitphotos";

  // Photo modal
  const [openPhotoModal, setOpenPhotoModal] = useState(false);
  const togglePhotoModal = () => setOpenPhotoModal(!openPhotoModal);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const fetchUploads = async (filterNonExtracted) => {
    setIsLoading(true);

    const res = await getPitAndPtmUploads(filterNonExtracted);

    const dictionary = res.response.reduce((dict, value) => {
      const circuitUploads = dict[value.circuit];
      if (!circuitUploads) {
        dict[value.circuit] = [];
      }

      dict[value.circuit].push(value);
      return dict;
    }, {});
    setUploads(dictionary);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchUploads(false);
  }, [user, isAdmin])

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    setCollapseIcon(collapseIcon === faChevronDown ? faChevronUp : faChevronDown);
  }


  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const PhotoFullscreenModal = () => {
    return (
      <Modal
        className="photoModal"
        returnFocusAfterClose={true}
        isOpen={openPhotoModal}
        size="xl"
      >
        <ModalHeader >
          Photo: {selectedPhoto}
        </ModalHeader>
        <ModalBody className="text-center">
          <img src={selectedPhotoUrl} alt="Pole" className="photo-fullscreen"></img>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={togglePhotoModal} block>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  const joinHub = async (monitoredCircuit, fileInfos) => {
    setIsLoading(true);

    const negotiate = await signalRNegotiate(SIGNALR_HUBNAME);

    if (negotiate.status === 200) {
      try {
        const connectionUrl = negotiate.response.url;
        const accessToken = negotiate.response.accessToken;

        const conn = new signalR.HubConnectionBuilder()
          .withUrl(connectionUrl, { accessTokenFactory: () => accessToken })
          .withAutomaticReconnect()
          .build();

        conn.onclose(() => {
          setIsLoading(false);
        });

        conn.on("extractionInfo", (obj) => {
          const notification = obj.notification;
          const action = notification.action;
          const message = notification.message;
          const circuit = message.circuit;

          if (action === "extractionFinished" && circuit === monitoredCircuit) {
            const zipName = message.zipName;
            const extractionSuccess = message.extractionSuccess;

            if (extractionSuccess && fileInfos?.length) {
              fileInfos.filter(fileInfo => zipName === fileInfo.zipName).forEach(fileInfo => {
                fileInfo.extracted = true;
              });
              setExtractionFileInfos(Array.from(fileInfos));
            }
            if (!extractionSuccess) {
              showCustomModal({
                title: "Extraction",
                message: "Exctraction failed"
              });
              extractionFileInfos.filter(fileInfo => zipName === fileInfo.zipName).forEach(fileInfo => {
                fileInfo.extracted = undefined;
                setExtractionFileInfos(Array.from(fileInfos));
              });
            }
          }
        });

        await conn.start().then(() => {
          setIsLoading(false);
        });

        setConnection(conn);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const closeConnection = async () => {
    if (connection) await connection.stop();
  };

  const validateForm = () => {
    return selectedCircuit?.length > 0;
  };

  const startExtraction = async (fileInfo) => {
    await copyForExtraction(selectedCircuit, fileInfo.type, fileInfo.csvName, fileInfo.zipName);
    fileInfo.extracted = false;
    setExtractionFileInfos(Array.from(extractionFileInfos));
  }

  const replaceEmptyWithNa = (value, transformationFn) => {
    if (!transformationFn) {
      transformationFn = value => value;
    }
    return value && value !== "N/A" ? transformationFn(value) : "N/A";
  }

  const datasetBuilderPitOnly = (pitData, processedDataSet) => {
    processedDataSet.values = {};
    Object.values(pitData).forEach(csvRow => {
      const datasetRow = {};
      datasetRow['Circuit'] = csvRow['OHPrimaryCircuitNumber_PIT'];
      datasetRow['GLNX - GLNY'] = csvRow['GLNX-GLNY_PIT'];
      datasetRow['Inspection Date'] = 'PIT: ' + csvRow['InspectionDate_PIT'];
      datasetRow['Inspection Type'] = replaceEmptyWithNa(csvRow['InspectionTagType_PIT']);
      datasetRow['Inspection Result'] = replaceEmptyWithNa(csvRow['PoleStatus_PIT']);
      datasetRow['Pole Tag Attached'] = replaceEmptyWithNa(csvRow['PoleTagAttached_PIT'], value => value === 'Y' ? 'Yes' : 'No');
      datasetRow['Pole Class'] = replaceEmptyWithNa(csvRow['PoleTagClass_PIT']);
      datasetRow['Installation Year'] = replaceEmptyWithNa(csvRow['PoleTagInstallationYear_PIT']);
      datasetRow['Pole Height'] = replaceEmptyWithNa(csvRow['PoleTagLength_PIT']);
      datasetRow['Treatment Code'] = replaceEmptyWithNa(csvRow['PoleTagSpeciesTreatmentCode_PIT']);
      datasetRow['Reason for Pole Rejection'] = replaceEmptyWithNa(csvRow['ReasonForPoleRejection_PIT']);
      datasetRow['Remaining Percent Strength'] = replaceEmptyWithNa(csvRow['RemainingPercentPoleStrength_PIT'], value => value + ' %');
      datasetRow['Truck Accessible'] = replaceEmptyWithNa(csvRow['TruckAccessible_PIT'], value => value === 'Y' ? 'Accessible' : 'Not Truck Accessible');
      datasetRow['GPS Coordinate'] = 'N/A';
      datasetRow['Cable Pole Tag'] = 'N/A';
      datasetRow['Joint Use'] = 'N/A';
      datasetRow['Remove Pole'] = replaceEmptyWithNa(csvRow['AddRemoveUpdatePole_PIT'], value => value === 'Remove' ? 'Yes' : 'No');

      processedDataSet.values[csvRow['GLNX-GLNY_PIT']] = datasetRow;
    })
  }

  const datasetBuilder = (pitData, ptmData, processedDataSet) => {
    processedDataSet.values = {};
    processedDataSet.conflicts = {};
    Object.entries(ptmData).forEach(([gln, ptmRow]) => {
      const datasetRow = {};
      const conflictRow = {};
      const pitRowExists = !!(pitData && pitData[gln]);
      datasetRow['Circuit'] = ptmRow['Circuit_PTM'];
      datasetRow['GLNX - GLNY'] = gln;
      const inspectionDateString = 'PTM: ' + ptmRow['InspectionDate_PTM'];
      datasetRow['Inspection Date'] = pitRowExists ? (inspectionDateString + ' PIT: ' + pitData[gln]['InspectionDate_PIT']) : inspectionDateString;
      datasetRow['GPS Coordinate'] = ptmRow['Latitude_PTM'] && ptmRow['Longitude_PTM'] ? ptmRow['Latitude_PTM'] + ' ' + ptmRow['Longitude_PTM'] : 'N/A';
      datasetRow['Cable Pole Tag'] = ptmRow['CIRCUITTYP_PTM'] && ptmRow['CIRCUITNUM_PTM'] ? ptmRow['CIRCUITTYP_PTM'] + ' - ' + ptmRow['CIRCUITNUM_PTM'] : "N/A";
      datasetRow['Joint Use'] = replaceEmptyWithNa(ptmRow['JointUse_PTM'], value => value === 'Y' ? 'Yes' : 'No');
      datasetRow['Remove Pole'] = replaceEmptyWithNa(ptmRow['AddRemoveUpdatePole_PTM'], value => value === 'Remove' ? 'Yes' : 'No');

      // process with PIT
      if (pitRowExists) {
        datasetRow['Inspection Type'] = replaceEmptyWithNa(pitData[gln]['InspectionTagType_PIT']);
        datasetRow['Inspection Result'] = replaceEmptyWithNa(ptmRow['PoleRejected_PTM'], value => value === 'Y'? 'Reject' : 'Non-Reject');
        datasetRow['Pole Tag Attached'] = replaceEmptyWithNa(pitData[gln]['PoleTagAttached_PIT'], value => value === 'Y' ? 'Yes' : 'No');
        datasetRow['Pole Class'] = ptmRow['PoleClass_PTM'] ? ptmRow['PoleClass_PTM'] : pitData[gln]['PoleTagClass_PIT'];
        datasetRow['Installation Year'] = ptmRow['YearInstalled_PTM'] ? ptmRow['YearInstalled_PTM'] : pitData[gln]['PoleTagInstallationYear_PIT'];
        datasetRow['Pole Height'] = ptmRow['PoleHeight_PTM'] ? ptmRow['PoleHeight_PTM'] : pitData[gln]['PoleTagLength_PIT'];
        datasetRow['Treatment Code'] = ptmRow['PoleTagSpeciesTreatmentCode_PTM'] ? ptmRow['PoleTagSpeciesTreatmentCode_PTM'] : pitData[gln]['PoleTagSpeciesTreatmentCode_PIT'];
        datasetRow['Reason for Pole Rejection'] = ptmRow['ReasonForPoleRejection_PTM'] ? ptmRow['ReasonForPoleRejection_PTM'] : pitData[gln]['ReasonForPoleRejection_PIT'];
        datasetRow['Remaining Percent Strength'] = replaceEmptyWithNa(pitData[gln]['RemainingPercentPoleStrength_PIT'], value => value + ' %');
        const truckAcc = ptmRow['TruckAccessible_PTM'] ? ptmRow['TruckAccessible_PTM'] : pitData[gln]['TruckAccessible_PIT'];
        datasetRow['Truck Accessible'] = truckAcc === 'Y' ? 'Accessible' : 'Not Truck Accessible';

        // conflict checker
        if (nonViewer) {
          if (pitData[gln]['PoleStatus_PIT'] === 'Non-Reject' && ptmRow['PoleRejected_PTM'] === 'Y') {
            conflictRow['Inspection Result'] = 'PoleRejected_PTM: Y<br/> PoleStatus_PIT: Non-Reject';
          } else if ((pitData[gln]['PoleStatus_PIT'] === 'Non-Restorable Reject' || pitData[gln]['PoleStatus_PIT'] === 'Restorable Reject') && ptmRow['PoleRejected_PTM'] === 'N') {
            conflictRow['Inspection Result'] = 'PoleRejected_PTM: N<br/> PoleStatus_PIT: Non-Restorable Reject';
          }
          if (ptmRow['PoleClass_PTM'] && pitData[gln]['PoleTagClass_PIT'] && ptmRow['PoleClass_PTM'] !== pitData[gln]['PoleTagClass_PIT']) {
            conflictRow['Pole Class'] = 'PTM: ' + ptmRow['PoleClass_PTM'] + '<br/>PIT:' + pitData[gln]['PoleTagClass_PIT'];
          }
          if (ptmRow['YearInstalled_PTM'] && pitData[gln]['PoleTagInstallationYear_PIT'] && ptmRow['YearInstalled_PTM'] !== pitData[gln]['PoleTagInstallationYear_PIT']) {
            conflictRow['Installation Year'] = 'PTM: ' + ptmRow['YearInstalled_PTM'] + '<br/>PIT:' + pitData[gln]['PoleTagInstallationYear_PIT'];
          }
          if (ptmRow['PoleHeight_PTM'] && pitData[gln]['PoleTagLength_PIT'] && ptmRow['PoleHeight_PTM'] !== pitData[gln]['PoleTagLength_PIT']) {
            conflictRow['Pole Height'] = 'PTM: ' + ptmRow['PoleHeight_PTM'] + '<br/>PIT:' + pitData[gln]['PoleTagLength_PIT'];
          }
          if (ptmRow['PoleTagSpeciesTreatmentCode_PTM'] && pitData[gln]['PoleTagSpeciesTreatmentCode_PIT'] && ptmRow['PoleTagSpeciesTreatmentCode_PTM'] !== pitData[gln]['PoleTagSpeciesTreatmentCode_PIT']) {
            conflictRow['Treatment Code'] = 'PTM: ' + ptmRow['PoleTagSpeciesTreatmentCode_PTM'] + '<br/>PIT:' + pitData[gln]['PoleTagSpeciesTreatmentCode_PIT'];
          }
          if (ptmRow['ReasonForPoleRejection_PTM'] && pitData[gln]['ReasonForPoleRejection_PIT'] && ptmRow['ReasonForPoleRejection_PTM'] !== pitData[gln]['ReasonForPoleRejection_PIT']) {
            conflictRow['Reason for Pole Rejection'] = 'PTM: ' + ptmRow['ReasonForPoleRejection_PTM'] + '<br/>PIT:' + pitData[gln]['ReasonForPoleRejection_PIT'];
          }
          if (ptmRow['TruckAccessible_PTM'] && pitData[gln]['TruckAccessible_PIT'] && ptmRow['TruckAccessible_PTM'] !== pitData[gln]['TruckAccessible_PIT']) {
            conflictRow['Truck Accessible'] = 'PTM: ' + ptmRow['TruckAccessible_PTM'] + '<br/>PIT:' + pitData[gln]['TruckAccessible_PIT'];
          }
          if (Object.keys(conflictRow).length) {
            processedDataSet.conflicts[gln] = conflictRow;
          }
        }
      } else {
        datasetRow['Inspection Type'] = 'N/A';
        datasetRow['Inspection Result'] = replaceEmptyWithNa(ptmRow['PoleRejected_PTM'], value => value === 'N' ? 'Non-Reject' : 'Reject');
        datasetRow['Pole Tag Attached'] = 'N/A';
        datasetRow['Pole Class'] = replaceEmptyWithNa(ptmRow['PoleClass_PTM']);
        datasetRow['Installation Year'] = replaceEmptyWithNa(ptmRow['YearInstalled_PTM']);
        datasetRow['Pole Height'] = replaceEmptyWithNa(ptmRow['PoleHeight_PTM']);
        datasetRow['Treatment Code'] = replaceEmptyWithNa(ptmRow['PoleTagSpeciesTreatmentCode_PTM']);
        datasetRow['Reason for Pole Rejection'] = replaceEmptyWithNa(ptmRow['ReasonForPoleRejection_PTM']);
        datasetRow['Remaining Percent Strength'] = 'N/A';
        datasetRow['Truck Accessible'] = replaceEmptyWithNa(ptmRow['TruckAccessible_PTM'], value => value === 'Y' ? 'Accessible' : 'Not Truck Accessible');
      }

      const datasetRowOrdered = {};
      datasetRowOrdered['Circuit'] = datasetRow['Circuit'];
      datasetRowOrdered['GLNX - GLNY'] = datasetRow['GLNX - GLNY'];
      datasetRowOrdered['Inspection Date'] = datasetRow['Inspection Date'];
      datasetRowOrdered['Inspection Type'] = datasetRow['Inspection Type'];
      datasetRowOrdered['Inspection Result'] = datasetRow['Inspection Result'];
      datasetRowOrdered['Pole Tag Attached'] = datasetRow['Pole Tag Attached'];
      datasetRowOrdered['Pole Class'] = datasetRow['Pole Class'];
      datasetRowOrdered['Installation Year'] = datasetRow['Installation Year'];
      datasetRowOrdered['Pole Height'] = datasetRow['Pole Height'];
      datasetRowOrdered['Treatment Code'] = datasetRow['Treatment Code'];
      datasetRowOrdered['Reason for Pole Rejection'] = datasetRow['Reason for Pole Rejection'];
      datasetRowOrdered['Remaining Percent Strength'] = datasetRow['Remaining Percent Strength'];
      datasetRowOrdered['Truck Accessible'] = datasetRow['Truck Accessible'];
      datasetRowOrdered['GPS Coordinate'] = datasetRow['GPS Coordinate'];
      datasetRowOrdered['Cable Pole Tag'] = datasetRow['Cable Pole Tag'];
      datasetRowOrdered['Joint Use'] = datasetRow['Joint Use'];
      datasetRowOrdered['Remove Pole'] = datasetRow['Remove Pole'];

      processedDataSet.values[gln] = datasetRowOrdered;
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setPhotos([]);
    setGlnInfo({});
    setSelectedPhoto("");
    setSelectedPhotoUrl("");
    closeConnection();
    setMismatchGlns([]);
    setMismatchFilterEnabled(false);
    setFilter("");

    try {
      const dto = await getPhotoLists(selectedCircuit);
      if (dto) {
        setPhotos(dto.photoList);
        setPhotosFiltered(dto.photoList);
        setExtractionFileInfos(dto.fileInfos);
        joinHub(selectedCircuit, dto.fileInfos);
      } else {
        showCustomModal({
          title: "Photos",
          message: "No Photos were found for this circuit"
        });
      }
    } catch (e) {
      console.log(e);
    }

    const csvData = {};
    for (const upload of uploads[selectedCircuit]) {
      await downloadCsvData(upload.fileName, 'application/csv').then(async csvRes => {
        const csvObject = {};
        const dictFunction = upload.type === 'poleTopMaintenance'
          ? ((row) => csvObject[row['GLNXGLNY_PTM']] = row)
          : ((row) => csvObject[row['GLNX-GLNY_PIT']] = row);

        await csv()
          .fromString(csvRes)
          .subscribe(dictFunction)
          .then(() => {
            const typeAbbr = FileTypeAbbr[upload.type];
            csvData[typeAbbr] = csvObject;
          });
      })
    }

    const processedDataset = {};
    if (!csvData.PTM) {
      datasetBuilderPitOnly(csvData.PIT, processedDataset);
    } else {
      datasetBuilder(csvData.PIT, csvData.PTM, processedDataset);
    }

    setDataset(processedDataset);
    toggleSearch();

    if (nonViewer && csvData.PTM && csvData.PIT) {
      const mismatchGlns = Object.keys(processedDataset.conflicts);
      setMismatchGlns(mismatchGlns);
    }
    setIsLoading(false);
  };

  const handleSelectedCircuit = async (circuit) => {
    setSelectedCircuit(circuit);
  };

  const handleSelectedPhoto = async (photo) => {
    if (isLoading) {
      return;
    }
    setSelectedPhoto(photo);
    const tempSplit = photo.split('-');
    const extractedGln = tempSplit[1] + '-' + tempSplit[2];
    const glnInfo = {};
    glnInfo.values = dataset.values[extractedGln];
    if (dataset.conflicts) {
      glnInfo.conflicts = dataset.conflicts[extractedGln];
    }
    setGlnInfo(glnInfo);

    const typeAbbr = photo.split('-')[0];
    const phototoBytesBase64 = await getPictureBytesBase64(selectedCircuit, typeAbbr, photo);
    setSelectedPhotoUrl('data:image/jpeg; base64, ' + phototoBytesBase64);
  }

  const handleFiltering = (filterValue, mismatchFilterValue) => {
    setFilter(filterValue);
    setMismatchFilterEnabled(mismatchFilterValue)
    let photosFiltered = photos;
    if (filterValue) {
      photosFiltered = photosFiltered.filter(name => name.includes(filterValue));
    }
    if (mismatchFilterValue) {
      photosFiltered = photosFiltered.filter(name => mismatchGlns.some(gln => name.includes(gln)));
    }
    setPhotosFiltered(photosFiltered);
  }

  const displayData = (glnInfo, key, id) => {
    return glnInfo.conflicts && glnInfo.conflicts[key] ? (<div>
      {glnInfo.values[key]}<FontAwesomeIcon icon={faExclamationCircle} id={id} className="pl-1"/>
      <Tooltip
        target={id}
        isOpen={tooltipOpen}
        toggle={toggleTooltip}
        dangerouslySetInnerHTML={{ __html: (glnInfo.conflicts[key]) }}>
      </Tooltip>
    </div>) : glnInfo.values[key];
  }

  const downloadGlnAsCsv = () => {
    downloadAsCsv([glnInfo.values] , glnInfo.values['GLNX - GLNY'] + '.csv');
  }

  const downloadCircuit = () => {
    downloadAsCsv(Object.values(dataset.values), selectedCircuit + '.csv')
  }

  const downloadAsCsv = (data, name) => {
    json2csv(data, (err, csvData) => {
      if (err) {
        showCustomModal({
          title: "Csv export error",
          message: err
        });
        return;
      }
      const file = new Blob([csvData], {type: 'application/csv'});

      const a = document.createElement('a');
      a.href = URL.createObjectURL(file);
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }

  return (
    <div className="CircuitPhotosComponent">
      <Card>
        <CardHeader tag="h5">View circuit photos</CardHeader>
        <CardBody>
          <Card>
            <CardHeader tag="h6">
              Search{" "}
              <Button
                className="collapse-button"
                color="default"
                onClick={toggleSearch}
                size="sm"
              >
                <FontAwesomeIcon icon={collapseIcon} />
              </Button>
            </CardHeader>
            <Collapse isOpen={isSearchOpen}>
              <CardBody>
                <Form onSubmit={handleSubmit} id="search-form">
                  <FormGroup>
                    <CardText>
                      <b>Select the circuit data you want view:</b>
                    </CardText>
                    <Input
                      type="select"
                      value={selectedCircuit}
                      onChange={(e) => handleSelectedCircuit(e.target.value)}
                      disabled={isLoading}>
                      <option value="">Pick a circuit from the list</option>
                      {Object.keys(uploads).map((circuit, i) => {
                        return (
                          <option value={circuit} key={i}>
                            {circuit}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                  {isAdmin ? <FormGroup check className="m-2">
                    <Input
                      type="checkbox"
                      id="filter-not-extracted"
                      onChange={(e) => fetchUploads(e.target.checked)}
                    />{" "}
                    <Label check>Filter for extraction</Label>
                  </FormGroup> : ""}
                  <Button
                    className="btn-lg"
                    disabled={!validateForm() || isLoading}
                    color="primary"
                    block
                  >
                    Load Circuit {isLoading && <Spinner color="light" />}
                  </Button>
                </Form>
              </CardBody>
            </Collapse>
          </Card>

          {selectedCircuit && photos?.length ? (
            <Card className="mt-3">
              <CardHeader tag="h6">Circuit {selectedCircuit}</CardHeader>
              <CardBody>
                {extractionFileInfos?.length && isAdmin ? extractionFileInfos.filter(info => !info.extracted && info.complete).map(info => (<div className="row" key={info.zipName}>
                  <div className="col-12">
                    <Alert color="warning">
                      {info.zipName} : {info.extracted !== undefined ? 'Extraction in progress' : (<span>Photos are not extracted <Button type="button" color="primary" className="ml-3" onClick={() => startExtraction(info)}>Extract</Button></span>)}
                    </Alert>
                  </div>

                </div>)) : ''}
                {mismatchGlns?.length && nonViewer ? (<div className="col-12">
                  <Alert color="warning">
                    This circuit contains inconsistencies between PTM and PIT
                  </Alert>
                </div>) : ''}
                <div className="row">
                  <div className="col-md-3">
                    <a className={'btn btn-primary btn-block' + (selectedPhoto ? '' : ' disabled')} href={selectedPhoto ? selectedPhotoUrl : ''} download={selectedPhoto}>
                      Download <FontAwesomeIcon icon={faDownload} className="pl-1"></FontAwesomeIcon>
                    </a>
                  </div>
                  <div className="col-md-3">
                    <Button color="primary" block disabled={!selectedPhoto} onClick={togglePhotoModal}>Fullscreen <FontAwesomeIcon icon={faExpand} className="pl-1"></FontAwesomeIcon></Button>
                  </div>
                  <div className="col-md-6">
                    <Input value={filter} onChange={(e) => handleFiltering(e.target.value, mismatchFilterEnabled)} placeholder="Filter by name"></Input>
                    {nonViewer && mismatchGlns?.length ? <FormGroup check className="m-2">
                      <Input
                        type="checkbox"
                        id="filter-data-mismatch"
                        onChange={(e) => handleFiltering(filter, e.target.checked)}
                      />{" "}
                      <Label check>Filter for inconsistent data</Label>
                    </FormGroup> : ""}
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-md-6 d-flex justify-content-center">
                    {selectedPhotoUrl && (<img src={selectedPhotoUrl} className="photo-container" alt="Pole"></img>)}
                  </div>
                  <div className="col-md-6">
                    <div className={isLoading? "row photo-container disabled" : "row photo-container"}>
                      {photosFiltered.map((photoName) => (<div className="col-md-6" key={photoName}><div className={selectedPhoto === photoName ? "photo active" : "photo"} onClick={() => handleSelectedPhoto(photoName)}>{photoName}</div></div>))}
                    </div>
                  </div>
                  <div className="col-12 pt-3">
                    <div className="row">
                      <div className="col-md-6 p-2">
                        <Button color="primary" block disabled={!selectedPhoto} type="button" onClick={downloadGlnAsCsv}>Download gln summary<FontAwesomeIcon icon={faDownload} className="pl-1"></FontAwesomeIcon></Button>
                      </div>
                      <div className="col-md-6 p-2">
                        <Button color="primary" block type="button" onClick={downloadCircuit}>Download circuit summary<FontAwesomeIcon icon={faDownload} className="pl-1"></FontAwesomeIcon></Button>
                      </div>
                    </div>
                  </div>
                </div>
                {selectedPhoto?.length && glnInfo?.values ? (<div className="row pt-5">
                  <div className="col-12 p-2">
                    <h4>Pole Inspection</h4>
                  </div>
                  <div className="col-md-4 p-2">
                    <h5>Circuit:</h5> {displayData(glnInfo, 'Circuit', 'circuit')}
                  </div>
                  <div className="col-md-4 p-2">
                    <h5>GLNX - GLNY:</h5> {displayData(glnInfo, 'GLNX - GLNY', 'glnx-glny')}
                  </div>
                  <div className="col-md-4 p-2">
                    <h5>Inspection Date:</h5> {displayData(glnInfo, 'Inspection Date', 'inspection-date')}
                  </div>
                  <div className="col-md-4 p-2">
                    <h5>Inspection Type:</h5> {displayData(glnInfo, 'Inspection Type', 'inspection-type')}
                  </div>
                  <div className="col-md-4 p-2">
                    <h5>Inspection Result:</h5> {displayData(glnInfo, 'Inspection Result', 'inspection-result')}
                  </div>
                  <div className="col-md-4 p-2">
                    <h5>Pole Tag Attached</h5> {displayData(glnInfo, 'Pole Tag Attached', 'pole-tag-attached')}
                  </div>
                  <div className="col-md-4 p-2">
                    <h5>Pole Class:</h5> {displayData(glnInfo, 'Pole Class', 'pole-class')}
                  </div>
                  <div className="col-md-4 p-2">
                    <h5>Installation Year:</h5>  {displayData(glnInfo, 'Installation Year', 'installation-year')}
                  </div>
                  <div className="col-md-4 p-2">
                    <h5>Pole Height:</h5> {displayData(glnInfo, 'Pole Height', 'pole-height')}
                  </div>
                  <div className="col-md-4 p-2">
                    <h5>Treatment Code:</h5> {displayData(glnInfo, 'Treatment Code', 'treatment-code')}
                  </div>
                  <div className="col-md-4 p-2">
                    <h5>Reason for Pole Rejection:</h5> {displayData(glnInfo, 'Reason for Pole Rejection', 'reason-for-pole-rejection')}
                  </div>
                  <div className="col-md-4 p-2">
                    <h5>Remaining Percent Strength:</h5> {displayData(glnInfo, 'Remaining Percent Strength', 'remaining-strength')}
                  </div>
                  <div className="col-md-4 p-2">
                    <h5>Truck Accessible?</h5> {displayData(glnInfo, 'Truck Accessible', 'truck-accessible')}
                  </div>
                  <div className="col-md-4 p-2">
                    <h5>GPS Coordinate:</h5> {displayData(glnInfo, 'GPS Coordinate', 'gps-coordinate')}
                  </div>
                  <div className="col-md-4 p-2">
                    <h5>Cable Pole Tag:</h5> {displayData(glnInfo, 'Cable Pole Tag', 'cable-pole-tag')}
                  </div>
                  <div className="col-md-4 p-2">
                    <h5>Joint Use:</h5> {displayData(glnInfo, 'Joint Use', 'joint-use')}
                  </div>
                </div>) : ""}
              </CardBody>
            </Card>
          ) : (
            ""
          )}
        </CardBody>
      </Card>
      <ErrorModal />
      <PhotoFullscreenModal />
    </div>
  );
};

export default CircuitPhotosComponent;

