import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from "reactstrap";
import User from "./User";
import { getUsers } from "../services/userService";
import { getSourceKeys } from "../services/sourceService";
import { getUserRoles } from "../models/UserRoles";
import "./UserList.css";

const roleOptions = [{ role: "all", label: "All" }, ...getUserRoles()];

const UserList = (props) => {
  const { user, sendEmail } = props;

  const [fileTypes, setFileTypes] = useState([]);

  useEffect(() => {
    const fetchFileTypes = async () => {
      await getSourceKeys().then((results) => {
        setFileTypes(results);
      });
    };

    fetchFileTypes();
  }, []);

  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  const [role, setRole] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [users, setUsers] = useState([]);

  const newSearch = () => {
    setIsLoading(false);

    setUsers([]);
    toggleSearch();
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const listUsers = async () => {
    let res = [];
    let query = {};
    if (role !== "all") query.role = role;

    try {
      res = await getUsers(query);
      setUsers(res);
    } catch (e) {}

    return res.length;
  };

  const validateForm = () => {
    return role.length > 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const numUsers = await listUsers();

      if (numUsers) {
        toggleSearch();
      } else {
        showCustomModal({
          title: "Search results",
          message: "No results were found following that criteria"
        });
      }
    } catch (e) {}

    setIsLoading(false);
  };

  const CustomModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const SearchResults = () => {
    return users.length ? (
      <>
        <div className="NewSearch">
          <Button onClick={newSearch} color="primary">
            New Search
          </Button>
        </div>
        <div className="UserCards">
          {users.map((o, i) => (
            <User
              key={i}
              user={o}
              currentUser={user}
              fileTypes={fileTypes}
              sendEmail={sendEmail}
            />
          ))}
        </div>
      </>
    ) : (
      " "
    );
  };

  return (
    <div className="UserList">
      <Card>
        <CardHeader tag="h5">User Management</CardHeader>
        <CardBody>
          <Collapse isOpen={isSearchOpen}>
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} id="search-form">
                  <FormGroup tag="fieldset">
                    <h5 className="card-title">
                      Select the role you will be searching for
                    </h5>
                    {roleOptions.map((el, i) => (
                      <FormGroup key={i} check>
                        <Label check>
                          <Input
                            type="radio"
                            name="role"
                            value={el.role}
                            onChange={(e) => setRole(e.target.value)}
                          />{" "}
                          {el.label}
                        </Label>
                      </FormGroup>
                    ))}
                  </FormGroup>
                  <Button
                    className="btn-lg"
                    disabled={!validateForm() || isLoading}
                    color="primary"
                    block
                  >
                    Search {isLoading && <Spinner color="light" />}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
          <SearchResults />
        </CardBody>
      </Card>
      <CustomModal />
    </div>
  );
};

export default UserList;
