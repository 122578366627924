import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from "reactstrap";
import {
  getDownloadUrl,
  followDownloadUrl,
  deleteFromBlobStorage
} from "../services/filesService";
import { getUsers } from "../services/userService";
import { myDriveFileShareNotify } from "../services/myDriveFileShareNotify";
import {
  updateMyDriveFile,
  deleteMyDriveFile
} from "../services/vendorDriveService";
import Moment from "react-moment";
import "moment-timezone";
import prettyBytes from "pretty-bytes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faShare, faSave } from "@fortawesome/free-solid-svg-icons";
import "./MyDriveFile.css";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM/DD/YYYY HH:mm:ss";

const MyDriveFile = (props) => {
  const { file, user, vendors, updateDeletedFiles, sendEmail } = props;

  const isAdmin = user.role === "admin" || user.role === "dev";
  const isVendor = user.role === "vendor";
  const canIShare = isAdmin && file.org === user.org;
  const sharedWithList =
    file.sharedWith && file.sharedWith.list ? file.sharedWith.list : [];

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [sharedWith, setSharedWith] = useState(sharedWithList);
  const [isSharing, setIsSharing] = useState(false);

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const downloadFile = async (e, fileId, fileName, contentType) => {
    e.preventDefault();

    try {
      // Get download url
      const blobUrl = await getDownloadUrl(fileId, "mydrive");

      // Create local blob and url
      const content = await followDownloadUrl(blobUrl);
      const blob = new Blob([content], {
        type: contentType
      });

      if (navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        // Create temporary link
        const url = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.download = fileName;
        link.href = url;
        link.target = "_blank";
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }
    } catch (e) {}
  };

  const shareFile = async (e, fileId, fileName, contentType) => {
    e.preventDefault();

    setIsSharing(true);
  };

  const CreatedByField = (props) => {
    const { by, at } = props;

    return (
      <>
        <b>Created by:</b>
        <br /> {by} {" @ "}
        <Moment>{at}</Moment>
      </>
    );
  };

  const UpdatedByField = (props) => {
    const { by, at } = props;

    return (
      <>
        <b>Updated by:</b>
        <br /> {by} {" @ "}
        <Moment>{at}</Moment>
      </>
    );
  };

  const handleDelete = async () => {
    setIsLoading(true);

    await deleteMyDriveFile(file.fileId).then((result) => {
      setIsDeleting(false);
      setIsLoading(false);

      if (result.deletedCount && result.deletedCount > 0) {
        setIsDeleted(true);
      } else {
        showCustomModal({
          title: "Error Deleting File (DB)",
          message:
            "Something wrong happened trying to delete the file from database. Please contact the administrator."
        });
      }
    });

    await deleteFromBlobStorage("mydrive", file.fileId).then((result) => {
      if (!result) {
        showCustomModal({
          title: "Error Deleting File (Blob)",
          message:
            "Something wrong happened trying to delete the file from blob storage. Please contact the administrator."
        });
      }
    });

    updateDeletedFiles(file.fileId);
  };

  const handleCancelDelete = () => {
    setIsDeleting(false);
  };

  const getToRecipients = async (recipients) => {
    const users = await getUsers({ page: 1, count: 1000, org: recipients });

    return users.map((user) => {
      return { name: user.name, address: user.email };
    });
  };

  const handleSaveShare = async () => {
    setIsLoading(true);

    const promises = [];

    const timestamp = new Date().getTime();

    try {
      // Add file share update
      promises.push(
        updateMyDriveFile(
          { fileId: file.fileId },
          {
            sharedWith: {
              list: sharedWith,
              by: { name: user.name, org: user.org },
              on: timestamp
            },
            updatedBy: {
              id: user.id,
              name: user.name,
              email: user.email,
              org: user.org
            }
          }
        )
      );

      // Work notifications
      if (sharedWith.length) {
        // Get vendor info
        const toRecipients = await getToRecipients(sharedWith);

        // Add email notifications
        if (toRecipients.length) {
          toRecipients.forEach((recipient) => {
            if (recipient.address !== user.email) {
              promises.push(
                myDriveFileShareNotify(sendEmail, user, [recipient], {
                  file
                })
              );
            }
          });
        }
      }

      Promise.all(promises).then((results) => {
        const message = sharedWith.length
          ? "File was successfully shared. Recipient(s) are being notified separately."
          : "File share was successfully removed.";

        showCustomModal({
          title: "Successful file share",
          message
        });
      });
    } catch (e) {
      showCustomModal({
        title: "Unexpected error while updating file share in database",
        message: JSON.stringify(e)
      });
    }

    setIsLoading(false);
    setIsSharing(false);
  };

  const handleCancelShare = () => {
    setIsSharing(false);
    setSharedWith(sharedWithList);
  };

  const handleShareOption = (checked, value) => {
    let temp = [...sharedWith];

    if (checked) {
      temp = [...sharedWith, value];
    } else {
      temp.splice(temp.indexOf(value), 1);
    }

    setSharedWith(temp);
  };

  const DeleteButton = (props) => {
    const { handleDelete } = props;

    return (
      <FormGroup>
        <hr />
        <Button
          outline
          color="danger"
          size="sm"
          className="deleteButton"
          onClick={() => handleDelete(true)}
        >
          Delete
        </Button>
      </FormGroup>
    );
  };

  const DeleteCancelButtons = (props) => {
    const { handleDelete, handleCancelDelete, isLoading } = props;

    return (
      <FormGroup>
        <hr />
        Are you sure you want to delete this file?
        <br />
        <Button
          className="deleteButton"
          color="success"
          size="sm"
          onClick={handleCancelDelete}
        >
          Not sure
        </Button>
        <Button
          className="deleteButton"
          color="danger"
          size="sm"
          onClick={handleDelete}
        >
          Yes, I'm sure {isLoading && <Spinner size="sm" color="light" />}
        </Button>
      </FormGroup>
    );
  };

  const ManageFileShare = (props) => {
    const { handleSaveShare, handleCancelShare, handleShareOption, isLoading } =
      props;

    return (
      <FormGroup>
        <hr />
        <b>Select the vendors from the list below</b>
        <br />
        <div className="vendor-list">
          {vendors.map((vendor, i) => (
            <FormGroup check key={i}>
              <Label check>
                <Input
                  type="checkbox"
                  disabled={isLoading}
                  value={vendor}
                  defaultChecked={sharedWith.includes(vendor)}
                  onChange={(e) =>
                    handleShareOption(e.target.checked, e.target.value)
                  }
                />{" "}
                {vendor}
              </Label>
            </FormGroup>
          ))}
        </div>
        <Button
          className="shareButton"
          color="primary"
          size="sm"
          disabled={isLoading}
          onClick={handleSaveShare}
        >
          <FontAwesomeIcon icon={faSave} /> Save share{" "}
          {isLoading && <Spinner size="sm" color="light" />}
        </Button>
        <Button
          className="shareButton"
          color="danger"
          size="sm"
          disabled={isLoading}
          onClick={handleCancelShare}
        >
          Cancel
        </Button>
      </FormGroup>
    );
  };

  const isSharedWithMe = () => {
    return sharedWithList.includes(user.org);
  };

  const isFileMine = () => {
    return user.org === file.org;
  };

  return (
    !isDeleted && (
      <div className="MyDriveFile">
        <Card className="file-card">
          <CardHeader>{file.fileName}</CardHeader>
          <CardBody>
            <Form id={`form-${file.fileId}`}>
              <Card body style={{ marginBottom: "1rem" }}>
                {isAdmin && (
                  <CardText>
                    <b>Id:</b> {file.fileId}
                  </CardText>
                )}
                <CardText>
                  <b>Name:</b> {file.fileName}
                </CardText>
                <CardText>
                  <b>Category:</b> {file.category}
                </CardText>
                <CardText>
                  <b>Owner:</b> {file.org}
                </CardText>
                {isAdmin && isFileMine() && (
                  <CardText>
                    <b>Shared with:</b>{" "}
                    {sharedWith.length ? sharedWith.join(", ") : "Nobody"}
                  </CardText>
                )}
                {isVendor && isSharedWithMe() && (
                  <CardText>
                    <b>Shared by:</b> {file.sharedWith.by.name} (
                    {file.sharedWith.by.org})
                  </CardText>
                )}
                <CardText>
                  <b>Type:</b> {file.contentType}
                </CardText>
                <CardText>
                  <b>Size:</b> {prettyBytes(file.size)}
                </CardText>
                <FormGroup>
                  {!isSharing && (
                    <Button
                      outline
                      color="secondary"
                      size="sm"
                      onClick={(e) =>
                        downloadFile(
                          e,
                          file.fileId,
                          file.fileName,
                          file.contentType
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faDownload} /> Download
                    </Button>
                  )}
                  {canIShare && !isSharing && vendors.length && (
                    <Button
                      outline
                      className="ml-0-25rem"
                      color="secondary"
                      size="sm"
                      onClick={(e) =>
                        shareFile(
                          e,
                          file.fileId,
                          file.fileName,
                          file.contentType
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faShare} /> Share
                    </Button>
                  )}
                  {isSharing && (
                    <ManageFileShare
                      handleSaveShare={handleSaveShare}
                      handleCancelShare={handleCancelShare}
                      handleShareOption={handleShareOption}
                      isLoading={isLoading}
                    />
                  )}
                </FormGroup>
                {isAdmin && !isDeleting && !isSharing && (
                  <DeleteButton handleDelete={setIsDeleting} />
                )}
                {isAdmin && isDeleting && (
                  <DeleteCancelButtons
                    handleDelete={handleDelete}
                    handleCancelDelete={handleCancelDelete}
                    isLoading={isLoading}
                  />
                )}
              </Card>
              <Card
                body
                inverse
                style={{
                  backgroundColor: "#f7f7f7"
                }}
              >
                <CardText>
                  <CreatedByField
                    by={file.createdBy.name}
                    at={file.createdAt}
                  />
                </CardText>
                {file.updatedBy && (
                  <CardText>
                    <UpdatedByField
                      by={file.updatedBy.name}
                      at={file.updatedAt}
                    />
                  </CardText>
                )}
              </Card>
            </Form>
          </CardBody>
        </Card>
        <ErrorModal />
      </div>
    )
  );
};

export default MyDriveFile;
