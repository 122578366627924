import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner
} from "reactstrap";
import UploadedFile from "./UploadedFile";
import { getUploads } from "../services/filesService";
import { getCollectionCount } from "../services/collectionService";
import { phraseToProperCase } from "../libs/case-utils";
import Moment from "react-moment";
import "moment-timezone";
import "./SearchFileBox.css";
import GetPagination from "./GetPagination";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM-DD-YYYY HH:mm:ss";

const SearchBox = (props) => {
  const { user } = props;
  const showOtherSearchOptions = user.role !== "vendor" ? true : false;
  const isVendor = user.role === "vendor";

  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  const [fileType, setFileType] = useState("all");
  const [fileCategory, setFileCategory] = useState("all");
  const [onlyErrorsOption, setOnlyErrorsOption] = useState(false);
  const [onlyNotProcessed, setOnlyNotProcessed] = useState(false);
  const [onlyProcEvents, setOnlyProcEvents] = useState(false);
  const [circuitContains, setCircuitContains] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [uploads, setUploads] = useState([]);

  const newSearch = () => {
    setIsLoading(false);

    // setOnlyErrorsOption(false);
    setCurrentPage(1);
    setUploads([]);
    toggleSearch();
  };

  // Control pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [uploadsCount, setUploadsCount] = useState(0);

  const totalUploadsPerPage = 20;

  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setUploads([]);

    listUserUploads(pageNumber, totalUploadsPerPage);
  };

  const incChange = (pageNumber, inc, isposOrneg) => {
    let finalPage;
    const residual = pageNumber % inc;
    const control = residual === 0 ? inc : residual;

    if (isposOrneg > 0) {
      finalPage = pageNumber + inc - control + 1;
    } else {
      finalPage = pageNumber - inc - control + 1;
    }

    setCurrentPage(finalPage);
    setUploads([]);

    listUserUploads(finalPage, totalUploadsPerPage);
  };

  const firstChange = () => {
    let finalPage = 1;

    setCurrentPage(finalPage);
    setUploads([]);

    listUserUploads(finalPage, totalUploadsPerPage);
  };

  const lastChange = (finalPage) => {
    setCurrentPage(finalPage);
    setUploads([]);

    listUserUploads(finalPage, totalUploadsPerPage);
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  // Add "All" option to types
  const fileTypeOptions =
    user.prefs.fileTypes.length > 1
      ? ["all", ...user.prefs.fileTypes]
      : user.prefs.fileTypes;

  // Add "All" option to categories
  const fileCatOptions =
    user.prefs.programs.length > 1
      ? ["all", ...user.prefs.programs]
      : user.prefs.programs;

  const listUserUploads = async (page, count) => {
    let resUploads = [];
    let query = {};

    if (fileType !== "all") query.type = fileType;
    if (fileCategory !== "all") query.category = fileCategory;
    if (circuitContains) query.circuit = circuitContains;
    if (onlyErrorsOption) query.showErrors = true;
    if (onlyNotProcessed) query.onlyNotProcessed = true;
    if (onlyProcEvents) query.onlyProcEvents = true;
    if (isVendor) query.owner = user.org;

    try {
      const resCollectionCount = await getCollectionCount({
        collection: "Uploads",
        ...query
      });

      setUploadsCount(resCollectionCount.count);
    } catch (e) {}

    if (page) query.page = page;
    if (count) query.count = count;

    try {
      resUploads = await getUploads(query);
      setUploads(resUploads);
    } catch (e) {}

    return resUploads.length;
  };

  const validateForm = () => {
    return fileType.length > 0 && fileCategory.length > 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const numUploads = await listUserUploads(1, totalUploadsPerPage);
      if (numUploads) {
        toggleSearch();
      } else {
        showCustomModal({
          title: "Search results",
          message: "No results were found following that criteria"
        });
      }
    } catch (e) {}

    setIsLoading(false);
  };

  const SearchResults = () => {
    let numberOfPages = 0;
    if (uploadsCount % totalUploadsPerPage === 0) {
      numberOfPages = Math.floor(uploadsCount / totalUploadsPerPage);
    } else {
      numberOfPages = Math.floor(uploadsCount / totalUploadsPerPage) + 1;
    }

    return uploads.length ? (
      <>
        <div className="NewSearch">
          <Button outline onClick={newSearch} color="secondary">
            New Search
          </Button>
        </div>
        <h5 className="card-title">Search results</h5>
        <GetPagination
          pages={numberOfPages}
          nextPage={nextPage}
          currentPage={currentPage}
          firstChange={firstChange}
          lastChange={lastChange}
          incChange={incChange}
        />
        <div className="UploadCards">
          {uploads.map((u, i) => (
            <UploadedFile key={i} upload={{ ...u, user: user }} />
          ))}
        </div>
      </>
    ) : (
      " "
    );
  };

  return (
    <div className="SearchFileBox">
      <Card>
        <CardHeader tag="h5">Search Submitted Files</CardHeader>
        <CardBody>
          <Collapse isOpen={isSearchOpen}>
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} id="search-form">
                  <FormGroup tag="fieldset">
                    <h5 className="card-title">Select the program</h5>
                    {fileCatOptions.map((el, i) => (
                      <FormGroup key={i} check>
                        <Label check>
                          <Input
                            type="radio"
                            name="fileCategory"
                            value={el}
                            defaultChecked={el === fileCategory}
                            onChange={(e) => setFileCategory(e.target.value)}
                          />{" "}
                          {el === "all" ? phraseToProperCase(el) : el}
                        </Label>
                      </FormGroup>
                    ))}
                  </FormGroup>
                  <FormGroup tag="fieldset">
                    <h5 className="card-title">
                      Select the file type you will be searching for
                    </h5>
                    {fileTypeOptions.map((el, i) => (
                      <FormGroup key={i} check>
                        <Label check>
                          <Input
                            type="radio"
                            name="filetype"
                            value={el}
                            defaultChecked={el === fileType}
                            onChange={(e) => setFileType(e.target.value)}
                          />{" "}
                          {phraseToProperCase(el)}
                        </Label>
                      </FormGroup>
                    ))}
                  </FormGroup>
                  <FormGroup tag="fieldset">
                    <h5 className="card-title">Circuit (Optional)</h5>
                    <FormGroup check>
                      <Input
                        type="text"
                        name="circuitContains"
                        id="circuitContains"
                        placeholder="Starts with or exact match"
                        value={circuitContains}
                        onChange={(e) => setCircuitContains(e.target.value)}
                      />
                    </FormGroup>
                  </FormGroup>
                  {showOtherSearchOptions && (
                    <FormGroup tag="fieldset">
                      <h5 className="card-title">Other options</h5>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            name="onlyErrorsOption"
                            value={true}
                            onChange={(e) =>
                              setOnlyErrorsOption(e.target.checked)
                            }
                          />{" "}
                          Show only files with errors
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            name="onlyNotProcessedOption"
                            value={true}
                            onChange={(e) =>
                              setOnlyNotProcessed(e.target.checked)
                            }
                          />{" "}
                          Show only not processed
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            name="onlyProcEventsOption"
                            value={true}
                            onChange={(e) =>
                              setOnlyProcEvents(e.target.checked)
                            }
                          />{" "}
                          Show only with processing events
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  )}
                  <Button
                    className="btn-lg"
                    disabled={!validateForm() || isLoading}
                    color="primary"
                    block
                  >
                    Search {isLoading && <Spinner color="light" />}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
          <SearchResults />
        </CardBody>
      </Card>
      <ErrorModal />
    </div>
  );
};

export default SearchBox;
