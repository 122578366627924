import axios from "axios";
import { config } from "./config";

const stage = config.stage;
const settings = config[stage].appService;

const getCircuitAudit = async (circuit) => {
  const url = `${settings.baseUrl}/api/circuitAuditGet?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: { circuit }
  });

  return data;
};

const getCircuitAudits = async (query) => {
  const url = `${settings.baseUrl}/api/circuitAuditsList?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: query
  });

  return data;
};

const createCircuitAudit = async (doc) => {
  const url = `${settings.baseUrl}/api/circuitAuditInsert?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "POST",
    url: url,
    data: doc
  });

  return data;
};

const updateCircuitAudit = async (circuit, doc) => {
  const url = `${settings.baseUrl}/api/circuitAuditUpdate?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "PUT",
    url: url,
    params: { circuit },
    data: doc
  });

  return data;
};

const deleteCircuitAudit = async (circuit) => {
  const url = `${settings.baseUrl}/api/circuitAuditDelete?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "DELETE",
    url: url,
    params: { circuit }
  });

  return data;
};

export {
  getCircuitAudit,
  getCircuitAudits,
  createCircuitAudit,
  updateCircuitAudit,
  deleteCircuitAudit
};
