import React from "react";
import { MailMessage } from "../models/Message";
import {
  Email,
  Item,
  Span,
  Box,
  renderEmail,
  configStyleValidator
} from "react-html-email";
import prettyBytes from "pretty-bytes";

const APP_NAME = "DTE Data Delivery";

configStyleValidator({
  strict: true,
  warn: true,
  platforms: [
    "gmail",
    "gmail-android",
    "apple-mail",
    "apple-ios",
    "yahoo-mail",
    "outlook",
    "outlook-legacy",
    "outlook-web"
  ]
});

const genHtml = (user, recipient, data) => {
  const { file } = data;

  const comp = renderEmail(
    <Email title={`${APP_NAME}`}>
      <Box cellSpacing={20} width="100%">
        <Item align="left">
          <Span fontSize={16}>{recipient.name},</Span>
          <br />
          <br />
          <Span fontSize={16}>
            {user.name} from {user.org} just shared a file with your
            organization.
          </Span>
          <br />
          <br />
          <Span fontSize={16}>
            Please follow the steps below to find the shared file.
          </Span>
          <br />
          <Span fontSize={16}>
            <ol>
              <li>Log in to the {APP_NAME} App</li>
              <li>Go to "My Drive"</li>
              <li>Check the box "Show only files shared with me"</li>
              <li>Hit "Search"</li>
              <li>Look for the file shown below</li>
            </ol>
          </Span>
        </Item>
        <Item align="left">
          <Span fontSize={16}>
            File name: {file.fileName} ({prettyBytes(file.size)})
          </Span>
        </Item>
      </Box>
    </Email>
  );

  return comp;
};

const myDriveFileShareNotify = async (
  handleNotify,
  user,
  toRecipients,
  data
) => {
  // Set mail delegate
  const fromRecipient = {
    name: user.name,
    address: user.email
  };

  // Set message subject
  const subject = `${APP_NAME}: File share notification...`;

  // Generate email content
  const content = genHtml(user, toRecipients[0], data);

  // Set message body
  const body = {
    contentType: "HTML",
    content
  };

  // Set one attachment
  const attachments = [];

  // Set Cc list
  const ccRecipients = [];

  // Set Bcc list
  const BccRecipients = [];

  const message = MailMessage(
    fromRecipient,
    toRecipients,
    subject,
    body,
    attachments,
    ccRecipients,
    BccRecipients,
    true
  );

  await handleNotify(message);
};

export { myDriveFileShareNotify };
