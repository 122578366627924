import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  FormFeedback,
  FormGroup,
  Input,
  Spinner,
  Table
} from "reactstrap";
import {
  getUsageData,
  followAzUsageLink
} from "../services/azCostManagementServices";
import { phraseToProperCase } from "../libs/case-utils";
import moment from "moment";
import prettyBytes from "pretty-bytes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import "./AzUsageDetails.css";

// import sampleUsageData from "../models/az-consumption/usage-data.json";

const getTimestamp = () => new Date().getTime();

const AzUsageDetails = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [usageData, setUsageData] = useState({});
  const [now, setNow] = useState(getTimestamp());
  const [startDate, setStartDate] = useState(moment(now).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment(now).format("YYYY-MM-DD"));
  const [error, setError] = useState(null);
  const [hrefCsv, setHrefCsv] = useState(null);
  const [csvSize, setCsvSize] = useState(null);

  const sortMultiFields = (prop) => {
    return function (a, b) {
      for (let i = 0; i < prop.length; i++) {
        var reg = /^\d+$/;
        var x = 1;
        var field1 = prop[i];
        if (prop[i].indexOf("-") === 0) {
          field1 = prop[i].substr(1, prop[i].length);
          x = -x;
        }

        if (reg.test(a[field1])) {
          a[field1] = parseFloat(a[field1]);
          b[field1] = parseFloat(b[field1]);
        }
        if (a[field1] > b[field1]) return x;
        else if (a[field1] < b[field1]) return -x;
      }
    };
  };

  const displayTags = (tags) => {
    if (tags) {
      const keys = Object.keys(tags);

      const lines = keys.map((key) => {
        return `${key}: ${tags[key]}`;
      });

      return lines.join("; ");
    } else {
      return "";
    }
  };

  const displayAdditionalInfo = (info) => {
    if (info !== "") {
      const parsed = JSON.parse(info);
      const keys = Object.keys(parsed);

      const lines = keys.map((key) => {
        return `${key}: ${parsed[key]}`;
      });

      return lines.join("; ");
    } else {
      return "";
    }
  };

  const handleUsageData = async () => {
    const timestamp = getTimestamp();
    const top = 1000;

    let nextLink = null;
    let usageData = [];
    let accessToken = null;

    setIsSubmitting(true);

    const usageDataRes = await getUsageData(startDate, endDate, top);

    if (usageDataRes.status === 200) {
      accessToken = usageDataRes.data.accessToken;
      usageData = usageDataRes.data.value;
      nextLink = usageDataRes.data?.nextLink ?? null;

      while (nextLink !== null) {
        const nextLinkRes = await followAzUsageLink(nextLink, accessToken);

        if (nextLinkRes.status === 200) {
          accessToken = nextLinkRes.data.accessToken;
          usageData = [...usageData, ...nextLinkRes.data.value];
          nextLink = nextLinkRes.data?.nextLink ?? null;
        } else {
          nextLink = null;
        }
      }

      const summary = usageData.map((item, i) => {
        const subscriptionName = item.properties.subscriptionName.toUpperCase();
        const resourceGroup = item.properties.resourceGroup.toUpperCase();
        const instanceName = item.properties.instanceName.replace(
          /^(.*)\//,
          ""
        );
        const serviceFamily = item.properties.serviceFamily;
        const resourceLocationNormalized =
          item.properties.resourceLocationNormalized;
        const product = item.properties.product;
        const meterName = item.properties.meterName;
        const meterSubCategory = item.properties.meterSubCategory;
        const chargeType = item.properties.chargeType;
        const unitOfMeasure = item.properties.unitOfMeasure;
        const unitPrice = item.properties.unitPrice;
        const pricingModel = item.properties.pricingModel;
        const date = item.properties.date;
        const quantity = item.properties.quantity;
        const paygCostInUSD = item.properties.paygCostInUSD;
        const takeDateTime = moment(timestamp).format("YYYY-MM-DD HH:mm:ss"); // the date time the report was pulled
        const additionalInfo = displayAdditionalInfo(
          item.properties.additionalInfo
        );
        const tags = displayTags(item.tags);

        return {
          subscriptionName,
          resourceGroup,
          serviceFamily,
          instanceName,
          resourceLocationNormalized,
          product,
          meterName,
          meterSubCategory,
          chargeType,
          pricingModel,
          date,
          takeDateTime,
          unitOfMeasure,
          quantity,
          unitPrice,
          paygCostInUSD,
          additionalInfo,
          tags
        };
      });

      const sortedSummary = summary.sort(
        sortMultiFields([
          "subscriptionName",
          "resourceGroup",
          "serviceFamily",
          "instanceName",
          "resourceLocationNormalized",
          "product",
          "meterName",
          "chargeType",
          "pricingModel",
          "date",
          "takeDateTime"
        ])
      );

      setUsageData(sortedSummary);
      setHrefCsv(null);
      setCsvSize(null);
      setNow(timestamp);
    }

    setIsSubmitting(false);
  };

  const handleDownload = async () => {
    const header = Object.keys(usageData[0]).join(",");
    const rowsCsv = usageData.map((row) => Object.values(row).join(","));

    // Create URL for CSV data
    const fileCsv = new Blob([`${header}\n${rowsCsv.join("\n")}`], {
      type: "text/csv"
    });

    setHrefCsv(URL.createObjectURL(fileCsv));
    setCsvSize(fileCsv.size);
  };

  const ShowTable = (props) => {
    const { data } = props;
    const header = Object.keys(data[0]);
    const totalCost = data.reduce((accum, o) => {
      return accum + o.paygCostInUSD;
    }, 0);
    const requestedOn = moment(now).format("YYYY-MM-DD HH:mm:ss");
    const downloadName = `usage-data-${requestedOn}`;

    return (
      <Card className="mt-1rem">
        <CardHeader>
          <h2>Cost By Resource</h2>
          <h3>Total cost: {totalCost.toFixed(2)} USD</h3>
          <CardText>Requested on: {requestedOn}</CardText>
        </CardHeader>
        <CardBody className="overflow-500">
          <Table size="sm" responsive bordered>
            <thead>
              <tr>
                {header.map((col, i) => {
                  return (
                    <th key={i}>
                      <b>{phraseToProperCase(col)}</b>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((row, i) => {
                return (
                  <tr key={i}>
                    {Object.keys(row).map((key, i) => {
                      return <td key={i}>{row[key]}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
        <CardFooter>
          {!hrefCsv && (
            <Button
              outline
              color="primary"
              size="sm"
              onClick={() => handleDownload()}
            >
              Download CSV
            </Button>
          )}
          {hrefCsv && (
            <>
              <CardText>
                Click on the link below to download the CSV file
              </CardText>
              <CardText>
                <a href={hrefCsv} download={downloadName}>
                  <FontAwesomeIcon icon={faDownload} /> Usage data (
                  {prettyBytes(csvSize ? csvSize : 0)})
                </a>
              </CardText>
            </>
          )}
        </CardFooter>
      </Card>
    );
  };

  const handleStartDate = (d) => {
    setStartDate(d);
    setEndDate(d);

    if (/^\d{4}-\d{2}-\d{2}$/.test(d)) {
      if (moment(d).isValid()) {
        if (moment(d).isAfter(now, "day")) {
          setError("Invalid future date");
        } else {
          setError(null);
        }
      } else {
        setError("Invalid date");
      }
    } else {
      setError("Bad date format. Example: YYYY-MM-DD");
    }
  };

  const validateForm = () => {
    let passed = false;

    if (/^\d{4}-\d{2}-\d{2}$/.test(startDate)) {
      if (moment(startDate).isValid()) {
        if (!moment(startDate).isAfter(now, "day")) {
          passed = true;
        }
      }
    }

    return passed;
  };

  return (
    <div className="AzUsageDetails">
      <Card>
        <CardHeader tag="h5">Azure Usage Details</CardHeader>
        <CardBody>
          <Card>
            <CardHeader>Search by date</CardHeader>
            <CardBody>
              <FormGroup tag="fieldset">
                <CardText>
                  <b>Date:</b>
                </CardText>
                <Input
                  invalid={error !== null}
                  type="text"
                  name="startDate"
                  id="startDate"
                  placeholder="YYYY-MM-DD"
                  value={startDate}
                  onChange={(e) => handleStartDate(e.target.value)}
                />
                <FormFeedback>{error}</FormFeedback>
              </FormGroup>
              <Button
                color="primary"
                size="sm"
                onClick={() => handleUsageData()}
                disabled={isSubmitting || !validateForm()}
              >
                Request usage data{" "}
                {isSubmitting && <Spinner size="sm" color="dark" />}
              </Button>
            </CardBody>
          </Card>
          {usageData.length > 0 && <ShowTable data={usageData} />}
        </CardBody>
      </Card>
    </div>
  );
};

export default AzUsageDetails;
