const getOperRecMd = (doc, supportDoc) => {
  const globalID = doc.GlobalID_PIT;
  const GLNX_GLNY = supportDoc["GLNX-GLNY"];
  const circuit = supportDoc.circuit;
  const map = supportDoc.map;
  const year = doc.InspectionDate_PIT.split("-")[0];

  return {
    GlobalID: globalID,
    "GNLX-GNLY": GLNX_GLNY,
    OHPrimaryCircuitNumber: circuit,
    Map: map,
    Year: year
  };
};

export { getOperRecMd };
