import { useEffect, useState } from "react";
import { defer } from "rxjs";
import { getFields } from "../services/fieldsService";

export const useGetFields = () => {
  const [fields, setFields] = useState(null);

  useEffect(() => {
    const fetchFields = async () => {
      const query = { offset: 0, count: 1500 };
      const results = await getFields(query);

      let temp = {};

      results.map((field) => {
        temp[field.fieldKey] = {
          fieldKey: field.fieldKey,
          alias: field.alias,
          description: field.description,
          required: field.required,
          validation: field.validation
        };

        return null;
      });

      return temp;
    };

    const subscription = defer(() => fetchFields()).subscribe(setFields);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return fields;
};
