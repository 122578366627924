import React from "react";
import MyDriveSearch from "../components/MyDriveSearch";
import "./MyDrive.css";

const MyDrive = (props) => {
  return (
    <div className="MyDrive">
      <div className="container">
        <MyDriveSearch {...props} />
      </div>
    </div>
  );
};

export default MyDrive;
