/* eslint no-eval: 0 */
import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import BuilderExpression from "./BuilderExpression";
import BuilderTest from "./BuilderTest";
import "./Builder.css";

// Defines how deep the groups get
const groupsUnderRoot = 2;

const Builder = (props) => {
  const {
    role,
    query,
    updateQuery,
    discardQuery,
    fieldSet,
    getVariablesObject
  } = props;

  const [newQuery, setNewQuery] = useState(query);
  const [isEditing, setIsEditing] = useState(false);

  const getVariableFieldSet = () => {
    const variables = getVariablesObject(query);

    // Clean up object
    if (variables.hasOwnProperty("NEW")) {
      delete variables["NEW"];
    }

    return Object.keys(variables).map((v) => fieldSet[v]);
  };

  const resolveIndex = (index) => {
    let propValue = newQuery;

    const propIndexes = index.split(":");

    propIndexes.map((prop) => {
      const propIndexPair = prop.split(",");

      if (propIndexPair.length > 1) {
        propValue = propValue[propIndexPair[0]][propIndexPair[1]];
      } else {
        propValue = propValue[propIndexPair[0]];
      }

      return null;
    });

    return propValue;
  };

  const getObjectIndexes = (index) => {
    const propIndexes = index.split(":");

    const args = propIndexes.map((prop) => {
      const propIndexPair = prop.split(",");
      let arg = null;

      if (propIndexPair.length > 1) {
        arg = `["${propIndexPair[0]}"][${propIndexPair[1]}]`;
      } else {
        arg = `["${propIndexPair[0]}"]`;
      }

      return arg;
    });

    return `${args.join("")}`;
  };

  const switchOperator = (objectIndexes, option) => {
    let newObjectIndexes = null;

    if (option === "and") {
      newObjectIndexes = objectIndexes.replace(/\["or"\]$/, '["and"]');
    } else {
      newObjectIndexes = objectIndexes.replace(/\["and"\]$/, '["or"]');
    }

    return newObjectIndexes;
  };

  const handleChangeOper = (groupIndex, option) => {
    let queryCopy = {
      ...newQuery
    };
    const objectIndexes = getObjectIndexes(groupIndex);
    const newObjectIndexes = switchOperator(objectIndexes, option);

    // Add new property
    eval(
      `queryCopy${newObjectIndexes} = ${JSON.stringify(
        resolveIndex(groupIndex)
      )}`
    );

    // Delete old property
    eval(`delete queryCopy${objectIndexes}`);

    setIsEditing(true);
    setNewQuery(queryCopy);
  };

  const HeaderLogicOperators = (props) => {
    const { defOperator, groupIndex } = props;
    const [rSelected, setRSelected] = useState(defOperator);

    const handleButtonGroup = (option) => {
      if (option !== rSelected) {
        setRSelected(option);

        handleChangeOper(groupIndex, option);
      }
    };

    return (
      <ButtonGroup>
        <Button
          className="operator-inactive-button"
          color="primary"
          size="sm"
          onClick={() => handleButtonGroup("and")}
          active={rSelected === "and"}
        >
          AND
        </Button>
        <Button
          className="operator-inactive-button"
          color="primary"
          size="sm"
          onClick={() => handleButtonGroup("or")}
          active={rSelected === "or"}
        >
          OR
        </Button>
      </ButtonGroup>
    );
  };

  const handleAddRule = (groupIndex) => {
    let queryCopy = {
      ...newQuery
    };

    // Set empty rule
    const newRule = {
      "===": [{ var: "NEW" }, ""]
    };

    const objectIndexes = getObjectIndexes(groupIndex);

    eval(`queryCopy${objectIndexes}.push(${JSON.stringify(newRule)})`);

    setNewQuery(queryCopy);
  };

  const handleUpdateRule = (expressionIndex, operator, variable, value) => {
    let queryCopy = {
      ...newQuery
    };

    let newRule = {};

    // Decode negator
    switch (operator) {
      case "!in":
        newRule["!"] = [{ in: [{ var: variable }, value] }];
        break;
      default:
        newRule[operator] = [{ var: variable }, value];
    }

    const objectIndexes = getObjectIndexes(expressionIndex);

    eval(`queryCopy${objectIndexes} = ${JSON.stringify(newRule)}`);

    setIsEditing(true);
    setNewQuery(queryCopy);
  };

  const AddRuleButton = (props) => {
    const { groupIndex } = props;

    return (
      <Button
        outline
        size="sm"
        color="primary"
        onClick={() => handleAddRule(groupIndex)}
      >
        + Rule
      </Button>
    );
  };

  const handleDeleteRule = (expressionIndex) => {
    let queryCopy = {
      ...newQuery
    };

    let groupIndex = expressionIndex.split(",");
    const indexToDelete = +groupIndex.pop();
    groupIndex = groupIndex.join(",");

    const objectIndexes = getObjectIndexes(groupIndex);

    eval(`queryCopy${objectIndexes}.splice(${indexToDelete}, 1)`);

    setIsEditing(true);
    setNewQuery(queryCopy);
  };

  const handleAddGroup = (groupIndex) => {
    let queryCopy = {
      ...newQuery
    };

    // Set empty group
    const newGroup = {
      and: []
    };

    const objectIndexes = getObjectIndexes(groupIndex);

    eval(`queryCopy${objectIndexes}.push(${JSON.stringify(newGroup)})`);

    setNewQuery(queryCopy);
  };

  const AddGroupButton = (props) => {
    const { groupIndex } = props;

    return (
      <Button
        outline
        size="sm"
        color="primary"
        onClick={() => handleAddGroup(groupIndex)}
      >
        + Group
      </Button>
    );
  };

  const handleDeleteGroup = (groupIndex) => {
    let queryCopy = {
      ...newQuery
    };

    let parentGroupIndex = groupIndex.split(":");
    parentGroupIndex.pop(); // remove last group
    parentGroupIndex = parentGroupIndex.join(":");
    parentGroupIndex = parentGroupIndex.split(",");
    const indexToDelete = +parentGroupIndex.pop(); // remove last index
    parentGroupIndex = parentGroupIndex.join(",");

    const objectIndexes = getObjectIndexes(parentGroupIndex);

    eval(`queryCopy${objectIndexes}.splice(${indexToDelete}, 1)`);

    setIsEditing(true);
    setNewQuery(queryCopy);
  };

  const DeleteGroupButton = (props) => {
    const { groupIndex } = props;

    return (
      <Button
        outline
        size="sm"
        color="danger"
        onClick={() => handleDeleteGroup(groupIndex)}
      >
        Delete
      </Button>
    );
  };

  const RuleGroupHeader = (props) => {
    const { operator, groupIndex, add, del } = props;

    return (
      <CardHeader>
        <HeaderLogicOperators defOperator={operator} groupIndex={groupIndex} />
        <div className="float-right">
          <ButtonGroup>
            <AddRuleButton groupIndex={groupIndex} />
            {add && <AddGroupButton groupIndex={groupIndex} />}
          </ButtonGroup>
          {del && (
            <ButtonGroup style={{ marginLeft: "2px" }}>
              <DeleteGroupButton groupIndex={groupIndex} />
            </ButtonGroup>
          )}
        </div>
      </CardHeader>
    );
  };

  const getFirstFieldName = () => {
    const field = fieldSet[Object.keys(fieldSet)[0]];
    const name = field.fieldKey;
    const options = field.validation.options ? field.validation.options : null;
    const value = options ? options[0] : field.type === "string" ? "" : 0;
    const type = field.validation.type;

    return { name, type, value };
  };

  const Rule = (props) => {
    const { ruleIndex, groupIndex, rule } = props;
    let operator = Object.keys(rule)[0];
    const groupDepth = groupIndex.split(",").length;

    let ruleCopy = {};

    // Encode negator
    switch (operator) {
      case "!":
        operator = "!in";
        ruleCopy[operator] = rule["!"][0]["in"];
        break;
      default:
        ruleCopy = { ...rule };
    }

    if (["and", "or"].includes(operator)) {
      return (
        <ListGroupItem className="Padding10px">
          <RuleGroup
            group={ruleCopy}
            groupIndex={`${groupIndex}`}
            ruleIndex={ruleIndex}
            add={groupDepth < groupsUnderRoot}
            del={true}
          />
        </ListGroupItem>
      );
    } else {
      const isNew = ruleCopy[operator][0]["var"] === "NEW";
      let variable = null;
      let value = null;

      if (isNew) {
        const firstField = getFirstFieldName();

        variable = firstField.name;
        value = firstField.value;
      } else {
        variable = ruleCopy[operator][0]["var"];
        value = ruleCopy[operator][1];
      }

      return (
        <ListGroupItem className="Padding10px">
          <>
            <BuilderExpression
              operator={operator}
              variable={variable}
              value={value}
              expressionIndex={`${groupIndex},${ruleIndex}`}
              handleUpdateRule={handleUpdateRule}
              handleDeleteRule={handleDeleteRule}
              fieldSet={fieldSet}
              isNew={isNew}
            />
          </>
        </ListGroupItem>
      );
    }
  };

  const handleSaveRule = () => {
    updateQuery(newQuery);
  };

  const SaveButton = () => {
    return (
      <>
        <hr />
        <ButtonGroup>
          <Button
            size="sm"
            color="primary"
            disabled={!isEditing}
            onClick={() => handleSaveRule()}
          >
            <b>Save logic</b>
          </Button>
          <Button
            outline
            size="sm"
            color="danger"
            disabled={!isEditing}
            onClick={() => discardQuery()}
          >
            Discard changes
          </Button>
        </ButtonGroup>
      </>
    );
  };

  const RuleGroup = (props) => {
    const { group, groupIndex, ruleIndex, add, del } = props;
    const operatorIndex = 0;
    const operator = Object.keys(group)[operatorIndex];
    const rules = group[operator];

    const currentGroupIndex = `${operator}`;
    const elGroupIndex = groupIndex
      ? `${groupIndex},${ruleIndex}:${currentGroupIndex}`
      : `${currentGroupIndex}`;

    return (
      <Card>
        <RuleGroupHeader
          operator={operator}
          groupIndex={elGroupIndex}
          add={add}
          del={del}
        />
        <CardBody className="Padding10px">
          <ListGroup>
            {rules.map((rule, i) => {
              return (
                <Rule
                  key={i}
                  ruleIndex={i}
                  groupIndex={elGroupIndex}
                  rule={rule}
                />
              );
            })}
          </ListGroup>
        </CardBody>
      </Card>
    );
  };

  const isAuthorised = () => role === "dev" || role === "admin";

  return (
    <div className="Builder">
      <RuleGroup group={newQuery} add={true} del={false} />
      <SaveButton />
      {isAuthorised() && !isEditing && (
        <BuilderTest
          logic={query}
          data={getVariablesObject(query)}
          fieldSets={getVariableFieldSet()}
        />
      )}
    </div>
  );
};

export default Builder;
