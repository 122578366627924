/* eslint no-eval: 0 */
import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormText,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import jsonLogic from "json-logic-js";
import { JsonLogicMethods, getNameSpace } from "../models/JsonLogicMethods";
import "./BuilderTest.css";

// Load JsonLogicMethods into Json Logic
const methodNamespace = getNameSpace();
jsonLogic.add_operation(methodNamespace, JsonLogicMethods);

// const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

const BuilderTest = (props) => {
  const { logic, data, fieldSets } = props;

  const [testData, setTestData] = useState(data);
  const [isTesting, setIsTesting] = useState(false);
  const [result, setResult] = useState(null);

  const handleTest = () => {
    setIsTesting(true);

    setResult(jsonLogic.apply(logic, testData));

    setIsTesting(false);
  };

  const handleValueUpdate = (key, type, item) => {
    const testDataCopy = { ...testData };

    testDataCopy[key] = type === "number" && !isNaN(item) ? +item : item;

    setTestData(testDataCopy);
  };

  const getCardHeaderClass = () => {
    if (result === null) return "";

    return result ? "card-header-success" : "card-header-danger";
  };

  const getStatusClass = () => {
    if (result === null) return "primary";

    return result ? "success" : "danger";
  };

  const handleLinkBadge = (event, key, type, item) => {
    event.preventDefault();

    const testDataCopy = { ...testData };

    testDataCopy[key] = type === "number" && !isNaN(item) ? +item : item;

    setTestData(testDataCopy);
  };

  const GetLinkBadges = (props) => {
    const { caption, arr, fieldKey, fieldType } = props;

    const badges = arr.map((item, i) => {
      return (
        <Badge
          className="badge-spacing"
          key={i}
          href="#"
          color="primary"
          onClick={(e) => handleLinkBadge(e, fieldKey, fieldType, item)}
        >
          {item}
        </Badge>
      );
    });

    return (
      <>
        <span>{caption}:</span> {badges}
      </>
    );
  };

  return (
    <div className="BuilderTest">
      <Card className="mt-1rem">
        <CardHeader className={getCardHeaderClass()}>Logic Test</CardHeader>
        <CardBody>
          <Form>
            {fieldSets.map((field, i) => {
              let feedback = field.description
                ? field.description
                : "No description available";
              const options = field.validation.options;
              const examples = field.validation.test;
              const fieldKey = field.fieldKey;
              const fieldType = field.validation.type;

              return (
                <FormGroup key={i}>
                  <Label for="variable">
                    <b>{fieldKey}</b>
                  </Label>
                  <Input
                    type="test"
                    id="variable"
                    value={
                      testData[fieldKey] !== null ? testData[fieldKey] : ""
                    }
                    disabled={isTesting}
                    onChange={(e) =>
                      handleValueUpdate(fieldKey, fieldType, e.target.value)
                    }
                  ></Input>
                  <FormText className="feedback">{feedback}</FormText>
                  {options && (
                    <GetLinkBadges
                      caption="Options"
                      arr={options}
                      fieldKey={fieldKey}
                      fieldType={fieldType}
                    />
                  )}
                  {examples && (
                    <GetLinkBadges
                      caption="Examples"
                      arr={examples}
                      fieldKey={fieldKey}
                      fieldType={fieldType}
                    />
                  )}
                </FormGroup>
              );
            })}
          </Form>
          {/* <Json data={testData} /> */}
          <Button
            size="sm"
            color={getStatusClass()}
            onClick={() => handleTest()}
          >
            <b>Test logic</b>
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default BuilderTest;
