import React from "react";
import ReportsComp from "../components/ReportsComp";
import "./Reports.css";

const Reports = (props) => {
  return (
    <div className="Reports">
      <div className="container">
        <ReportsComp {...props} />
      </div>
    </div>
  );
};

export default Reports;
