import axios from "axios";
import jsonwebtoken from "jsonwebtoken";
import { config } from "./config";

const stage = config.stage;
const settings = config[stage].appService;

const JWT_SECRET = "aasdkjj5ja8q37*&%kasd&W$Hfk_9994*())asedfaj1234kjdm,kdj$5%";
const JWT_EXPIRATION_TIME = "60m";

const getAccessToken = (user) => {
  return jsonwebtoken.sign({ ...user }, JWT_SECRET, {
    expiresIn: JWT_EXPIRATION_TIME
  });
};

const getUser = async (id) => {
  const url = `${settings.baseUrl}/api/userGet?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: { id }
  });

  return data;
};

const getUsers = async (query) => {
  const url = `${settings.baseUrl}/api/usersList?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: query
  });

  return data;
};

const getVendors = async (query) => {
  const url = `${settings.baseUrl}/api/usersVendorList?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: query
  });

  return data;
};

const createUser = async (doc) => {
  const url = `${settings.baseUrl}/api/userInsert?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "POST",
    url: url,
    data: doc
  });

  return data;
};

const updateUser = async (id, doc) => {
  const url = `${settings.baseUrl}/api/userUpdate?code=${settings.hostKey}`;
  const { data } = await axios({
    method: "PUT",
    url: url,
    params: { id },
    data: doc
  });

  return data;
};

const deleteUser = async (id) => {
  const url = `${settings.baseUrl}/api/userDelete?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "DELETE",
    url: url,
    params: { id }
  });

  return data;
};

export {
  getUser,
  getUsers,
  getVendors,
  createUser,
  updateUser,
  deleteUser,
  getAccessToken
};
