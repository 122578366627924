const methodNamespace = "JsonLogicMethods";
const currentDate = new Date();

const JsonLogicMethods = {
  CurrentYearMinus19Years: {
    method: () => currentDate.getFullYear() - 19,
    caption: "Current Year minus 19 years"
  }
};

const getJsonLogicMethodList = () => {
  return Object.keys(JsonLogicMethods);
};

const getJsonLogicMethodRefs = () => {
  return Object.keys(JsonLogicMethods).map((m) => {
    let ref = {};
    ref[`${methodNamespace}.${m}.method`] = [];

    return { name: m, caption: JsonLogicMethods[m].caption, ref };
  });
};

const getNameSpace = () => {
  return methodNamespace;
};

export {
  JsonLogicMethods,
  getJsonLogicMethodList,
  getJsonLogicMethodRefs,
  getNameSpace
};
