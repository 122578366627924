import React from "react";
import GenTestData from "../components/GenTestData";
import "./TestData.css";

const TestData = props => {
  return (
    <div className="TestData">
      <div className="container">
        <GenTestData {...props} />
      </div>
    </div>
  );
};

export default TestData;
