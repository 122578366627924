import { getSomeFieldsObj } from "../services/fieldsService";
import { getSource } from "../services/sourceService";
import { getComplexRules } from "../services/complexRuleService";
import jsonLogic from "json-logic-js";
import { JsonLogicMethods, getNameSpace } from "../models/JsonLogicMethods";
import csv from "csvtojson";
import Moment from "moment";
import "moment-timezone";
import _ from "lodash";

// Load JsonLogicMethods into Json Logic
const methodNamespace = getNameSpace();
jsonLogic.add_operation(methodNamespace, JsonLogicMethods);

// const result = jsonLogic.apply(logic, data);

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

const parseCsv = async (fileType, csvContent) => {
  const Source = await getSource(fileType);
  const SourceFields = await getSomeFieldsObj(Source.fields);
  const Types = Object.fromEntries(
    Source.fields.map((k) => [k, SourceFields[k].validation.type])
  );

  let Fields = {};
  let missingFields = [];
  let unknownFields = [];
  let validationErrors = [];
  let complexRuleErrors = [];
  let primaryCircuitNumbers = [];
  let purchaseOrderNumbers = [];
  let globalPolePosition = [];
  let globalIds = [];
  let processedRows = 0;

  // Load complex rules for file type
  const ComplexRules = await getComplexRules({
    applyTo: fileType,
    count: 1000
  });

  const compareKeys = (src, compare) => {
    const Cols = Object.fromEntries(src.map((k) => [k, true]));

    const keys = compare.filter((k) => {
      return SourceFields[k].required;
    });

    return keys
      .map((k) => {
        if (!Cols.hasOwnProperty(k)) {
          return k;
        } else {
          return undefined;
        }
      })
      .filter((n) => n);
  };

  const checkUnknownKeys = (src, compare) => {
    const unknown = [];

    for (const v in src) {
      if (compare.indexOf(src[v]) === -1) {
        unknown.push(src[v]);
      }
    }

    return unknown;
  };

  const getFieldsToValidate = (pick, omit = []) => {
    const selFields = _.pick(SourceFields, pick);

    return _.omit(selFields, omit);
  };

  const parseMomentCount = (countObj) => {
    const total = countObj.map((expr) => {
      let count = 0;

      switch (expr.oper) {
        case "plus":
          count = expr.func ? parseMomentFunc(expr) : expr.count;
          break;
        case "minus":
          count = -1 * (expr.func ? parseMomentFunc(expr) : expr.count);
          break;
        default:
          count = expr.func ? parseMomentFunc(expr) : expr.count;
      }

      return count;
    });

    return total.reduce((a, b) => a + b, 0);
  };

  const parseMomentFunc = (m) => {
    let hold = null;
    let holdCount = 0;

    switch (m.func) {
      case "subtract":
        holdCount = Number(m.count) ? m.count : parseMomentCount(m.count);
        hold = Moment().subtract(holdCount, m.unit);
        break;
      case "add":
        holdCount = Number(m.count) ? m.count : parseMomentCount(m.count);
        hold = Moment().add(holdCount, m.unit);
        break;
      case "currentDate":
        hold = Moment();
        break;
      case "dayOfYear":
        hold = Moment().dayOfYear();
        break;
      default:
        hold = null;
    }

    return hold;
  };

  // Exception: transform to YYYY-M-D (Excel default)
  const transformDate = (dateStr) => {
    const [m, d, y] = dateStr.split("/");

    const pad = (n) => {
      return n.length === 1 ? `0${n}` : n;
    };

    if (m === undefined || d === undefined || y === undefined) return dateStr;

    return `${y}-${pad(m)}-${pad(d)}`;
  };

  const validateData = (row, index) => {
    // To mimic the spreadsheet row numbers
    const newIndex = index + 1;

    for (const prop in row) {
      const rules = Fields[prop].validation;
      const required = Fields[prop].required;
      const cellValue = row[prop];

      // Check empty and required
      if (required && cellValue.toString().trim().length === 0) {
        validationErrors.push({
          field: prop,
          row: newIndex,
          value: cellValue,
          msg: `A value is required`
        });
      }

      // Check if not empty
      if (cellValue.toString().trim().length > 0) {
        // Check type
        if (rules.hasOwnProperty("type")) {
          if (
            typeof cellValue !== rules.type ||
            (rules.type === "number" && isNaN(cellValue))
          ) {
            if (rules.typeException) {
              if (!rules.typeException.includes(cellValue)) {
                validationErrors.push({
                  field: prop,
                  row: newIndex,
                  value: cellValue,
                  msg: `Value must be of type ${rules.type}`
                });
              }
            } else {
              validationErrors.push({
                field: prop,
                row: newIndex,
                value: cellValue,
                msg: `Value must be of type ${rules.type}`
              });
            }
          }
        }

        // Check options
        if (rules.hasOwnProperty("options")) {
          if (
            rules.options.indexOf(cellValue) === -1 &&
            rules.options.indexOf(cellValue.toString()) === -1
          )
            validationErrors.push({
              field: prop,
              row: newIndex,
              value: cellValue,
              msg: `"${cellValue}" is not an option`
            });
        }

        // Check fixed length
        if (rules.hasOwnProperty("length")) {
          if (cellValue.toString().length !== rules.length) {
            validationErrors.push({
              field: prop,
              row: newIndex,
              value: cellValue,
              msg: `String should be ${rules.length} characters in length`
            });
          }
        }

        // Check minimum length
        if (rules.hasOwnProperty("minLength")) {
          if (cellValue.length < rules.minLength) {
            validationErrors.push({
              field: prop,
              row: newIndex,
              value: cellValue,
              msg: `String should be greater than or equal to ${rules.minLength} characters in length`
            });
          }
        }

        // Check maximum length
        if (rules.hasOwnProperty("maxLength")) {
          if (cellValue.length > rules.maxLength) {
            validationErrors.push({
              field: prop,
              row: newIndex,
              value: cellValue,
              msg: `String should be less than or equal to ${rules.maxLength} characters in length`
            });
          }
        }

        // Check patterns
        if (rules.hasOwnProperty("pattern")) {
          const regex = RegExp(rules.pattern.replace(/\\/g, "\\"));

          if (!regex.test(cellValue)) {
            // Check options
            if (rules.hasOwnProperty("orOptions")) {
              if (rules.orOptions.indexOf(cellValue) === -1)
                validationErrors.push({
                  field: prop,
                  row: newIndex,
                  value: cellValue,
                  msg: `String doesn't match format pattern (${rules.patternMsg})`
                });
            } else {
              validationErrors.push({
                field: prop,
                row: newIndex,
                value: cellValue,
                msg: `String doesn't match format pattern (${rules.patternMsg})`
              });
            }
          } else {
            // Check date (evaluate date only it passes the pattern rules)
            if (rules.hasOwnProperty("date")) {
              const inputDate = Moment(transformDate(cellValue));

              // Check if date is valid
              if (inputDate.isValid()) {
                // Validate from starting date
                if (rules["date"].hasOwnProperty("start")) {
                  let startCompareDate = null;

                  if (rules["date"]["start"].hasOwnProperty("moment")) {
                    startCompareDate = parseMomentFunc(
                      rules["date"].start.moment
                    );
                  } else {
                    startCompareDate = Moment({
                      year: rules["date"].start.year,
                      month: rules["date"].start.month - 1,
                      day: rules["date"].start.day
                    });
                  }

                  // Check if given date happens before set date
                  if (inputDate.isBefore(startCompareDate)) {
                    const startingDate = startCompareDate.format(
                      rules["date"].format
                    );

                    validationErrors.push({
                      field: prop,
                      row: newIndex,
                      value: cellValue,
                      msg: `Date should be later than or equal to ${startingDate}`
                    });
                  }
                }

                // Validate from ending date
                if (rules["date"].hasOwnProperty("end")) {
                  let endCompareDate = null;

                  if (rules["date"]["end"].hasOwnProperty("moment")) {
                    endCompareDate = parseMomentFunc(rules["date"].end.moment);
                  } else {
                    endCompareDate = Moment({
                      year: rules["date"].end.year,
                      month: rules["date"].end.month - 1,
                      day: rules["date"].end.day
                    });
                  }

                  // Check if given date happens after set date
                  if (inputDate.isAfter(endCompareDate)) {
                    const endingDate = endCompareDate.format(
                      rules["date"].format
                    );

                    validationErrors.push({
                      field: prop,
                      row: newIndex,
                      value: cellValue,
                      msg: `Date should be before or equal to ${endingDate}`
                    });
                  }
                }
              } else {
                validationErrors.push({
                  field: prop,
                  row: newIndex,
                  value: cellValue,
                  msg: `"${cellValue}" is an invalid date`
                });
              }
            }
          }
        }

        // Check numeric range
        if (rules.hasOwnProperty("range")) {
          if (rules["range"].hasOwnProperty("start")) {
            if (Math.abs(cellValue) < Math.abs(rules["range"].start)) {
              validationErrors.push({
                field: prop,
                row: newIndex,
                value: cellValue,
                msg: `Number outside range: [${rules["range"].start}, ${rules["range"].end}]`
              });
            }

            if (Math.abs(cellValue) > Math.abs(rules["range"].end)) {
              validationErrors.push({
                field: prop,
                row: newIndex,
                value: cellValue,
                msg: `Number outside range: [${rules["range"].start}, ${rules["range"].end}]`
              });
            }
          }
        }

        // Collect primary circuit numbers to check if they are the same in every row
        if (prop.match(/^OHPrimaryCircuitNumber_/) !== null || prop.match(/^Circuit_PTM$/)) {
          primaryCircuitNumbers.push(cellValue);
        }

        // Collect purchase order numbers to check if they are the same in every row
        if (prop.match(/^PO_/) !== null) {
          purchaseOrderNumbers.push(cellValue);
        }

        // Collect global pole position string to look for duplicates
        if (prop.match(/^GLNX-GLNY_/) !== null) {
          globalPolePosition.push(cellValue);
        }

        // Collect global ids to look for duplicates
        if (prop.match(/^GlobalID_/) !== null) {
          globalIds.push(cellValue);
        }
      }
    }
  };

  const processLine = (csv) => {
    // Force types
    const newCsv = Object.keys(Types).map((k, i) => {
      let hold = csv[i];

      if (typeof hold !== Types[k]) {
        // Values can come as undefined
        hold = hold ? hold : "";

        switch (Types[k]) {
          case "string":
            hold = `${hold.trim()}`;
            break;
          case "number":
            const holdToString = hold.toString().trim();

            // Did this because Number evaluates blank as 0
            if (holdToString.length) {
              const test = Number(hold);

              hold = isNaN(test) ? hold : test;
            } else {
              hold = holdToString;
            }
            break;
          default:
        }
      }

      return hold;
    });

    return Object.fromEntries(
      Source.fields.map((_, i) => [Source.fields[i], newCsv[i]])
    );
  };

  const runComplexRules = (row, index) => {
    // To mimic the spreadsheet row numbers
    const newIndex = index + 1;

    const populateDataObj = (data, row) => {
      let sample = {};
      const dataKeys = Object.keys(data);

      for (let i = 0; i < dataKeys.length; i++) {
        sample[dataKeys[i]] = row[dataKeys[i]];
      }

      return sample;
    };

    const execRule = (rule) => {
      if (rule.active) {
        const dataSample = populateDataObj(rule.rules.data, row);
        const res = jsonLogic.apply(rule.rules.logic, dataSample);

        if (!res) {
          complexRuleErrors.push({
            name: rule.name,
            description: rule.description,
            row: newIndex,
            values: dataSample,
            display: rule.display
          });
        }

        return { result: res };
      }
    };

    ComplexRules.map((r) => execRule(r));
  };

  await csv({
    colParser: Types,
    checkType: true,
    trim: true,
    checkColumn: true,
    output: "csv"
  })
    .fromString(csvContent)
    .on("header", async (header) => {
      // Check for missing columns
      missingFields = compareKeys(header, Source.fields);

      // Check for unknown columns
      unknownFields = checkUnknownKeys(header, Source.fields);

      // Load fields to validate against
      Fields = getFieldsToValidate(Source.fields, missingFields);
    })
    .subscribe((csv, index) => {
      // Keep count of processed rows
      processedRows += 1;

      // This was necessary because subscribe json messes up the resulting keys
      const jsonObj = processLine(csv);

      // Validate every row
      if (!missingFields.length && !unknownFields.length) {
        validateData(jsonObj, index);

        // Process complex rules if no validation errors
        if (!validationErrors[index]) {
          runComplexRules(jsonObj, index);
        }
      }
    });

  const reduceArray = (arr) => {
    const uniqueSet = new Set(arr);

    return [...uniqueSet];
  };

  const findDuplicatesInArray = (arr) => {
    return arr.reduce((acc, el, i, arr) => {
      if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el);
      return acc;
    }, []);
  };

  const distinctPrimaryCircuitNumbers = reduceArray(primaryCircuitNumbers);
  const distinctPurchaseOrderNumbers = reduceArray(purchaseOrderNumbers);
  const duplicateGlobalPolePosition = findDuplicatesInArray(globalPolePosition);
  const duplicateGlobalIds = findDuplicatesInArray(globalIds);

  const displayComplexRuleErrors =
    _.filter(complexRuleErrors, {
      display: true
    }) || [];

  return validationErrors.length ||
    missingFields.length ||
    unknownFields.length ||
    distinctPrimaryCircuitNumbers.length > 1 ||
    distinctPurchaseOrderNumbers.length > 1 ||
    duplicateGlobalPolePosition.length ||
    duplicateGlobalIds.length ||
    displayComplexRuleErrors.length
    ? {
        result: false,
        errors: {
          processedRows,
          validation: validationErrors,
          missingFields,
          unknownFields,
          distinctPrimaryCircuitNumbers,
          distinctPurchaseOrderNumbers,
          duplicateGlobalPolePosition,
          duplicateGlobalIds,
          displayComplexRuleErrors
        }
      }
    : {
        result: true,
        info: {
          processedRows,
          distinctPrimaryCircuitNumbers,
          distinctPurchaseOrderNumbers,
          duplicateGlobalPolePosition,
          duplicateGlobalIds,
          complexRuleErrors
        }
      };
};

export default parseCsv;
