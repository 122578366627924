import React from "react";
import Uploader from "../components/Uploader";
import "./Upload.css";

const Upload = props => {
  return (
    <div className="Upload">
      <div className="container">
        <Uploader {...props} />
      </div>
    </div>
  );
};

export default Upload;
