import { useEffect, useState } from "react";
import { defer } from "rxjs";
import { getVendors } from "../services/userService";

export const useGetVendors = () => {
  const [vendors, setVendors] = useState(null);

  useEffect(() => {
    const fetchVendors = async () => {
      return await getVendors();
    };

    const subscription = defer(() => fetchVendors()).subscribe(setVendors);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return vendors;
};
