import React, { useState } from "react";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  CardText,
  ListGroup,
  ListGroupItem,
  Spinner
} from "reactstrap";
import { useGetOrgInquiries } from "./useGetOrgInquiries";
import {
  answerUserInquiry,
  fixInspectionDefects
} from "../services/poleReinfInspCustomServices";
import { parseAction, getActionHandler } from "../models/verifyActionsHandlers";
import { MailMessage } from "../models/Message";
import {
  Email,
  Item,
  Span,
  Box,
  renderEmail,
  configStyleValidator
} from "react-html-email";
import { phraseToProperCase } from "../libs/case-utils";
import moment from "moment";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import "./ShowUserInquiries.css";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM/DD/YYYY HH:mm:ss";

configStyleValidator({
  strict: true,
  warn: true,
  platforms: [
    "gmail",
    "gmail-android",
    "apple-mail",
    "apple-ios",
    "yahoo-mail",
    "outlook",
    "outlook-legacy",
    "outlook-web"
  ]
});

const APP_NAME = "DTE Data Delivery";
const DEBUG_ON = false;
const getNewTimestamp = () => {
  return new Date().getTime();
};
const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;
// const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const ShowUserInquiries = (props) => {
  const { user, sendEmail } = props;

  const userInfo = {
    id: user.id,
    name: user.name,
    org: user.org,
    email: user.email
  };

  const [answered, setAnswered] = useState([]);

  const overrideMailContacts = user.role === "dev" || user.role === "admin";
  const inquiries = useGetOrgInquiries(userInfo.org);
  const pendingInquiries = inquiries ? inquiries.length - answered.length : 0;
  const isInquiries = inquiries ? (pendingInquiries ? true : false) : false;
  const inquiryPlural = isInquiries
    ? pendingInquiries > 1
      ? true
      : false
    : false;

  const ShowAdditionalInfo = (props) => {
    const { info } = props;

    return (
      <div>
        {Object.keys(info).map((k, i) => {
          return (
            <div key={i}>
              <b>{k}:</b> {info[k]}
            </div>
          );
        })}
      </div>
    );
  };

  const consolidateActions = (inquiry) => {
    const subjectActions = {};

    let items = inquiry.items;

    const criticalItems = items.filter(
      (item) => item.defect.cat === "critical"
    );

    if (criticalItems.length > 0) items = criticalItems;

    items.forEach((item) => {
      const actions = item.actions;
      const subjects = Object.keys(actions);

      subjects.forEach((subject) => {
        if (!subjectActions[subject]) subjectActions[subject] = [];

        if (actions[subject]) {
          subjectActions[subject] = [
            ...subjectActions[subject],
            ...actions[subject]
          ];

          // Make it unique
          subjectActions[subject] = [...new Set(subjectActions[subject])];
        }
      });
    });

    return { ...inquiry, subjectActions };
  };

  const genHtml = (inquiry, contact) => {
    const { scope, action, info, items, user, attempts, subjectActions } =
      inquiry;
    const inquirySubject = scope;
    const requestedAt = attempts[attempts.length - 1].at;
    const numItems = items.length;
    const isPlural = numItems > 1;
    const actions = items.map((item) => {
      return { ...item.actions };
    });
    const isActions = actions.length > 0;

    const comp = renderEmail(
      <Email title={`${APP_NAME}`}>
        <Box cellSpacing={10} width="100%">
          <Item>
            <Span fontSize={14}>
              {contact.name},<br />
              <br />
              Your request to fix {numItems} defect{isPlural ? "s" : ""} for the
              pole below has received a response.
              <br />
              <br />
            </Span>
          </Item>
        </Box>
        <Box cellSpacing={10} width="100%">
          <Item>
            <Span fontSize={14}>
              <b>Request details:</b>
            </Span>
          </Item>
        </Box>
        <Box cellSpacing={10} width="100%" style={{ border: "1px solid gray" }}>
          <Item>
            <Span fontSize={14}>
              <b>Scope:</b> {phraseToProperCase(scope)}
            </Span>
          </Item>
          <Item>
            <Span fontSize={14}>
              <b>Action:</b> {phraseToProperCase(action)}
            </Span>
          </Item>
          <Item>
            <Span fontSize={14}>
              <b>Circuit:</b> {info["circuit"]}
            </Span>
          </Item>
          <Item>
            <Span fontSize={14}>
              <b>
                Pole <small>(GLNX-GLNY)</small>:
              </b>{" "}
              {info["GLNX-GLNY"]}
            </Span>
          </Item>
          <Item>
            <Span fontSize={14}>
              <b>Requested to:</b>
              <br />
              {user.name} ({user.org}) @{" "}
              {moment(requestedAt).format("MM/DD/YYYY HH:mm:s")}
            </Span>
          </Item>
        </Box>
        {isActions && (
          <>
            <Box cellSpacing={10} width="100%">
              <Item>
                <Span fontSize={14}>
                  <b>
                    List of defects fixed by the {phraseToProperCase(scope)}{" "}
                    vendor:
                  </b>
                </Span>
              </Item>
            </Box>
            {Object.keys(subjectActions).map((subject, i) => {
              const actions = subjectActions[subject];

              return (
                actions.length > 0 && (
                  <Box
                    key={i}
                    cellSpacing={10}
                    width="100%"
                    style={{ border: "1px solid gray" }}
                  >
                    <Item>
                      <Span fontSize={14}>
                        <b>
                          {phraseToProperCase(inquirySubject)}{" "}
                          {phraseToProperCase(subject)}:
                        </b>
                      </Span>
                    </Item>
                    {actions.map((action, j) => {
                      const parsedAction = parseAction(action);

                      return (
                        <div key={j}>
                          <Item key={`${i}-${j}`}>
                            <Span fontSize={13}>
                              {j + 1}. {parsedAction.statement}
                            </Span>
                          </Item>
                        </div>
                      );
                    })}
                  </Box>
                )
              );
            })}
          </>
        )}
      </Email>
    );

    return comp;
  };

  const callback = (response) => {
    // if (response.status === 202) setIsSubmitting(false);
  };

  const overrideContacts = (contacts) => {
    if (overrideMailContacts) {
      return [
        {
          name: user.name,
          address: user.email
        }
      ];
    } else {
      return contacts;
    }
  };

  const sendEmailNotification = async (inquiry) => {
    const attempts = inquiry.attempts;
    const contact = attempts[attempts.length - 1].by;
    const info = inquiry.info;

    const contacts = overrideContacts([
      {
        name: contact.name,
        address: contact.email
      }
    ]);

    // Set mail delegate
    const fromRecipient = {
      name: userInfo.name,
      address: userInfo.email
    };

    const toRecipients = contacts;

    // Set message subject
    const subject = `${APP_NAME}: Reinf. Defects: ${info["circuit"]}: ${info["GLNX-GLNY"]}`;

    const consolidatedInquiry = consolidateActions(inquiry);

    // Set message body
    const body = {
      contentType: "HTML",
      content: genHtml(consolidatedInquiry, contact)
    };

    // Set one attachment
    const attachments = [];

    // Set Cc list
    const ccRecipients = [];

    // Set Bcc list
    const BccRecipients = [];

    const message = MailMessage(
      fromRecipient,
      toRecipients,
      subject,
      body,
      attachments,
      ccRecipients,
      BccRecipients,
      true
    );

    // Send email request
    sendEmail(message, callback);
  };

  const PostedByField = (props) => {
    const { attempts } = props;
    const last = attempts[attempts.length - 1];

    return (
      <>
        <b>Posted by:</b>
        <br /> {last.by.name} {" @ "}
        <Moment>{last.at}</Moment>
      </>
    );
  };

  const ShowInquiry = (props) => {
    const { inquiry, index } = props;

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isResponding, setIsResponding] = useState(false);
    const [isErrors, setIsErrors] = useState(null);
    const [errors, setErrors] = useState(null);

    const scope = inquiry.scope;
    const subjectActions = inquiry.subjectActions;
    const subjects = Object.keys(subjectActions);

    const checkHandlers = async () => {
      const errors = [];

      // Find & evaluate handlers
      for (let i = 0; i < subjects.length; i++) {
        const subject = subjects[i];
        const actions = subjectActions[subject];

        for (let j = 0; j < actions.length; j++) {
          const action = actions[j];

          const parsedAction = parseAction(action);

          if (parsedAction.checks) {
            const checks = parsedAction.checks.split(",");

            for (let k = 0; k < checks.length; k++) {
              let params = {};
              let handler = null;

              const check = checks[k];

              switch (check) {
                case "pitPoleStatusNonRestorableReject":
                  params = {
                    dataPointId: inquiry.info["GLNX-GLNY"],
                    fieldset: scope,
                    circuit: inquiry.info["circuit"]
                  };

                  handler = await getActionHandler(check)(params);

                  if (handler !== true) {
                    errors.push({
                      message: "PoleStatus_PIT is not up-to-date"
                    });
                  }
                  break;
                case "ripRemovePole":
                  params = {
                    dataPointId: inquiry.info["GLNX-GLNY"],
                    fieldset: scope,
                    circuit: inquiry.info["circuit"]
                  };

                  handler = await getActionHandler(check)(params);

                  if (handler !== true) {
                    errors.push({
                      message: "Pole was not removed from RIP data"
                    });
                  }
                  break;
                default:
                // Do nothing
              }
            }
          }
        }
      }

      if (errors.length === 0) {
        answerInquiry();
      } else {
        setErrors(errors);
        setIsErrors(true);
      }
    };

    const answerInquiry = async () => {
      const inquiryId = inquiry.inquiryId;
      const scope = inquiry.scope;
      const inspectionId = inquiry.key.value;
      const criticalItems = inquiry.items.filter(
        (item) => item.defect.cat === "critical"
      );
      const isCritical = criticalItems.length > 0;

      const now = getNewTimestamp();
      const res = [];

      setIsSubmitting(true);

      res["answer"] = await answerUserInquiry(
        inquiryId,
        userInfo,
        now,
        isCritical
      );

      if (res["answer"].status === 200) {
        res["fix"] = await fixInspectionDefects(
          inspectionId,
          scope,
          now,
          isCritical
        );

        if (res["fix"].status === 200) {
          setAnswered([...answered, inquiryId]);

          await sendEmailNotification(inquiry);
        }
      }

      setIsSubmitting(false);
    };

    const handleNotYet = () => {
      setIsResponding(false);
      setIsErrors(false);
      setErrors(null);
    };

    return (
      <>
        <Card className="mb-1rem">
          <CardHeader className="bg-primary text-white">
            <b>{phraseToProperCase(inquiry.scope)}</b>
            {inquiryPlural && (
              <i>
                <small>
                  {" "}
                  ({index + 1} of {pendingInquiries})
                </small>
              </i>
            )}
          </CardHeader>
          <CardBody>
            <CardText>
              <b>Action: </b>
              {phraseToProperCase(inquiry.action)}
            </CardText>
            <CardText>
              <b>Additional info: </b>
            </CardText>
            <Card body className="mb-1rem">
              <ShowAdditionalInfo info={inquiry.info} />
            </Card>
            {!isErrors && (
              <>
                <CardText>
                  <b>Requested actions: </b>
                </CardText>
                <ListGroup>
                  {subjects.map((subject, i) => {
                    return (
                      subjectActions[subject].length > 0 && (
                        <ListGroupItem key={i}>
                          <CardText>
                            <b>
                              {phraseToProperCase(
                                `${inquiry.scope} ${subject}`
                              )}
                            </b>
                          </CardText>
                          <ol>
                            {subjectActions[subject].map((action, i) => {
                              const parsedAction = parseAction(action);

                              return <li key={i}>{parsedAction.statement}</li>;
                            })}
                          </ol>
                        </ListGroupItem>
                      )
                    );
                  })}
                </ListGroup>
              </>
            )}
            {isErrors && (
              <Card>
                <CardHeader className="bg-danger text-white">
                  <b>Requested Actions Not Completed</b>
                </CardHeader>
                <CardBody>
                  <ListGroup>
                    {errors.map((error, i) => {
                      return (
                        <ListGroupItem key={i}>{error.message}</ListGroupItem>
                      );
                    })}
                  </ListGroup>
                </CardBody>
              </Card>
            )}
            <Card
              body
              inverse
              style={{
                backgroundColor: "#f7f7f7",
                marginTop: "1.5rem"
              }}
            >
              <CardText style={{ color: "black" }}>
                <PostedByField attempts={inquiry.attempts} />
              </CardText>
            </Card>
          </CardBody>
          <CardFooter>
            {!isResponding && (
              <Button
                color="primary"
                size="sm"
                onClick={() => setIsResponding(true)}
              >
                <b>Respond to request</b>
              </Button>
            )}
            {isResponding && (
              <>
                <CardText>
                  <b>Are all defects fixed?</b>
                  <br />
                  <small>
                    <i>
                      Your response will be submitted only if you state that all
                      defects are fixed.
                    </i>
                  </small>
                </CardText>
                <Button
                  color="success"
                  size="sm"
                  onClick={checkHandlers}
                  disabled={isSubmitting || isErrors}
                >
                  <b>Yes, they are fixed</b>{" "}
                  {isSubmitting && <Spinner size="sm" color="light" />}
                </Button>
                <Button
                  className="ml-05rem"
                  color="warning"
                  size="sm"
                  onClick={handleNotYet}
                >
                  <b>Not yet</b>
                </Button>
              </>
            )}
          </CardFooter>
        </Card>
      </>
    );
  };

  return isInquiries ? (
    <div className="ShowUserInquiries">
      <Card className="card-margins">
        <CardHeader tag="h4" className="bg-danger text-white">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <span className="ml-075rem">Action Required</span>
        </CardHeader>
        <CardBody>
          <CardText>
            You have {pendingInquiries} unanswered inquir
            {inquiryPlural ? "ies" : "y"} from the project manager.
          </CardText>
          {inquiries.map((inquiry, i) => {
            const consolidatedInquiry = consolidateActions(inquiry);

            return (
              !answered.includes(inquiry.inquiryId) && (
                <ShowInquiry key={i} index={i} inquiry={consolidatedInquiry} />
              )
            );
          })}
        </CardBody>
      </Card>
      {DEBUG_ON && (
        <Card className="mt-1rem">
          <CardHeader tag="h5">DEBUG</CardHeader>
          <CardBody className="overflow-500">
            <Json data={inquiries} />
          </CardBody>
        </Card>
      )}
    </div>
  ) : (
    ""
  );
};

export default ShowUserInquiries;
