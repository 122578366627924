import { bulkGet } from "../services/bulkServices";

const handlers = {
  pitPoleStatusNonRestorableReject: async (params) => {
    const { dataPointId, fieldset, circuit } = params;

    const query = {
      collection: "DataFiles",
      filter: {
        dataPointId,
        fieldset,
        circuit
      },
      sort: {},
      project: {
        _id: 0,
        "document.PoleStatus": 1
      },
      confirm: true
    };

    let ret = null;
    const res = await bulkGet(query);

    if (res.status === 200) {
      const poleStatus =
        res.response.length > 0 ? res.response[0].document.PoleStatus : "";

      // Check PIT pole status
      ret = poleStatus === "Non-Restorable Reject";
    }

    return ret;
  },
  ripRemovePole: async (params) => {
    const { dataPointId, fieldset, circuit } = params;

    const query = {
      collection: "DataFiles",
      filter: {
        dataPointId,
        fieldset,
        circuit
      },
      sort: {},
      project: {
        _id: 0,
        dataPointId: 1
      },
      confirm: true
    };

    let ret = null;
    const res = await bulkGet(query);

    if (res.status === 200) {
      // Check pole was found
      ret = res.response.length === 0;
    }

    return ret;
  }
};

const getActionHandlers = () => {
  return handlers;
};

const getActionHandler = (handler) => {
  return handlers[handler];
};

const parseAction = (action) => {
  const [statement, checks] = action.split("|");

  return { statement, checks };
};

export { parseAction, getActionHandlers, getActionHandler };
