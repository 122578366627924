import axios from "axios";
import { config } from "./config";

const stage = config.stage;
const settings = config[stage].appService;

const createField = async (doc) => {
  const url = `${settings.baseUrl}/api/fieldInsert?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "POST",
    url: url,
    data: doc
  });

  return data;
};

const getField = async (query) => {
  const url = `${settings.baseUrl}/api/fieldGet?code=${settings.hostKey}`;

  const { status, data } = await axios({
    method: "GET",
    url: url,
    params: query
  });

  return { status, data };
};

const getFields = async (query) => {
  const url = `${settings.baseUrl}/api/fieldsList?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: query
  });

  return data;
};

const getSomeFieldsObj = async (fields) => {
  const url = `${settings.baseUrl}/api/fieldsSome?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "POST",
    url: url,
    data: fields
  });

  // Turn array into an obj indexed by fieldKey
  let newObj = {};
  newObj = Object.fromEntries(data.map((o) => [o.fieldKey, o]));

  return newObj;
};

const getFlexibleFields = async (query) => {
  const url = `${settings.baseUrl}/api/fieldsFlexibleList?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: query
  });

  return data;
};

const updateField = async (query, doc) => {
  const url = `${settings.baseUrl}/api/fieldUpdate?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "PUT",
    url: url,
    params: query,
    data: doc
  });

  return data;
};

export {
  createField,
  getField,
  getFields,
  getSomeFieldsObj,
  getFlexibleFields,
  updateField
};
