import React, { useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  CustomInput,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Spinner
} from "reactstrap";
import { updateDataFileEntry } from "../services/dataFileService";
import {
  updateReinfInspection,
  getVendorUploadsByType,
  postUserInquiries,
  pushNewAttemptToInquiry,
  getInspectionInquiries
} from "../services/poleReinfInspCustomServices";
import { emailBillingCategories } from "../models/qaSurvey";
import { parseAction } from "../models/verifyActionsHandlers";
import { useGetUserInquiryByInspId } from "./useGetUserInquiryByInspId";
import { MailMessage } from "../models/Message";
import {
  Email,
  Item,
  Span,
  Box,
  renderEmail,
  configStyleValidator
} from "react-html-email";
import { phraseToProperCase } from "../libs/case-utils";
import moment from "moment";
import Moment from "react-moment";
import { v4 as uuidv4 } from "uuid";
import "./PoleReinforcementInspectionResolve.css";

const APP_NAME = "DTE Data Delivery";
const FIELD_SET = "poleReinforcementAudit";
const TASK_NAME = "poleReinforcementAudit";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM/DD/YYYY HH:mm:ss";

configStyleValidator({
  strict: true,
  warn: true,
  platforms: [
    "gmail",
    "gmail-android",
    "apple-mail",
    "apple-ios",
    "yahoo-mail",
    "outlook",
    "outlook-legacy",
    "outlook-web"
  ]
});

const getNewTimestamp = () => {
  return new Date().getTime();
};

// const delay = (ms) => new Promise((res) => setTimeout(res, ms));
// const Json = ({ data }) => (
//   <pre className="mt-1rem">{JSON.stringify(data, null, 4)}</pre>
// );

const PoleReinforcementInspectionResolve = (props) => {
  const {
    user,
    userInfo,
    overrideMailContacts,
    inspection,
    handleLoadInspections,
    sendEmail
  } = props;

  const results = inspection.results;
  const defectFields = results.defects?.list;
  const fixFields = results.defects.fixes;
  const metadata = results.defects?.metadata;
  const actions = results.defects?.actions;
  let approveBilling = results.billing?.approve ?? null;
  let rejectBilling = results.billing?.reject ?? null;
  let reinspectDefects = results.billing?.reinspect ?? null;
  let isNotFixables = false;

  const getBillingCategories = () => {
    const cats = {};

    Object.keys(actions).forEach((field) => {
      const action =
        actions[field].onResolved.reinforcementInspector.onApproved;

      cats[field] = action.invoices.category;
    });

    return cats;
  };

  const billingCategories = getBillingCategories();
  const [isBillingValid, setIsBillingValid] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResolving, setIsResolving] = useState(false);
  const [isFixing, setIsFixing] = useState(fixFields === null);

  // Collect critical items
  const criticalItems = defectFields
    ? defectFields.filter((field) => metadata[field].cat === "critical")
    : [];
  const areThereCriticalItems = criticalItems.length > 0;

  // Collect non-critical items
  const nonCriticalItems = defectFields
    ? defectFields.filter(
        (field) => metadata[field].cat !== "critical" && actions[field].fixable
      )
    : [];
  const areThereNonCriticalItems = nonCriticalItems.length > 0;

  const [vendorInfo, setVendorInfo] = useState(null);

  const getVendorInfo = async () => {
    if (vendorInfo) {
      return vendorInfo;
    } else {
      const res = await getVendorUploadsByType(inspection.circuit);

      if (res.status === 200) {
        if (res.response.length > 0) {
          setVendorInfo(res.response);

          return res.response;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  };

  const genRequestFixesFrom = (items) => {
    const fixes = [];

    items.forEach(async (field) => {
      const actionsByType = actions[field].onDefect.vendor;

      const fixed = Object.fromEntries(
        Object.keys(actionsByType).map((type) => [type, false])
      );

      const fixedAt = Object.fromEntries(
        Object.keys(actionsByType).map((type) => [type, null])
      );

      const types = Object.keys(actionsByType);
      const allContacts = await getVendorInfo();
      const requestedTo = allContacts.filter((contact) =>
        types.includes(contact.type)
      );

      fixes.push({
        field,
        defect: metadata[field],
        actions: actionsByType,
        fixed,
        fixedAt,
        requestedBy: userInfo,
        requestedAt: getNewTimestamp(),
        requestedTo
      });
    });

    return fixes;
  };

  const setDefaultRequestFixes = () => {
    if (areThereCriticalItems) {
      return genRequestFixesFrom(criticalItems);
    } else {
      if (areThereNonCriticalItems) {
        return genRequestFixesFrom(nonCriticalItems);
      }

      return fixFields;
    }
  };

  const [requestFixes, setRequestFixes] = useState(setDefaultRequestFixes());
  const [oneReinspection, setOneReinspection] = useState(false);

  const getDefDefectActions = () => {
    const defActions = defectFields.reduce((a, v) => {
      // const action = metadata[v].cat === "critical" ? "reject" : null;
      const action = null;
      const fixable = actions[v].fixable;
      const critical = metadata[v].cat === "critical";

      if (!fixable) isNotFixables = true;

      return { ...a, [v]: { action, fixable, critical } };
    }, {});

    // let retActions = defActions;

    // // Clean up if criticals
    // if (areThereCriticalItems) {
    //   retActions = {};

    //   Object.keys(defActions).forEach((key) => {
    //     if (defActions[key].critical) retActions[key] = defActions[key];
    //   });
    // }

    return defActions;
  };

  const [defectActions, setDefectActions] = useState(getDefDefectActions());

  const [isApproved, setIsApproved] = useState(inspection.approved);
  const [status, setStatus] = useState(inspection.status);

  const isInspect = status === "inspect";
  const isReinspect = status === "reinspect";
  const statusLabel = isInspect ? "inspect" : status;
  const document = inspection.document ?? null;

  const areDefectsFixed = () => {
    let fixed = true;

    const isFixed = (obj) => {
      return Object.values(obj).every((v) => v === true);
    };

    if (fixFields) {
      for (let i = 0; i < fixFields.length; i++) {
        if (!isFixed(fixFields[i].fixed)) {
          if (areThereCriticalItems) {
            if (criticalItems.includes(fixFields[i].field)) {
              return false;
            }
          } else {
            return false;
          }
        }
      }
    } else {
      return fixed;
    }

    return fixed;
  };

  const [isDefectFixes, setIsDefectFixes] = useState(
    fixFields && fixFields.length > 0 ? true : false
  );
  const [showDefectFixes, setShowDefectFixes] = useState(isDefectFixes);
  const [isDefectsFixed, setIsDefectsFixed] = useState(areDefectsFixed());

  const allowResolution =
    !isApproved && !(isInspect || isReinspect) && isDefectsFixed !== false;
  const [verifyFixes, setVerifyFixes] = useState(
    areThereCriticalItems && isDefectFixes && isDefectsFixed
  );
  const [skipReinspection, setSkipReinspection] = useState(false);

  const inquiries = useGetUserInquiryByInspId(inspection.inspectionId, false);

  let classStatus = "";

  // Assign status color
  switch (status) {
    case "submitted":
      classStatus = "success";
      break;
    case "submitted with warnings":
      classStatus = "warning";
      break;
    case "submitted with defects":
      classStatus = "danger";
      break;
    default:
      classStatus = "primary";
  }

  const overrideContacts = (contacts) => {
    if (overrideMailContacts) {
      return [
        {
          name: user.name,
          address: user.email
        }
      ];
    } else {
      return contacts;
    }
  };

  const genHtmlToVendor = (inquiry, contact) => {
    const { scope, action, info, items, attempts, subjectActions } = inquiry;
    const inquirySubject = scope;
    const numAttempts = attempts.length;
    const requested = attempts[numAttempts - 1];
    const numItems = items.length;
    const isPlural = numItems > 1;
    const actions = items.map((item) => {
      return { ...item.actions };
    });
    const isActions = actions.length > 0;

    const comp = renderEmail(
      <Email title={`${APP_NAME}`}>
        <Box cellSpacing={10} width="100%">
          <Item>
            <Span fontSize={14}>
              {contact.name},<br />
              <br />
              You are required to fix {numItems} defect{isPlural ? "s" : ""} for
              the pole below.
              <br />
              <br />
            </Span>
          </Item>
        </Box>
        <Box cellSpacing={10} width="100%">
          <Item>
            <Span fontSize={14}>
              <b>Request details:</b>
            </Span>
          </Item>
        </Box>
        <Box cellSpacing={10} width="100%" style={{ border: "1px solid gray" }}>
          <Item>
            <Span fontSize={14}>
              <b>Scope:</b> {phraseToProperCase(scope)}
            </Span>
          </Item>
          <Item>
            <Span fontSize={14}>
              <b>Action:</b> {phraseToProperCase(action)}
            </Span>
          </Item>
          <Item>
            <Span fontSize={14}>
              <b>Circuit:</b> {info["circuit"]}
            </Span>
          </Item>
          <Item>
            <Span fontSize={14}>
              <b>
                Pole <small>(GLNX-GLNY)</small>:
              </b>{" "}
              {info["GLNX-GLNY"]}
            </Span>
          </Item>
          <Item>
            <Span fontSize={14}>
              <b>Requested by:</b>
              <br />
              {requested.by.name} @{" "}
              {moment(requested.at).format("MM/DD/YYYY HH:mm:s")}
            </Span>
          </Item>
        </Box>
        {isActions && (
          <>
            <Box cellSpacing={10} width="100%">
              <Item>
                <Span fontSize={14}>
                  <b>
                    List of defects to fix by the{" "}
                    {phraseToProperCase(inquirySubject)} vendor:
                  </b>
                </Span>
              </Item>
            </Box>
            {Object.keys(subjectActions).map((subject, i) => {
              const actions = subjectActions[subject];

              return (
                actions.length > 0 && (
                  <Box
                    key={i}
                    cellSpacing={10}
                    width="100%"
                    style={{ border: "1px solid gray" }}
                  >
                    <Item>
                      <Span fontSize={14}>
                        <b>
                          {phraseToProperCase(inquirySubject)}{" "}
                          {phraseToProperCase(subject)}:
                        </b>
                      </Span>
                    </Item>
                    {actions.map((action, j) => {
                      const parsedAction = parseAction(action);

                      return (
                        <div key={j}>
                          <Item key={`${i}-${j}`}>
                            <Span fontSize={13}>
                              {j + 1}. {parsedAction.statement}
                            </Span>
                          </Item>
                        </div>
                      );
                    })}
                  </Box>
                )
              );
            })}
          </>
        )}
      </Email>
    );

    return comp;
  };

  const genHtmlToInspector = (info) => {
    const comp = renderEmail(
      <Email title={`${APP_NAME}`}>
        <Box cellSpacing={10} width="100%">
          <Item>
            <Span fontSize={14}>
              To the reinforcement inspector,
              <br />
              <br />
              Actions required for the following pole.
              <br />
              <br />
            </Span>
          </Item>
        </Box>
        <Box cellSpacing={10} width="100%">
          <Item>
            <Span fontSize={14}>
              <b>Request details:</b>
            </Span>
          </Item>
        </Box>
        <Box cellSpacing={10} width="100%" style={{ border: "1px solid gray" }}>
          <Item>
            <Span fontSize={14}>
              <b>Circuit:</b> {info["circuit"]}
            </Span>
          </Item>
          <Item>
            <Span fontSize={14}>
              <b>
                Pole <small>(GLNX-GLNY)</small>:
              </b>{" "}
              {info["GLNX-GLNY"]}
            </Span>
          </Item>
          <Item>
            <Span fontSize={14}>
              <b>Requested by:</b>
              <br />
              {info.requestedBy.name} @{" "}
              {moment(info.requestedAt).format("MM/DD/YYYY HH:mm:s")}
            </Span>
          </Item>
        </Box>
        <Box cellSpacing={10} width="100%">
          <Item>
            <Span fontSize={14}>
              <b>Actions required by inspector:</b>
            </Span>
          </Item>
        </Box>
        <Box cellSpacing={10} width="100%" style={{ border: "1px solid gray" }}>
          {info.actions.map((action, i) => {
            return (
              <Item key={i}>
                <Span fontSize={13}>
                  {i + 1}. {action}
                </Span>
              </Item>
            );
          })}
        </Box>
      </Email>
    );

    return comp;
  };

  const callback = (response) => {
    if (response.status === 202) setIsSubmitting(false);
  };

  const consolidateActions = (inquiry) => {
    const subjectActions = {};
    let items = inquiry.items;

    if (areThereCriticalItems) {
      items = inquiry.items.filter((item) =>
        criticalItems.includes(item.field)
      );
    }

    items.forEach((item) => {
      const actions = item.actions;
      const subjects = Object.keys(actions);

      subjects.forEach((subject) => {
        if (!subjectActions[subject]) subjectActions[subject] = [];

        if (actions[subject]) {
          subjectActions[subject] = [
            ...subjectActions[subject],
            ...actions[subject]
          ];

          // Make it unique
          subjectActions[subject] = [...new Set(subjectActions[subject])];
        }
      });
    });

    return { ...inquiry, subjectActions };
  };

  const sendEmailNotificationToVendor = async (inquiry) => {
    const contact = inquiry.user;
    const info = inquiry.info;

    const contacts = overrideContacts([
      {
        name: contact.name,
        address: contact.email
      }
    ]);

    // Set mail delegate
    const fromRecipient = {
      name: userInfo.name,
      address: userInfo.email
    };

    const toRecipients = contacts;

    // Set message subject
    const subject = `${APP_NAME}: Reinf. Defects: ${info["circuit"]}: ${info["GLNX-GLNY"]}`;

    const consolidatedInquiry = consolidateActions(inquiry);

    // Set message body
    const body = {
      contentType: "HTML",
      content: genHtmlToVendor(consolidatedInquiry, contact)
    };

    // Set one attachment
    const attachments = [];

    // Set Cc list
    const ccRecipients = [];

    // Set Bcc list
    const BccRecipients = [];

    const message = MailMessage(
      fromRecipient,
      toRecipients,
      subject,
      body,
      attachments,
      ccRecipients,
      BccRecipients,
      true
    );

    // Send email request
    sendEmail(message, callback);
  };

  const sendEmailNotificationToInspector = async (request) => {
    const contact = request.contact;
    const info = request.info;

    // Set mail delegate
    const fromRecipient = {
      name: userInfo.name,
      address: userInfo.email
    };

    const toRecipients = contact;

    // Set message subject
    const subject = `${APP_NAME}: Reinf. Action Required: ${info["circuit"]}: ${info["GLNX-GLNY"]}`;

    // Set message body
    const body = {
      contentType: "HTML",
      content: genHtmlToInspector(info)
    };

    // Set one attachment
    const attachments = [];

    // Set Cc list
    const ccRecipients = [];

    // Set Bcc list
    const BccRecipients = [];

    const message = MailMessage(
      fromRecipient,
      toRecipients,
      subject,
      body,
      attachments,
      ccRecipients,
      BccRecipients,
      true
    );

    // Send email request
    sendEmail(message, callback);
  };

  const validateBillingCatStatus = (actions) => {
    const stati = {};
    let passed = true;

    Object.keys(actions).forEach((field) => {
      const cat = billingCategories[field];

      const action = actions[field].action;

      if (!stati[cat]) stati[cat] = [];

      if (action && !stati[cat].includes(action)) stati[cat].push(action);
    });

    if (stati.all && stati.all.length > 0) {
      Object.keys(stati).forEach((cat) => {
        if (cat !== "all") {
          const action = stati["all"][0];

          if (!stati[cat].includes(action)) stati[cat].push(action);
        }
      });
    }

    Object.keys(stati).forEach((field) => {
      if (stati[field].length > 1) passed = false;
    });

    return passed;
  };

  const handleApproval = (field, action) => {
    let actionsCopy = {};

    if (isDefectFixes) {
      if (areThereCriticalItems) {
        criticalItems.forEach((field) => {
          actionsCopy[field] = defectActions[field];
        });
      } else {
        fixFields.forEach((item) => {
          actionsCopy[item.field] = defectActions[item.field];
        });
      }
    } else {
      actionsCopy = { ...defectActions };
    }

    if (action === "reinspect") {
      Object.keys(actionsCopy).forEach(
        (field) => (actionsCopy[field].action = "reinspect")
      );

      setOneReinspection(true);
    } else {
      actionsCopy[field].action = action;
    }

    setDefectActions(actionsCopy);

    setIsBillingValid(validateBillingCatStatus(actionsCopy));
  };

  const checkFixRequest = (field) => {
    if (requestFixes) {
      const test = requestFixes.find((el) => el.field === field);

      return test !== undefined;
    } else {
      return false;
    }
  };

  const handleFixRequest = async (field, action) => {
    let fixes = requestFixes !== null ? [...requestFixes] : [];
    const actionsByType = actions[field].onDefect.vendor;

    switch (action) {
      case "fix":
        if (!checkFixRequest(field)) {
          const fixed = Object.fromEntries(
            Object.keys(actionsByType).map((type) => [type, false])
          );

          const fixedAt = Object.fromEntries(
            Object.keys(actionsByType).map((type) => [type, null])
          );

          const types = Object.keys(actionsByType);
          const allContacts = await getVendorInfo();
          const requestedTo = allContacts.filter((contact) =>
            types.includes(contact.type)
          );

          fixes.push({
            field,
            defect: metadata[field],
            actions: actionsByType,
            fixed,
            fixedAt,
            requestedBy: userInfo,
            requestedAt: getNewTimestamp(),
            requestedTo
          });

          setRequestFixes(fixes);
        }
        break;
      case "nofix":
        if (checkFixRequest(field)) {
          fixes = fixes.filter((item) => item.field !== field);

          setRequestFixes(fixes);
        }
        break;
      default:
      // Do nothing
    }
  };

  const handleResolution = async () => {
    const approvals = Object.keys(defectActions).filter(
      (field) => defectActions[field].action === "approve"
    );

    const rejections = Object.keys(defectActions).filter(
      (field) => defectActions[field].action === "reject"
    );

    const reinspections = Object.keys(defectActions).filter(
      (field) => defectActions[field].action === "reinspect"
    );

    const isResolved = reinspections.length === 0;
    const finalStatus = isResolved ? status : "reinspect";

    if (approvals.length) {
      approveBilling = {
        approvals,
        approvedBy: userInfo,
        approvedAt: getNewTimestamp()
      };
    }

    if (rejections.length) {
      rejectBilling = {
        rejections,
        rejectedBy: userInfo,
        rejectedAt: getNewTimestamp()
      };
    }

    if (reinspections.length) {
      reinspectDefects = {
        reinspections,
        orderedBy: userInfo,
        orderedAt: getNewTimestamp()
      };
    }

    setIsSubmitting(true);

    const res = [];

    try {
      // Update inspection
      res["updateInspection"] = await updateReinfInspection(
        inspection.inspectionId,
        {
          status: finalStatus,
          approved: isResolved,
          "results.billing": {
            approve: approveBilling,
            reject: rejectBilling,
            reinspect: reinspectDefects,
            metadata
          },
          notifiedReinspection: finalStatus === "reinspect" ? false : null,
          updatedBy: userInfo,
          updatedAt: getNewTimestamp()
        }
      );

      if (res["updateInspection"].status === 200) {
        // Update RIA document
        res["updateRia"] = await updateDataFileEntry(
          inspection.dataPointId,
          FIELD_SET,
          {
            status: finalStatus,
            approved: isResolved,
            notifiedReinspection: finalStatus === "reinspect" ? false : null,
            updatedBy: userInfo,
            updatedAt: getNewTimestamp()
          }
        );

        if (res["updateRia"].status === 200) {
          setStatus(finalStatus);
          setIsApproved(isResolved);
          setIsSubmitting(false);
          setIsResolving(false);

          handleLoadInspections(TASK_NAME);

          if (finalStatus !== "reinspect") {
            // Set approvals
            let actionableItems = approvals.map((field) => {
              const onApproved =
                actions[field].onResolved.reinforcementInspector.onApproved;
              const targetSubject = "invoices";
              const category = onApproved[targetSubject].category;

              return `Approve ${category} ${targetSubject}`;
            });

            // Set rejections
            actionableItems = [
              ...actionableItems,
              ...rejections.map((field) => {
                const onRejected =
                  actions[field].onResolved.reinforcementInspector.onRejected;
                const targetSubject = "invoices";
                const category = onRejected[targetSubject].category;

                return `Reject ${category} ${targetSubject}`;
              })
            ];

            const actionableItemsFindAll = actionableItems.filter((action) =>
              /all invoices$/.test(action)
            );

            if (actionableItemsFindAll.length > 0) {
              actionableItems = actionableItemsFindAll;
            }

            // Remove duplicate actions
            actionableItems = [...new Set(actionableItems)];

            // Consolidate to all for reject
            if (
              actionableItems.filter((action) =>
                /Reject .* invoices$/.test(action)
              ).length >= emailBillingCategories.length
            ) {
              actionableItems = ["Reject all invoices"];
            }

            // Consolidate to all for approve
            if (
              actionableItems.filter((action) =>
                /Approve .* invoices$/.test(action)
              ).length >= emailBillingCategories.length
            ) {
              actionableItems = ["Approve all invoices"];
            }

            // Send email to Reinforcement Inspector if resolved approved or rejected
            const contacts = [
              {
                name: inspection.user.name,
                address: inspection.user.email
              }
            ];

            const request = {
              contact: overrideContacts(contacts),
              info: {
                circuit: inspection.circuit,
                "GLNX-GLNY": inspection.dataPointId,
                actions: actionableItems,
                requestedBy: userInfo,
                requestedAt: getNewTimestamp()
              }
            };

            console.log("request", request);

            // Send email to Reinforcement Inspector if defects are resolved as approved or rejected
            await sendEmailNotificationToInspector(request);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const splitFixesBySource = (fixes) => {
    const fixesBySource = {};
    for (let i = 0; i < fixes.length; i++) {
      let fix = fixes[i];
      const sources = Object.keys(fix.actions);

      sources.forEach((source) => {
        if (!fixesBySource[source]) fixesBySource[source] = [];

        const requestedTo = fix.requestedTo.filter(
          (contact) => contact.type === source
        );

        fixesBySource[source].push({
          ...fix,
          actions: fix.actions[source],
          actionsSubject: source,
          fixed: fix.fixed[source],
          fixedAt: fix.fixedAt[source],
          requestedTo: requestedTo[0].contact
        });
      });
    }

    return fixesBySource;
  };

  // const handleSubmitFixRequestTest = async () => {
  //   const fixes = requestFixes !== null ? requestFixes : [];

  //   if (fixes.length > 0) {
  //     const userInquiries = [];
  //     const fixesBySource = splitFixesBySource(fixes);
  //     const now = getNewTimestamp();

  //     Object.keys(fixesBySource).forEach((source) => {
  //       const contacts = vendorInfo.filter(
  //         (contact) => contact.type === source
  //       );

  //       userInquiries.push({
  //         inquiryId: uuidv4(),
  //         user: contacts[0].contact,
  //         scope: source,
  //         key: { field: "inspectionId", value: inspection.inspectionId },
  //         info: {
  //           "GLNX-GLNY": inspection.dataPointId,
  //           circuit: inspection.circuit
  //         },
  //         action: "fixDefects",
  //         items: fixesBySource[source],
  //         postedBy: userInfo,
  //         postedAt: now,
  //         answered: false,
  //         answeredBy: null,
  //         answeredAt: null,
  //         attempts: [{ by: userInfo, at: now }]
  //       });
  //     });

  //     for (let i = 0; i < userInquiries.length; i++) {
  //       await sendEmailNotificationToVendor(userInquiries[i]);
  //     }
  //   }
  // };

  const handleSubmitFixRequest = async () => {
    const fixes = requestFixes !== null ? requestFixes : [];

    setRequestFixes(fixes);

    let res = [];
    const now = getNewTimestamp();

    // Update inspection
    res["inspections"] = await updateReinfInspection(inspection.inspectionId, {
      approved: false,
      "results.defects.fixes": fixes,
      updatedBy: userInfo,
      updatedAt: now
    });

    if (res["inspections"].status === 200 && fixes.length > 0) {
      const userInquiries = [];
      const fixesBySource = splitFixesBySource(fixes);

      Object.keys(fixesBySource).forEach((source) => {
        const contacts = vendorInfo.filter(
          (contact) => contact.type === source
        );

        userInquiries.push({
          inquiryId: uuidv4(),
          user: contacts[0].contact,
          scope: source,
          key: { field: "inspectionId", value: inspection.inspectionId },
          info: {
            "GLNX-GLNY": inspection.dataPointId,
            circuit: inspection.circuit
          },
          action: "fixDefects",
          items: fixesBySource[source],
          postedBy: userInfo,
          postedAt: now,
          answered: false,
          answeredBy: null,
          answeredAt: null,
          attempts: [{ by: userInfo, at: now }]
        });
      });

      for (let i = 0; i < userInquiries.length; i++) {
        res["users"] = await postUserInquiries([userInquiries[i]]);

        if (res["users"].status === 200) {
          await sendEmailNotificationToVendor(userInquiries[i]);
        }
      }

      setIsResolving(false);
      setIsFixing(false);
      setIsDefectsFixed(false);
      setShowDefectFixes(true);
    } else {
      setIsFixing(false);
      setIsDefectsFixed(true);
      setShowDefectFixes(false);
    }

    handleLoadInspections(TASK_NAME);
  };

  const handleReset = () => {
    setDefectActions(getDefDefectActions());
    setIsBillingValid(null);
    setOneReinspection(false);
  };

  const handleCancel = () => {
    handleReset();

    setIsResolving(false);
  };

  const validateResolution = () => {
    let passed = true;
    const fields = Object.keys(defectActions);

    for (let i = 0; i < fields.length; i++) {
      if (passed && defectActions[fields[i]].action === null) {
        passed = false;
        break;
      }
    }

    return passed && isBillingValid === true;
  };

  const ResolveView = () => {
    let fields = null;

    if (isDefectFixes) {
      if (areThereCriticalItems) {
        fields = criticalItems;
      } else {
        fields = fixFields.map((el) => el.field);
      }
    } else {
      fields = Object.keys(defectActions);
    }

    return (
      <Card body className="mt-1rem">
        {!skipReinspection && (
          <CardText style={{ fontSize: "1rem", fontWeight: "bold" }}>
            Check whether to reinspect or approve/reject billing per defect.
          </CardText>
        )}
        {skipReinspection && (
          <Alert
            color="danger"
            style={{ fontSize: "1rem", fontWeight: "bold" }}
          >
            This pole will be replaced. Approve/reject billing per defect
            accordingly.
          </Alert>
        )}
        <CardText className="mb-1rem">
          <i>
            <b>Notes:</b>
            <br />
            1. Approving or rejecting billing is disabled for all if any one
            defect needs reinspection.
            <br />
            2. Defects are categorized as Truss or Treatment based on the units
            in the contract.
            <br />
            3. If you approve a defect for one category, for example Truss, you
            must approve all defects for that category. The same applies for
            rejecting defects.
            <br />
            <br />
          </i>
        </CardText>
        {isBillingValid === false && (
          <Alert color="danger">
            <b>You can’t approve and reject the same contract unit</b>
          </Alert>
        )}
        {fields.map((field, i) => {
          const meta = metadata[field];
          const isCritical = meta.cat === "critical";

          const colorClass = isCritical ? "danger" : "warning";

          return (
            <div key={i}>
              <Badge color={colorClass}>
                <b>{phraseToProperCase(billingCategories[field])}</b>:{" "}
                {meta.question}
              </Badge>
              <br />
              <FormGroup check>
                {!skipReinspection && (
                  <>
                    <CustomInput
                      type="radio"
                      id={`${inspection.inspectionId}-reinspect-radio-${i}`}
                      name={`${inspection.inspectionId}-action-radio-${i}`}
                      label="Reinspect"
                      value="reinspect"
                      checked={defectActions[field].action === "reinspect"}
                      onChange={(e) => handleApproval(field, e.target.value)}
                      disabled={isSubmitting}
                      inline
                    />{" "}
                  </>
                )}
                {!oneReinspection && !isDefectFixes && (
                  <>
                    <CustomInput
                      type="radio"
                      id={`${inspection.inspectionId}-approve-radio-${i}`}
                      name={`${inspection.inspectionId}-action-radio-${i}`}
                      label="Approve"
                      value="approve"
                      checked={defectActions[field].action === "approve"}
                      onChange={(e) => handleApproval(field, e.target.value)}
                      disabled={isSubmitting}
                      inline
                    />{" "}
                    <CustomInput
                      type="radio"
                      id={`${inspection.inspectionId}-reject-radio-${i}`}
                      name={`${inspection.inspectionId}-action-radio-${i}`}
                      label="Reject"
                      value="reject"
                      checked={defectActions[field].action === "reject"}
                      onChange={(e) => handleApproval(field, e.target.value)}
                      disabled={isSubmitting}
                      inline
                    />
                  </>
                )}
              </FormGroup>
            </div>
          );
        })}
        <FormGroup className="mt-1rem mb-0rem">
          <Button
            color="primary"
            size="sm"
            onClick={() => handleResolution()}
            disabled={isSubmitting || !validateResolution()}
          >
            Apply resolution{" "}
            {isSubmitting && <Spinner size="sm" color="dark" />}
          </Button>{" "}
          <Button
            color="danger"
            size="sm"
            onClick={() => handleCancel()}
            disabled={isSubmitting}
          >
            Cancel
          </Button>{" "}
          <Button
            outline
            color="default"
            size="sm"
            onClick={() => handleReset()}
            disabled={isSubmitting}
          >
            Reset
          </Button>
        </FormGroup>
      </Card>
    );
  };

  const RequestFixes = () => {
    return (
      <Card body className="mt-1rem">
        <CardText style={{ fontSize: "1rem" }}>
          <b>Request fixes to vendor before approval</b>
        </CardText>
        <ul>
          <li>
            <small>
              By default, the recommended fixes have been pre-selected. These
              fixes have an effect on field re-work, data updates, and changes
              to invoicing. Do not make any changes unless you understand the
              impact.
            </small>
          </li>
          {isNotFixables && (
            <li>
              <small>
                Repairs for disabled items are managed outside the application.
                These are automatically selected as “Don't Fix”.
              </small>
            </li>
          )}
        </ul>
        {Object.keys(defectActions).map((field, i) => {
          const meta = metadata[field];
          const isCritical = meta.cat === "critical";
          const isFixable = defectActions[field].fixable;

          const colorClass = isCritical ? "danger" : "warning";

          return (
            <div key={i}>
              <Badge color={colorClass}>
                {meta.question}{" "}
                <i>({phraseToProperCase(billingCategories[field])})</i>
              </Badge>
              <br />
              <FormGroup check>
                <CustomInput
                  type="radio"
                  id={`${inspection.inspectionId}-fix-radio-${i}`}
                  name={`${inspection.inspectionId}-action-radio-${i}`}
                  label="Fix"
                  value="fix"
                  checked={requestFixes && checkFixRequest(field)}
                  onChange={(e) => handleFixRequest(field, e.target.value)}
                  disabled={isSubmitting || !isFixable}
                  inline
                />{" "}
                <CustomInput
                  type="radio"
                  id={`${inspection.inspectionId}-nofix-radio-${i}`}
                  name={`${inspection.inspectionId}-action-radio-${i}`}
                  label="Don't fix"
                  value="nofix"
                  checked={!requestFixes || !checkFixRequest(field)}
                  onChange={(e) => handleFixRequest(field, e.target.value)}
                  disabled={isSubmitting}
                  inline
                />
              </FormGroup>
            </div>
          );
        })}
        <FormGroup className="mt-1rem mb-0rem">
          <Button
            color="primary"
            size="sm"
            onClick={() => handleSubmitFixRequest()}
            disabled={isSubmitting}
          >
            Submit {isSubmitting && <Spinner size="sm" color="dark" />}
          </Button>{" "}
          {/* <Button
            color="warning"
            size="sm"
            onClick={() => handleSubmitFixRequestTest()}
            disabled={isSubmitting}
          >
            Submit (test) {isSubmitting && <Spinner size="sm" color="dark" />}
          </Button>{" "} */}
          <Button
            color="danger"
            size="sm"
            onClick={() => handleCancel()}
            disabled={isSubmitting}
          >
            Cancel
          </Button>{" "}
        </FormGroup>
      </Card>
    );
  };

  const ShowInquiry = (props) => {
    const { inquiry } = props;

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [notifiedOnce, setNotifiedOnce] = useState(false);

    const areFixed = inquiry.items
      .map((item) => item.fixed)
      .every((v) => v === true);

    const statusColor = areFixed ? "bg-success" : "bg-danger";
    const lastAttempt = inquiry
      ? inquiry.attempts[inquiry.attempts.length - 1]
      : null;
    const inquirySubject = inquiry.scope;

    const resendFixRequest = async () => {
      setIsSubmitting(true);

      // Add attempt to inquiry
      const res = await pushNewAttemptToInquiry(inquiry.inquiryId, {
        by: userInfo,
        at: getNewTimestamp()
      });

      if (res.status === 200) {
        await sendEmailNotificationToVendor(inquiry);

        setNotifiedOnce(true);
      }
    };

    let items = inquiry.items;

    if (areThereCriticalItems) {
      items = inquiry.items.filter((item) =>
        criticalItems.includes(item.field)
      );
    }

    return (
      <Card className="mt-1rem">
        <CardHeader
          tag="h5"
          className={`${statusColor} text-white font-weight-bold`}
        >
          Fixes requested to {phraseToProperCase(inquirySubject)} vendor
        </CardHeader>
        <CardBody>
          {areThereCriticalItems && (
            <Alert color="primary">
              <b>Important!</b>
              <br />
              When vendor is requested to fix critical defects, only critical
              defects are communicated, all others are suppressed in the email
              and below.
            </Alert>
          )}
          <ListGroup>
            {items.map((fix, i) => {
              const meta = metadata[fix.field];
              const isCritical = meta.cat === "critical";
              const questionColor = isCritical ? "danger" : "secondary";
              const isFixed = fix.fixed[inquirySubject];

              return (
                <ListGroupItem key={i}>
                  <CardText>
                    <b>Defect:</b>{" "}
                    <Badge pill color={questionColor}>
                      {meta.question}
                    </Badge>
                  </CardText>
                  <CardText>
                    <b>Inspector answered:</b> {meta.answer}
                  </CardText>
                  <CardText>
                    <b>Requested to:</b> {fix.requestedTo.name} (
                    {fix.requestedTo.org})
                  </CardText>
                  <CardText>
                    <b>Requested by:</b> {fix.requestedBy.name} @{" "}
                    <Moment>{fix.requestedAt}</Moment>
                  </CardText>
                  <CardText>
                    <b>Status:</b> {isFixed ? "Fixed" : "Not fixed"}
                  </CardText>
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </CardBody>
        {areFixed && inquiry && (
          <CardFooter>
            <CardText>
              <b>Answered by:</b>
            </CardText>
            <CardText>
              {inquiry.answeredBy.name} ({inquiry.answeredBy.org}) @{" "}
              <Moment>{inquiry.answeredAt}</Moment>
            </CardText>
          </CardFooter>
        )}
        {!notifiedOnce && !areFixed && inquiry && (
          <CardFooter>
            <Button
              color="danger"
              size="sm"
              onClick={() => resendFixRequest()}
              disabled={isSubmitting}
            >
              <b>Send request #{inquiry.attempts.length + 1} to vendor</b>{" "}
              {isSubmitting && <Spinner size="sm" color="light" />}
            </Button>
            {lastAttempt && (
              <CardText className="mt-1rem">
                <b>Last attempt by:</b>
                <br />
                {lastAttempt.by.name} @ <Moment>{lastAttempt.at}</Moment>
              </CardText>
            )}
          </CardFooter>
        )}
      </Card>
    );
  };

  const RequestedFixesView = () => {
    return (
      inquiries &&
      inquiries.map((inquiry, i) => {
        return <ShowInquiry key={i} inquiry={inquiry} />;
      })
    );
  };

  const VerifyFixes = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isShowing, setIsShowing] = useState(false);
    const [inquiries, setInquiries] = useState(null);

    const handleActions = async () => {
      setIsLoading(true);

      const ret = [];

      const res = await getInspectionInquiries(inspection.inspectionId, true);

      if (res.status === 200) {
        const results = res.response;

        for (let i = 0; i < results.length; i++) {
          const inquiry = results[i];
          const consolidated = consolidateActions(inquiry);

          ret.push({
            scope: consolidated.scope,
            actions: consolidated.subjectActions,
            answeredBy: consolidated.answeredBy,
            answeredAt: consolidated.answeredAt
          });
        }

        setInquiries(ret);
      }

      setIsLoading(false);
      setIsShowing(true);
    };

    const handleVerifyFixes = () => {
      setVerifyFixes(false);
      setIsDefectFixes(false);
      setSkipReinspection(true);
    };

    return (
      <Card className="mt-1rem">
        <CardHeader tag="h5" className="bg-danger text-white">
          Action Required!
        </CardHeader>
        <CardBody>
          <CardText>
            If you're seeing this, make sure the vendor carried out the
            following actions.
          </CardText>
          {inquiries && (
            <ListGroup className="mt-1rem">
              {inquiries.map((inquiry, i) => {
                return (
                  <ListGroupItem key={i}>
                    <CardText>
                      <b>Scope:</b> {phraseToProperCase(inquiry.scope)}
                    </CardText>
                    <CardText>
                      <b>Answered by:</b>
                      <br />
                      {inquiry.answeredBy.name}{" "}
                      <i>({inquiry.answeredBy.org})</i> @{" "}
                      <Moment>{inquiry.answeredAt}</Moment>
                    </CardText>
                    <CardText>
                      <b>Actions</b>
                    </CardText>
                    <ul>
                      {Object.keys(inquiry.actions).map((subject, i) => {
                        const actions = inquiry.actions[subject];

                        return (
                          actions.length > 0 && (
                            <li key={i}>
                              <b>{phraseToProperCase(subject)}</b>
                              <ol>
                                {actions.map((action, i) => {
                                  const parsedAction = parseAction(action);

                                  return (
                                    <li key={i}>{parsedAction.statement}</li>
                                  );
                                })}
                              </ol>
                            </li>
                          )
                        );
                      })}
                    </ul>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          )}
        </CardBody>
        {isShowing && verifyFixes && (
          <CardFooter>
            <FormGroup style={{ marginBottom: "0rem" }}>
              <Button
                color="danger"
                size="sm"
                disabled={isLoading}
                onClick={handleVerifyFixes}
              >
                Yes, all actions were verified
              </Button>
            </FormGroup>
          </CardFooter>
        )}
        {!isShowing && (
          <CardHeader>
            <FormGroup style={{ marginBottom: "0rem" }}>
              <Button
                color="danger"
                size="sm"
                disabled={isLoading}
                onClick={handleActions}
              >
                Show actions {isLoading && <Spinner size="sm" color="dark" />}
              </Button>
            </FormGroup>
          </CardHeader>
        )}
      </Card>
    );
  };

  return (
    <div className="PoleReinforcementInspectionResolve">
      <Card className="mb-1rem">
        <CardBody className="display-card" style={{ marginBottom: "0rem" }}>
          <CardText>
            <b>Pole: </b>
            {inspection.dataPointId}
          </CardText>
          <CardText>
            <b>Status: </b>
            <Badge color={classStatus} pill>
              {phraseToProperCase(statusLabel)}
            </Badge>
          </CardText>
          {inspection && (
            <>
              {!isResolving && (
                <>
                  <CardText>
                    <b>Defects:</b>
                    <br />
                    {Object.keys(defectActions).map((field, i) => {
                      const meta = metadata[field] ?? {
                        positiveAnswer: null,
                        question: null,
                        answer: null,
                        cat: null
                      };

                      // console.log(field, defectActions[field], meta);

                      const colorClass =
                        meta.cat === "critical" ? "danger" : "warning";

                      return (
                        <span key={i}>
                          <Badge color={colorClass}>
                            {meta.question}{" "}
                            <i>
                              (
                              {phraseToProperCase(
                                billingCategories[field] ?? "Not found"
                              )}
                              )
                            </i>
                          </Badge>
                          <br />
                        </span>
                      );
                    })}
                  </CardText>
                </>
              )}
              {results.otherDefects && (
                <CardText>
                  <b>Other defects: </b> Yes
                </CardText>
              )}
              {document.ReinforcementQAComments && (
                <CardText>
                  <b>Comments: </b>
                  {document.ReinforcementQAComments}
                </CardText>
              )}
              <CardText>
                <b>Submitted by:</b>
                <br />
                {inspection.user.name} @{" "}
                <Moment>{inspection.submittedAt}</Moment>
              </CardText>
              {isResolving && !isFixing && <ResolveView />}
              {isResolving && isFixing && <RequestFixes />}
              {!isResolving && showDefectFixes && <RequestedFixesView />}
              {verifyFixes && <VerifyFixes />}
            </>
          )}
        </CardBody>
        {allowResolution && !isResolving && !verifyFixes && (
          <CardFooter>
            <FormGroup style={{ marginBottom: "0rem" }}>
              <Button
                color="danger"
                size="sm"
                onClick={() => setIsResolving(true)}
              >
                Resolve
              </Button>
            </FormGroup>
          </CardFooter>
        )}
      </Card>
    </div>
  );
};

export default PoleReinforcementInspectionResolve;
