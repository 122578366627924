import axios from "axios";
import { config } from "./config";

const stage = config.stage;
const settings = config[stage].appService;

const getLogEntry = async (entryId) => {
  const url = `${settings.baseUrl}/api/logEntryGet?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: { entryId }
  });

  return data;
};

const getLogEntries = async (query) => {
  const url = `${settings.baseUrl}/api/logEntriesList?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: query
  });

  return data;
};

const createLogEntry = async (doc) => {
  const url = `${settings.baseUrl}/api/logEntryInsert?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "POST",
    url: url,
    data: doc
  });

  return data;
};

const updateLogEntry = async (entryId, doc) => {
  const url = `${settings.baseUrl}/api/logEntryUpdate?code=${settings.hostKey}`;
  const { data } = await axios({
    method: "PUT",
    url: url,
    params: { entryId },
    data: doc
  });

  return data;
};

const deleteLogEntry = async (entryId) => {
  const url = `${settings.baseUrl}/api/logEntryDelete?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "DELETE",
    url: url,
    params: { entryId }
  });

  return data;
};

export {
  getLogEntry,
  getLogEntries,
  createLogEntry,
  updateLogEntry,
  deleteLogEntry
};
