import axios from "axios";
import { config } from "./config";

const stage = config.stage;
const settings = config[stage].appService;

const getAdminMailPrefs = async () => {
  const apiEndpoint = `${settings.baseUrl}/api/userAdminMailPrefsGet?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: apiEndpoint
  });

  return data;
};

export { getAdminMailPrefs };
