import { useEffect, useState } from "react";
import { defer } from "rxjs";
import { getSettings } from "../services/settingsService";

export const useGetFieldDefinitionReleases = () => {
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      const query = {
        page: 1,
        count: 100,
        scope: "fieldDefinitionReleases"
      };

      return await getSettings(query);
    };

    const subscription = defer(() => fetchSettings()).subscribe(setSettings);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return settings;
};
