import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Form, Button, Spinner } from "reactstrap";
import logo from "../images/desktop@2x.png";
import "./Login.css";

const Login = () => {
  const { instance } = useMsal();

  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = (event) => {
    event.preventDefault();

    setIsLoading(true);

    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  return (
    <div className="FormContainer">
      <Form className="form-signin" onSubmit={handleLogin}>
        <img className="mb-1" src={logo} alt="DTE Logo" width="" height="" />
        <h1 className="h3 mb-3 font-weight-normal">Data Delivery</h1>
        <Button className="btn-lg" disabled={isLoading} color="primary" block>
          Sign in {isLoading && <Spinner color="light" />}
        </Button>
      </Form>
    </div>
  );
};

export default Login;
