const ContentTypes = [
  {
    label: ".csv",
    contentType: "text/csv",
    variations: ["text/csv", "application/vnd.ms-excel"],
    kind: "Comma-separated values (CSV)"
  },
  {
    label: ".doc",
    contentType: "application/msword",
    variations: ["application/msword"],
    kind: "Microsoft Word"
  },
  {
    label: ".docx",
    contentType:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    variations: [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ],
    kind: "Microsoft Word (OpenXML)"
  },
  {
    label: ".pdf",
    contentType: "application/pdf",
    variations: ["application/pdf"],
    kind: "Adobe Portable Document Format (PDF)"
  },
  {
    label: ".ppt",
    contentType: "application/vnd.ms-powerpoint",
    variations: ["application/vnd.ms-powerpoint"],
    kind: "Microsoft PowerPoint"
  },
  {
    label: ".pptx",
    contentType:
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    variations: [
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ],
    kind: "Microsoft PowerPoint (OpenXML)"
  },
  {
    label: ".xls",
    contentType: "application/vnd.ms-excel",
    variations: ["application/vnd.ms-excel"],
    kind: "Microsoft Excel"
  },
  {
    label: ".xlsx",
    contentType:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    variations: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ],
    kind: "Microsoft Excel (OpenXML)"
  },
  {
    label: ".zip",
    contentType: "application/zip",
    variations: ["application/zip", "application/x-zip-compressed"],
    kind: "ZIP archive"
  }
];

const getContentTypes = () => {
  return ContentTypes;
};

const getContentTypeVariations = () => {
  let variations = [];

  ContentTypes.map((v) => {
    variations = [...variations, ...v.variations];

    return null;
  });

  return variations;
};

const standarizeContentType = (type) => {
  let ct = null;

  for (let i = 0; i < ContentTypes.length; i++) {
    if (ContentTypes[i].variations.includes(type)) {
      return ContentTypes[i].contentType;
    }
  }

  return ct;
};

export { getContentTypes, getContentTypeVariations, standarizeContentType };
