import axios from "axios";
import { config } from "./config";

const stage = config.stage;
const settings = config[stage].appService;

const getMyDriveFile = async (query) => {
  const url = `${settings.baseUrl}/api/myDriveGet?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "GET",
    url: url,
    params: query
  });

  return { data, status };
};

const getMyDriveFiles = async (query) => {
  const url = `${settings.baseUrl}/api/myDriveList?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: query
  });

  return data;
};

const deleteMyDriveFile = async (fileId) => {
  const url = `${settings.baseUrl}/api/myDriveDelete?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "DELETE",
    url: url,
    params: { fileId }
  });

  return data;
};

const insertMyDriveFile = async (doc) => {
  const url = `${settings.baseUrl}/api/myDriveInsert?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { data, status };
};

const updateMyDriveFile = async (query, doc) => {
  const url = `${settings.baseUrl}/api/myDriveUpdate?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "PUT",
    url: url,
    params: query,
    data: doc
  });

  return data;
};

export {
  getMyDriveFile,
  getMyDriveFiles,
  deleteMyDriveFile,
  insertMyDriveFile,
  updateMyDriveFile
};
