import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
  Table,
  CardText
} from "reactstrap";
import UploaderZipDataFactory from "./UploaderZipDataFactory";
import bsCustomFileInput from "bs-custom-file-input";
import prettyBytes from "pretty-bytes";
import parseCsv from "../libs/parseCsv";
import {
  storeData,
  insertUpload,
  getUpload,
  updateUpload
} from "../services/filesService";
import { getUploadsBySignature } from "../services/uploaderCustomServices";
import {
  circuitAuditExists,
  resetCircuitAuditPipeline,
  resetCircuitAuditPipelineTask
} from "../models/CircuitAuditPipeline";
import { MailMessage } from "../models/Message";
import { phraseToProperCase } from "../libs/case-utils";
import { useFileSignatures } from "../libs/signFile";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import moment from "moment";
import "moment-timezone";
import "./Uploader.css";

// Set the timezone for every instance.
moment.globalTimezone = "America/Detroit";

const APP_NAME = "DTE Data Delivery";
const MAX_UPLOAD_SIZE = 60000000; // 60Mb

const UPLOAD_VERSION = "1.1.0";

const PIPELINE_TRIGGER_TASK = "poleInspection";
const FILE_TYPE_PTM = "poleTopMaintenance";

const todayTimestamp = new Date().getTime();

const Uploader = (props) => {
  const { user, adminMailPrefs, sendEmail } = props;

  let csvFileReader;

  const [csvFileName, setCsvFileName] = useState("");
  const [fileCsvContent, setCsvFileContent] = useState("");
  const [fileCategory, setFileCategory] = useState("");
  const [fileType, setFileType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  // File type abbreviations (for file naming)
  const FileTypeAbbr = {
    poleInspection: "PIT",
    poleInspectionAudit: "PIA",
    poleReinforcement: "RIP",
    poleReinforcementAudit: "RIA",
    poletopInspection: "PTP",
    poleTopMaintenance: "PTM"
  };

  // Validation errors
  const [isUploadOpen, setIsUploadOpen] = useState(true);
  const toggleUpload = () => setIsUploadOpen(!isUploadOpen);
  const newUpload = () => {
    setDistinctPrimaryCircuitNumbers([]);
    setDistinctPurchaseOrderNumbers([]);
    setDuplicateGlobalPolePosition([]);
    setDuplicateGlobalIds([]);
    setValidationErrors([]);
    setMissingColumns([]);
    setUnknownColumns([]);
    setDisplayComplexRuleErrors([]);
    toggleUpload();
  };
  const [distinctPrimaryCircuitNumbers, setDistinctPrimaryCircuitNumbers] =
    useState([]);
  const [distinctPurchaseOrderNumbers, setDistinctPurchaseOrderNumbers] =
    useState([]);
  const [duplicateGlobalPolePosition, setDuplicateGlobalPolePosition] =
    useState([]);
  const [duplicateGlobalIds, setDuplicateGlobalIds] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [missingColumns, setMissingColumns] = useState([]);
  const [unknownColumns, setUnknownColumns] = useState([]);
  const [displayComplexRuleErrors, setDisplayComplexRuleErrors] = useState([]);

  const [signFile] = useFileSignatures();
  const [signature, setSignature] = useState("");
  const [warningMessage, setWarningMessage] = useState(null);

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  useEffect(() => {
    bsCustomFileInput.init();
  }, []);

  const getRegRecipients = () => {
    const admins = adminMailPrefs.users.map((u) => {
      if (u.notifications.uploads.length > 0)
        return {
          name: u.name,
          address: u.email,
          uploads: u.notifications.uploads
        };

      return null;
    });

    return admins.filter((u) => u);
  };

  const sendRequest = async (uploadRecord, resubmitted) => {
    // Set to recipients
    const regRecipients = getRegRecipients();

    const validRecipients = regRecipients.filter((u) => {
      return u.uploads.includes(fileType);
    });

    if (validRecipients.length <= 0) return false;

    // Set mail delegate
    const fromRecipient = {
      name: user.name,
      address: user.email
    };

    // Set to recipients
    const toRecipients = validRecipients.map((u) => {
      return { name: u.name, address: u.address };
    });

    // Set message subject
    const subject = `${APP_NAME}: Upload confirmation: ${
      resubmitted ? "Resubmission" : "Submission"
    }`;

    // Work on message body
    let msgBody = { ...uploadRecord };

    // Remove errors from msg body
    if (msgBody.errors || msgBody.errors === false) delete msgBody.errors;

    // Set message body
    const body = {
      content: JSON.stringify(msgBody, null, 4)
    };

    // Set one attachment
    const attachments = [];

    // Set Cc list
    const ccRecipients = [];

    // Set Bcc list
    const BccRecipients = [];

    const message = MailMessage(
      fromRecipient,
      toRecipients,
      subject,
      body,
      attachments,
      ccRecipients,
      BccRecipients,
      true
    );

    // Send email request
    sendEmail(message);
  };

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const validateForm = () => {
    return (
      fileType.length > 0 &&
      fileCategory.length > 0 &&
      fileCsvContent.length > 0
    );
  };

  const resetForm = () => {
    // Reset signature
    setSignature("");
    setWarningMessage(null);

    // Reset form state
    setFileCategory("");
    setFileType("");
    setCsvFileContent("");

    // Reset form
    document.getElementById("upload-form").reset();
  };

  const handleCsvFileRead = () => {
    const content = csvFileReader.result;

    setCsvFileContent(content);
  };

  const checkCsvFile = (name, type) => {
    let isCsv = false;

    // Check content type
    const csvContentTypes = [
      "text/csv", // For Unix
      "application/vnd.ms-excel" // For Windows
    ];

    if (type === "") {
      const nameParts = name.split(".");

      isCsv = ["csv", "CSV"].includes(nameParts[1].toLowerCase());
    } else {
      isCsv = csvContentTypes.includes(type);
    }

    return isCsv;
  };

  const checkSignature = async (fileName, signature) => {
    const res = await getUploadsBySignature(fileType, signature);

    if (res.status === 200) {
      if (res.response.length > 0) {
        const upload = res.response[0];

        showCustomModal({
          title: "File Signature Check",
          message: `Data was previously uploaded by ${
            upload.createdBy.name
          } on ${moment(upload.createdAt).format("MM/DD/YYYY HH:mm:s")}.`
        });

        resetForm();
      }
    } else {
      showCustomModal({
        title: "File Signature Check",
        message: `Something went wrong while checking ${fileName}'s signature.`
      });

      resetForm();
    }
  };

  const handleCsvFileChange = async (e) => {
    const file = e.target.files[0];

    // Check for maximum file size
    if (file && file.size > MAX_UPLOAD_SIZE) {
      showCustomModal({
        title: "File size limit error",
        message: `Please pick a data file smaller than ${prettyBytes(
          MAX_UPLOAD_SIZE
        )}.`
      });

      resetForm();
      return;
    }

    // Check for minimum file size
    if (file && file.size <= 0) {
      showCustomModal({
        title: "File size limit error",
        message: `${file.name} is empty.`
      });

      resetForm();
      return;
    }

    if (file && !checkCsvFile(file.name, file.type)) {
      showCustomModal({
        title: "File type error",
        message: `${file.name} is not a csv file.`
      });

      resetForm();
      return;
    }

    setCsvFileName(file.name);
    csvFileReader = new FileReader();
    csvFileReader.onloadend = handleCsvFileRead;
    csvFileReader.readAsText(file);

    const signature = await signFile(file);

    await checkSignature(file.name, signature);

    setSignature(signature);
  };

  const reportParsingErrors = (data) => {
    const {
      validation,
      missingFields,
      unknownFields,
      distinctPrimaryCircuitNumbers,
      distinctPurchaseOrderNumbers,
      duplicateGlobalPolePosition,
      duplicateGlobalIds,
      displayComplexRuleErrors
    } = data;

    setValidationErrors(validation);
    setMissingColumns(missingFields);
    if (!missingFields.length) setUnknownColumns(unknownFields);
    setDistinctPrimaryCircuitNumbers(distinctPrimaryCircuitNumbers);
    setDistinctPurchaseOrderNumbers(distinctPurchaseOrderNumbers);
    setDuplicateGlobalPolePosition(duplicateGlobalPolePosition);
    setDuplicateGlobalIds(duplicateGlobalIds);
    setDisplayComplexRuleErrors(displayComplexRuleErrors);

    if (
      missingFields.length ||
      unknownFields.length ||
      validation.length ||
      distinctPrimaryCircuitNumbers.length > 1 ||
      distinctPurchaseOrderNumbers.length > 1 ||
      duplicateGlobalPolePosition.length ||
      duplicateGlobalIds.length ||
      displayComplexRuleErrors.length
    )
      toggleUpload();
  };

  const convertComplexRulesErrorsToCsv = (arr, options) => {
    // Build summary
    const rulesGroupedByName = _.groupBy(arr, (item) => item.name);
    const summaryItems = _.orderBy(
      Object.keys(rulesGroupedByName).map((key) => {
        const row = {
          name: key,
          numberOfDefects: rulesGroupedByName[key].length,
          OHPrimaryCircuitNumber: options.OHPrimaryCircuitNumber,
          dateUploaded: options.dateUploaded,
          source: options.source,
          contractor: options.contractor
        };

        return row;
      }),
      ["numberOfDefects"],
      "desc"
    );
    const summaryHeader = [
      "Rule Name",
      "# of Defects",
      "OHPrimaryCircuitNumber",
      "Date Uploaded",
      "Source",
      "Contractor"
    ];
    const summaryRows = summaryItems.map((item) => {
      const {
        name,
        numberOfDefects,
        OHPrimaryCircuitNumber,
        dateUploaded,
        source,
        contractor
      } = item;

      return `${name}, ${numberOfDefects}, ${OHPrimaryCircuitNumber}, ${dateUploaded}, ${source}, ${contractor}`;
    });
    const summary = `${summaryHeader.join(",")}\n${summaryRows.join("\n")}\n`;

    // Build list of errors
    const blocks = arr.map((block) => {
      const rows = Object.keys(block).map((key) => {
        let row = [];

        switch (key) {
          case "name":
            row.push(`Rule name, ${block[key]}`);
            break;
          case "description":
            row.push(`Description, ${block[key] === "" ? "N/A" : block[key]}`);
            break;
          case "row":
            row.push(`Row, ${block[key]}`);
            break;
          case "values":
            row = Object.keys(block[key]).map((valueKey) => {
              return `${valueKey}, ${block[key][valueKey]}`;
            });

            row = ["Input values,", ...row];
            break;
          default:
        }

        return row.join("\n");
      });

      return rows.join("\n");
    });

    return `${summary}\n${blocks.join("\n")}`;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    setShowSpinner(true);

    const taskReset = (taskName) => {
      let update = null;

      switch (taskName) {
        case "poleReinforcement":
          update = {
            required: true,
            checks: {
              fileExists: null,
              dataExist: null,
              pullReinforcedPoles: null
            },
            variables: {},
            settings: {},
            status: "pending"
          };
          break;
        default:
          update = { required: true };
      }

      return update;
    };

    try {
      // Parse and validate csv contents
      const parseRes = await parseCsv(fileType, fileCsvContent);

      // console.log("parseRes: ", parseRes);

      if (parseRes.result === true) {
        const info = parseRes.info;
        const primaryCircuitNumber = info.distinctPrimaryCircuitNumbers[0];
        const isCircuitAutdit = await circuitAuditExists(primaryCircuitNumber);
        const isTriggerTask = fileType === PIPELINE_TRIGGER_TASK;
        const isPoleTopMaintenance = fileType === FILE_TYPE_PTM;

        //Check if PIT has been uploaded and data in DB
        if (fileType !== "poleInspection") {
          // TODO: query Uploads collection:
          // circuit = primaryCircuitNumber, type = poleInspection, datapoints > 0
        }

        // Check if circuit audit was created
        if (isTriggerTask || (isCircuitAutdit && !isTriggerTask) || isPoleTopMaintenance) {
          const orgForFileName = user.org.toUpperCase().replace(" ", "-");
          const newFileName = `${primaryCircuitNumber}_${FileTypeAbbr[fileType]}_${orgForFileName}`;
          const userInfo = {
            id: user.id,
            name: user.name,
            org: user.org,
            email: user.email
          };
          let resubmitted = false;

          try {
            // Store CSV in Blob storage
            await storeData({
              fileName: `${newFileName}.csv`,
              data: fileCsvContent
            });

            try {
              let uploadRecord = {
                uploadId: uuidv4(),
                contentType: "text/csv",
                category: fileCategory,
                type: fileType,
                fileName: `${newFileName}.csv`,
                circuit: primaryCircuitNumber,
                errors: false,
                signature,
                support: {
                  compressed: {
                    batches: [],
                    final: null
                  },
                  photos: {
                    list: [],
                    count: 0,
                    complete: false
                  }
                },
                transferred: false,
                version: UPLOAD_VERSION
              };

              // Update error info
              if (info.complexRuleErrors && info.complexRuleErrors.length) {
                uploadRecord.errors = {
                  contentType: "application/csv",
                  fileName: `${newFileName}-errors.csv`,
                  count: info.complexRuleErrors.length
                };

                // Store CSV in Blob storage
                await storeData({
                  fileName: uploadRecord.errors.fileName,
                  data: convertComplexRulesErrorsToCsv(info.complexRuleErrors, {
                    OHPrimaryCircuitNumber: primaryCircuitNumber,
                    dateUploaded: moment(todayTimestamp).format("MM/DD/YYYY"),
                    contractor: user.org,
                    source: FileTypeAbbr[fileType]
                  })
                });
              }

              // Update db
              uploadRecord.status = "submitted";
              uploadRecord.createdBy = userInfo;

              // Insert upload record in database
              if ((await getUpload(uploadRecord.fileName)) === null) {
                // Reset datapoint count
                uploadRecord.datapoints = 0;

                await insertUpload(uploadRecord);
              } else {
                resubmitted = true;

                await updateUpload(uploadRecord.fileName, {
                  signature,
                  status: uploadRecord.status,
                  transferred: false,
                  updatedBy: userInfo
                });
              }

              // Manage circuit audit pipeline
              if (isTriggerTask) {
                // Set pipeline task updates
                const tasks = {};

                // Add task update: poletopInspection
                tasks.poletopInspection = { required: true, status: "pending" };

                // Process audit pipeline
                await resetCircuitAuditPipeline(
                  primaryCircuitNumber,
                  user.org,
                  {
                    user: userInfo,
                    tasks
                  }
                );
              } else {
                if (resubmitted && !isPoleTopMaintenance) {
                  await resetCircuitAuditPipelineTask(
                    primaryCircuitNumber,
                    fileType,
                    taskReset(fileType),
                    { user: userInfo }
                  );
                }
              }

              // Send email confirmation
              await sendRequest(uploadRecord, resubmitted);

              showCustomModal({
                title: "Successful file upload",
                message: `${phraseToProperCase(
                  uploadRecord.type
                )} file was successfully uploaded.`
              });
            } catch (e) {
              showCustomModal({
                title: "Unexpected error while inserting upload in database",
                message: JSON.stringify(e)
              });
            }
          } catch (e) {
            showCustomModal({
              title: "Unexpected error while uploading file",
              message: JSON.stringify(e)
            });
          }
        } else {
          showCustomModal({
            title: "CSV Data Not Allowed",
            message: "Pole inspection data should be uploaded first..."
          });
        }
      } else {
        reportParsingErrors(parseRes.errors);
      }
    } catch (e) {
      showCustomModal({
        title: e.name,
        message: e.message
      });
    }

    resetForm();
    setIsLoading(false);
    setShowSpinner(false);
  };

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const DistinctPrimaryCircuitNumbersResults = () => {
    return distinctPrimaryCircuitNumbers.length > 1 ? (
      <>
        <div className="NewUpload">
          <Button onClick={newUpload} color="primary">
            New Upload
          </Button>
        </div>
        <div className="table-responsive">
          <h2>Non-distinct Primary Circuit Numbers were found</h2>
          <h4>{csvFileName}</h4>
          <Table striped hover bordered>
            <thead>
              <tr>
                <th>Primary Circuit Number</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {distinctPrimaryCircuitNumbers.map((c, i) => (
                <tr key={i}>
                  <td>{c}</td>
                  <td>Non-distinct</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    ) : (
      " "
    );
  };

  const DistinctPurchaseOrderNumbersResults = () => {
    return distinctPurchaseOrderNumbers.length > 1 ? (
      <>
        <div className="NewUpload">
          <Button onClick={newUpload} color="primary">
            New Upload
          </Button>
        </div>
        <div className="table-responsive">
          <h2>Non-distinct Purchase Order Numbers were found</h2>
          <h4>{csvFileName}</h4>
          <Table striped hover bordered>
            <thead>
              <tr>
                <th>Purchase Order Number</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {distinctPurchaseOrderNumbers.map((c, i) => (
                <tr key={i}>
                  <td>{c}</td>
                  <td>Non-distinct</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    ) : (
      " "
    );
  };

  const DuplicateGlobalPolePositionResults = () => {
    return duplicateGlobalPolePosition.length ? (
      <>
        <div className="NewUpload">
          <Button onClick={newUpload} color="primary">
            New Upload
          </Button>
        </div>
        <div className="table-responsive">
          <h2>Duplicate Global Location Numbers were found</h2>
          <h4>{csvFileName}</h4>
          <Table striped hover bordered>
            <thead>
              <tr>
                <th>GLNX-GLNY</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {duplicateGlobalPolePosition.map((c, i) => (
                <tr key={i}>
                  <td>{c}</td>
                  <td>Duplicate</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    ) : (
      " "
    );
  };

  const DuplicateGlobalIds = () => {
    return duplicateGlobalIds.length ? (
      <>
        <div className="NewUpload">
          <Button onClick={newUpload} color="primary">
            New Upload
          </Button>
        </div>
        <div className="table-responsive">
          <h2>Duplicate Global Ids were found</h2>
          <h4>{csvFileName}</h4>
          <Table striped hover bordered>
            <thead>
              <tr>
                <th>GlobalID</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {duplicateGlobalIds.map((c, i) => (
                <tr key={i}>
                  <td>{c}</td>
                  <td>Duplicate</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    ) : (
      " "
    );
  };

  const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

  const ComplexRuleResults = () => {
    return displayComplexRuleErrors.length ? (
      <>
        <div className="NewUpload">
          <Button onClick={newUpload} color="primary">
            New Upload
          </Button>
        </div>
        <div className="table-responsive">
          <h2>Complex rules</h2>
          <h4>{csvFileName}</h4>
          <Table striped hover bordered>
            <thead>
              <tr>
                <th>Row</th>
                <th>Rule name</th>
                <th>Description</th>
                <th>Values found</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {displayComplexRuleErrors.map((r, i) => (
                <tr key={i}>
                  <td>{r.row}</td>
                  <td>{r.name}</td>
                  <td>{r.description}</td>
                  <td>
                    <Json data={r.values} />
                  </td>
                  <td>failed</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    ) : (
      " "
    );
  };

  const MissingColumnsResults = () => {
    return missingColumns.length ? (
      <>
        <div className="NewUpload">
          <Button onClick={newUpload} color="primary">
            New Upload
          </Button>
        </div>
        <div className="table-responsive">
          <h2>Missing columns</h2>
          <h4>{csvFileName}</h4>
          <Table striped hover bordered>
            <thead>
              <tr>
                <th>Column</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {missingColumns.map((c, i) => (
                <tr key={i}>
                  <td>{c}</td>
                  <td>missing</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    ) : (
      " "
    );
  };

  const UnknownColumnsResults = () => {
    return unknownColumns.length ? (
      <>
        <div className="NewUpload">
          <Button onClick={newUpload} color="primary">
            New Upload
          </Button>
        </div>
        <div className="table-responsive">
          <h2>Unknown columns</h2>
          <h4>{csvFileName}</h4>
          <Table striped hover bordered>
            <thead>
              <tr>
                <th>Column</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {unknownColumns.map((c, i) => (
                <tr key={i}>
                  <td>{c}</td>
                  <td>unknown</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    ) : (
      " "
    );
  };

  const ValidationResults = () => {
    return validationErrors.length ? (
      <>
        <div className="NewUpload">
          <Button onClick={newUpload} color="primary">
            New Upload
          </Button>
        </div>
        <div className="table-responsive">
          <h2>Validation errors</h2>
          <h4>{csvFileName}</h4>
          <Table striped hover bordered>
            <thead>
              <tr>
                <th>Row</th>
                <th>Field</th>
                <th>Value found</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {validationErrors.map((e, i) => (
                <tr key={i}>
                  <td>{e.row}</td>
                  <td>{e.field}</td>
                  <td>{e.value.toString().length ? e.value : "{empty}"}</td>
                  <td>{e.msg}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    ) : (
      " "
    );
  };

  // Control tabs
  const [activeTab, setActiveTab] = useState("1");

  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="Uploader">
      <Collapse isOpen={isUploadOpen}>
        <Card>
          <CardHeader tag="h5">Upload Pole Inspection Data</CardHeader>
          <CardBody>
            <Nav tabs className="top-nav">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "1"
                  })}
                  onClick={() => {
                    toggleTabs("1");
                  }}
                  style={{
                    marginLeft: "0.5rem"
                  }}
                >
                  Upload CSV Data
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "2"
                  })}
                  onClick={() => {
                    toggleTabs("2");
                  }}
                >
                  Upload Pictures
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Card className="mt-1rem">
                  <CardBody>
                    <Form onSubmit={handleSubmit} id="upload-form">
                      <h5 className="card-title">Select the program</h5>
                      <FormGroup tag="fieldset">
                        {user.prefs.programs.map((el, i) => (
                          <FormGroup key={i} check>
                            <Label check>
                              <Input
                                type="radio"
                                name="fileCategory"
                                disabled={isLoading}
                                value={el}
                                checked={fileCategory === el ? "checked" : ""}
                                onChange={(e) =>
                                  setFileCategory(e.target.value)
                                }
                              />{" "}
                              {el}
                            </Label>
                          </FormGroup>
                        ))}
                      </FormGroup>{" "}
                      <h5 className="card-title">
                        Select the file type you will be uploading
                      </h5>
                      <FormGroup tag="fieldset">
                        {user.prefs.fileTypes.map((el, i) => (
                          <FormGroup key={i} check>
                            <Label check>
                              <Input
                                type="radio"
                                name="filetype"
                                disabled={isLoading}
                                value={el}
                                checked={fileType === el ? "checked" : ""}
                                onChange={(e) => setFileType(e.target.value)}
                              />{" "}
                              {phraseToProperCase(el)}
                            </Label>
                          </FormGroup>
                        ))}
                      </FormGroup>
                      <h5 className="card-title">
                        Please select a .csv file for validation
                      </h5>
                      <div className="form-group custom-file">
                        <input
                          id="data-file"
                          type="file"
                          className="custom-file-input"
                          disabled={isLoading}
                          onChange={handleCsvFileChange}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="data-file"
                        >
                          Choose file
                        </label>
                      </div>
                      {warningMessage && <CardText>Re-upload...</CardText>}
                      <Button
                        className="btn-lg"
                        disabled={!validateForm() || isLoading}
                        color="primary"
                        block
                      >
                        Upload {showSpinner && <Spinner color="light" />}
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="2">
                <UploaderZipDataFactory user={user} />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Collapse>
      <ValidationResults />
      <MissingColumnsResults />
      <UnknownColumnsResults />
      <DistinctPrimaryCircuitNumbersResults />
      <DistinctPurchaseOrderNumbersResults />
      <DuplicateGlobalPolePositionResults />
      <DuplicateGlobalIds />
      <ComplexRuleResults />
      <ErrorModal />
    </div>
  );
};

export default Uploader;
