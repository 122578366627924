import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  CardText,
  Col,
  Form,
  FormGroup,
  Progress,
  Row,
  Spinner
} from "reactstrap";
import {
  storeData,
  deleteFromBlobStorage,
  getDownloadUrl,
  followDownloadUrl
} from "../services/filesService";
import { updateDataFileEntry } from "../services/dataFileService";
import { v4 as uuidv4 } from "uuid";
import base64 from "base-64";
import prettyBytes from "pretty-bytes";
import bsCustomFileInput from "bs-custom-file-input";
import "./PhotoUpload.css";

// const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const MAX_UPLOADS = 15;

const PhotoUpload = (props) => {
  const { curMedia, updateMedia, docInfo } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectPhotos, setSelectPhotos] = useState(true);
  const [newMedia, setNewMedia] = useState(null);
  const [progress, setProgress] = useState(0);
  const [progressMsg, setProgressMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const curMediaLen = curMedia ? curMedia.length : 0;
  const showBrowse = curMediaLen < MAX_UPLOADS;

  useEffect(() => {
    bsCustomFileInput.init();
  }, []);

  const saveMedia = async (media) => {
    const { dataPointId, fieldset } = docInfo;

    // Clean up doc
    let updated;
    if (media !== null) {
      updated = media.map((file) => {
        const { name, size, type, storedAs } = file;

        return {
          name,
          size,
          type,
          storedAs
        };
      });
    } else {
      updated = media;
    }

    const doc = { media: updated };

    await updateDataFileEntry(dataPointId, fieldset, doc);
  };

  const getContent = async (file) => {
    return new Promise((resolve, reject) => {
      let fileReader = null;

      try {
        const handleFileRead = () => {
          resolve(fileReader.result);
        };

        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsBinaryString(file);
      } catch (e) {
        console.log(e);

        reject(null);
      }
    });
  };

  const getExt = (type) => {
    let ext;

    switch (type) {
      case "image/jpeg":
        ext = "jpg";
        break;
      default:
        ext = "jpg";
    }

    return ext;
  };

  const checkInvalidTypes = (files) => {
    let invalid = false;

    // Check types
    const invalidTypes = Array.from(files)
      .filter((file) => file.type !== "image/jpeg")
      .map((file) => {
        return file.name;
      });

    if (invalidTypes.length > 0) {
      const plural = invalidTypes.length > 1;

      if (plural) {
        setErrorMsg(`These files are not photos: ${invalidTypes.join(", ")}`);
      } else {
        setErrorMsg(`This file is not a photo: ${invalidTypes.join(", ")}`);
      }

      invalid = true;
    }

    return invalid;
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;

    setErrorMsg(null);

    // Check max num of uploads
    const totalPhotos = files.length + curMediaLen;
    if (totalPhotos > MAX_UPLOADS) {
      setErrorMsg(
        `Please pick no more than ${MAX_UPLOADS} photos to upload...`
      );

      // Reset form
      resetUpload();

      return false;
    }

    if (checkInvalidTypes(files)) {
      // Reset form
      resetUpload();

      return false;
    }

    setSelectPhotos(false);
    setIsLoading(true);

    const info = Array.from(files).map((info, i) => {
      const { name, size, type } = info;
      const uuid = uuidv4();

      const storedAs = `RIA-${uuid}.${getExt(type)}`;

      return {
        name,
        size,
        type,
        storedAs,
        content: null,
        url: URL.createObjectURL(files[i])
      };
    });

    for (let i = 0; i < info.length; i++) {
      info[i].content = await getContent(files[i]);
    }

    setNewMedia(info);
    setIsLoading(false);
  };

  const GatheringPhotoData = () => {
    return (
      <>
        <Spinner size="sm" color="primary" /> Gathering photo data...
      </>
    );
  };

  const handleRemovePhoto = (photoIndex) => {
    const copy = [...newMedia];

    const updated = copy
      .map((file, i) => {
        if (i !== photoIndex) {
          return file;
        } else {
          return null;
        }
      })
      .filter((el) => el !== null);

    if (updated.length > 0) {
      setNewMedia(updated);
    } else {
      resetUpload();
    }
  };

  const handlePreviewPhoto = async (photoIndex) => {
    const { storedAs, type } = curMedia[photoIndex];

    // Get download url
    const blobUrl = await getDownloadUrl(storedAs, "uploads");

    // Create local blob and url
    const content = await followDownloadUrl(blobUrl);
    const blob = new Blob([content], {
      type
    });
    const url = URL.createObjectURL(blob);

    const copy = [...curMedia];
    copy[photoIndex].url = url;
    updateMedia(copy);
  };

  const PreviewPhotos = () => {
    return (
      <Card className="mb-05rem">
        <CardHeader className="card-header-warning">Preview Photos</CardHeader>
        <CardBody>
          <Row>
            {newMedia.map((file, i) => {
              return (
                <Col sm="3" key={i}>
                  <Card className="mb-05rem" key={i}>
                    {file.url && (
                      <CardImg
                        alt={file.name}
                        src={file.url}
                        top
                        width="100%"
                      />
                    )}
                    {!file.url && (
                      <CardHeader className="align-preview">
                        <Button
                          color="primary"
                          outline
                          size="sm"
                          onClick={() => handlePreviewPhoto(i)}
                        >
                          Preview
                        </Button>
                      </CardHeader>
                    )}
                    <CardBody>
                      <CardText className="photo-des">
                        <b>Name:</b> {file.name}
                      </CardText>
                      <CardText className="photo-des">
                        <b>Size:</b> {prettyBytes(file.size)}
                      </CardText>
                      <hr />
                      <Button
                        className="mt-05rem"
                        color="danger"
                        outline
                        size="sm"
                        onClick={() => handleRemovePhoto(i)}
                      >
                        Remove
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </CardBody>
      </Card>
    );
  };

  const UploadedPhoto = (props) => {
    const { file, index } = props;
    const [isDeleting, setIsDeleting] = useState(false);
    const [attemptDelete, setAttemptDelete] = useState(false);

    const handleDeletePhoto = async () => {
      setIsDeleting(true);

      await deleteFromBlobStorage("uploads", curMedia[index].storedAs).then(
        (result) => {
          if (result) {
            const copy = [...curMedia];

            const newMedia = copy
              .map((file, i) => {
                if (i !== index) {
                  return file;
                } else {
                  return null;
                }
              })
              .filter((el) => el !== null);

            const updated = newMedia.length > 0 ? newMedia : null;

            updateMedia(updated);

            // Save in db
            saveMedia(updated);
          } else {
            console.log("Error while deleting photo: ", result);
          }

          setIsDeleting(false);
        }
      );
    };

    const DeleteCancelButtons = (props) => {
      return (
        <FormGroup>
          <hr />
          <CardText className="photo-des">
            Are you sure you want to delete this photo?
          </CardText>
          {!isDeleting && (
            <Button
              className="mr-025rem"
              color="success"
              size="sm"
              onClick={() => setAttemptDelete(false)}
            >
              No
            </Button>
          )}
          <Button
            color="danger"
            size="sm"
            disabled={isDeleting}
            onClick={handleDeletePhoto}
          >
            Yes {isDeleting && <Spinner size="sm" color="light" />}
          </Button>
        </FormGroup>
      );
    };

    return (
      <Card className="mb-05rem">
        {file.url && (
          <CardImg alt={file.name} src={file.url} top width="100%" />
        )}
        {!file.url && (
          <CardHeader className="align-preview">
            <Button
              color="primary"
              outline
              size="sm"
              onClick={() => handlePreviewPhoto(index)}
            >
              Preview
            </Button>
          </CardHeader>
        )}
        <CardBody>
          <CardText className="photo-des">
            <b>Name:</b> {file.name}
          </CardText>
          <CardText className="photo-des">
            <b>Size:</b> {prettyBytes(file.size)}
          </CardText>
          {!attemptDelete && (
            <Button
              className="mt-05rem"
              color="danger"
              outline
              size="sm"
              disabled={isDeleting}
              onClick={() => setAttemptDelete(true)}
            >
              Delete {isDeleting && <Spinner size="sm" color="dark" />}
            </Button>
          )}
          {attemptDelete && <DeleteCancelButtons />}
        </CardBody>
      </Card>
    );
  };

  const UploadedPhotos = () => {
    return (
      <Card className="mb-05rem">
        <CardHeader className="card-header-success">Uploaded Photos</CardHeader>
        <CardBody>
          <Row>
            {curMedia.map((file, i) => {
              return (
                <Col sm="3" key={i}>
                  <UploadedPhoto file={file} index={i} />
                </Col>
              );
            })}
          </Row>
        </CardBody>
      </Card>
    );
  };

  const resetUpload = () => {
    setProgress(0);
    setProgressMsg(null);
    setNewMedia(null);
    setSelectPhotos(true);

    if (document.getElementById("upload-form"))
      document.getElementById("upload-form").reset();
  };

  const mergeMedia = (newMedia) => {
    if (curMedia) {
      return [...curMedia, ...newMedia];
    } else {
      return newMedia;
    }
  };

  const handleUpload = async () => {
    setSelectPhotos(false);
    setIsUploading(true);

    const l = newMedia.length;

    const calProgress = (numFiles) => (numFiles / l) * 100;

    for (let i = 0; i < l; i++) {
      const file = newMedia[i];
      setProgressMsg(file.name);

      try {
        // Upload data
        await storeData({
          fileName: file.storedAs,
          data: base64.encode(file.content),
          base64: true
        });
      } catch (e) {
        console.log(e);
      }

      setProgress(calProgress(i + 1));
    }

    await delay(1000);

    setIsUploading(false);
    resetUpload();

    const updated = mergeMedia(newMedia);

    updateMedia(updated);

    // Save in db
    saveMedia(updated);
  };

  const ErrorMsg = (props) => {
    const { msg } = props;

    return (
      <Alert color="danger" className="mt-05rem">
        {msg}
      </Alert>
    );
  };

  return (
    <div className="PhotoUpload">
      <Card>
        <CardBody>
          {curMedia && selectPhotos && <UploadedPhotos />}
          {selectPhotos && showBrowse && (
            <Form id="upload-form">
              <div className="form-group custom-file">
                <input
                  className="custom-file-input"
                  id="user-photo"
                  name="user-photo"
                  type="file"
                  multiple
                  onChange={handleFileChange}
                />
                <label className="custom-file-label" htmlFor="user-photo">
                  Choose up to {MAX_UPLOADS - curMediaLen} photos
                </label>
                {errorMsg && <ErrorMsg msg={errorMsg} />}
              </div>
            </Form>
          )}
          {isLoading && <GatheringPhotoData />}
          {newMedia && !isUploading && <PreviewPhotos />}
          {isUploading && (
            <>
              <Progress animated color="primary" striped value={progress} />
              <CardText>Uploading {progressMsg}...</CardText>
            </>
          )}
        </CardBody>
        {!selectPhotos && !isUploading && (
          <CardFooter>
            <Button
              className="mr-025rem"
              color="primary"
              size="sm"
              onClick={handleUpload}
            >
              Upload
            </Button>{" "}
            <Button color="danger" size="sm" onClick={resetUpload}>
              Cancel
            </Button>
          </CardFooter>
        )}
      </Card>
    </div>
  );
};

export default PhotoUpload;
