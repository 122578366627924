import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane
} from "reactstrap";
import CircuitAudit from "./CircuitAudit";
import { getCircuitStati } from "../models/CircuitAuditPipeline";
import { getCircuitAudits } from "../services/circuitAuditService";
import { getCollectionCount } from "../services/collectionService";
import { useGetVendors } from "./useGetVendors";
import { phraseToProperCase } from "../libs/case-utils";
import classnames from "classnames";
import "./CircuitAuditList.css";
import GetPagination from "./GetPagination";

const CircuitAuditList = (props) => {
  const { user, sendEmail } = props;

  const vendors = useGetVendors();

  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);
  const [isLoading, setIsLoading] = useState(false);

  const [circuits, setCircuits] = useState([]);
  const [status, setStatus] = useState("all");
  const [orgs, setOrgs] = useState([]);
  const [circuitContains, setCircuitContains] = useState("");

  const statiOptions = ["all", ...getCircuitStati(), "not delivered"];

  const newSearch = () => {
    setIsLoading(false);
    setCircuits([]);
    toggleSearch();
  };

  // Control pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [circuitsCount, setCircuitsCount] = useState(0);

  const totalCircuitsPerPage = 20;

  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setCircuits([]);

    listCircuits(pageNumber, totalCircuitsPerPage);
  };

  const incChange = (pageNumber, inc, isposOrneg) => {
    let finalPage;
    const residual = pageNumber % inc;
    const control = residual === 0 ? inc : residual;

    if (isposOrneg > 0) {
      finalPage = pageNumber + inc - control + 1;
    } else {
      finalPage = pageNumber - inc - control + 1;
    }

    setCurrentPage(finalPage);
    setCircuits([]);

    listCircuits(finalPage, totalCircuitsPerPage);
  };

  const firstChange = () => {
    let finalPage = 1;

    setCurrentPage(finalPage);
    setCircuits([]);

    listCircuits(finalPage, totalCircuitsPerPage);
  };

  const lastChange = (finalPage) => {
    setCurrentPage(finalPage);
    setCircuits([]);

    listCircuits(finalPage, totalCircuitsPerPage);
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const validateForm = () => {
    return status.length > 0;
  };

  const listCircuits = async (page, count) => {
    let resCircuits = [];
    let query = {};

    if (status !== "all") {
      if (status === "not delivered") {
        query.statusNeg = "delivered";
      } else {
        query.status = status;
      }
    }
    if (circuitContains !== "") query.circuit = circuitContains;
    if (orgs.length > 0) query.createdByOrg = orgs;

    try {
      const resCircuitsCount = await getCollectionCount({
        collection: "CircuitAudits",
        ...query
      });

      setCircuitsCount(resCircuitsCount.count);
    } catch (e) {}

    if (page) query.page = page;
    if (count) query.count = count;

    try {
      resCircuits = await getCircuitAudits(query);
      setCircuits(resCircuits);
    } catch (e) {}

    return resCircuits.length;
  };

  const handleVendor = (checked, value) => {
    let temp = orgs;

    if (checked) {
      temp = [...orgs, value];
    } else {
      temp.splice(temp.indexOf(value), 1);
    }

    setOrgs(temp);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const numCircuits = await listCircuits(1, totalCircuitsPerPage);
      if (numCircuits) {
        toggleSearch();
      } else {
        showCustomModal({
          title: "Search results",
          message: "No results were found following that criteria"
        });
      }
    } catch (e) {}

    setIsLoading(false);
  };

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const SearchResults = () => {
    let numberOfPages = 0;
    if (circuitsCount % totalCircuitsPerPage === 0) {
      numberOfPages = Math.floor(circuitsCount / totalCircuitsPerPage);
    } else {
      numberOfPages = Math.floor(circuitsCount / totalCircuitsPerPage) + 1;
    }

    return circuits.length ? (
      <>
        <div className="NewSearch">
          <Button outline onClick={newSearch} color="secondary">
            New Search
          </Button>
        </div>
        <h5 className="card-title">Search results</h5>
        <GetPagination
          pages={numberOfPages}
          nextPage={nextPage}
          currentPage={currentPage}
          firstChange={firstChange}
          lastChange={lastChange}
          incChange={incChange}
        />
        <div className="mt-1rem">
          {circuits.map((circuit, i) => (
            <CircuitAudit
              key={i}
              circuitData={circuit}
              user={user}
              sendEmail={sendEmail}
            />
          ))}
        </div>
      </>
    ) : (
      ""
    );
  };

  // Control tabs
  const [activeTab, setActiveTab] = useState("1");

  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="CircuitAuditList">
      <Card>
        <CardHeader tag="h5">Search Circuit Audits</CardHeader>
        <CardBody>
          <Nav tabs className="top-nav">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTabs("1");
                }}
                style={{ marginLeft: "0.5rem" }}
              >
                Search
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Collapse isOpen={isSearchOpen}>
                <Card className="search-engine">
                  <CardBody>
                    <Form onSubmit={handleSubmit} id="search-form">
                      <FormGroup tag="fieldset">
                        <h5 className="card-title">Select a status</h5>
                        {statiOptions.map((el, i) => (
                          <FormGroup key={i} check>
                            <Label check>
                              <Input
                                type="radio"
                                id={`status-${i}`}
                                name="status"
                                value={el}
                                defaultChecked={el === status}
                                onChange={(e) => setStatus(e.target.value)}
                              />{" "}
                              {el === "all" ? phraseToProperCase(el) : el}
                            </Label>
                          </FormGroup>
                        ))}
                      </FormGroup>
                      <FormGroup tag="fieldset">
                        <h5 className="card-title">Circuit (Optional)</h5>
                        <FormGroup check>
                          <Input
                            type="text"
                            name="circuitContains"
                            id="circuitContains"
                            placeholder="Type the exact match"
                            value={circuitContains}
                            onChange={(e) => setCircuitContains(e.target.value)}
                          />
                        </FormGroup>
                      </FormGroup>
                      <FormGroup tag="fieldset">
                        <h5 className="card-title">
                          Select a vendor (Optional)
                        </h5>
                        {vendors ? (
                          vendors.map((el, i) => (
                            <FormGroup key={i} check>
                              <Input
                                type="checkbox"
                                value={el}
                                id={`vendor-${i}`}
                                name="vendor"
                                defaultChecked={false}
                                onChange={(e) =>
                                  handleVendor(e.target.checked, e.target.value)
                                }
                              />
                              <Label for="vendor" check>
                                {el}
                              </Label>
                            </FormGroup>
                          ))
                        ) : (
                          <div>
                            <Spinner size="sm" color="primary" /> Gathering
                            vendors
                          </div>
                        )}
                      </FormGroup>
                      <Button
                        className="btn-lg"
                        disabled={!validateForm() || isLoading}
                        color="primary"
                        block
                      >
                        Search {isLoading && <Spinner color="light" />}
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Collapse>
              <SearchResults />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
      <ErrorModal />
    </div>
  );
};

export default CircuitAuditList;
