import React, { useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import {
  getOperatorsArray,
  getOperatorsObject
} from "../models/BuilderOperators";
import { getJsonLogicMethodRefs } from "../models/JsonLogicMethods";
import "./BuilderExpression.css";

const methods = getJsonLogicMethodRefs();

const BuilderExpression = (props) => {
  const {
    operator: defOperator,
    variable: defVariable,
    value: defValue,
    expressionIndex,
    handleUpdateRule,
    handleDeleteRule,
    fieldSet,
    isNew
  } = props;

  const [operator, setOperator] = useState(defOperator);
  const [variable, setVariable] = useState(defVariable);
  const [value, setValue] = useState(defValue);

  const isMethod = typeof value === "object" && !Array.isArray(value);
  const [valueType, setValueType] = useState(isMethod ? "dynamic" : "literal");

  const variables = Object.keys(fieldSet);
  const OperatorsArray = getOperatorsArray();
  const OperatorsObject = getOperatorsObject();

  const [isEditing, setIsEditing] = useState(isNew);

  const [valueInputError, setValueInputError] = useState(null);

  const handleVariableUpdate = (variable) => {
    setVariable(variable);

    if (isNew) {
      const validation = fieldSet[variable]["validation"];
      const options = validation.options ? validation.options : null;
      const multiple = ["in", "!in"].includes(operator) ? true : false;
      let tempValue = null;

      if (options) {
        const type = validation.type;
        const typeException = validation.typeException
          ? validation.typeException
          : null;
        const isException = typeException && typeException.includes(options[0]);

        let option;
        if (isException) {
          option = options[0];
        } else {
          option = type === "number" ? +options[0] : options[0];
        }

        if (multiple) {
          tempValue = [option];
        } else {
          tempValue = option;
        }
      } else {
        tempValue = "";
      }

      setValue(tempValue);
    }
  };

  const ShowVariable = (props) => {
    const { isEditing, variable } = props;

    return isEditing ? (
      <FormGroup>
        <Label for="variable">
          <b>Field</b>
        </Label>
        <Input
          value={variable}
          type="select"
          id="variable"
          onChange={(e) => handleVariableUpdate(e.target.value)}
        >
          {variables.map((o, i) => (
            <option key={i} value={o}>
              {o}
            </option>
          ))}
        </Input>
      </FormGroup>
    ) : (
      <Badge color="primary">{variable}</Badge>
    );
  };

  const handleOperatorUpdate = (operator) => {
    setOperator(operator);

    let tempValue = value;
    const type = fieldSet[variable].type;

    if (["in", "!in"].includes(operator)) {
      const validation = fieldSet[variable]["validation"];
      const options = validation.options ? validation.options : null;
      tempValue = options
        ? type === "number"
          ? [+options[0]]
          : [options[0]]
        : "";
    } else {
      if (Array.isArray(value)) {
        tempValue = type === "number" ? +value[0] : value[0];
      }
    }

    setValue(tempValue);
  };

  const ShowOperator = (props) => {
    const { isEditing, operator } = props;

    const type = fieldSet[variable]["validation"].type;
    const operList = OperatorsArray.filter((o) => {
      return o.type.includes(type);
    });

    return isEditing ? (
      <FormGroup>
        <Label for="operator">
          <b>Operator</b>
        </Label>
        <Input
          value={operator}
          type="select"
          id="operator"
          onChange={(e) => handleOperatorUpdate(e.target.value)}
        >
          {operList.map((o, i) => (
            <option key={i} value={o.oper}>
              {o.label}
            </option>
          ))}
        </Input>
      </FormGroup>
    ) : (
      <Badge color="warning">{OperatorsObject[operator].label}</Badge>
    );
  };

  const handleValueDynamicListUpdate = (method) => {
    setValue(JSON.parse(method));
  };

  const ValueDynamicList = (props) => {
    const { value, type } = props;

    return (
      <FormGroup>
        <Label for="value">
          <b>Value ({type})</b>
        </Label>
        <ValueTypeOptions />
        <Input
          value={JSON.stringify(value)}
          type="select"
          id="dynamic-value"
          onChange={(e) => handleValueDynamicListUpdate(e.target.value)}
        >
          {methods.map((o, i) => (
            <option key={i} value={JSON.stringify(o.ref)}>
              {o.caption}
            </option>
          ))}
        </Input>
      </FormGroup>
    );
  };

  const handleValueOptionListUpdate = (
    target,
    type,
    typeException,
    multiple
  ) => {
    const isException = typeException
      ? typeException.includes(target.value)
      : false;

    let tempValue = multiple
      ? Array.from(target.selectedOptions, (item) => item.value)
      : type === "number"
      ? isException
        ? target.value
        : +target.value
      : target.value;

    if (multiple && type === "number") {
      tempValue = tempValue.map((item) => {
        return isException ? item : +item;
      });
    }

    setValue(tempValue);
  };

  const ValueOptionList = (props) => {
    const { options, value, multiple, type, typeException } = props;

    return (
      <FormGroup>
        <Label for="value">
          <b>Value ({type})</b>
        </Label>
        {!["in", "!in"].includes(operator) && <ValueTypeOptions />}
        <Input
          value={value}
          type="select"
          id="value"
          onChange={(e) =>
            handleValueOptionListUpdate(e.target, type, typeException, multiple)
          }
          multiple={multiple}
        >
          {options.map((o, i) => (
            <option key={i} value={o}>
              {o}
            </option>
          ))}
        </Input>
      </FormGroup>
    );
  };

  const checkValue = (value, validation) => {
    let passed = true;
    const pattern = validation.pattern
      ? RegExp(validation.pattern.replace(/\\/g, "\\"))
      : null;
    const patternMsg = validation.patternMsg ? validation.patternMsg : "";
    const examples = validation.test ? validation.test : null;

    if (pattern && !pattern.test(value)) {
      setValueInputError(
        `${patternMsg}${examples && `. Examples: ${examples.join(", ")}`}`
      );

      return false;
    }

    if (validation.range) {
      const { start, end } = validation.range;

      passed =
        Math.abs(value) >= Math.abs(start) && Math.abs(value) <= Math.abs(end);
      if (!passed) setValueInputError(`Enter a value from ${start} to ${end}`);

      return passed;
    }

    return passed;
  };

  const handleValueInputTextUpdate = (value, validation) => {
    const type = validation.type;
    const valueCopy = type === "number" ? +value : value;

    if (checkValue(valueCopy, validation)) {
      setValue(valueCopy);
      setValueInputError(null);
    }
  };

  const ValueInputText = (props) => {
    const { value, validation } = props;
    const patternMsg = validation.patternMsg ? validation.patternMsg : "";

    return (
      <FormGroup>
        <Label for="value">
          <b>Value ({validation.type})</b>
        </Label>
        <ValueTypeOptions />
        <Input
          invalid={valueInputError ? true : false}
          type="text"
          id="value"
          placeholder={patternMsg}
          defaultValue={value}
          onBlurCapture={(e) =>
            handleValueInputTextUpdate(e.target.value, validation)
          }
        />
        {valueInputError && (
          <FormFeedback className="ruleName">{valueInputError}</FormFeedback>
        )}
      </FormGroup>
    );
  };

  const checkIfMethod = (v) => {
    return typeof v === "object" && !Array.isArray(v);
  };

  const handleValueType = (valueType) => {
    if (valueType === "dynamic") {
      if (!checkIfMethod(value)) {
        setValue(methods[0].ref);
      }
    }

    setValueType(valueType);
  };

  const ValueTypeOptions = () => {
    return (
      <>
        <FormGroup check>
          <Label check>
            <Input
              type="radio"
              name="valueTypes"
              value="literal"
              checked={valueType === "literal"}
              onChange={(e) => handleValueType(e.target.value)}
            />{" "}
            Literal
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check className="dynamic-radio">
            <Input
              type="radio"
              name="valueTypes"
              value="dynamic"
              checked={valueType === "dynamic"}
              onChange={(e) => handleValueType(e.target.value)}
            />{" "}
            Dynamic
          </Label>
        </FormGroup>
      </>
    );
  };

  const getMethodNameOfDynValue = (o) => {
    const name = Object.keys(o)[0].split(".");

    return name[1];
  };

  const ShowValue = (props) => {
    const { isEditing, value } = props;
    const type = fieldSet[variable].validation.type;
    const validation = fieldSet[variable]["validation"];
    const typeException = validation.typeException
      ? validation.typeException
      : null;
    const isException = typeException ? typeException.includes(value) : false;
    const options = validation.options ? validation.options : null;
    const multiple = ["in", "!in"].includes(operator) ? true : false;

    let valueCopy = value;

    if (multiple && !Array.isArray(value)) {
      valueCopy = [value];
    }

    if (!multiple && Array.isArray(value)) {
      valueCopy = value[0];
    }

    let formattedValue = value;

    if (type === "string" || isException) {
      formattedValue = `"${valueCopy}"`;
    }

    if (Array.isArray(valueCopy)) {
      formattedValue = valueCopy.map((el) =>
        typeof el === "string" ? `"${el}"` : el
      );

      formattedValue = formattedValue.join(", ");
    }

    if (isMethod) {
      formattedValue = `FUNCTION: ${getMethodNameOfDynValue(valueCopy)}`;
    }

    return isEditing ? (
      valueType === "dynamic" ? (
        <ValueDynamicList value={valueCopy} type={validation.type} />
      ) : options ? (
        <ValueOptionList
          options={options}
          value={valueCopy}
          multiple={multiple}
          type={validation.type}
          typeException={typeException}
        />
      ) : (
        <ValueInputText value={valueCopy} validation={validation} />
      )
    ) : (
      <Badge color="success">{formattedValue}</Badge>
    );
  };

  const handleEditRule = () => {
    setIsEditing(true);
  };

  const handleApplyRule = (expressionIndex) => {
    const validation = fieldSet[variable]["validation"];

    if (checkValue(value, validation)) {
      setIsEditing(false);
      handleUpdateRule(expressionIndex, operator, variable, value);
    }
  };

  const handleCancelRule = (expressionIndex) => {
    if (isNew) {
      handleDeleteRule(expressionIndex);
    } else {
      setOperator(defOperator);
      setVariable(defVariable);
      setValue(defValue);

      const isMethod = typeof defValue === "object" && !Array.isArray(defValue);
      setValueType(isMethod ? "dynamic" : "literal");
    }

    setIsEditing(false);
  };

  const ManageRuleButton = (props) => {
    const { isEditing } = props;

    return isEditing ? (
      <ButtonGroup className="manage-rule-button">
        <Button
          outline
          size="sm"
          color="primary"
          onClick={() => handleApplyRule(expressionIndex)}
        >
          Apply
        </Button>
        <Button
          outline
          size="sm"
          color="danger"
          onClick={() => handleCancelRule(expressionIndex)}
        >
          Cancel
        </Button>
      </ButtonGroup>
    ) : (
      <ButtonGroup className="manage-rule-button">
        <Button
          outline
          size="sm"
          color="primary"
          onClick={() => handleEditRule()}
        >
          Edit
        </Button>
        <Button
          outline
          size="sm"
          color="danger"
          onClick={() => handleDeleteRule(expressionIndex)}
        >
          Delete
        </Button>
      </ButtonGroup>
    );
  };

  return (
    <div className="BuilderExpression">
      <Form>
        <div>
          <ShowVariable isEditing={isEditing} variable={variable} />{" "}
          <ShowOperator isEditing={isEditing} operator={operator} />{" "}
          <ShowValue isEditing={isEditing} value={value} />
        </div>
        <ManageRuleButton isEditing={isEditing} />
      </Form>
    </div>
  );
};

export default BuilderExpression;
