import React, { useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Collapse,
  CustomInput,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner
} from "reactstrap";
import {
  getSurveyCategories,
  getQuestionsByCategory,
  getAllQuestions
} from "../models/qaSurvey";
import PhotoUpload from "./PhotoUpload";
import PhotoPreview from "./PhotoPreview";
import { updateDataFileEntry } from "../services/dataFileService";
import {
  upsertReinfInspection,
  getReinfInspections,
  getLastReinfInspection
} from "../services/poleReinfInspCustomServices";
import { getMyDriveFile } from "../services/vendorDriveService";
import { getDownloadUrl, followDownloadUrl } from "../services/filesService";
import { phraseToProperCase } from "../libs/case-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import "./ReinforcementInspectionPole.css";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM/DD/YYYY HH:mm:ss";

const defFieldset = "poleReinforcementAudit";

const getCurrentYear = () => new Date().getFullYear();
const getCurrentFormattedDate = () => {
  const d = new Date();
  return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
};
const xYearsAgo = (years) => new Date().getFullYear() - years;

const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

// Status color coding
const statusColorCoding = {
  submitted: "success",
  inspect: "primary",
  reinspect: "primary",
  "submitted with defects": "danger",
  "submitted with warnings": "warning"
};

const readyForInspectionStati = ["inspect", "reinspect"];

const CUR_VERSION = "1.1.0";

const ReinforcementInspectionPole = (props) => {
  const { dataFileDef, userInfo } = props;

  const [dataFile, setDataFile] = useState(dataFileDef);
  const [pole, setPole] = useState(dataFile.document);
  const [surveyResults, setSurveyResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isReviewing, setIsReviewing] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(
    !readyForInspectionStati.includes(dataFileDef.status)
  );
  const [statusClass, setStatusClass] = useState(
    statusColorCoding[dataFileDef.status]
  );
  const [overrides, setOverrides] = useState({});
  const [media, setMedia] = useState(dataFileDef.media);
  const [defComments, setDefComments] = useState(null);
  const [riaVersion, setRiaVersion] = useState(dataFileDef.version);

  const isOutdated = riaVersion !== CUR_VERSION;
  const isReinspect = dataFileDef.status === "reinspect";
  const areThereInspections = dataFile.inspections > 0;
  const isThereBillingData = dataFile.billing ? true : false;
  const billingData = isThereBillingData
    ? dataFile.billing
    : {
        approve: null,
        reject: null,
        reinspect: null
      };

  const [activePole, setActivePole] = useState(null);
  const [isPhotosCollapsed, setIsPhotosCollapsed] = useState(false);

  const poleMap = dataFile.map;

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const resetSurvey = () => {
    setPole(dataFile.document);
    setIsChanged(false);
    setIsSaved(true);
    setSurveyResults(null);
  };

  const saveSurvey = async () => {
    const dataFileCopy = { ...dataFile };
    const poleCopy = { ...pole };
    const timestamp = new Date().getTime();

    // Update data file logs
    dataFileCopy.updatedBy = userInfo;
    dataFileCopy.updatedAt = timestamp;

    setIsLoading(true);

    const responses = {};

    try {
      const doc = {
        document: poleCopy,
        updatedBy: userInfo,
        updatedAt: timestamp
      };

      responses["update"] = await updateDataFileEntry(
        dataFileCopy.dataPointId,
        defFieldset,
        doc
      );

      if (responses["update"].status === 200) {
        setIsLoading(false);
        setIsSaved(true);
        setIsChanged(false);
        setDataFile(dataFileCopy);
      }
    } catch (e) {
      showCustomModal({
        title: "Error while saving survey",
        message: (
          <>
            The server returns:
            <br />
            <i>"{e.message}"</i>
            <br />
            <br />
            <small>
              Please try again. If the issue persists, contact the
              administrator.
            </small>
          </>
        )
      });

      setIsLoading(false);
    }
  };

  const cleanMediaList = () => {
    // Clean up doc
    let updated;
    if (media) {
      updated = media.map((file) => {
        const { name, size, type, storedAs } = file;

        return {
          name,
          size,
          type,
          storedAs
        };
      });
    } else {
      updated = null;
    }

    return updated;
  };

  const submitSurvey = async (inspectionId, comments, status, otherDefects) => {
    const dataFileCopy = { ...dataFile };
    const poleCopy = { ...pole };
    const user = overrides?.survey?.user || userInfo;
    const timestamp = overrides?.survey?.timestamp ?? new Date().getTime();
    const ReinforcementQADate =
      overrides?.survey?.ReinforcementQADate || getCurrentFormattedDate();
    const isApproved =
      status === "submitted" || status === "submitted with warnings";

    // Override user info and timestamp

    // Update data file
    dataFileCopy.status = status;
    dataFileCopy.updatedBy = user;
    dataFileCopy.updatedAt = timestamp;
    dataFileCopy.approved = isApproved;
    dataFileCopy.version = CUR_VERSION;

    // Update pole
    poleCopy["ReinforcementQAComments"] = comments;
    poleCopy["ReinforcementQADate"] = ReinforcementQADate;

    // Increment number of inspections
    dataFileCopy.inspections = 1;

    setIsSubmitting(true);

    const responses = {};

    // Insert pole reinforcement inspection
    try {
      // Build and store new inspection object
      const newInspection = {
        dataPointId: dataFileCopy.dataPointId,
        circuit: dataFileCopy.circuit,
        status: status,
        approved: isApproved,
        submittedAt: timestamp,
        user: user,
        results: {
          defects: surveyResults.defects,
          otherDefects,
          billing: null
        },
        document: poleCopy,
        media: cleanMediaList()
      };

      // Insert inspection
      responses["insert"] = await upsertReinfInspection(
        inspectionId,
        newInspection
      );
    } catch (e) {
      showCustomModal({
        title: "Error while creating inspection",
        message: (
          <>
            The server returns:
            <br />
            <i>"{e.message}"</i>
            <br />
            <br />
            <small>
              Please try again. If the issue persists, contact the
              administrator.
            </small>
          </>
        )
      });

      setIsSubmitting(false);
    }

    // Update data file
    try {
      if (responses["insert"].status === 200) {
        const doc = {
          status,
          document: poleCopy,
          updatedBy: user,
          updatedAt: timestamp,
          approved: isApproved,
          media: null,
          inspections: dataFileCopy.inspections,
          notifiedReinspection: null,
          version: CUR_VERSION
        };

        // Update data file
        responses["update"] = await updateDataFileEntry(
          dataFileCopy.dataPointId,
          defFieldset,
          doc
        );

        if (responses["update"].status === 200) {
          setDataFile(dataFileCopy);
          setPole(poleCopy);
          setRiaVersion(CUR_VERSION);

          setIsSubmitting(false);
          setIsReviewing(false);
          setIsSubmitted(true);
          setStatusClass(statusColorCoding[status]);
        }
      }
    } catch (e) {
      showCustomModal({
        title: "Error while updating data file",
        message: (
          <>
            The server returns:
            <br />
            <i>"{e.message}"</i>
            <br />
            <br />
            <small>
              Please try again. If the issue persists, contact the
              administrator.
            </small>
          </>
        )
      });

      setIsSubmitting(false);
    }
  };

  const [more, setMore] = useState(false);

  const toggleMore = () => setMore(!more);

  const MoreButton = (props) => {
    const { caption } = props;

    return (
      <Button
        outline
        size="sm"
        color={statusClass}
        style={{ float: "right" }}
        onClick={toggleMore}
      >
        {caption}
      </Button>
    );
  };

  const updatePole = (prop, val) => {
    const poleCopy = { ...pole };

    poleCopy[prop] = val;

    setActivePole(dataFile.dataPointId);
    setPole(poleCopy);
    setIsChanged(true);
    setIsSaved(false);
    setSurveyResults(null);
  };

  const yesToAll = () => {
    const poleCopy = { ...pole };

    const questions = getAllQuestions();

    questions.forEach((question) => {
      poleCopy[question.field] = question.positiveAnswer;
    });

    setActivePole(dataFile.dataPointId);
    setPole(poleCopy);
    setIsChanged(true);
    setIsSaved(false);
    setSurveyResults(null);
  };

  const callFunction = (fn, args = []) => {
    let ret = null;

    switch (fn) {
      case "currentYear":
        ret = getCurrentYear();
        break;
      case "xYearsAgo":
        ret = xYearsAgo(...args);
        break;
      default:
      // Do nothing
    }

    return ret;
  };

  const resolveRange = (range) => {
    let min = 0;
    let max = 0;
    let args = [];

    if (range.min.func) {
      args = range.min.args ?? [];
      min = callFunction(range.min.func, args);
    } else {
      min = range.min;
    }

    if (range.max.func) {
      args = range.max.args ?? [];
      max = callFunction(range.max.func, args);
    } else {
      max = range.max;
    }

    return { min, max };
  };

  const DynInputElement = (props) => {
    const { name, newId, question, el } = props;
    const inputElement = el.value.inputElement;
    const elOptions = el.value.options ?? null;
    let elValues = [];

    if (inputElement === "selectint" && elOptions) {
      if (elOptions.first) elValues.push(elOptions.first);

      const range = resolveRange(elOptions.range);

      for (let i = range.min; i <= range.max; i++) {
        elValues.push({ caption: i, value: i });
      }
    } else {
      elValues = el.value.values ?? [];
    }

    return (
      <div>
        <Label for={name}>{el.value.caption}</Label>
        <div>
          {inputElement === "select" && (
            <CustomInput
              className="mb-05rem"
              type={inputElement}
              id={newId}
              name={name}
              value={pole[question.field] ?? ""}
              onChange={(e) => updatePole(question.field, e.target.value)}
            >
              {elValues.map((item, i) => {
                return (
                  <option key={i} value={item.value}>
                    {item.caption}
                  </option>
                );
              })}
            </CustomInput>
          )}
          {inputElement === "selectint" && (
            <CustomInput
              className="mb-05rem"
              type="select"
              id={newId}
              name={name}
              value={pole[question.field] ?? ""}
              onChange={(e) => updatePole(question.field, e.target.value)}
            >
              {elValues.map((item, i) => {
                return (
                  <option key={i} value={item.value}>
                    {item.caption}
                  </option>
                );
              })}
            </CustomInput>
          )}
        </div>
      </div>
    );
  };

  const downloadFile = async (e, fileName, contentType, setIsDownloading) => {
    e.preventDefault();
    setIsDownloading(true);

    try {
      // Get file id from name
      const fileIdRes = await getMyDriveFile({ fileName });

      if (fileIdRes.status === 200 && fileIdRes.data) {
        const fileId = fileIdRes.data.fileId;

        // Get download url
        const blobUrl = await getDownloadUrl(fileId, "mydrive");

        // Create local blob and url
        const content = await followDownloadUrl(blobUrl);
        const blob = new Blob([content], {
          type: contentType
        });

        if (navigator.msSaveBlob) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          // Create temporary link
          const url = URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.download = fileName;
          link.href = url;
          link.target = "_blank";
          link.click();
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        }
      }
    } catch (e) {
      console.log(e);
    }

    setIsDownloading(false);
  };

  const ShowMediaLink = (props) => {
    const { media } = props;
    const [isDownloading, setIsDownloading] = useState(false);

    return (
      <Button
        className="ml-05rem cursor-pointer"
        href={media.path}
        color="primary"
        size="sm"
        disabled={isDownloading}
        onClick={(e) =>
          downloadFile(e, media.fileName, media.contentType, setIsDownloading)
        }
      >
        {media.caption} <FontAwesomeIcon icon={faDownload} />{" "}
        {isDownloading && <Spinner size="sm" color="light" />}
      </Button>
    );
  };

  const PickInputElement = (props) => {
    const { id, question, defValue } = props;
    const caption = question.caption.replace("{defValue}", `"${defValue}"`);
    const input = question.inputElement;
    const map = dataFile.map;
    const glnxy = pole.default["GLNX-GLNY"];
    const name = `${map}-${glnxy}-${id}`.replace(" ", "-");
    const isMedia = question.pullMedia ? true : false;

    let newId = null;

    const [showDes, setShowDes] = useState(true);
    const toggle = () => setShowDes(!showDes);

    if (input === "radio") {
      return (
        <div>
          <Label for={name}>
            <Button color="link" onClick={() => toggle()}>
              <b>{caption}</b>
            </Button>
            {isMedia &&
              question.pullMedia.map((item, i) => (
                <ShowMediaLink key={i} media={item} />
              ))}
            {showDes && (
              <CardText className="pl-1rem">
                <small>
                  <i>{question.description}</i>
                </small>
              </CardText>
            )}
          </Label>
          <div className="pl-1rem">
            {question.values.map((el, i) => {
              newId = `${map}-${glnxy}-${id}-${i}`.replace(" ", "-");
              const isString = typeof el.value === "string";

              return isString ? (
                <CustomInput
                  key={i}
                  type={question.inputElement}
                  id={newId}
                  name={name}
                  label={el.caption}
                  value={el.value}
                  defaultChecked={el.value === pole[question.field]}
                  onClick={(e) => updatePole(question.field, e.target.value)}
                  inline
                />
              ) : (
                <DynInputElement
                  key={i}
                  name={name}
                  newId={newId}
                  question={question}
                  el={el}
                />
              );
            })}
            {pole[question.field] && (
              <>
                <Button
                  outline
                  color="primary"
                  size="sm"
                  onClick={(e) => updatePole(question.field, null)}
                >
                  Clear
                </Button>
                <CardText className="small-italics mt-05rem">
                  Click on the clear button to leave the question unanswered
                </CardText>
              </>
            )}
          </div>
        </div>
      );
    }

    if (input === "select") {
      const captions = ["Select an answer", ...question.values];
      const values = ["", ...question.values];
      newId = `${map}-${glnxy}-${id}`.replace(" ", "-");

      return (
        <div>
          <Label for={name}>
            <u>{caption}</u>
          </Label>
          <CustomInput
            className="mb-05rem"
            type="select"
            id={newId}
            name={name}
            value={pole[question.field] ?? ""}
            onChange={(e) => updatePole(question.field, e.target.value)}
          >
            {values.map((v, i) => {
              return (
                <option key={i} value={v}>
                  {captions[i]}
                </option>
              );
            })}
          </CustomInput>
        </div>
      );
    }
  };

  const ParsedByField = (props) => {
    const { by, at } = props;

    return (
      <>
        <b>Made available by:</b>
        <br /> {by} {" @ "}
        <Moment>{at}</Moment>
      </>
    );
  };

  const UpdatedByField = (props) => {
    const { by, at } = props;

    return (
      <>
        <b>Last updated by:</b>
        <br /> {by} {" @ "}
        <Moment>{at}</Moment>
      </>
    );
  };

  const PoleLog = () => {
    const parsedBy = dataFile.parsedBy.name;
    const parsedAt = dataFile.parsedAt;

    const updatedBy = dataFile?.updatedBy?.name;
    const updatedAt = dataFile?.updatedAt;

    return (
      <Card
        body
        style={{
          backgroundColor: "#f7f7f7",
          marginTop: "1.5rem"
        }}
      >
        <CardText>
          <ParsedByField by={parsedBy} at={parsedAt} />
        </CardText>
        {updatedBy && (
          <CardText>
            <UpdatedByField by={updatedBy} at={updatedAt} />
          </CardText>
        )}
      </Card>
    );
  };

  const prepareSubmit = () => {
    const questions = getAllQuestions();
    const defects = {
      list: [],
      fixes: null,
      listByCat: {},
      actions: {},
      metadata: {}
    };
    const unansweredFields = { list: [] };
    const totalQuestions = { total: 0, totalByCat: {} };

    // const evalTrigger = (trigger, test) => {
    //   // Eval not in
    //   if (trigger.notIn) {
    //     return !trigger.notIn.includes(test);
    //   }

    //   // Eval in
    //   if (trigger.in) {
    //     return trigger.in.includes(test);
    //   }

    //   return false;
    // };

    questions.forEach((question, i) => {
      const answer = pole[question.field];
      const posAnswer = question.positiveAnswer;
      const isDefect = answer !== posAnswer;
      const isUnanswer = answer === null;
      const answerDefValue =
        dataFile["document"]["default"][question.field] ?? "";

      // Count questions
      totalQuestions.total += 1;
      if (!totalQuestions.totalByCat[question.category])
        totalQuestions.totalByCat[question.category] = 0;
      totalQuestions.totalByCat[question.category] += 1;

      // Collect defects
      if (!isUnanswer && isDefect) {
        defects.list.push(question.field);
        defects.metadata[question.field] = {
          positiveAnswer: posAnswer,
          question: question.caption.replace(
            "{defValue}",
            `"${answerDefValue}"`
          ),
          answer,
          cat: question.category
        };

        // OLD WORKFLOW
        // // Collect actions on defect
        // if (question?.onDefect?.state) {
        //   if (evalTrigger(question.onDefect.trigger, answer)) {
        //     // Push action
        //     defects.actions[question.field] = {
        //       fixable: question.onDefect.fixable,
        //       onDefect: question.onDefect.actions,
        //       onResolved: question.onResolved.actions
        //     };
        //   }
        // }

        // Collect actions on defect
        if (question?.onDefect?.state) {
          // Push action
          defects.actions[question.field] = {
            fixable: question.onDefect.fixable,
            onDefect: question.onDefect.actions,
            onResolved: question.onResolved.actions
          };
        }

        if (!defects.listByCat[question.category])
          defects.listByCat[question.category] = [];
        defects.listByCat[question.category].push(question.field);
      }
      if (isUnanswer) {
        unansweredFields.list.push(question.field);
      }
    });

    setIsReviewing(true);
    setActivePole(dataFile.dataPointId);
    setSurveyResults({ totalQuestions, defects, unansweredFields });
  };

  const Debug = (props) => {
    return (
      <Card style={{ marginTop: "1rem" }}>
        <CardHeader>Debug</CardHeader>
        <CardBody style={{ overflow: "auto", maxHeight: "250px" }}>
          {props.children}
        </CardBody>
      </Card>
    );
  };

  const RequiredLabel = (props) => {
    const { isRequired } = props;
    const caption = isRequired ? "Required" : "Optional";
    const className = isRequired ? "danger" : "primary";

    return (
      <Badge color={className} pill>
        {caption}
      </Badge>
    );
  };

  const ReviewSummary = (props) => {
    const { criticalDefects, unansweredFields } = props;
    const cdLabel = criticalDefects === 0 ? "No" : criticalDefects;
    const cdSing = criticalDefects === 1;
    const ufLabel = unansweredFields === 0 ? "No" : unansweredFields;
    const ufSing = unansweredFields === 1;

    return (
      <div>
        <CardText style={{ marginBottom: "0.25rem" }}>
          <b>Defects Summary</b>
        </CardText>
        <div>
          <ul>
            <li>
              {cdLabel} critical defect{cdSing ? "" : "s"} reported in survey
              questions
            </li>
            <li>
              {ufLabel} question{ufSing ? "" : "s"} were left unanswered
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const ReviewActions = (props) => {
    const { fieldsActions, metadata } = props;
    const fields = Object.keys(fieldsActions);

    return fields.length > 0 ? (
      <div>
        <CardText style={{ marginBottom: "0.25rem" }}>
          <b>Actions</b>
        </CardText>
        <div>
          <ul>
            {fields.map((field, i) => {
              const question = metadata[field].question;
              const answer = metadata[field].answer;
              const actor = "reinforcementInspector";
              const actions = fieldsActions[field]["onDefect"][actor];
              const subject = "submission";

              return (
                <li key={i}>
                  <b>Q:</b> {question} <b>A:</b> {answer}
                  <ol>
                    {/* {Object.keys(actions).map((subject, j) => {
                      return (
                        <li key={j}>
                          <b>Regarding {subject}:</b>
                          <br />
                          <u>{actions[subject]}</u>
                        </li>
                      );
                    })} */}
                    <li>
                      <b>Regarding {subject}:</b>
                      <br />
                      <u>{actions[subject]}</u>
                    </li>
                  </ol>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    ) : (
      <div>
        <CardText style={{ marginBottom: "0.25rem" }}>
          <b>Actions</b>
        </CardText>
        <div>
          <ol>
            <li>Approve the invoice(s) for the pole</li>
          </ol>
        </div>
      </div>
    );
  };

  const SurveyReview = () => {
    const inspectionId = uuidv4();

    const [comments, setComments] = useState(defComments);
    const [commentsError, setCommentsError] = useState(null);
    const [commentsLen, setCommentsLen] = useState(0);
    const [otherDefects, setOtherDefects] = useState(false);

    const allDefects = surveyResults.defects.list;
    const defectsActions = surveyResults.defects.actions;
    const fieldsMetadata = surveyResults.defects.metadata;
    const defectsByCat = surveyResults.defects.listByCat;
    const criticalDefects = defectsByCat?.critical?.length ?? 0;
    const approvedDefects = billingData.approve?.approvals ?? [];
    const unansweredFields = surveyResults.unansweredFields.list.length;
    const shouldItBeRequired = criticalDefects === 0 && unansweredFields > 0;
    const [isRequired, setIsRequired] = useState(shouldItBeRequired);

    let status = "submitted";

    const isDebug = false;

    // Set maximum length to 600 chars
    const maxLen = 2000;

    // Set minimum words
    const minWords = 3;

    // Override status
    if (allDefects.length - approvedDefects.length > 0) {
      status = "submitted with defects";
    } else if (unansweredFields > 0) {
      status = "submitted with warnings";
    }

    const ShowCommentsLength = () => {
      return (
        <span style={{ float: "right", color: "#6c757d!important" }}>
          <small>{maxLen - commentsLen} chars left</small>
        </span>
      );
    };

    const handleComments = (value, isRequired) => {
      // Remove carriage returns
      let testString = value.replace(/[\r\n]/g, " ");

      // Remove extra spaces
      testString = testString.replace(/[ ]{2,}/g, " ");

      // Check for full sentence: 3 words minimum
      const words = testString.match(/\b(\w|')+\b/gim) ?? 0;

      // Exit if max length is exceeded
      if (testString.length > maxLen) return false;

      setCommentsLen(testString.length);

      if (words.length < minWords) {
        setCommentsError("Please type full sentences");
      } else {
        setCommentsError(null);
      }

      if (isRequired) {
        setComments(testString);
      } else {
        setComments(testString);
      }
    };

    const handleOtherDefects = (flag) => {
      setOtherDefects(flag);

      if (!shouldItBeRequired) {
        setIsRequired(flag);
      }
    };

    const validateReview = () => {
      if (isRequired && !comments) return false;

      if (comments) {
        // Check for full sentence: 3 words minimum
        const words = comments.match(/\b(\w|')+\b/gim) ?? 0;

        // Exit if max length is exceeded
        if (words.length < minWords) return false;
      }

      return true;
    };

    return (
      <Card className="mt-1rem mb-1rem">
        <CardHeader className={`card-header-${statusClass}`}>
          <div>Inspection Review</div>
        </CardHeader>
        <CardBody>
          <ReviewSummary
            criticalDefects={criticalDefects}
            unansweredFields={unansweredFields}
          />
          <ReviewActions
            fieldsActions={defectsActions}
            metadata={fieldsMetadata}
          />
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                id={`other-defects-${inspectionId}`}
                defaultChecked={otherDefects}
                onChange={(e) => handleOtherDefects(e.target.checked)}
                disabled={isSubmitting}
              />{" "}
              Other defects found{" "}
              <small>
                <i>(comments required)</i>
              </small>
            </Label>
          </FormGroup>
          <FormText className="mb-1rem">
            Check this box for defects that are not covered by the survey
            questions
          </FormText>
          {/* These would the action items based on current responses */}
          <FormGroup>
            <Label for={`comments-${inspectionId}`}>
              <b>Comments</b> <RequiredLabel isRequired={isRequired} />
            </Label>
            <ShowCommentsLength />
            <Input
              invalid={commentsError ? true : false}
              type="textarea"
              name={`comments-${inspectionId}`}
              id={`comments-${inspectionId}`}
              onChange={(e) => handleComments(e.target.value, isRequired)}
              value={comments ? comments : ""}
              disabled={isSubmitting}
            />
            {isRequired && (
              <FormText>
                Comments are required when there was at least one question
                unanswered and there were no critical defects reported.
              </FormText>
            )}
            <FormFeedback>{commentsError}</FormFeedback>
          </FormGroup>
          <Button
            outline
            color="danger"
            size="sm"
            onClick={() =>
              submitSurvey(inspectionId, comments, status, otherDefects)
            }
            disabled={isSubmitting || !validateReview()}
          >
            <b>Submit Inspection</b>{" "}
            {isSubmitting && <Spinner size="sm" color="dark" />}
          </Button>{" "}
          <Button
            outline
            color="default"
            size="sm"
            onClick={() => setIsReviewing(false)}
          >
            Cancel
          </Button>
          {isDebug && (
            <Debug>
              <Json data={surveyResults} />
            </Debug>
          )}
        </CardBody>
      </Card>
    );
  };

  const CategoryBox = (props) => {
    const { cat, children, statusClass } = props;

    return (
      <Card className="mb-1rem">
        <CardHeader className={`card-header-${statusClass}`}>
          {phraseToProperCase(cat)}
        </CardHeader>
        <CardBody>{children}</CardBody>
      </Card>
    );
  };

  const DefectsLabel = (props) => {
    const { defects, metadata } = props;
    const listOfDefects = [];

    if (defects.list.length > 0) {
      const keys = Object.keys(defects.listByCat);

      keys.forEach((cat) => {
        defects.listByCat[cat].map((name) => listOfDefects.push({ cat, name }));
      });
    }

    return listOfDefects.length ? (
      <>
        {listOfDefects.map((defect, i) => {
          const colorClass = defect.cat === "critical" ? "danger" : "warning";

          return (
            <span key={i}>
              <Badge color={colorClass}>{metadata[defect.name].question}</Badge>
              <br />
            </span>
          );
        })}
      </>
    ) : (
      "No defects found"
    );
  };

  const OnDefectReinfInsActionsList = (props) => {
    const { actions, metadata, billing } = props;
    const fields = Object.keys(actions);
    let showActions = true;

    const defects = billing?.reinspect?.reinspections ?? [];

    showActions = defects.length !== 0;

    return showActions ? (
      <ol>
        {fields.map((field, i) => {
          const question = metadata[field].question;
          const answer = metadata[field].answer;
          const actorActions =
            actions[field]["onDefect"]["reinforcementInspector"];

          if (defects && !defects.includes(field)) return "";

          const subjects = Object.keys(actorActions).filter(
            (subject) => subject === "reinspection"
          );
          const show = subjects.length > 0;

          return (
            <li key={i}>
              <b>Q: </b> {question} <b>A: </b> {answer}
              <br />
              {show &&
                subjects.map((subject, i) => {
                  return (
                    <ul key={i}>
                      <li>
                        <b>Regarding {phraseToProperCase(subject)}:</b>
                        <ul>
                          <li>
                            <u>{actorActions[subject]}</u>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  );
                })}
            </li>
          );
        })}
      </ol>
    ) : (
      <CardText className="ml-1rem">No actions required</CardText>
    );
  };

  const OnDefectVendorActionsList = (props) => {
    const { actions, metadata, billing } = props;
    const fields = Object.keys(actions);
    let showActions = true;

    const defects = billing?.reinspect?.reinspections ?? [];

    showActions = defects.length !== 0;

    return showActions ? (
      <ol>
        {fields.map((field, i) => {
          const question = metadata[field].question;
          const answer = metadata[field].answer;
          const actorActions = actions[field]["onDefect"]["vendor"];

          if (defects && !defects.includes(field)) return "";

          return (
            <li key={i}>
              <b>Q: </b> {question} <b>A: </b> {answer}
              <br />
              {Object.keys(actorActions).map((source, i) => {
                const subjects = Object.keys(actorActions[source]).filter(
                  (subject) => subject !== "invoices"
                );

                const show = subjects.length > 0;

                return (
                  show && (
                    <span key={i}>
                      <ul>
                        {subjects.map((subject, k) => {
                          return (
                            <li key={k}>
                              <b>Regarding {phraseToProperCase(subject)}:</b>
                              <ul>
                                <li>
                                  <u>{actorActions[source][subject]}</u>
                                </li>
                              </ul>
                            </li>
                          );
                        })}
                      </ul>
                    </span>
                  )
                );
              })}
            </li>
          );
        })}
      </ol>
    ) : (
      <CardText className="ml-1rem">No actions required</CardText>
    );
  };

  const InspectionCard = (props) => {
    const { inspection, cats } = props;

    const isCollapsed = false;
    const [collapse, setCollapse] = useState(isCollapsed);
    const [collapseIcon, setCollapseIcon] = useState(faChevronDown);

    const toggle = () => setCollapse(!collapse);
    const onEntering = () => setCollapseIcon(faChevronUp);
    const onExiting = () => setCollapseIcon(faChevronDown);

    const statusClass = statusColorCoding[inspection.status];
    const defects = inspection.results.defects;
    const actions = inspection.results.defects.actions;
    const actionsFields = Object.keys(actions);
    const metadata = inspection.results.defects.metadata;
    const otherDefects = inspection.results.otherDefects;
    const billing = inspection.results.billing;
    const comments = inspection.document["ReinforcementQAComments"];
    const qaDate = inspection.document["ReinforcementQADate"];

    return (
      <Card className="mb-1rem">
        <CardHeader className={`card-header-${statusClass}`}>
          <div>
            Inspection by {inspection.user.name} on {qaDate}{" "}
            <Button
              color={statusClass}
              size="sm"
              onClick={toggle}
              className="collapse-button"
            >
              <FontAwesomeIcon icon={collapseIcon} />
            </Button>
          </div>
        </CardHeader>
        <Collapse
          isOpen={collapse}
          onEntering={onEntering}
          onExiting={onExiting}
        >
          <CardBody>
            <CardBody style={{ paddingTop: "0px" }}>
              <CardText>
                <b>Inspection Id:</b> {inspection.inspectionId}
              </CardText>
              <CardText>
                <b>Status:</b>{" "}
                <Badge color={statusClass} pill>
                  {phraseToProperCase(inspection.status)}
                </Badge>
              </CardText>
              <CardText>
                <b>Inspected by:</b> {inspection.user.name} @{" "}
                <Moment>{inspection.submittedAt}</Moment>
              </CardText>
              <CardText>
                <b>Defects:</b>
                <br />
                <DefectsLabel defects={defects} metadata={metadata} />
              </CardText>
              {otherDefects && (
                <CardText style={{ marginBottom: "0px" }}>
                  <b>Other defects:</b> {otherDefects ? "yes" : "no"}
                </CardText>
              )}
              {comments && (
                <CardText className="mt-1rem">
                  <b>Comments: </b>
                  <br />
                  {comments}
                </CardText>
              )}
            </CardBody>
            {actionsFields.length > 0 && (
              <Card className="mb-15rem">
                <CardHeader>Actions</CardHeader>
                <CardBody style={{ paddingLeft: "0rem" }}>
                  <OnDefectVendorActionsList
                    actions={actions}
                    metadata={metadata}
                    billing={billing}
                  />
                </CardBody>
              </Card>
            )}
            <Card>
              <CardHeader>Survey</CardHeader>
              <CardBody>
                {cats.map((cat, i) => {
                  const questions = getQuestionsByCategory(cat);

                  return questions.length > 0 ? (
                    <CategoryBox key={i} cat={cat} statusClass="default">
                      {questions.map((q, j) => {
                        const defValue =
                          inspection["document"]["default"][q.field] ?? "";
                        const caption = q.caption.replace(
                          "{defValue}",
                          `"${defValue}"`
                        );
                        const fieldValue = pole[q.field];
                        const isUnanswer = fieldValue === null;
                        const isPos = q.positiveAnswer === fieldValue;
                        const answer = isPos
                          ? "Yes"
                          : isUnanswer
                          ? "Not answered"
                          : fieldValue;

                        return (
                          <div key={j}>
                            <b>{caption}</b>
                            <br />
                            <i>Answer: {answer}</i>
                          </div>
                        );
                      })}
                    </CategoryBox>
                  ) : (
                    ""
                  );
                })}
              </CardBody>
            </Card>
            <Card className="mt-1rem">
              <CardHeader>Photos</CardHeader>
              <CardBody>
                {inspection.media && (
                  <PhotoPreview curMedia={inspection.media} />
                )}
                {!inspection.media && (
                  <CardText>No photos available...</CardText>
                )}
              </CardBody>
            </Card>
          </CardBody>
        </Collapse>
      </Card>
    );
  };

  const DisplaySubmittedSurveys = () => {
    const cats = getSurveyCategories();

    const [inspections, setInspections] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const isCollapsed = false;
    const [collapse, setCollapse] = useState(isCollapsed);
    const [collapseIcon, setCollapseIcon] = useState(faChevronDown);

    const toggle = () => setCollapse(!collapse);
    const onEntering = () => setCollapseIcon(faChevronUp);
    const onExiting = () => setCollapseIcon(faChevronDown);

    const handlePull = async () => {
      setIsLoading(true);

      const res = await getReinfInspections(
        dataFile.circuit,
        dataFile.dataPointId
      );

      if (res.status === 200) {
        setInspections(res.response);

        setIsLoading(false);
      }
    };

    return (
      <Card>
        <CardHeader className="default">
          <div>
            Inspection History
            <Button
              color="default"
              size="sm"
              onClick={toggle}
              className="collapse-button"
            >
              <FontAwesomeIcon icon={collapseIcon} />
            </Button>
          </div>
        </CardHeader>
        <Collapse
          isOpen={collapse}
          onEntering={onEntering}
          onExiting={onExiting}
        >
          <CardBody>
            {!inspections && (
              <Button
                color={statusClass}
                outline
                size="sm"
                onClick={handlePull}
                disabled={isLoading}
              >
                Pull inspections{" "}
                {isLoading && <Spinner size="sm" color="dark" />}
              </Button>
            )}
            {inspections &&
              inspections.map((inspection, k) => {
                return (
                  <InspectionCard key={k} inspection={inspection} cats={cats} />
                );
              })}
          </CardBody>
        </Collapse>
      </Card>
    );
  };

  const DisplayBillingData = () => {
    const metadata = billingData.metadata;
    const approved = billingData.approve;
    const rejected = billingData.reject;

    return (
      <Card className="mb-1rem">
        <CardHeader>Billing History</CardHeader>
        <CardBody style={{ paddingBottom: "0.25rem" }}>
          {approved.map((defect, i) => {
            return (
              <Card className="mb-1rem" key={i}>
                <CardHeader>Approved</CardHeader>
                <CardBody>
                  <CardText className="mb-05rem">
                    <b>Approved by:</b>
                    <br />
                    {defect.approvedBy.name} {"@"}
                    <Moment>{defect.approvedAt}</Moment>
                  </CardText>
                  <CardText className="mb-05rem">
                    <b>Approvals:</b>
                  </CardText>
                  <ol>
                    {defect.approvals.map((field, i) => {
                      return (
                        <li key={i}>
                          <b>Q:</b> {metadata[field].question}
                          <br />
                          <b>A:</b> {metadata[field].answer}
                        </li>
                      );
                    })}
                  </ol>
                </CardBody>
              </Card>
            );
          })}
          {rejected.map((defect, i) => {
            return (
              <Card className="mb-1rem" key={i}>
                <CardHeader>Rejected</CardHeader>
                <CardBody>
                  <CardText className="mb-05rem">
                    <b>Rejected by:</b>
                    <br />
                    {defect.rejectedBy.name} {"@"}
                    <Moment>{defect.rejectedAt}</Moment>
                  </CardText>
                  <CardText className="mb-05rem">
                    <b>Rejections:</b>
                  </CardText>
                  <ol>
                    {defect.rejections.map((field, i) => {
                      return (
                        <li key={i}>
                          <b>Q:</b> {metadata[field].question}
                          <br />
                          <b>A:</b> {metadata[field].answer}
                        </li>
                      );
                    })}
                  </ol>
                </CardBody>
              </Card>
            );
          })}
        </CardBody>
      </Card>
    );
  };

  const DisplayReinspectionAction = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [inspectionResults, setInspectionResults] = useState(null);

    // // Override status class
    // const statusClass = "default";

    const handlePull = async () => {
      setIsLoading(true);

      const res = await getLastReinfInspection(
        dataFile.circuit,
        dataFile.dataPointId
      );

      if (res.status === 200) {
        if (res.response.length > 0) {
          setInspectionResults(res.response[0].results);

          setIsLoading(false);
        }
      }
    };

    return (
      <Card className="mb-1rem">
        <CardHeader>Reinspection Actions</CardHeader>
        <CardBody style={{ paddingLeft: "0rem" }}>
          {!inspectionResults && (
            <Button
              className="ml-1rem"
              color={statusClass}
              outline
              size="sm"
              onClick={handlePull}
              disabled={isLoading}
            >
              Pull inspection actions{" "}
              {isLoading && <Spinner size="sm" color="dark" />}
            </Button>
          )}
          {inspectionResults && (
            <OnDefectReinfInsActionsList
              actions={inspectionResults.defects.actions}
              metadata={inspectionResults.defects.metadata}
              billing={inspectionResults.billing}
            />
          )}
        </CardBody>
      </Card>
    );
  };

  const ManageMedia = () => {
    const [collapse, setCollapse] = useState(isPhotosCollapsed);
    const [collapseIcon, setCollapseIcon] = useState(faChevronDown);

    const toggle = () => setCollapse(!collapse);
    const onEntering = () => setCollapseIcon(faChevronUp);
    const onExiting = () => setCollapseIcon(faChevronDown);

    // Override status class
    const statusClass = "default";

    const docInfo = {
      dataPointId: dataFile.dataPointId,
      fieldset: dataFile.fieldset
    };

    const updateMedia = (media) => {
      setMedia(media);
      setIsPhotosCollapsed(true);
    };

    return (
      <Card className="mt-1rem mb-1rem">
        <CardHeader className={`card-header-${statusClass}`}>
          <div>
            Manage Photos{" "}
            <Button
              color={statusClass}
              size="sm"
              onClick={toggle}
              className="collapse-button"
            >
              <FontAwesomeIcon icon={collapseIcon} />
            </Button>
          </div>
        </CardHeader>
        <Collapse
          isOpen={collapse}
          onEntering={onEntering}
          onExiting={onExiting}
        >
          <PhotoUpload
            curMedia={media}
            updateMedia={updateMedia}
            docInfo={docInfo}
          />
        </Collapse>
      </Card>
    );
  };

  const DisplaySurvey = () => {
    const cats = getSurveyCategories();

    const isCollapsed = dataFile.dataPointId === activePole;
    const [collapse, setCollapse] = useState(isCollapsed);
    const [collapseIcon, setCollapseIcon] = useState(faChevronDown);

    const toggle = () => setCollapse(!collapse);
    const onEntering = () => setCollapseIcon(faChevronUp);
    const onExiting = () => setCollapseIcon(faChevronDown);

    // Override status class
    const statusClass = "default";

    const YesToAllButton = () => {
      return (
        <div style={{ overflow: "hidden" }}>
          <Button
            color="link"
            className="float-right"
            onClick={() => yesToAll()}
          >
            Yes to all
          </Button>
        </div>
      );
    };

    return (
      <Card className="mt-1rem mb-1rem">
        <CardHeader className={`card-header-${statusClass}`}>
          <div>
            Survey Questions{" "}
            <Button
              color={statusClass}
              size="sm"
              onClick={toggle}
              className="collapse-button"
            >
              <FontAwesomeIcon icon={collapseIcon} />
            </Button>
          </div>
        </CardHeader>
        <Collapse
          isOpen={collapse}
          onEntering={onEntering}
          onExiting={onExiting}
        >
          <div>
            <CardBody style={{ paddingTop: "0px", paddingBottom: "0.5rem" }}>
              <YesToAllButton />
              {cats.map((cat, i) => {
                const questions = getQuestionsByCategory(cat);

                return questions.length > 0 ? (
                  <CategoryBox key={i} cat={cat} statusClass={statusClass}>
                    {questions.map((q, j) => {
                      const id = `${phraseToProperCase(cat)}-q${j}`;
                      const defValue =
                        dataFile.document["default"][q.field] ?? "";

                      return (
                        <FormGroup key={j}>
                          <PickInputElement
                            id={id}
                            question={q}
                            defValue={defValue}
                          />
                        </FormGroup>
                      );
                    })}
                  </CategoryBox>
                ) : (
                  ""
                );
              })}
              <YesToAllButton />
            </CardBody>
            <CardFooter>
              <Button
                outline
                color="primary"
                size="sm"
                onClick={() => saveSurvey()}
                disabled={isLoading || !isChanged}
              >
                Save {isLoading && <Spinner size="sm" color="dark" />}
              </Button>{" "}
              <Button
                outline
                color="danger"
                size="sm"
                onClick={() => resetSurvey()}
                disabled={isLoading || !isChanged}
              >
                Undo
              </Button>{" "}
              <Button
                outline
                color="primary"
                size="sm"
                onClick={() => prepareSubmit()}
                disabled={isLoading || !isSaved}
                style={{ float: "right" }}
              >
                Review
              </Button>
            </CardFooter>
          </div>
        </Collapse>
      </Card>
    );
  };

  const getDefValue = (field) => {
    const defValues = pole.default ?? null;

    if (defValues === null) return "";

    return defValues[field];
  };

  const StatusBadge = () => {
    const status = dataFile["status"];
    let statusLabel = status;

    switch (status) {
      case "inspect":
        statusLabel = "ready for inspection";
        break;
      case "reinspect":
        statusLabel = "ready for reinspection";
        break;
      default:
      // Do nothing
    }

    return (
      <Badge color={statusColorCoding[status]} pill>
        {phraseToProperCase(statusLabel)}
      </Badge>
    );
  };

  const OutdatedWarning = () => {
    const handleUpdate = () => {
      const inspection = dataFile.inspections[0];
      const comments = inspection.document["ReinforcementQAComments"];
      const media = inspection.media;

      const copyOverrides = { ...overrides };
      if (!copyOverrides.survey) copyOverrides["survey"] = {};

      copyOverrides["survey"]["user"] = inspection.user;
      copyOverrides["survey"]["timestamp"] = inspection.submittedAt;
      copyOverrides["survey"]["ReinforcementQADate"] =
        inspection.document.ReinforcementQADate;

      setOverrides(copyOverrides);
      setDefComments(comments);
      setMedia(media);
      setRiaVersion(CUR_VERSION);
      prepareSubmit();
      setIsReviewing(true);
    };

    return (
      <>
        <Alert color="danger">
          <CardText>
            <b>Unabled to display inspections!</b>
            <br />
            The current version of the Pole Reinforcement Audit document is
            outdated.
          </CardText>
          <Button
            size="sm"
            color="danger"
            onClick={handleUpdate}
            disabled={isLoading}
          >
            Update to v. {CUR_VERSION}{" "}
            {isSubmitting && <Spinner size="sm" color="light" />}
          </Button>
        </Alert>
      </>
    );
  };

  return (
    <div className="ReinforcementInspectionPole">
      <Card className="mb-1rem">
        <CardHeader className={`card-header-${statusClass}`}>
          Pole: {getDefValue("GLNX-GLNY")}
        </CardHeader>
        <CardBody>
          {!more && <MoreButton caption="More..." />}
          {more && <MoreButton caption="Less..." />}
          <CardText>
            <b>Pole:</b> {getDefValue("GLNX-GLNY")}
          </CardText>
          <CardText>
            <b>Status:</b> <StatusBadge />
          </CardText>
          {isSubmitted && (
            <>
              <CardText>
                <b>Last inspected on:</b> {pole["ReinforcementQADate"]}
              </CardText>
              {pole["ReinforcementQAComments"] && (
                <CardText>
                  <b>Comments:</b> {pole["ReinforcementQAComments"]}
                </CardText>
              )}
            </>
          )}
          <CardText>
            <b>Map:</b> {poleMap}
          </CardText>
          <CardText>
            <b>Circuit:</b> {getDefValue("OHPrimaryCircuitNumber")}
          </CardText>
          <CardText>
            <b>Version:</b> {riaVersion}
          </CardText>
          {more && (
            <>
              <CardText>
                <b>Inspection tag:</b> {getDefValue("InspectionTagType")}
              </CardText>
              <CardText>
                <b>Owner:</b> {getDefValue("PoleTagOwner")}
              </CardText>
              <CardText>
                <b>Install year:</b> {getDefValue("PoleTagInstallationYear")}
              </CardText>
              <CardText>
                <b>Class:</b> {getDefValue("PoleTagClass")}
              </CardText>
              <CardText>
                <b>Height:</b> {getDefValue("PoleTagLength")}
              </CardText>
              <CardText>
                <b>Species/Trt:</b> {getDefValue("PoleTagSpeciesTreatmentCode")}
              </CardText>
              <CardText>
                <b>Void Trt:</b> {getDefValue("VoidTreatmentTagAttached")}
              </CardText>
              <CardText>
                <b>Truss:</b> {getDefValue("TrussInstalledSize")}
              </CardText>
            </>
          )}
          {isSubmitted && isThereBillingData && <DisplayBillingData />}
          {isReinspect && <DisplayReinspectionAction />}
          {!isReviewing && !isSubmitted && <DisplaySurvey />}
          {!isReviewing && !isSubmitted && <ManageMedia />}
          {isReviewing && surveyResults && <SurveyReview />}
          {areThereInspections && <DisplaySubmittedSurveys />}
          {isOutdated && areThereInspections && !isReviewing && (
            <OutdatedWarning />
          )}
          <PoleLog />
        </CardBody>
      </Card>
      <ErrorModal />
    </div>
  );
};

export default ReinforcementInspectionPole;
