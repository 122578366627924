import React from "react";
import Rep2Comp from "../components/Rep2Comp";
import "./Rep2.css";

const Rep2 = (props) => {
  return (
    <div className="Rep2">
      <div className="container">
        <Rep2Comp {...props} />
      </div>
    </div>
  );
};

export default Rep2;
