import { useEffect, useState } from "react";
import { defer } from "rxjs";
import { getDataFileEntry } from "../services/dataFileService";

export const useGetDataFile = (dataPointId, fieldset) => {
  const [dataFile, setDataFile] = useState(null);

  useEffect(() => {
    const fetchDataFile = async () => {
      return await getDataFileEntry(dataPointId, fieldset);
    };

    const subscription = defer(() => fetchDataFile()).subscribe(setDataFile);

    return () => {
      subscription.unsubscribe();
    };
  }, [dataPointId, fieldset]);

  return dataFile;
};
