import { v4 as uuidv4 } from "uuid";

const NewSetting = (scope, object, setting, userInfo) => {
  const ret = {
    settingId: uuidv4(),
    scope,
    object,
    setting,
    createdBy: userInfo
  };

  return ret;
};

export { NewSetting };
