import React from "react";
import DoBreakFixData from "../components/DoBreakFixData";
import "./BreakFixData.css";

const BreakFixData = (props) => {
  return (
    <div className="BreakFixData">
      <div className="container">
        <DoBreakFixData {...props} />
      </div>
    </div>
  );
};

export default BreakFixData;
