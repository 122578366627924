import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner
} from "reactstrap";
import { insertMyDriveFile } from "../services/vendorDriveService";
import { storeInMyDrive } from "../services/filesService";
import { myDriveNotify } from "../services/myDriveNotify";
import bsCustomFileInput from "bs-custom-file-input";
import prettyBytes from "pretty-bytes";
import { v4 as uuidv4 } from "uuid";
import base64 from "base-64";
import {
  getContentTypeVariations,
  standarizeContentType
} from "../models/ContentTypes";
import "./MyDriveUpload.css";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const contentTypeVariations = getContentTypeVariations();

const checkContentType = (type) => {
  return contentTypeVariations.includes(type);
};

const MyDriveUpload = (props) => {
  const { user, adminMailPrefs, sendEmail } = props;

  const [isLoading, setIsLoading] = useState(false);

  let fileReader = [];
  let tempFileContents = [];

  const [fileCategory, setFileCategory] = useState("");
  const [fileContents, setFileContents] = useState(tempFileContents);

  useEffect(() => {
    bsCustomFileInput.init();
  }, []);

  const MAX_UPLOAD_FILES = 1;
  const MAX_UPLOAD_SIZE = 60000000; // 60Mb

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const validateForm = () => {
    return fileCategory.length > 0 && fileContents.length > 0;
  };

  const resetForm = () => {
    // Reset form state
    tempFileContents = [];
    setFileContents(tempFileContents);
    setFileCategory("");

    // Reset form
    document.getElementById("upload-mydrive-form").reset();
  };

  const getToRecipients = () => {
    const admins = adminMailPrefs.users ? adminMailPrefs.users : [];
    const recipients = [];

    admins.map((admin) => {
      if (admin.programs.includes(fileCategory)) {
        recipients.push({ name: admin.name, address: admin.email });
      }

      return false;
    });

    return recipients;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    await delay(1000);

    // Process
    try {
      // Upload files
      if (fileContents.length) {
        const fileContent = fileContents[0];

        const uploadInfo = {
          user: {
            id: user.id,
            name: user.name,
            email: user.email,
            org: user.org
          },
          program: fileCategory,
          uploads: []
        };

        // Store zip file in Blob storage
        const storeRes = await storeInMyDrive({
          fileName: fileContent.fileId,
          data: base64.encode(fileContent.data),
          base64: true
        });

        if (storeRes.status === 200) {
          // Insert upload record in database
          const standardContentType = standarizeContentType(
            fileContent.contentType
          );

          const uploadDoc = {
            fileId: fileContent.fileId,
            fileName: fileContent.fileName,
            size: fileContent.size,
            contentType: standardContentType,
            category: fileCategory,
            org: user.org,
            sharedWith: { list: [] },
            purge: true,
            createdBy: {
              id: user.id,
              name: user.name,
              email: user.email,
              org: user.org
            }
          };

          const insertRes = await insertMyDriveFile(uploadDoc);

          if (insertRes.status === 200) {
            // Collect upload info for notification
            uploadInfo.uploads.push({
              fileId: fileContent.fileId,
              fileName: fileContent.fileName,
              size: fileContent.size,
              contentType: standardContentType
            });

            // Send notifications
            const toRecipients = getToRecipients();
            if (toRecipients.length) {
              const userInfo = {
                id: user.id,
                name: user.name,
                address: user.email
              };

              await myDriveNotify(
                sendEmail,
                userInfo,
                toRecipients,
                uploadInfo
              );
            }

            showCustomModal({
              title: "Successful file upload",
              message: "File was successfully uploaded."
            });
          } else {
            showCustomModal({
              title: "Unsuccessful DB insert",
              message: `Remote server returned ${insertRes.status}`
            });
          }
        } else {
          showCustomModal({
            title: "Unsuccessful file upload",
            message: `Remote server returned ${storeRes.status}`
          });
        }
      }
    } catch (e) {
      showCustomModal({
        title: "Unexpected error while inserting upload in database",
        message: JSON.stringify(e)
      });
    }

    resetForm();
    setIsLoading(false);
  };

  const handleFileChange = (e) => {
    const fileList = e.target.files;
    let objIndex;

    setIsLoading(true);

    if (fileList.length > MAX_UPLOAD_FILES) {
      showCustomModal({
        title: "File quantity limit error",
        message: `Please pick no more than ${MAX_UPLOAD_FILES} file(s) to upload.`
      });

      resetForm();
      setIsLoading(false);
      return;
    }

    const onLoadEndHandler = (file) => {
      return (e) => {
        tempFileContents.push({
          fileId: uuidv4(),
          fileName: file.name,
          size: file.size,
          contentType: file.type,
          data: e.target.result
        });

        setFileContents(tempFileContents);
        setIsLoading(false);
      };
    };

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];

      // Check file maximum size
      if (file && file.size > MAX_UPLOAD_SIZE) {
        showCustomModal({
          title: "File size limit error",
          message: `${
            file.name
          } is too large. Please pick a file smaller than ${prettyBytes(
            MAX_UPLOAD_SIZE
          )}.`
        });

        resetForm();
        setIsLoading(false);
        return;
      }

      // Check file minimum size
      if (file && file.size <= 0) {
        showCustomModal({
          title: "File size limit error",
          message: `${file.name} is empty.`
        });

        resetForm();
        setIsLoading(false);
        return;
      }

      if (file && !checkContentType(file.type)) {
        showCustomModal({
          title: "File type error",
          message: `The content type of ${file.name} is not allowed.`
        });

        resetForm();
        setIsLoading(false);
        return;
      }

      // Add to file content
      objIndex = fileReader.push(new FileReader()) - 1;
      fileReader[objIndex].onloadend = onLoadEndHandler(file);
      fileReader[objIndex].readAsBinaryString(file);
    }

    return true;
  };

  return (
    <div className="MyDriveUpload">
      <Card className="upload-card">
        <CardBody>
          <Form onSubmit={handleSubmit} id="upload-mydrive-form">
            <h5 className="card-title">Select the program</h5>
            <FormGroup tag="fieldset">
              {user.prefs.programs.map((el, i) => (
                <FormGroup key={i} check>
                  <Label check>
                    <Input
                      type="radio"
                      name="fileCategory"
                      value={el}
                      checked={fileCategory === el ? "checked" : ""}
                      onChange={(e) => setFileCategory(e.target.value)}
                      disabled={isLoading}
                    />{" "}
                    {el}
                  </Label>
                </FormGroup>
              ))}
            </FormGroup>
            <h5 className="card-title">Please select the file to upload</h5>
            <div className="form-group custom-file">
              <input
                id="my-drive-files"
                type="file"
                multiple
                className="custom-file-input"
                disabled={isLoading}
                onChange={handleFileChange}
              />
              <label className="custom-file-label" htmlFor="my-drive-files">
                Pick a file up to {prettyBytes(MAX_UPLOAD_SIZE)} in size
              </label>
            </div>
            <Button
              className="btn-lg"
              disabled={!validateForm() || isLoading}
              color="primary"
              block
            >
              Upload {isLoading && <Spinner size="sm" color="light" />}
            </Button>
          </Form>
        </CardBody>
      </Card>
      <ErrorModal />
    </div>
  );
};

export default MyDriveUpload;
