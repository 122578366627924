import React from "react";
import { Route, Switch } from "react-router-dom";

// Containers
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Upload from "./containers/Upload";
import Uploads from "./containers/Uploads";
import MyDrive from "./containers/MyDrive";
import DataFields from "./containers/DataFields";
import Users from "./containers/Users";
import ComplexRules from "./containers/ComplexRules";
import Releases from "./containers/Releases";
import CircuitAudits from "./containers/CircuitAudits";
import ReinforcementInspection from "./containers/ReinforcementInspection";
import Reports from "./containers/Reports";
import Rep1 from "./containers/Rep1";
import Rep2 from "./containers/Rep2";
import IntegrityChecks from "./containers/IntegrityChecks";
import TestData from "./containers/TestData";
import Seeding from "./containers/Seeding";
import BreakFixData from "./containers/BreakFixData";
import Mail from "./containers/Mail";
import UploadPicturesTest from "./containers/UploadPicturesTest";
import AssocTableTest from "./containers/AssocTableTest";
import PoleReinforcementInspection from "./containers/PoleReinforcementInspection";
import AzCostManagement from "./containers/AzCostManagement";
import PreviewUploads from "./containers/CircuitPhotos";

// Authentication
import AuthenticatedRoute from "./components/AuthenticatedRoute";

const Routes = ({ appProps }) => {
  return (
    <Switch>
      <AuthenticatedRoute path="/" exact component={Home} appProps={appProps} />
      <AuthenticatedRoute
        path="/upload"
        exact
        component={Upload}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/uploads"
        exact
        component={Uploads}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/my-drive"
        exact
        component={MyDrive}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/data-fields"
        exact
        component={DataFields}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/users"
        exact
        component={Users}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/complex-rules"
        exact
        component={ComplexRules}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/releases"
        exact
        component={Releases}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/circuit-audits"
        exact
        component={CircuitAudits}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/reinforcement-inspection"
        exact
        component={ReinforcementInspection}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/reports"
        exact
        component={Reports}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/rep1"
        exact
        component={Rep1}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/rep2"
        exact
        component={Rep2}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/testdata"
        exact
        component={TestData}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/integrity"
        exact
        component={IntegrityChecks}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/seeding"
        exact
        component={Seeding}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/break-fix-data"
        exact
        component={BreakFixData}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/mail"
        exact
        component={Mail}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/upload-pictures-test"
        exact
        component={UploadPicturesTest}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/assoc-table-test"
        exact
        component={AssocTableTest}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/pole-reinforcement-inspection"
        exact
        component={PoleReinforcementInspection}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/az-cost-management"
        exact
        component={AzCostManagement}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/circuit-photos"
        exact
        component={PreviewUploads}
        appProps={appProps}
      />
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
