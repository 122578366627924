import React from "react";
import Guest from "../components/Guest";
import Admin from "../components/Admin";
import Audit from "../components/Audit";
import Analyst from "../components/Analyst";
import ReinforcementInspector from "../components/ReinforcementInspector";
import LeadReinforcementInspector from "../components/LeadReinforcementInspector";
import Vendor from "../components/Vendor";
import Developer from "../components/Developer";
import PhotoViewer from "../components/PhotoViewer";
import "./Home.css";

const Home = (props) => {
  const { user, adminMailPrefs, sendEmail } = props;

  const renderLander = () => {
    return (
      <div className="container">
        <h2>Not authenticated</h2>
      </div>
    );
  };

  const renderAdminOptions = () => {
    return (
      <div className="container">
        <Admin user={user} />
      </div>
    );
  };

  const renderPhotoViewerOptions = () => {
    return (
      <div className="container">
        <PhotoViewer user={user} />
      </div>
    )
  }

  const renderAuditOptions = () => {
    return (
      <div className="container">
        <Audit user={user} />
      </div>
    );
  };

  const renderAnalystOptions = () => {
    return (
      <div className="container">
        <Analyst user={user} />
      </div>
    );
  };

  const renderRInspectorOptions = () => {
    return (
      <div className="container">
        <ReinforcementInspector user={user} />
      </div>
    );
  };

  const renderLRInspectorOptions = () => {
    return (
      <div className="container">
        <LeadReinforcementInspector user={user} />
      </div>
    );
  };

  const renderVendorOptions = () => {
    return (
      <div className="container">
        <Vendor user={user} />
      </div>
    );
  };

  const renderDevOptions = () => {
    return (
      <div className="container">
        <Developer user={user} sendEmail={sendEmail} />
      </div>
    );
  };

  const renderGuestOptions = () => {
    return (
      <div className="container">
        <Guest
          user={user}
          adminMailPrefs={adminMailPrefs}
          sendEmail={sendEmail}
        />
      </div>
    );
  };

  const renderOptions = () => {
    if (!user) return renderLander();

    switch (user.role) {
      case "admin":
        return renderAdminOptions();
      case "vendor":
        return renderVendorOptions();
      case "audit":
        return renderAuditOptions();
      case "analyst":
        return renderAnalystOptions();
      case "reinforcementInspector":
        return renderRInspectorOptions();
      case "leadReinforcementInspector":
        return renderLRInspectorOptions();
      case "dev":
        return renderDevOptions();
      case "guest":
        return renderGuestOptions();
      case "photo-viewer":
        return renderPhotoViewerOptions();
      default:
    }
  };

  return <div className="Home">{renderOptions()}</div>;
};

export default Home;
