import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  CardTitle,
  Form,
  Spinner
} from "reactstrap";
import {
  getLogEntries,
  createLogEntry,
  updateLogEntry
} from "../services/logService";
import { releaseNotifyInMany } from "../services/releaseNotify";
import { createSetting, updateSetting } from "../services/settingsService";
import { updateField } from "../services/fieldsService";
import { useGetFieldDefinitionReleases } from "./useGetFieldDefinitionReleases";
import { NewSetting } from "../models/Settings";
import { NewRelease } from "../models/LogEntries";
import "./ReleaseCreate.css";
import { phraseToProperCase } from "../libs/case-utils";

// const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

const WAIT_MSG = "Click the button below to pull the changes to be released";
const FAIL_MSG = "There are no changes to release at this time";
const DEF_VERSION = "1.0.0";
const DEF_FIELDDEFRELSCOPE = "fieldDefinitionReleases";

const ReleaseCreate = (props) => {
  const { scope, userInfo, sendEmail } = props;

  const settings = useGetFieldDefinitionReleases();

  const [logEntries, setLogEntries] = useState([]);
  const [previewMessage, setPreviewMessage] = useState(WAIT_MSG);

  const updateLogEntries = (ids) => {
    let entries = [];

    logEntries.map((entry) => {
      if (!ids.includes(entry.entryId)) {
        entries.push(entry);
      }

      return true;
    });

    setLogEntries(entries);
  };

  const handlePreview = async (setIsLoading) => {
    const query = {
      page: 1,
      count: 1000,
      scope,
      action: "stage",
      released: false
    };

    setIsLoading(true);

    await getLogEntries(query).then((result) => {
      setIsLoading(false);

      setLogEntries(result);

      if (result.length === 0) setPreviewMessage(FAIL_MSG);
    });
  };

  const PreviewButton = (props) => {
    const { handlePreview } = props;

    const [isLoading, setIsLoading] = useState(false);

    return (
      <>
        <Button
          className="previewButton"
          color="secondary"
          size="sm"
          onClick={() => handlePreview(setIsLoading)}
          disabled={isLoading}
        >
          Preview changes {isLoading && <Spinner size="sm" color="light" />}
        </Button>
      </>
    );
  };

  const generateSummary = () => {
    const summary = {};

    for (let i = 0; i < logEntries.length; i++) {
      if (!summary[logEntries[i].object.fieldSet])
        summary[logEntries[i].object.fieldSet] = [];

      summary[logEntries[i].object.fieldSet].push({
        entryId: logEntries[i].entryId,
        fieldKey: logEntries[i].object.fieldKey,
        releaseNote: logEntries[i].object.releaseNote,
        current: logEntries[i].object.update.current,
        update: logEntries[i].object.update.next
      });
    }

    return summary;
  };

  const increaseVersionPatch = (version) => {
    const [major, minor, patch] = version.split(".");

    return `${major}.${minor}.${+patch + 1}`;
  };

  const ReleaseGroup = (props) => {
    const { group, data, setting } = props;

    const version = increaseVersionPatch(
      setting ? setting.version : DEF_VERSION
    );

    return (
      <Card className="margin-bottom-1rem">
        <CardHeader>
          {phraseToProperCase(group)} (v. {version})
        </CardHeader>
        <CardBody className="padding-bottom-05rem">
          {data.map((e, i) => {
            return (
              <div key={i} className="margin-bottom-1rem">
                <CardTitle>
                  <b>{e.fieldKey}</b>
                </CardTitle>
                <CardText>{e.releaseNote}</CardText>
              </div>
            );
          })}
        </CardBody>
        <CardFooter>
          <ReleaseButton group={group} handleRelease={handleRelease} />
        </CardFooter>
      </Card>
    );
  };

  const generateSettingsObj = () => {
    const obj = {};

    settings.map((k) => {
      obj[k.object] = { settingId: k.settingId, ...k.setting };

      return false;
    });

    return obj;
  };

  const ShowPreview = () => {
    const summary = generateSummary();
    const summaryKeys = Object.keys(summary);

    const settingsObj = generateSettingsObj();

    return (
      <>
        <h4 className="margin-bottom-125rem">Staged Changes</h4>
        {summaryKeys.map((g, i) => (
          <ReleaseGroup
            key={i}
            group={g}
            data={summary[g]}
            setting={settingsObj[g]}
          />
        ))}
      </>
    );
  };

  const handleRelease = async (fieldSet, setIsLoading) => {
    const summary = generateSummary();
    const settingsObj = generateSettingsObj();
    const promises = [];
    const updateEntryIds = [];
    const newVersions = {};

    setIsLoading(true);

    // Set new version
    const newVersion = increaseVersionPatch(
      settingsObj[fieldSet] ? settingsObj[fieldSet].version : DEF_VERSION
    );

    // Record new versions
    newVersions[fieldSet] = { version: newVersion };

    // Collect log entry's promises
    const entryIds = summary[fieldSet].map((e) => e.entryId);
    const logEntryDoc = NewRelease(fieldSet, newVersion, entryIds, userInfo);

    promises.push(createLogEntry(logEntryDoc));

    // Collect all change entry ids
    updateEntryIds.push(...entryIds);

    // Collect settings' promises
    let settingDoc = {};

    if (settingsObj[fieldSet]) {
      const settingId = settingsObj[fieldSet].settingId;
      const setting = {
        version: newVersion
      };

      settingDoc = { setting, updatedBy: userInfo };

      promises.push(updateSetting(settingId, settingDoc));
    } else {
      const setting = {
        version: newVersion
      };

      settingDoc = NewSetting(
        DEF_FIELDDEFRELSCOPE,
        fieldSet,
        setting,
        userInfo
      );

      promises.push(createSetting(settingDoc));
    }

    // Update field options
    for (let i = 0; i < summary[fieldSet].length; i++) {
      const fieldKey = summary[fieldSet][i].fieldKey;
      const options = summary[fieldSet][i].update.options;
      const useTransformation = summary[fieldSet][i].update.useTransformation;
      const transformation = summary[fieldSet][i].update.transformation;
      const acceptableValue = summary[fieldSet][i].update.acceptableValue;
      const isOhPackage = summary[fieldSet][i].current.isOhPackage;
      const fieldUpdateDoc = {
        "validation.options": options,
        "validation.useTransformation": useTransformation,
        "validation.transformation": transformation,
        "validation.acceptableValue": isOhPackage ? acceptableValue : "",
        staged: null
      };

      promises.push(updateField({ fieldKey }, fieldUpdateDoc));
    }

    // Collect change update promises
    for (let i = 0; i < updateEntryIds.length; i++) {
      promises.push(
        updateLogEntry(updateEntryIds[i], {
          action: "update",
          released: true,
          updatedBy: userInfo
        })
      );
    }

    // Send notifications
    const notifyDoc = {
      summary: {},
      newVersions: {}
    };
    notifyDoc["summary"][fieldSet] = summary[fieldSet];
    notifyDoc["newVersions"][fieldSet] = newVersions[fieldSet];

    // Don't push if only transformation
    promises.push(releaseNotifyInMany(sendEmail, userInfo, notifyDoc));

    Promise.all(promises).then(() => {
      setIsLoading(false);

      updateLogEntries(entryIds);
    });
  };

  const ReleaseButton = (props) => {
    const { group, handleRelease, setIsReleased } = props;

    const [isLoading, setIsLoading] = useState(false);

    return (
      <>
        <Button
          className="releaseButton"
          color="danger"
          size="sm"
          onClick={() => handleRelease(group, setIsLoading, setIsReleased)}
          disabled={isLoading}
        >
          <b>Release changes</b>{" "}
          {isLoading && <Spinner size="sm" color="light" />}
        </Button>
      </>
    );
  };

  return (
    <div className="ReleaseCreate">
      <Card className="FormCard">
        <CardBody>
          {!settings && <Alert color="primary">Pulling settings...</Alert>}
          <Form id="form-create-release">
            <Card body className="group-container">
              {logEntries.length > 0 ? <ShowPreview /> : previewMessage}
            </Card>
            {logEntries.length <= 0 && (
              <PreviewButton handlePreview={handlePreview} />
            )}
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default ReleaseCreate;
