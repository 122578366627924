import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane
} from "reactstrap";
import ComplexRule from "./ComplexRule";
import ComplexRuleAdd from "./ComplexRuleAdd";
import { getComplexRules } from "../services/complexRuleService";
import { getCollectionCount } from "../services/collectionService";
import { phraseToProperCase } from "../libs/case-utils";
import Moment from "react-moment";
import "moment-timezone";
import classnames from "classnames";
import "./ComplexRulesList.css";
import GetPagination from "./GetPagination";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM-DD-YYYY HH:mm:ss";

// Set file types
const fileTypes = [
  "poleInspection",
  "poleInspectionAudit",
  "poleReinforcement",
  "poleReinforcementAudit",
  "poletopInspection"
];

const ComplexRulesList = (props) => {
  const { user } = props;

  const userInfo = {
    id: user.id,
    name: user.name,
    email: user.email
  };

  const userFileTypes = user?.prefs?.fileTypes ?? ["all"];

  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  const [fileType, setFileType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [rules, setRules] = useState([]);

  const newSearch = () => {
    setIsLoading(false);
    setCurrentPage(1);
    setRules([]);
    toggleSearch();
  };

  // Control pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rulesCount, setRulesCount] = useState(0);

  const totalRulesPerPage = 20;

  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setRules([]);

    listComplexRules(pageNumber, totalRulesPerPage);
  };

  const incChange = (pageNumber, inc, isposOrneg) => {
    let finalPage;
    const residual = pageNumber % inc;
    const control = residual === 0 ? inc : residual;

    if (isposOrneg > 0) {
      finalPage = pageNumber + inc - control + 1;
    } else {
      finalPage = pageNumber - inc - control + 1;
    }

    setCurrentPage(finalPage);
    setRules([]);

    listComplexRules(finalPage, totalRulesPerPage);
  };

  const firstChange = () => {
    let finalPage = 1;

    setCurrentPage(finalPage);
    setRules([]);

    listComplexRules(finalPage, totalRulesPerPage);
  };

  const lastChange = (finalPage) => {
    setCurrentPage(finalPage);
    setRules([]);

    listComplexRules(finalPage, totalRulesPerPage);
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  // Add "All" option to types
  const fileTypeOptions =
    userFileTypes.length > 1 ? ["all", ...userFileTypes] : userFileTypes;

  const listComplexRules = async (page, count) => {
    let resRules = [];
    let query = {};

    if (fileType !== "all") query.applyTo = fileType;

    try {
      const resCollectionCount = await getCollectionCount({
        collection: "ComplexRules",
        ...query
      });

      setRulesCount(resCollectionCount.count);
    } catch (e) {}

    if (page) query.page = page;
    if (count) query.count = count;

    try {
      resRules = await getComplexRules(query);
      setRules(resRules);
    } catch (e) {}

    return resRules.length;
  };

  const validateForm = () => {
    return fileType.length > 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const numRules = await listComplexRules(1, totalRulesPerPage);
      if (numRules) {
        toggleSearch();
      } else {
        showCustomModal({
          title: "Search results",
          message: "No results were found following that criteria"
        });
      }
    } catch (e) {}

    setIsLoading(false);
  };

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const SearchResults = () => {
    let numberOfPages = 0;
    if (rulesCount % totalRulesPerPage === 0) {
      numberOfPages = Math.floor(rulesCount / totalRulesPerPage);
    } else {
      numberOfPages = Math.floor(rulesCount / totalRulesPerPage) + 1;
    }

    return rules.length ? (
      <>
        <div className="NewSearch">
          <Button outline onClick={newSearch} color="secondary">
            New Search
          </Button>
        </div>
        <h5 className="card-title">Search results</h5>
        <GetPagination
          pages={numberOfPages}
          nextPage={nextPage}
          currentPage={currentPage}
          firstChange={firstChange}
          lastChange={lastChange}
          incChange={incChange}
        />
        <div className="RuleCards">
          {rules.map((r, i) => (
            <ComplexRule
              role={user.role}
              key={i}
              rule={r}
              userInfo={userInfo}
              fileTypes={fileTypes}
            />
          ))}
        </div>
      </>
    ) : (
      " "
    );
  };

  // Control tabs
  const [activeTab, setActiveTab] = useState("1");

  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="ComplexRulesList">
      <Card>
        <CardHeader tag="h5">Search Engineering Standards</CardHeader>
        <CardBody>
          <Nav tabs className="top-nav">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTabs("1");
                }}
                style={{ marginLeft: "0.5rem" }}
              >
                Search
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggleTabs("2");
                }}
              >
                Add new standard
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Collapse isOpen={isSearchOpen}>
                <Card className="search-engine">
                  <CardBody>
                    <Form onSubmit={handleSubmit} id="search-form">
                      <FormGroup tag="fieldset">
                        <h5 className="card-title">
                          Select the file type the engineering standards apply
                          to
                        </h5>
                        {fileTypeOptions.map((el, i) => (
                          <FormGroup key={i} check>
                            <Label check>
                              <Input
                                type="radio"
                                name="filetype"
                                value={el}
                                onChange={(e) => setFileType(e.target.value)}
                              />{" "}
                              {phraseToProperCase(el)}
                            </Label>
                          </FormGroup>
                        ))}
                      </FormGroup>
                      <Button
                        className="btn-lg"
                        disabled={!validateForm() || isLoading}
                        color="primary"
                        block
                      >
                        Search {isLoading && <Spinner color="light" />}
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Collapse>
              <SearchResults />
            </TabPane>
            <TabPane tabId="2">
              <ComplexRuleAdd userInfo={userInfo} fileTypes={fileTypes} />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
      <ErrorModal />
    </div>
  );
};

export default ComplexRulesList;
