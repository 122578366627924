import {
  bulkGet,
  bulkGetAggregate,
  bulkInsert,
  bulkUpdate,
  bulkUpdateGen
} from "./bulkServices";

const insertReinfInspection = async (docs) => {
  const query = {
    collection: "PoleReinforcementInspections",
    docs
  };

  const res = await bulkInsert(query);

  return res;
};

const upsertReinfInspection = async (inspectionId, doc) => {
  const query = {
    collection: "PoleReinforcementInspections",
    filter: {
      inspectionId
    },
    update: {
      $set: doc,
      $push: {},
      $pull: {},
      $setOnInsert: {
        inspectionId
      }
    },
    options: {
      upsert: true
    },
    confirm: true
  };

  const res = await bulkUpdateGen(query);

  return res;
};

const getReinfInspections = async (circuit, dataPointId = null) => {
  let filter = {
    circuit
  };

  if (dataPointId) {
    filter["dataPointId"] = dataPointId;
  }

  const query = {
    collection: "PoleReinforcementInspections",
    filter,
    sort: {
      submittedAt: -1
    },
    project: {
      _id: 0
    },
    confirm: true
  };

  const res = await bulkGet(query);

  return res;
};

const getLastReinfInspection = async (circuit, dataPointId = null) => {
  let match = {
    circuit
  };

  if (dataPointId) {
    match["dataPointId"] = dataPointId;
  }

  const query = {
    collection: "PoleReinforcementInspections",
    aggregate: [
      {
        $match: match
      },
      {
        $sort: {
          submittedAt: -1
        }
      },
      {
        $skip: 0
      },
      {
        $limit: 1
      },
      {
        $project: {
          _id: 0,
          inspectionId: 1,
          results: 1
        }
      }
    ],
    confirm: true
  };

  const res = await bulkGetAggregate(query);

  return res;
};

const updateReinfInspection = async (id, doc) => {
  const query = {
    collection: "PoleReinforcementInspections",
    filter: {
      inspectionId: id
    },
    update: doc,
    confirm: true
  };

  const res = await bulkUpdate(query);

  return res;
};

const getVendorUploadsByType = async (circuit) => {
  let filter = {
    circuit
  };

  const query = {
    collection: "Uploads",
    filter,
    sort: {},
    project: {
      _id: 0,
      type: 1,
      createdBy: 1
    },
    confirm: true
  };

  const res = await bulkGet(query);

  let newData = [];
  if (res.response.length > 0) {
    newData = res.response.map((item) => {
      return { type: item.type, contact: item.createdBy };
    });
  }

  return { status: res.status, response: newData };
};

const getInspectorsInfo = async (lead = false) => {
  let filter = {
    role: "leadReinforcementInspector"
  };

  const query = {
    collection: "Users",
    filter,
    sort: {},
    project: {
      _id: 0
    },
    confirm: true
  };

  const res = await bulkGet(query);

  return res;
};

const postUserInquiries = async (docs) => {
  const query = {
    collection: "UserInquiries",
    docs
  };

  const res = await bulkInsert(query);

  return res;
};

const getUserInquiries = async (userId) => {
  let filter = {
    "user.id": userId,
    answered: false
  };

  const query = {
    collection: "UserInquiries",
    filter,
    sort: {},
    project: {
      _id: 0
    },
    confirm: true
  };

  const res = await bulkGet(query);

  return res;
};

const getInspectionInquiries = async (inspectionId, answered) => {
  let filter = {
    "key.value": inspectionId,
    answered
  };

  const query = {
    collection: "UserInquiries",
    filter,
    sort: {},
    project: {
      _id: 0
    },
    confirm: true
  };

  const res = await bulkGet(query);

  return res;
};

const getOrgInquiries = async (org) => {
  let filter = {
    "user.org": org,
    answered: false
  };

  const query = {
    collection: "UserInquiries",
    filter,
    sort: {},
    project: {
      _id: 0
    },
    confirm: true
  };

  const res = await bulkGet(query);

  return res;
};

const answerUserInquiry = async (
  inquiryId,
  answeredBy,
  answeredAt,
  isCritical
) => {
  let query = {
    collection: "UserInquiries",
    filter: {
      inquiryId
    },
    array: {},
    confirm: true
  };

  if (!isCritical) {
    query["update"] = {
      "items.$[].fixed": true,
      "items.$[].fixedAt": answeredAt,
      answered: true,
      answeredBy,
      answeredAt
    };
  } else {
    query["update"] = {
      "items.$[item].fixed": true,
      "items.$[item].fixedAt": answeredAt,
      answered: true,
      answeredBy,
      answeredAt
    };
    query["options"] = {
      arrayFilters: [{ "item.defect.cat": "critical" }]
    };
  }

  const res = await bulkUpdate(query);

  return res;
};

const fixInspectionDefects = async (
  inspectionId,
  scope,
  fixedAt,
  isCritical
) => {
  const update = {};
  const options = {};

  if (!isCritical) {
    update[`results.defects.fixes.$[].fixed.${scope}`] = true;
    update[`results.defects.fixes.$[].fixedAt.${scope}`] = fixedAt;
  } else {
    update[`results.defects.fixes.$[fix].fixed.${scope}`] = true;
    update[`results.defects.fixes.$[fix].fixedAt.${scope}`] = fixedAt;

    options["arrayFilters"] = [{ "fix.defect.cat": "critical" }];
  }

  const query = {
    collection: "PoleReinforcementInspections",
    filter: {
      inspectionId
    },
    update,
    array: {},
    options,
    confirm: true
  };

  const res = await bulkUpdate(query);

  return res;
};

const getUserInquiryByInspectionId = async (inspectionId, answered) => {
  let filter = {
    "key.value": inspectionId,
    answered
  };

  const query = {
    collection: "UserInquiries",
    filter,
    sort: {},
    project: {
      _id: 0
    },
    confirm: true
  };

  const res = await bulkGet(query);

  return res;
};

const pushNewAttemptToInquiry = async (inquiryId, attempt) => {
  const query = {
    collection: "UserInquiries",
    filter: {
      inquiryId
    },
    update: {},
    array: {
      $push: {
        attempts: attempt
      }
    },
    confirm: true
  };

  const res = await bulkUpdate(query);

  return res;
};

export {
  insertReinfInspection,
  upsertReinfInspection,
  getReinfInspections,
  getLastReinfInspection,
  updateReinfInspection,
  getVendorUploadsByType,
  getInspectorsInfo,
  postUserInquiries,
  getOrgInquiries,
  getUserInquiries,
  getInspectionInquiries,
  answerUserInquiry,
  fixInspectionDefects,
  getUserInquiryByInspectionId,
  pushNewAttemptToInquiry
};
