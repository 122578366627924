import React from "react";
import { MailMessage } from "../models/Message";
import {
  Email,
  Item,
  Span,
  Box,
  renderEmail,
  configStyleValidator
} from "react-html-email";
import { getUsers } from "./userService";
import { phraseToProperCase } from "../libs/case-utils";

const APP_NAME = "DTE Data Delivery";

configStyleValidator({
  strict: true,
  warn: true,
  platforms: [
    "gmail",
    "gmail-android",
    "apple-mail",
    "apple-ios",
    "yahoo-mail",
    "outlook",
    "outlook-legacy",
    "outlook-web"
  ]
});

const getVendorContactInfo = async (fieldSets) => {
  const query = {
    roles: ["vendor", "audit"],
    registration: "approved"
  };
  const response = await getUsers(query);
  const vendors = [];

  response.map((user) => {
    const fileTypes = user.prefs.fileTypes;
    let found = false;

    for (let i = 0; i < fieldSets.length; i++) {
      if (fileTypes.includes(fieldSets[i])) {
        found = true;
        break;
      }
    }

    if (found)
      vendors.push({
        contact: { name: user.name, address: user.email },
        fileTypes: [...user.prefs.fileTypes]
      });

    return false;
  });

  return vendors;
};

const genHtml = (data) => {
  const { summary, newVersions } = data;

  const comp = renderEmail(
    <Email title={`${APP_NAME}`}>
      {Object.keys(summary).map((fieldSet, i) => {
        return (
          <Box
            key={i}
            cellSpacing={20}
            width="100%"
            style={{ borderTop: "1px solid black" }}
          >
            <Item align="left">
              <Span fontSize={18}>
                {phraseToProperCase(fieldSet)} (v.{" "}
                {newVersions[fieldSet].version})
              </Span>
            </Item>
            {summary[fieldSet].map((item, i) => {
              return (
                <Item key={i} align="left">
                  <Span fontSize={14}>{item.fieldKey}</Span>
                  <br />
                  <Span fontSize={12}>{item.releaseNote}</Span>
                </Item>
              );
            })}
          </Box>
        );
      })}
    </Email>
  );

  return comp;
};

const releaseNotifyInOne = async (handleNotify, user, data) => {
  // Get field sets involved in release
  const fieldSets = Object.keys(data.summary);

  // Get user info data
  const userInfo = await getVendorContactInfo(fieldSets);

  // Set to recipients
  const toRecipients = userInfo.map((item) => item.contact);

  // Continue only if there are recipients
  if (toRecipients.length) {
    // Set mail delegate
    const fromRecipient = {
      name: user.name,
      address: user.email
    };

    // Set message subject
    const subject = `${APP_NAME}: Data Dictionary Update`;

    // Set message body
    const body = {
      contentType: "HTML",
      content: genHtml(data)
    };

    // Set one attachment
    const attachments = [];

    // Set Cc list
    const ccRecipients = [];

    // Set Bcc list
    const BccRecipients = [];

    const message = MailMessage(
      fromRecipient,
      toRecipients,
      subject,
      body,
      attachments,
      ccRecipients,
      BccRecipients,
      true
    );

    await handleNotify(message);
  }
};

const filterUserReleasesData = (data, fileTypes) => {
  const tempData = { newVersions: {}, summary: {} };
  const summaryKeys = Object.keys(data.summary);

  for (let i = 0; i < summaryKeys.length; i++) {
    if (fileTypes.includes(summaryKeys[i])) {
      tempData.summary[summaryKeys[i]] = [...data.summary[summaryKeys[i]]];
      tempData.newVersions[summaryKeys[i]] = {
        ...data.newVersions[summaryKeys[i]]
      };
    }
  }

  return tempData;
};

const releaseNotifyInMany = async (handleNotify, user, data) => {
  // Get field sets involved in release
  const fieldSets = Object.keys(data.summary);

  // Get user info data
  const userInfo = await getVendorContactInfo(fieldSets);

  // Do only if there's recipients
  if (userInfo.length) {
    // Set mail delegate
    const fromRecipient = {
      name: user.name,
      address: user.email
    };

    // Set message subject
    const subject = `${APP_NAME}: Data Dictionary Update`;

    // Set one attachment
    const attachments = [];

    // Set Cc list
    const ccRecipients = [];

    // Set Bcc list
    const BccRecipients = [];

    // Send a separate email to every recipient
    for (let i = 0; i < userInfo.length; i++) {
      // Get user releases
      const userReleaseData = filterUserReleasesData(
        data,
        userInfo[i].fileTypes
      );

      // Check if there's content to send to this vendor
      const isSummary = Object.keys(userReleaseData.summary).length;

      if (isSummary) {
        // Set message body
        const body = {
          contentType: "HTML",
          content: genHtml(userReleaseData)
        };

        // Build message
        const message = MailMessage(
          fromRecipient,
          [userInfo[i].contact],
          subject,
          body,
          attachments,
          ccRecipients,
          BccRecipients,
          true
        );

        await handleNotify(message);
      }
    }
  }
};

export { releaseNotifyInOne, releaseNotifyInMany };
