import axios from "axios";
import { config } from "./config";

const stage = config.stage;
const settings = config[stage].appService;

const getUsageData = async (startDate, endDate, top) => {
  const url = `${settings.baseUrl}/api/getAzUsage?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "GET",
    url: url,
    params: { startDate, endDate, top }
  });

  return { data, status };
};

const followAzUsageLink = async (link, accessToken) => {
  const url = `${settings.baseUrl}/api/getAzUsageFollow?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: { link, accessToken }
  });

  return { data, status };
};

export { getUsageData, followAzUsageLink };
