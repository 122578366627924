import React from "react";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import DownloadTemplates from "./DownloadTemplates";

import "./LeadReinforcementInspector.css";

const LeadReinforcementInspector = (props) => {
  const { user } = props;

  // const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

  return (
    <div className="LeadReinforcementInspector">
      <Card>
        <CardHeader tag="h5" className="bg-light text-dark font-weight-bold">
          Lead Reinforcement Inspector
        </CardHeader>
        <CardBody>
          <CardTitle>
            {user.name}, you are logged on as lead reinforcement inspector.
          </CardTitle>
          {/* <Json data={user} /> */}
        </CardBody>
      </Card>
      {user.prefs.fileTypes && user.prefs.fileTypes.length ? (
        <DownloadTemplates
          params={{ role: user.role, fileTypes: user.prefs.fileTypes }}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default LeadReinforcementInspector;
