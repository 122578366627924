import React from "react";
import "./CircuitPhotos.css";
import CircuitPhotosComponent from "../components/CircuitPhotosComponent";

const CircuitPhotos = props => {
  return (
    <div className="CircuitPhotos">
      <div className="container">
        <CircuitPhotosComponent {...props} />
      </div>
    </div>
  );
};

export default CircuitPhotos;