import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane
} from "reactstrap";
import Release from "./Release";
import ReleaseCreate from "./ReleaseCreate";
import { getLogEntries } from "../services/logService";
import { getCollectionCount } from "../services/collectionService";
import { phraseToProperCase } from "../libs/case-utils";
import Moment from "react-moment";
import "moment-timezone";
import classnames from "classnames";
import "./ReleasesList.css";
import GetPagination from "./GetPagination";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM-DD-YYYY HH:mm:ss";

const ReleasesList = (props) => {
  const { user, sendEmail } = props;

  const userInfo = {
    id: user.id,
    name: user.name,
    email: user.email
  };

  const userFileTypes = user?.prefs?.fileTypes ?? ["all"];

  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  const [fileType, setFileType] = useState("all");
  const [isLoading, setIsLoading] = useState(false);

  const scopeOptions = ["fieldDefinitionReleases", "fieldDefinitionChanges"];
  const [scope, setScope] = useState(scopeOptions[0]);

  //   const isFieldDefReleases = scope === scopeOptions[0];
  const isFieldDefChanges = scope === scopeOptions[1];

  const [releases, setReleases] = useState([]);

  const newSearch = () => {
    setIsLoading(false);
    setCurrentPage(1);
    setReleases([]);
    toggleSearch();
  };

  // Control pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [releasesCount, setReleasesCount] = useState(0);

  const totalReleasesPerPage = 20;

  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setReleases([]);

    listReleases(pageNumber, totalReleasesPerPage);
  };

  const incChange = (pageNumber, inc, isposOrneg) => {
    let finalPage;
    const residual = pageNumber % inc;
    const control = residual === 0 ? inc : residual;

    if (isposOrneg > 0) {
      finalPage = pageNumber + inc - control + 1;
    } else {
      finalPage = pageNumber - inc - control + 1;
    }

    setCurrentPage(finalPage);
    setReleases([]);

    listReleases(finalPage, totalReleasesPerPage);
  };

  const firstChange = () => {
    let finalPage = 1;

    setCurrentPage(finalPage);
    setReleases([]);

    listReleases(finalPage, totalReleasesPerPage);
  };

  const lastChange = (finalPage) => {
    setCurrentPage(finalPage);
    setReleases([]);

    listReleases(finalPage, totalReleasesPerPage);
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  // Add "All" option to types
  const fileTypeOptions =
    userFileTypes.length > 1 ? ["all", ...userFileTypes] : userFileTypes;

  const listReleases = async (page, count) => {
    let resReleases = [];
    let query = {};

    query.scope = scope;
    if (isFieldDefChanges && fileType !== "all")
      query.fieldSuffixFor = fileType;

    try {
      const resCollectionCount = await getCollectionCount({
        collection: "Logs",
        ...query
      });

      setReleasesCount(resCollectionCount.count);
    } catch (e) {}

    if (page) query.page = page;
    if (count) query.count = count;

    try {
      resReleases = await getLogEntries(query);
      setReleases(resReleases);
    } catch (e) {}

    return resReleases.length;
  };

  const validateForm = () => {
    return scope.length > 0 && fileType.length > 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const numRules = await listReleases(1, totalReleasesPerPage);
      if (numRules) {
        toggleSearch();
      } else {
        showCustomModal({
          title: "Search results",
          message: "No results were found following that criteria"
        });
      }
    } catch (e) {}

    setIsLoading(false);
  };

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const SearchResults = () => {
    let numberOfPages = 0;
    if (releasesCount % totalReleasesPerPage === 0) {
      numberOfPages = Math.floor(releasesCount / totalReleasesPerPage);
    } else {
      numberOfPages = Math.floor(releasesCount / totalReleasesPerPage) + 1;
    }

    return releases.length ? (
      <>
        <div className="NewSearch">
          <Button outline onClick={newSearch} color="secondary">
            New Search
          </Button>
        </div>
        <h4 className="card-title">{phraseToProperCase(scope)}</h4>
        <h5 className="card-title">Search results</h5>
        <GetPagination
          pages={numberOfPages}
          nextPage={nextPage}
          currentPage={currentPage}
          firstChange={firstChange}
          lastChange={lastChange}
          incChange={incChange}
        />
        <div className="ReleaseCards">
          {releases.map((r, i) => (
            <Release key={i} release={r} scope={scope} />
          ))}
        </div>
      </>
    ) : (
      " "
    );
  };

  // Control tabs
  const [activeTab, setActiveTab] = useState("1");

  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="ReleasesList">
      <Card>
        <CardHeader tag="h5">Search Data Changes</CardHeader>
        <CardBody>
          <Nav tabs className="top-nav">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTabs("1");
                }}
                style={{ marginLeft: "0.5rem" }}
              >
                Search
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggleTabs("2");
                }}
              >
                Create release
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Collapse isOpen={isSearchOpen}>
                <Card className="search-engine">
                  <CardBody>
                    <Form onSubmit={handleSubmit} id="search-form">
                      <FormGroup tag="fieldset">
                        <h5 className="card-title">Select the scope</h5>
                        {scopeOptions.map((el, i) => (
                          <FormGroup key={i} check>
                            <Label check>
                              <Input
                                type="radio"
                                name="scope"
                                value={el}
                                checked={el === scope}
                                onChange={(e) => setScope(e.target.value)}
                              />{" "}
                              {phraseToProperCase(el)}
                            </Label>
                          </FormGroup>
                        ))}
                      </FormGroup>
                      {isFieldDefChanges && (
                        <FormGroup tag="fieldset">
                          <h5 className="card-title">
                            Select the file type the changes apply to
                          </h5>
                          {fileTypeOptions.map((el, i) => (
                            <FormGroup key={i} check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="filetype"
                                  value={el}
                                  checked={el === fileType}
                                  onChange={(e) => setFileType(e.target.value)}
                                />{" "}
                                {phraseToProperCase(el)}
                              </Label>
                            </FormGroup>
                          ))}
                        </FormGroup>
                      )}
                      <Button
                        className="btn-lg"
                        disabled={!validateForm() || isLoading}
                        color="primary"
                        block
                      >
                        Search {isLoading && <Spinner color="light" />}
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Collapse>
              <SearchResults />
            </TabPane>
            <TabPane tabId="2">
              <ReleaseCreate
                userInfo={userInfo}
                scope={scopeOptions[1]}
                sendEmail={sendEmail}
              />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
      <ErrorModal />
    </div>
  );
};

export default ReleasesList;
