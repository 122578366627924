import { useEffect, useState } from "react";
import { defer } from "rxjs";
import { getUser } from "../services/userService";

export const useGetUser = (id) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!id) {
      return;
    }

    const fetchData = async (id) => {
      return await getUser(id);
    };

    const subscription = defer(() => fetchData(id)).subscribe(setUser);

    return () => {
      subscription.unsubscribe();
    };
  }, [id]);

  return user;
};
