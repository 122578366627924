import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CustomInput,
  Form,
  FormFeedback,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
  FormGroup
} from "reactstrap";
import {
  updateComplexRule,
  deleteComplexRule,
  getComplexRule
} from "../services/complexRuleService";
import { useGetBuilderSource } from "./useGetBuilderSource";
import { useGetBuilderFields } from "./useGetBuilderFields";
import Builder from "./Builder";
import jsonLogic from "json-logic-js";
import { phraseToProperCase } from "../libs/case-utils";
import Moment from "react-moment";
import "moment-timezone";
import classnames from "classnames";
import "./ComplexRule.css";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM/DD/YYYY HH:mm:ss";

const currentDate = new Date();

// Instantiate Utils object
// (Add new methods as needed)
const Utils = {
  getCurrentYear: () => currentDate.getFullYear()
};

// Load Utils into Json Logic
jsonLogic.add_operation("Utils", Utils);

// const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

const ComplexRule = (props) => {
  const { rule: defRule, userInfo, role, fileTypes } = props;

  const source = useGetBuilderSource(defRule.applyTo);
  const fields = useGetBuilderFields(source ? source.fields : null);

  const [currentComplexRule, setCurrentComplexRule] = useState(defRule);
  const [isDeleted, setIsDeleted] = useState(false);

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  // Control tabs
  const [activeTab, setActiveTab] = useState("1");
  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const EditButton = (props) => {
    const { handleEdit } = props;

    return (
      <Button
        outline
        color="primary"
        size="sm"
        className="editButton"
        onClick={() => handleEdit(true)}
      >
        Edit
      </Button>
    );
  };

  const DeleteButton = (props) => {
    const { handleDelete } = props;

    return (
      <Button
        outline
        color="danger"
        size="sm"
        className="deleteButton"
        onClick={() => handleDelete(true)}
      >
        Delete
      </Button>
    );
  };

  const SaveCancelButtons = (props) => {
    const { handleSave, handleCancel, isLoading } = props;

    return (
      <>
        <Button
          className="editButton"
          color="primary"
          size="sm"
          onClick={handleSave}
        >
          Save {isLoading && <Spinner size="sm" color="light" />}
        </Button>
        <Button
          className="editButton"
          color="default"
          size="sm"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </>
    );
  };

  const DeleteCancelButtons = (props) => {
    const { handleDelete, handleCancelDelete, isLoading } = props;

    return (
      <>
        <hr />
        Are you sure you want to delete this standard?
        <br />
        <Button
          className="deleteButton"
          color="success"
          size="sm"
          onClick={handleCancelDelete}
        >
          Not sure
        </Button>
        <Button
          className="deleteButton"
          color="danger"
          size="sm"
          onClick={handleDelete}
        >
          Yes, I'm sure {isLoading && <Spinner size="sm" color="light" />}
        </Button>
      </>
    );
  };

  const ComplexRuleTab = (props) => {
    const {
      ruleId: defRuleId,
      ruleName: defRuleName,
      ruleDescription: defRuleDescription,
      ruleApplyTo: defRuleApplyTo,
      ruleDisplay: defRuleDisplay,
      ruleActive: defRuleActive,
      ruleLogic: defRuleLogic,
      ruleCreatedAt: defRuleCreatedAt,
      ruleCreatedBy: defRuleCreatedBy,
      ruleUpdatedAt: defRuleUpdateAt,
      ruleUpdatedBy: defRuleUpdatedBy,
      handleCurrentComplexRuleUpdate
    } = props;

    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [ruleName, setRuleName] = useState(defRuleName);
    const [ruleDescription, setRuleDescription] = useState(defRuleDescription);
    const [applyTo, setApplyTo] = useState(defRuleApplyTo);
    const [display, setDisplay] = useState(defRuleDisplay);
    const [active, setActive] = useState(defRuleActive);

    const [ruleNameError, setRuleNameError] = useState(null);
    const [ruleDescriptionError, setRuleDescriptionError] = useState(null);

    const validateInput = () => {
      // Check rule name format
      if (!/^[A-Za-z0-9 #]*$/.test(ruleName.trim())) {
        setRuleNameError("Field can't be empty or last character is invalid");

        return false;
      }

      // Check rule name length
      if (ruleName.length > 64) {
        setRuleNameError("Value can't be longer than 64 characters");

        return false;
      }

      // Check rule description format
      if (!/^[A-Za-z0-9 .,]*$/.test(ruleDescription.trim())) {
        setRuleDescriptionError(
          "Field can't be empty or last character is invalid"
        );

        return false;
      }

      // Check rule description length
      if (ruleDescription.length > 256) {
        setRuleDescriptionError("Value can't be longer than 256 characters");

        return false;
      }

      setRuleNameError(null);
      setRuleDescriptionError(null);

      return true;
    };

    const handleSave = async () => {
      if (!validateInput()) return false;

      setIsLoading(true);
      setIsSaving(true);

      await updateComplexRule(defRuleId, {
        name: ruleName,
        description: ruleDescription,
        applyTo,
        display,
        active,
        updatedBy: userInfo
      }).then((results) => {
        setIsEditing(false);
        setIsLoading(false);
        setIsSaving(false);

        // Update current complex rule global state
        handleCurrentComplexRuleUpdate({
          ruleId: defRuleId,
          name: ruleName,
          description: ruleDescription,
          applyTo: applyTo,
          display: display,
          active: active,
          rules: defRuleLogic,
          createdBy: defRuleCreatedBy,
          createdAt: defRuleCreatedAt,
          updatedBy: userInfo,
          updatedAt: results.updatedAt
        });
      });
    };

    const handleCancel = () => {
      setRuleName(defRuleName);
      setRuleDescription(defRuleDescription);
      setApplyTo(defRuleApplyTo);
      setDisplay(defRuleDisplay);
      setActive(defRuleActive);

      setIsEditing(false);
    };

    const handleDelete = async () => {
      setIsLoading(true);

      await deleteComplexRule(defRuleId).then((results) => {
        setIsDeleting(false);
        setIsLoading(false);

        if (results.deletedCount && results.deletedCount > 0) {
          setIsDeleted(true);
        } else {
          showCustomModal({
            title: "Error Deleting Engineering Standard",
            message:
              "Something wrong happened trying to delete engineering standard. Please contact the administrator."
          });
        }
      });
    };

    const handleCancelDelete = () => {
      setIsDeleting(false);
    };

    const handleRuleName = (option) => {
      setRuleName(option);
    };

    const handleRuleDescription = (option) => {
      setRuleDescription(option);
    };

    const handleApplyTo = (option) => {
      setApplyTo(option);
    };

    const ApplyToSelect = (props) => {
      const { applyTo, handleUpdate } = props;

      let updatedApplyTo = applyTo;
      if (!fileTypes.includes(applyTo)) {
        updatedApplyTo = fileTypes[0];

        handleUpdate(updatedApplyTo);
      }

      return (
        <Input
          className="text-capitalize applyTo"
          value={updatedApplyTo}
          type="select"
          id={`registration-${defRuleId}`}
          onChange={(e) => handleUpdate(e.target.value)}
        >
          {fileTypes.map((o, i) => (
            <option key={i} value={o}>
              {phraseToProperCase(o)}
            </option>
          ))}
        </Input>
      );
    };

    const handleDisplay = (option) => {
      setDisplay(option);
    };

    const DisplaySwitch = (props) => {
      const { display, isChecked, isEditing, handleUpdate } = props;

      return (
        <CustomInput
          className="ruleDisplay"
          type="switch"
          id={`display-${defRuleId}`}
          label={isChecked ? "Yes" : "No"}
          checked={isChecked}
          value={display}
          disabled={!isEditing}
          onChange={(e) => handleUpdate(e.target.checked)}
        />
      );
    };

    const DisplayBadge = (props) => {
      const { option } = props;
      const color = option ? "success" : "danger";
      const label = option ? "Yes" : "No";

      return (
        <Badge color={color} className="text-uppercase">
          {label}
        </Badge>
      );
    };

    const handleActive = (option) => {
      setActive(option);
    };

    const ActiveSwitch = (props) => {
      const { active, isChecked, isEditing, handleUpdate } = props;

      return (
        <CustomInput
          className="ruleActive"
          type="switch"
          id={`active-${defRuleId}`}
          label={isChecked ? "Yes" : "No"}
          checked={isChecked}
          value={active}
          disabled={!isEditing}
          onChange={(e) => handleUpdate(e.target.checked)}
        />
      );
    };

    const ActiveBadge = (props) => {
      const { option } = props;
      const color = option ? "success" : "danger";
      const label = option ? "Yes" : "No";

      return (
        <Badge color={color} className="text-uppercase">
          {label}
        </Badge>
      );
    };

    const CreatedByField = (props) => {
      const { by, at } = props;

      return (
        <>
          <b>Created by:</b>
          <br /> {by} {" @ "}
          <Moment>{at}</Moment>
        </>
      );
    };

    const UpdatedByField = (props) => {
      const { by, at } = props;

      return (
        <>
          <b>Updated by:</b>
          <br /> {by} {" @ "}
          <Moment>{at}</Moment>
        </>
      );
    };

    return (
      <TabPane tabId="1">
        <CardBody>
          <Form id={`form-${defRuleId}`}>
            <Card body style={{ marginBottom: "1rem" }}>
              {!isEditing && (
                <CardText>
                  <b>Standard Id:</b> {defRuleId}
                </CardText>
              )}
              <CardText>
                <b>Name:</b> {!isEditing && ruleName}
              </CardText>
              {isEditing && (
                <FormGroup>
                  <Input
                    className="ruleName"
                    invalid={ruleNameError ? true : false}
                    type="text"
                    name="rule-name"
                    id={`rule-name-${defRuleId}`}
                    placeholder="Max. length: 64 characters"
                    value={ruleName}
                    disabled={isSaving}
                    onChange={(e) => handleRuleName(e.target.value)}
                  />
                  {ruleNameError && (
                    <FormFeedback className="ruleName">
                      {ruleNameError}
                    </FormFeedback>
                  )}
                </FormGroup>
              )}
              <CardText>
                <b>Description:</b> {!isEditing && ruleDescription}
              </CardText>
              {isEditing && (
                <FormGroup>
                  <Input
                    className="ruleDescription"
                    invalid={ruleDescriptionError ? true : false}
                    type="textarea"
                    name="description"
                    id={`rule-description-${defRuleId}`}
                    rows="3"
                    placeholder="Max. length: 256 characters"
                    defaultValue={ruleDescription}
                    onChange={(e) => handleRuleDescription(e.target.value)}
                    disabled={isSaving}
                  />
                  {ruleDescriptionError && (
                    <FormFeedback className="ruleDescription">
                      {ruleDescriptionError}
                    </FormFeedback>
                  )}
                </FormGroup>
              )}
              <CardText>
                <b>Apply to:</b>{" "}
                {isEditing ? (
                  <ApplyToSelect
                    applyTo={applyTo}
                    handleUpdate={handleApplyTo}
                  />
                ) : (
                  phraseToProperCase(applyTo)
                )}
              </CardText>
              <CardText>
                <b>Halt on failure:</b>{" "}
                {!isEditing && <DisplayBadge option={display} />}
              </CardText>
              {isEditing && (
                <DisplaySwitch
                  display={display}
                  isChecked={display}
                  isEditing={isEditing}
                  handleUpdate={handleDisplay}
                />
              )}
              <CardText>
                <b>Active:</b> {!isEditing && <ActiveBadge option={active} />}
              </CardText>
              {isEditing && (
                <ActiveSwitch
                  active={active}
                  isChecked={active}
                  isEditing={isEditing}
                  handleUpdate={handleActive}
                />
              )}
            </Card>
            <Card body inverse style={{ backgroundColor: "#f7f7f7" }}>
              {defRuleCreatedBy && (
                <CardText>
                  <CreatedByField
                    by={defRuleCreatedBy.name}
                    at={defRuleCreatedAt}
                  />
                </CardText>
              )}
              {defRuleUpdatedBy && (
                <CardText>
                  <UpdatedByField
                    by={defRuleUpdatedBy.name}
                    at={defRuleUpdateAt}
                  />
                </CardText>
              )}
            </Card>
            {!isEditing && !isDeleting && (
              <EditButton handleEdit={setIsEditing} />
            )}
            {isEditing && (
              <SaveCancelButtons
                handleSave={handleSave}
                handleCancel={handleCancel}
                isLoading={isLoading}
              />
            )}
            {!isDeleting && !isEditing && (
              <DeleteButton handleDelete={setIsDeleting} />
            )}
            {isDeleting && (
              <DeleteCancelButtons
                handleDelete={handleDelete}
                handleCancelDelete={handleCancelDelete}
                isLoading={isLoading}
              />
            )}
          </Form>
        </CardBody>
      </TabPane>
    );
  };

  const LogicTab = (props) => {
    const { rule, handleCurrentComplexRuleUpdate } = props;

    const [isSaving, setIsSaving] = useState(false);
    const [isDiscarding, setIsDiscarding] = useState(false);

    // Default to empty rule set
    let logic = Object.keys(rule.rules.logic).length
      ? rule.rules.logic
      : { and: [] };

    const getVariablesObject = (logic) => {
      let data = {};
      const rootOperator = Object.keys(logic)[0];

      const findNested = (obj) => {
        const key = "var";

        if (obj[key]) {
          data[obj[key]] = null;
        } else {
          const oKeys = Object.keys(obj);

          for (let i = 0; i < oKeys.length; i++) {
            if (typeof obj[oKeys[i]] == "object") {
              findNested(obj[oKeys[i]]);
            }
          }
        }
      };

      findNested(logic[rootOperator]);

      return data;
    };

    const handleSave = async (logic) => {
      const ruleCopy = { ...rule };

      // Update rules
      ruleCopy.rules.logic = logic;

      setIsSaving(true);

      await updateComplexRule(rule.ruleId, {
        rules: { logic, data: getVariablesObject(logic) },
        updatedBy: userInfo
      }).then((result) => {
        setIsSaving(false);

        // Update current complex rule global state
        handleCurrentComplexRuleUpdate({
          ...ruleCopy,
          updatedBy: userInfo,
          updatedAt: result.updatedAt
        });
      });
    };

    const handleDiscard = async () => {
      const ruleCopy = { ...rule };

      setIsDiscarding(true);

      await getComplexRule(rule.ruleId).then((result) => {
        setIsDiscarding(false);

        // Revert current complex rule global state
        handleCurrentComplexRuleUpdate({
          ...ruleCopy,
          rules: result.rules
        });
      });
    };

    return (
      <TabPane tabId="2">
        <CardBody>
          <Card className="SupportCard">
            <CardHeader>
              <h5>Logic Builder</h5>
            </CardHeader>
            <CardBody>
              {/* <Json data={logic} /> */}
              {fields ? (
                <>
                  {isSaving && (
                    <div>
                      <Spinner type="grow" color="primary" /> Saving logic...
                    </div>
                  )}
                  {isDiscarding && (
                    <div>
                      <Spinner type="grow" color="primary" /> Discarding
                      logic...
                    </div>
                  )}
                  <Builder
                    role={role}
                    query={logic}
                    updateQuery={handleSave}
                    discardQuery={handleDiscard}
                    fieldSet={fields}
                    getVariablesObject={getVariablesObject}
                  />
                </>
              ) : (
                <div>
                  <Spinner type="grow" color="primary" /> Gathering field
                  definitions...
                </div>
              )}
            </CardBody>
          </Card>
        </CardBody>
      </TabPane>
    );
  };

  const cardHeaderClass = currentComplexRule.active
    ? "card-header-success"
    : "card-header-danger";

  return (
    !isDeleted && (
      <div className="ComplexRule">
        <Card className="RuleCard">
          <CardHeader className={cardHeaderClass}>
            {currentComplexRule.name}
          </CardHeader>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTabs("1");
                }}
                style={{ marginLeft: "0.5rem" }}
              >
                Engineering Standard
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggleTabs("2");
                }}
              >
                Logic
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <ComplexRuleTab
              ruleId={currentComplexRule.ruleId}
              ruleName={currentComplexRule.name}
              ruleDescription={currentComplexRule.description}
              ruleApplyTo={currentComplexRule.applyTo}
              ruleDisplay={currentComplexRule.display}
              ruleActive={currentComplexRule.active}
              ruleLogic={currentComplexRule.rules}
              ruleCreatedAt={currentComplexRule.createdAt}
              ruleCreatedBy={currentComplexRule.createdBy}
              ruleUpdatedAt={currentComplexRule.updatedAt}
              ruleUpdatedBy={currentComplexRule.updatedBy}
              handleCurrentComplexRuleUpdate={setCurrentComplexRule}
            />
            <LogicTab
              rule={currentComplexRule}
              handleCurrentComplexRuleUpdate={setCurrentComplexRule}
            />
          </TabContent>
        </Card>
        <ErrorModal />
      </div>
    )
  );
};

export default ComplexRule;
