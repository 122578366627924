import React from "react";
import { MailMessage } from "../models/Message";
import {
  Email,
  Item,
  Span,
  Box,
  renderEmail,
  configStyleValidator
} from "react-html-email";
import prettyBytes from "pretty-bytes";

const APP_NAME = "DTE Data Delivery";

configStyleValidator({
  strict: true,
  warn: true,
  platforms: [
    "gmail",
    "gmail-android",
    "apple-mail",
    "apple-ios",
    "yahoo-mail",
    "outlook",
    "outlook-legacy",
    "outlook-web"
  ]
});

const genHtml = (user, program, uploads) => {
  const comp = renderEmail(
    <Email title={`${APP_NAME}`}>
      <Box cellSpacing={20} width="100%">
        <Item align="left">
          <Span fontSize={18}>Vendor: {user.org}</Span>
          <br /> <Span fontSize={16}>Program: {program}</Span>
        </Item>
        {uploads.map((upload, i) => {
          return (
            <Item key={i} align="left">
              <Span fontSize={14}>
                File name: {upload.fileName} ({prettyBytes(upload.size)})
              </Span>
            </Item>
          );
        })}
      </Box>
    </Email>
  );

  return comp;
};

const myDriveNotify = async (handleNotify, user, toRecipients, data) => {
  // Set mail delegate
  const fromRecipient = {
    name: user.name,
    address: user.email
  };

  // Set message subject
  const subject = `${APP_NAME}: Vendor upload for ${data.program}`;

  // Set message body
  const body = {
    contentType: "HTML",
    content: genHtml(data.user, data.program, data.uploads)
  };

  // Set one attachment
  const attachments = [];

  // Set Cc list
  const ccRecipients = [];

  // Set Bcc list
  const BccRecipients = [];

  const message = MailMessage(
    fromRecipient,
    toRecipients,
    subject,
    body,
    attachments,
    ccRecipients,
    BccRecipients,
    true
  );

  await handleNotify(message);
};

export { myDriveNotify };
