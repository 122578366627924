import axios from "axios";
import { config } from "./config";

const stage = config.stage;
const settings = config[stage].appService;

const storeData = async (doc) => {
  const url = `${settings.baseUrl}/api/storeData?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { data, status };
};

const downloadData = async (fileName, contentType) => {
  const url = `${settings.baseUrl}/api/downloadUploads?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: { fileName, contentType }
  });

  return data;
};

const storeInMyDrive = async (doc) => {
  const url = `${settings.baseUrl}/api/storeInMyDrive?code=${settings.hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { data, status };
};

const deleteFromBlobStorage = async (container, fileName) => {
  const url = `${settings.baseUrl}/api/deleteFromBlobStorage?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "DELETE",
    url: url,
    params: { container, fileName }
  });

  return data;
};

const getDownloadUrl = async (fileName, container) => {
  const url = `${settings.baseUrl}/api/getDownloadUrl?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: { fileName, container }
  });

  return data;
};

const followDownloadUrl = async (url) => {
  const { data } = await axios({
    method: "GET",
    url: url,
    responseType: "arraybuffer"
  });

  return data;
};

const insertUpload = async (doc) => {
  const url = `${settings.baseUrl}/api/uploadInsert?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "POST",
    url: url,
    data: doc
  });

  return data;
};

const getUpload = async (fileName) => {
  const url = `${settings.baseUrl}/api/uploadGet?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: { fileName }
  });

  return data;
};

const getUploads = async (query) => {
  const url = `${settings.baseUrl}/api/uploadsList?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: query
  });

  return data;
};

const updateUpload = async (fileName, doc) => {
  const url = `${settings.baseUrl}/api/uploadUpdate?code=${settings.hostKey}`;
  const { data, status } = await axios({
    method: "PUT",
    url: url,
    params: { fileName },
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { data, status };
};

export {
  storeData,
  downloadData,
  deleteFromBlobStorage,
  storeInMyDrive,
  getDownloadUrl,
  followDownloadUrl,
  insertUpload,
  getUpload,
  getUploads,
  updateUpload
};
