import { useEffect, useState } from "react";
import { defer } from "rxjs";
import { getOrgInquiries } from "../services/poleReinfInspCustomServices";

export const useGetOrgInquiries = (org) => {
  const [inquiries, serInquiries] = useState(null);

  useEffect(() => {
    const fetchDataFile = async () => {
      const res = await getOrgInquiries(org);

      if (res.status === 200) {
        return res.response;
      } else {
        return null;
      }
    };

    const subscription = defer(() => fetchDataFile()).subscribe(serInquiries);

    return () => {
      subscription.unsubscribe();
    };
  }, [org]);

  return inquiries;
};
