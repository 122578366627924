import React from "react";
import SeedManager from "../components/SeedManager";
import "./Seeding.css";

const Seeding = (props) => {
  return (
    <div className="Seeding">
      <div className="container">
        <SeedManager {...props} />
      </div>
    </div>
  );
};

export default Seeding;
