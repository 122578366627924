import { bulkGet, bulkUpdate } from "./bulkServices";

const getUploadsByTypeOwnerCompletion = async (type, owner, complete, isAdmin) => {
  const query = {
    collection: "Uploads",
    filter: {
      type,
      "support.photos.complete": complete,
      version: "1.1.0"
    },
    sort: {},
    project: {
      _id: 0,
      "support.photos.list": 0,
      "support.needWork.list": 0
    },
    confirm: true
  };

  if (!isAdmin) {
    query.filter["createdBy.org"] = owner;
  }

  const res = await bulkGet(query);

  return res;
};

const getListOfPhotos = async (circuit, type) => {
  const query = {
    collection: "Uploads",
    filter: {
      circuit,
      type
    },
    sort: {},
    project: {
      _id: 0,
      "support.photos.list": 1
    },
    confirm: true
  };

  const res = await bulkGet(query);

  return res;
};

const updateUploadBatches = async (circuit, type, batches) => {
  const query = {
    collection: "Uploads",
    filter: {
      circuit,
      type
    },
    update: {
      "support.compressed.batches": batches
    },
    confirm: true
  };

  const res = await bulkUpdate(query);

  return res;
};

const updateBatchFinish = async (circuit, type, signature, flag) => {
  const query = {
    collection: "Uploads",
    filter: {
      circuit,
      type,
      "support.compressed.batches.signature": signature
    },
    update: {
      "support.compressed.batches.$.finished": flag
    },
    confirm: true
  };

  const res = await bulkUpdate(query);

  return res;
};

export {
  getUploadsByTypeOwnerCompletion,
  getListOfPhotos,
  updateUploadBatches,
  updateBatchFinish
};
