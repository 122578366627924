import axios from "axios";
import { config } from "./config";

const stage = config.stage;
const settings = config[stage].appService;

const getComplexRule = async (ruleId) => {
  const url = `${settings.baseUrl}/api/complexRuleGet?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: { ruleId }
  });

  return data;
};

const getComplexRules = async (query) => {
  const url = `${settings.baseUrl}/api/complexRulesList?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: query
  });

  return data;
};

const createComplexRule = async (doc) => {
  const url = `${settings.baseUrl}/api/complexRuleInsert?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "POST",
    url: url,
    data: doc
  });

  return data;
};

const updateComplexRule = async (ruleId, doc) => {
  const url = `${settings.baseUrl}/api/complexRuleUpdate?code=${settings.hostKey}`;
  const { data } = await axios({
    method: "PUT",
    url: url,
    params: { ruleId },
    data: doc
  });

  return data;
};

const deleteComplexRule = async (ruleId) => {
  const url = `${settings.baseUrl}/api/complexRuleDelete?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "DELETE",
    url: url,
    params: { ruleId }
  });

  return data;
};

export {
  getComplexRule,
  getComplexRules,
  createComplexRule,
  updateComplexRule,
  deleteComplexRule
};
