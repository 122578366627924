import {
  createCircuitAudit,
  getCircuitAudit,
  updateCircuitAudit
} from "../services/circuitAuditService";
import _ from "lodash";

const PIPELINE_VERSION = "1.0.0";

const CIRCUITSTATUS_INITIATED = "initiated";
const CIRCUITSTATUS_STAGING = "staging";
const CIRCUITSTATUS_AUDITING = "auditing";
const CIRCUITSTATUS_DELIVERING = "delivering";
const CIRCUITSTATUS_DELIVERED = "delivered";

const getCircuitStati = () => {
  return [
    CIRCUITSTATUS_INITIATED,
    CIRCUITSTATUS_STAGING,
    CIRCUITSTATUS_AUDITING,
    CIRCUITSTATUS_DELIVERING,
    CIRCUITSTATUS_DELIVERED
  ];
};

const getNewTimestamp = () => {
  return new Date().getTime();
};

const datasetAbbr = {
  poleInspection: "PIT",
  poleInspectionAudit: "PIA",
  poleReinforcement: "RIP",
  poleReinforcementAudit: "RIA",
  poletopInspection: "PTP"
};

// Tasks object defines the tasks in the pipeline
const Tasks = {
  poleInspection: {
    name: "poleInspection",
    label: "Pole Inspection",
    description:
      "This is always provided in Phase 1. If the csv file exists, this would be green.",
    type: "staging",
    upload: true,
    required: true,
    checks: {
      fileExists: null,
      dataExist: null,
      checkDuplicateGlnxy: null,
      checkRestorableReject: null,
      checkImageIntegrity: null
    },
    display: [
      {
        caption: "Duplicate GLNX-GLNY",
        handler: "displayCheckDuplicateGlnxy"
      },
      {
        caption: "Image Integrity",
        handler: "displayImageIntegrityReport"
      }
    ],
    logs: {},
    variables: {},
    settings: {},
    status: "pending",
    order: 1
  },
  poleGlobalIdValidation: {
    name: "poleGlobalIdValidation",
    label: "Pole Global Id Validation",
    description:
      "This task runs a process to check if there are missing global ids in the pole inspection data. The verification passes when no missing global ids are found. The process provides a downloadable CSV file report with all the missing global ids.",
    type: "staging",
    upload: false,
    required: false,
    checks: {
      missingGlobalIds: null,
      invalidGlobalIds: null
    },
    display: [
      {
        caption: "Global Id Validation",
        handler: "displayGlobalIdValidationProgress"
      },
      {
        caption: "Poles Missing Global Ids",
        handler: "displayMissingGlobalIds"
      },
      {
        caption: "Invalid Global Ids",
        handler: "displayInvalidGlobalIds"
      },
      {
        caption: "Last Responses",
        handler: "displayGlobalIdValidationResponses"
      },
      {
        caption: "Last Payloads",
        handler: "displayGlobalIdValidationPayloads"
      },
      {
        caption: "Last Errors",
        handler: "displayGlobalIdValidationErrors"
      },
      {
        caption: "Last Load Test",
        handler: "displayGlobalIdValidationLoadTest"
      }
    ],
    logs: {},
    variables: {},
    settings: {},
    status: null,
    order: 2
  },
  poletopInspection: {
    name: "poletopInspection",
    label: "Poletop Inspection",
    description:
      "In most cases, this will be provided with pole inspections. To automate, a question during the pole inspection upload should ask if poletop inspections are going to be included. If yes, this would be yellow until the file is uploaded and then it would turn green.",
    type: "staging",
    upload: true,
    required: false,
    checks: { fileExists: null, dataExist: null },
    display: [],
    logs: {},
    variables: {},
    settings: { allowActivation: true, allowDeactivation: true },
    status: null,
    order: 3
  },
  poleInspectionInvoices: {
    name: "poleInspectionInvoices",
    label: "Pole Inspection Invoices",
    description:
      "This is yellow if Pole Inspections is green. Query Servrectrans table in Maximo to see if the PO is in any record. If PO exists, this is green.",
    type: "staging",
    upload: false,
    required: false,
    checks: { purchaseOrderExists: null },
    display: [
      {
        caption: "Purchase Order Check",
        handler: "displayPoCheckProgress"
      },
      {
        caption: "Purchase Order Query",
        handler: "displayPoCheckPOFound"
      },
      {
        caption: "Service Errors",
        handler: "displayPoCheckErrors"
      },
      {
        caption: "Service Response",
        handler: "displayPoCheckResponses"
      }
    ],
    logs: {},
    variables: {},
    settings: {},
    status: null,
    order: 4
  },
  poleInspectionAudit: {
    name: "poleInspectionAudit",
    label: "Pole Inspection QA",
    description:
      "Only 10% of poles will be in this group so it must be set to yellow by Project Manager if data is expected.  It will be green once csv file uploaded.",
    type: "staging",
    upload: true,
    required: false,
    checks: {
      fileExists: null,
      dataExist: null,
      checkImageIntegrity: null
    },
    display: [
      {
        caption: "Image Integrity",
        handler: "displayImageIntegrityReport"
      }
    ],
    logs: {},
    variables: {},
    settings: { allowActivation: true, allowDeactivation: true },
    status: null,
    order: 5
  },
  poleInspectionAuditInvoices: {
    name: "poleInspectionAuditInvoices",
    label: "Pole Inspection QA Invoices",
    description:
      "If Pole Inspection Audit is green, make this yellow. Query Servrectrans table in Maximo to see if the PO is in any record. If PO exists, this is green.",
    type: "staging",
    upload: false,
    required: false,
    checks: { purchaseOrderExists: null },
    display: [
      {
        caption: "Purchase Order Check",
        handler: "displayPoCheckProgress"
      },
      {
        caption: "Purchase Order Found",
        handler: "displayPoCheckPOFound"
      },
      {
        caption: "Purchase Order Check Errors",
        handler: "displayPoCheckErrors"
      },
      {
        caption: "Purchase Order Check Responses",
        handler: "displayPoCheckResponses"
      }
    ],
    logs: {},
    variables: {},
    settings: {},
    status: null,
    order: 6
  },
  poleReinforcement: {
    name: "poleReinforcement",
    label: "Pole Reinforcement",
    description:
      "If the field Pole Status = Restorable Reject in Pole Inspections Update Source, then make yellow. It will be green once csv file uploaded.",
    type: "staging",
    upload: true,
    required: false,
    checks: { fileExists: null, dataExist: null, pullReinforcedPoles: null },
    display: [
      {
        captions: "Reinforced Poles Transfer",
        handler: "displayReinforcedPolesProgress"
      }
    ],
    logs: {},
    variables: {},
    settings: {},
    status: null,
    order: 7
  },
  poleReinforcementInvoices: {
    name: "poleReinforcementInvoices",
    label: "Pole Reinforcement Invoices",
    description:
      "If Pole Reinforcements is green, make this yellow. Query Servrectrans table in Maximo to see if the PO is in any record. If PO exists, this is green.",
    type: "staging",
    upload: false,
    required: false,
    checks: { purchaseOrderExists: null },
    display: [
      {
        caption: "Purchase Order Check",
        handler: "displayPoCheckProgress"
      },
      {
        caption: "Purchase Order Found",
        handler: "displayPoCheckPOFound"
      },
      {
        caption: "Purchase Order Check Errors",
        handler: "displayPoCheckErrors"
      },
      {
        caption: "Purchase Order Check Responses",
        handler: "displayPoCheckResponses"
      }
    ],
    logs: {},
    variables: {},
    settings: {},
    status: null,
    order: 8
  },
  poleReinforcementAudit: {
    name: "poleReinforcementAudit",
    label: "Pole Reinforcement QA",
    description:
      "Pull defects from pole reinforcement data and notify vendor and inspector.",
    type: "staging",
    upload: false,
    required: false,
    checks: {
      loadInspections: null,
      approveRelease: null
    },
    display: [
      {
        caption: null,
        handler: "displayReinforcementReinspections"
      },
      {
        caption: null,
        handler: "displayReinforcementInspections"
      }
    ],
    logs: {},
    variables: {},
    settings: {},
    status: null,
    order: 9
  },
  dataIntegrityChecks: {
    name: "dataIntegrityChecks",
    label: "Data Integrity Checks",
    description:
      "Format validation and engineering standards compliance checks against PIT, PTP, PIA, and RIP datasets.",
    type: "audit",
    upload: false,
    required: false,
    checks: {
      formatValidation: null,
      engineeringStandardsCompliance: null
    },
    display: [
      {
        caption: "Format Validation Progress",
        handler: "displayFormatValidationProgress"
      },
      {
        caption: "Format Validation Results",
        handler: "displayFormatValidationResults"
      },
      {
        caption: "Engineering Standards Compliance Results",
        handler: "displayEngineeringStandardsCompliance"
      }
    ],
    logs: {},
    variables: {},
    settings: {},
    status: null,
    order: 10
  },
  circuitAudit: {
    name: "circuitAudit",
    label: "Circuit Audit",
    description:
      "The logic will be similar to the Engineering Standards design except the logic will cross compare fields between one of the other five data sources below instead of fields within the same data source.",
    type: "audit",
    upload: false,
    required: false,
    checks: { runAudit: null },
    display: [],
    logs: {},
    variables: {},
    settings: {},
    status: null,
    order: 11
  },
  circuitAuditSignOff: {
    name: "circuitAuditSignOff",
    label: "Circuit Audit Sign Off",
    description:
      "Project manager signs off on circuit audit. Approval will initiate a data freeze, enable data delivery processes, and send reminder to stakeholders to generate OH package.",
    type: "audit",
    upload: false,
    required: false,
    checks: { circuitAuditApproval: null },
    display: [],
    logs: {},
    variables: {},
    settings: {},
    status: null,
    order: 12
  },
  dataPowerESRI: {
    name: "dataPowerESRI",
    label: "Data Power ESRI",
    description: "This task prepares and transfers pole location data to ESRI.",
    type: "delivery",
    upload: false,
    required: false,
    checks: {
      transferLocationDataToDataPowerESRI: null,
      poleInspectionDataUpdateState: null
    },
    display: [
      {
        caption: "ESB Transfer",
        handler: "displayDataPowerESRITransferProgress"
      },
      {
        caption: "Last Responses",
        handler: "displayDataPowerESRITransferResponses"
      },
      {
        caption: "Last Payloads",
        handler: "displayDataPowerESRITransferPayloads"
      },
      {
        caption: "Last Errors",
        handler: "displayDataPowerESRITransferErrors"
      },
      {
        caption: "Load test",
        handler: "displayDataPowerESRILoadTest"
      }
    ],
    logs: {},
    variables: {},
    settings: {},
    status: null,
    order: 13
  },
  dataPowerMaximo: {
    name: "dataPowerMaximo",
    label: "Data Power Maximo",
    description:
      "This task prepares and transfers pole inspection data to ESB one record at a time. (maximoLocationDataUpdateCheck: true)",
    type: "delivery",
    upload: false,
    required: false,
    checks: {
      stageOperationalRecordForDataPowerMaximo: null,
      transferOperationalRecordToDataPowerMaximo: null,
      maximoLocationDataUpdateCheck: true,
      transferReinforcementInspectionRecordToDataPower: null
    },
    display: [
      {
        caption: null,
        handler: "manageRiaPoRequest"
      },
      {
        caption: "ESB Transfer",
        handler: "displayDataPowerMaximoTransferProgress"
      },
      {
        caption: "Mismatched Location Records",
        handler: "displayMismatchedLocationData"
      },
      {
        caption: "Last Responses",
        handler: "displayDataPowerMaximoTransferResponses"
      },
      {
        caption: "Last Errors",
        handler: "displayDataPowerMaximoTransferErrors"
      },
      {
        caption: "Last Transfer Payloads",
        handler: "displayDataPowerMaximoTransferPayloads"
      },
      {
        caption: "Load test",
        handler: "displayDataPowerMaximoLoadTest"
      }
    ],
    logs: {},
    variables: {},
    settings: {},
    status: null,
    order: 14
  },
  dataLake: {
    name: "dataLake",
    label: "Data Lake",
    description:
      "This task prepares and transfers pole inspection data, CSV files, and pole pictures to the EDA Data Lake in two separate processes as follows.",
    type: "delivery",
    upload: false,
    required: false,
    checks: {
      stageDataToDataLake: null,
      transferDataToDataLake: null,
      transferArtifactsToDataLake: null,
      transferImagesToDataLake: null
    },
    display: [
      {
        caption: "EDA Transfer",
        handler: "displayDataLakeTransferProgress"
      },
      {
        caption: "Files Missing Global Ids",
        handler: "displayDataLakeTransferMissingGlobalIds"
      },
      {
        caption: "EDA Responses",
        handler: "displayDataLakeTransferResponses"
      },
      {
        caption: "EDA Errors",
        handler: "displayDataLakeTransferErrors"
      },
      {
        caption: "EDA Transfer Docs",
        handler: "displayDataLakeTransferDocs"
      },
      {
        caption: "Load test",
        handler: "displayDataLakeLoadTest"
      }
    ],
    logs: {},
    variables: {},
    settings: {},
    status: null,
    order: 15
  }
};

// Handlers are used to update the pipeline state
const taskState = {};

const taskHandlers = {
  poleInspection: (task) => {
    const taskCopy = { ...task };

    return taskCopy;
  },
  poleGlobalIdValidation: (task) => {
    const taskCopy = { ...task };

    // Required if pole inspection passed
    if (taskState["poleInspection"].status === "passed") {
      if (!taskCopy.required) {
        taskCopy.required = true;
        taskCopy.status = "pending";
      }
    }

    return taskCopy;
  },
  poletopInspection: (task) => {
    const taskCopy = { ...task };

    // Disallow deactivation if status is "passed"
    if (taskCopy.checks.fileExists) {
      if (taskCopy.settings.allowDeactivation) {
        taskCopy.settings.allowDeactivation = false;
      }
    }

    return taskCopy;
  },
  poleInspectionInvoices: (task) => {
    const taskCopy = { ...task };

    // Required if pole inspection passed
    if (taskState["poleInspection"].status === "passed") {
      if (!taskCopy.required) {
        taskCopy.required = true;
        taskCopy.status = "pending";
      }
    }

    return taskCopy;
  },
  poleInspectionAudit: (task) => {
    const taskCopy = { ...task };

    // Disallow deactivation if status is "passed"
    if (taskCopy.checks.fileExists) {
      if (taskCopy.settings.allowDeactivation) {
        taskCopy.settings.allowDeactivation = false;
      }
    }

    return taskCopy;
  },
  poleInspectionAuditInvoices: (task) => {
    const taskCopy = { ...task };

    // Required if pole inspection audit is passed
    if (taskState["poleInspectionAudit"].status === "passed") {
      if (!taskCopy.required) {
        taskCopy.required = true;
        taskCopy.status = "pending";
      }
    }

    return taskCopy;
  },
  poleReinforcement: (task) => {
    const taskCopy = { ...task };

    // If pole inspection has at least one pole status "restorable reject"
    if (taskState["poleInspection"].variables.restorableReject) {
      if (!taskCopy.required) {
        taskCopy.required = true;
        taskCopy.status = "pending";
      }
    }

    return taskCopy;
  },
  poleReinforcementInvoices: (task) => {
    const taskCopy = { ...task };

    // Required if pole reinforcement passed
    if (taskState["poleReinforcement"].status === "passed") {
      if (!taskCopy.required) {
        taskCopy.required = true;
        taskCopy.status = "pending";
      }
    }

    return taskCopy;
  },
  poleReinforcementAudit: (task) => {
    const taskCopy = { ...task };

    // Required if pole reinforcement passed
    if (taskState["poleReinforcement"].status === "passed") {
      if (!taskCopy.required) {
        taskCopy.required = true;
        taskCopy.status = "pending";
      }
    }

    return taskCopy;
  },
  dataIntegrityChecks: (task) => {
    const taskCopy = { ...task };

    const activeStagingTaskNames = Object.keys(taskState).filter(
      (taskName) =>
        taskState[taskName].type === "staging" && taskState[taskName].required
    );

    // Run all checks
    let artifactsPassed = true;
    activeStagingTaskNames.forEach((taskName) => {
      if (taskState[taskName].status !== "passed") {
        artifactsPassed = false;
      }
    });

    if (artifactsPassed) {
      if (!taskCopy.required) {
        taskCopy.required = true;
        taskCopy.status = "pending";
      }
    }

    return taskCopy;
  },
  circuitAudit: (task) => {
    const taskCopy = { ...task };

    // Required if pole inspection passed
    if (taskState["dataIntegrityChecks"].status === "passed") {
      if (!taskCopy.required) {
        taskCopy.required = true;
        taskCopy.status = "pending";
      }
    }

    return taskCopy;
  },
  circuitAuditSignOff: (task) => {
    const taskCopy = { ...task };

    // Required if pole inspection audit is passed
    if (taskState["circuitAudit"].status === "passed") {
      if (!taskCopy.required) {
        taskCopy.required = true;
        taskCopy.status = "pending";
      }
    }

    return taskCopy;
  },
  dataPowerESRI: (task) => {
    const taskCopy = { ...task };

    // If audit passed enable transfer
    if (taskState["circuitAuditSignOff"].status === "passed") {
      if (!taskCopy.required) {
        taskCopy.required = true;
        taskCopy.status = "pending";
      }
    }

    return taskCopy;
  },
  dataPowerMaximo: (task) => {
    const taskCopy = { ...task };

    // If audit passed enable transfer
    if (taskState["dataPowerESRI"].status === "passed") {
      if (!taskCopy.required) {
        taskCopy.required = true;
        taskCopy.status = "pending";
      }
    }

    return taskCopy;
  },
  dataLake: (task) => {
    const taskCopy = { ...task };

    // If audit passed enable transfer
    if (
      taskState["circuitAuditSignOff"].status === "passed" &&
      taskState["dataPowerMaximo"].status === "passed"
    ) {
      if (!taskCopy.required) {
        taskCopy.required = true;
        taskCopy.status = "pending";
      }
    }

    return taskCopy;
  }
};

const runPipeline = (pipeline) => {
  const taskKeys = Object.keys(pipeline);
  const newPipeline = [];

  // Update engine
  taskKeys.forEach((key) => {
    // Copy task
    const task = pipeline[key];

    // Call task handler
    if (taskHandlers[task.name]) {
      const newTask = taskHandlers[task.name](task);

      // Update state
      taskState[task.name] = { ...newTask };

      // Update task in pipeline
      newPipeline.push(newTask);
    } else {
      // Update state
      taskState[task.name] = { ...task };

      // Update task in pipeline
      newPipeline.push(task);
    }
  });

  return newPipeline;
};

const findTaskIndex = (taskName, pipeline) => {
  return _.findIndex(pipeline, (t) => {
    return t.name === taskName;
  });
};

const resetCircuitAuditPipelineTask = async (
  circuitName,
  taskName,
  update,
  options
) => {
  // Get circuit
  const circuit = await getCircuitAudit(circuitName);
  const pipeline = circuit.pipeline;
  const taskIndex = findTaskIndex(taskName, pipeline);
  const defTask = Tasks[taskName];

  // Reset task
  pipeline[taskIndex] = {
    ...defTask,
    ...update,
    logs: {
      updated: {
        by: options.user,
        at: getNewTimestamp()
      }
    }
  };

  const newPipeline = runPipeline(pipeline);

  // Update audit
  const doc = {
    pipeline: newPipeline,
    updatedBy: options.user
  };

  // Reset circuit audit
  const result = await updateCircuitAudit(circuitName, doc);

  return { doc, result };
};

const circuitAuditExists = async (circuit) => {
  return (await getCircuitAudit(circuit)) !== null;
};

const resetCircuitAuditPipeline = async (circuit, org, options) => {
  const pipeline = [];

  // Add tasks to pipeline
  Object.keys(Tasks).forEach((task) => {
    let dataset = {};

    if (options.tasks[task]) {
      dataset = { ...Tasks[task], ...options.tasks[task] };
    } else {
      dataset = { ...Tasks[task] };
    }

    pipeline.push(dataset);
  });

  // Set document
  let doc = null;

  // Set db operation result
  let result = null;

  // Create or update circuit audit document
  if ((await getCircuitAudit(circuit)) === null) {
    // Create audit
    doc = {
      version: PIPELINE_VERSION,
      circuit,
      org,
      status: CIRCUITSTATUS_INITIATED,
      pipeline,
      createdBy: options.user
    };

    // Insert circuit audit
    result = await createCircuitAudit(doc);
  } else {
    // Update audit
    doc = {
      status: CIRCUITSTATUS_INITIATED,
      pipeline,
      updatedBy: options.user
    };

    // Reset circuit audit
    result = await updateCircuitAudit(circuit, doc);
  }

  return { doc, result };
};

const genCircuitAuditPipeline = async (circuit, org, createdBy, createdAt) => {
  const pipeline = [];

  // Add tasks to pipeline
  Object.keys(Tasks).forEach((task) => {
    const dataset = { ...Tasks[task] };

    pipeline.push(dataset);
  });

  // Create audit
  const doc = {
    version: PIPELINE_VERSION,
    circuit,
    org,
    status: CIRCUITSTATUS_INITIATED,
    pipeline,
    createdBy,
    createdAt
  };

  return doc;
};

const buildCsvFileName = (circuit, taskName, org) => {
  const testOrg = org.replace(/[ ]/g, "-");

  return `${circuit}_${datasetAbbr[taskName]}_${testOrg.toUpperCase()}.csv`;
};

const getPipelineVersion = () => PIPELINE_VERSION;

export {
  circuitAuditExists,
  genCircuitAuditPipeline,
  resetCircuitAuditPipeline,
  resetCircuitAuditPipelineTask,
  runPipeline,
  buildCsvFileName,
  CIRCUITSTATUS_INITIATED,
  CIRCUITSTATUS_STAGING,
  CIRCUITSTATUS_AUDITING,
  CIRCUITSTATUS_DELIVERING,
  CIRCUITSTATUS_DELIVERED,
  getCircuitStati,
  datasetAbbr,
  getPipelineVersion
};
