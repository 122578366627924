import { useEffect, useState } from "react";
import { defer } from "rxjs";
import { getSources } from "../services/sourceService";

export const useGetUserSources = (fileTypes) => {
  const [sources, setSources] = useState(null);

  useEffect(() => {
    if (!fileTypes) {
      return;
    }

    const fetchSources = async (fileTypes) => {
      const results = await getSources();

      let temp = {};

      results.map((source) => {
        if (fileTypes.includes(source.sourceKey) === true) {
          temp[source.sourceKey] = {
            fields: source.fields
          };
        }

        return null;
      });

      return temp;
    };

    const subscription = defer(() => fetchSources(fileTypes)).subscribe(
      setSources
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [fileTypes]);

  return sources;
};
