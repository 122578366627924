import { v4 as uuidv4 } from "uuid";

const DEF_RELEASESCOPE = "fieldDefinitionReleases";
const DEF_RELEASEACTION = "release";

const NewRelease = (fieldSet, version, entryIds, userInfo) => {
  const entry = {
    entryId: uuidv4(),
    scope: DEF_RELEASESCOPE,
    action: DEF_RELEASEACTION,
    object: {
      fieldSet,
      version,
      entryIds
    },
    createdBy: userInfo
  };

  return entry;
};

const NewDataChange = () => {
  return null;
};

export { NewRelease, NewDataChange };
