import { getSource } from "../services/sourceService";
import { getSomeFieldsObj } from "../services/fieldsService";

const getRandomInt = max => {
  return Math.floor(Math.random() * Math.floor(max));
};

const evalRules = rules => {
  let value = null;

  if (rules.hasOwnProperty("options")) {
    value = rules.options[getRandomInt(rules.options.length)];
  } else {
    value = rules.hasOwnProperty("test")
      ? rules.test[getRandomInt(rules.test.length)]
      : "";
  }

  return isNaN(Number(value)) ? `"${value}"` : value;
};

const genCsvTestData = async (type, maxRows = 1) => {
  const Source = await getSource(type);
  const header = Source.fields;
  const Fields = await getSomeFieldsObj(header);

  let rows = [];
  let cells = [];

  // Header to csv
  rows.push(`"${header.join('","')}"`);

  const addCell = h => {
    cells.push(evalRules(Fields[h].validation));
  };

  // Generate maxRows number of rows
  for (let i = 0; i < maxRows; i++) {
    // Generate test value
    header.map(h => addCell(h));

    // Collect all values as cells
    rows.push(cells.join(","));

    // Reset cell collection
    cells = [];
  }

  return rows.join("\n");
};

export { genCsvTestData as default };
