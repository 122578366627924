import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Collapse,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Progress,
  Spinner
} from "reactstrap";
import { startCase } from "lodash";
import {
  getUploadsByTypeOwnerCompletion,
  getListOfPhotos,
  updateUploadBatches,
  updateBatchFinish
} from "../services/uploaderZipCustomServices";
import {
  getUploadUrl,
  uploadZipChunk,
  commitUpload,
  getBlockListUrl,
  getUploadedBlocks,
  postUploadMd
} from "../services/uploaderZipServices";
import { signalRNegotiate } from "../services/signalrServices";
import * as signalR from "@microsoft/signalr";
import Moment from "react-moment";
import prettyBytes from "pretty-bytes";
import { useFileSignatures } from "../libs/signFile";
import { phraseToProperCase } from "../libs/case-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faChevronUp,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import "./UploaderZipDataFactory.css";

const SIGNALR_HUBNAME = "uploads";
const DEBUG_ON = false;

// Utils
const getTimestamp = () => new Date().getTime();
const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;
// const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const maxFileSizeGb = 30;
const fileChunkSize = 1024 * 1024 * 99;
// const fileChunkSize = 1024 * 1024 * 1;
const fileSizeDifference = 194;

// Source mapping
const FileTypeAbbr = {
  poleInspection: "PIT",
  poleInspectionAudit: "PIA",
  poleReinforcement: "RIP",
  poleReinforcementAudit: "RIA",
  poletopInspection: "PTP",
  poleTopMaintenance: "PTM"
};

const UploaderZipDataFactory = (props) => {
  const { user } = props;

  const userInfo = {
    name: user.name,
    org: user.org,
    email: user.email
  };

  const isAdmin = ["admin", "dev"].includes(user.role);

  const [uploads, setUploads] = useState({});
  const [selectedCircuit, setSelectedCircuit] = useState("");
  const [selectedUploadObject, setSelectedUploadObject] = useState(null);
  const [compressedBatches, setCompressedBatches] = useState([]);
  const [finalFile, setFinalFile] = useState(null);
  const [missingPhotos, setMissingPhotos] = useState([]);
  const [photosComplete, setPhotosComplete] = useState(false);
  const [type, setType] = useState("poleInspection");
  const [uploadUrl, setUploadUrl] = useState("");
  const [file, setFile] = useState(null);
  const [signature, setSignature] = useState("");
  const [chunks, setChunks] = useState([]);
  const [uploadedChunks, setUploadedChunks] = useState({});
  const [progress, setProgress] = useState(0);
  const [progressMax, setProgressMax] = useState(null);
  const [benchmarkEstimate, setBenchmarkEstimate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [events, setEvents] = useState([]);
  const [connection, setConnection] = useState(null);

  const [signFile] = useFileSignatures();

  // Collapse control
  const isCollapsed = true;
  const [collapse, setCollapse] = useState(isCollapsed);
  const [collapseIcon, setCollapseIcon] = useState(faChevronDown);
 
  const toggleSearch = () => setCollapse(!collapse);
  const onEntering = () => setCollapseIcon(faChevronUp);
  const onExiting = () => setCollapseIcon(faChevronDown);

  const defaultMissingPhotosOpen = false;
  const [missingCollapse, setMissingCollapse] = useState(defaultMissingPhotosOpen);
  const toggleMissingCollapse = () => setMissingCollapse(!missingCollapse);

  const joinHub = async (selectedCircuit, batches) => {
    setIsLoading(true);

    const negotiate = await signalRNegotiate(SIGNALR_HUBNAME);

    if (negotiate.status === 200) {
      try {
        const connectionUrl = negotiate.response.url;
        const accessToken = negotiate.response.accessToken;

        const conn = new signalR.HubConnectionBuilder()
          .withUrl(connectionUrl, { accessTokenFactory: () => accessToken })
          // .configureLogging(signalR.LogLevel.Trace)
          .withAutomaticReconnect()
          .build();

        conn.onclose(() => {
          setIsLoading(false);

          appendEvent({ signalR: { status: "disconnected" } });
        });

        conn.on(user.id, (obj) => {
          const notification = obj.notification;
          const action = notification.action;
          const message = notification.message;
          const signature = message.batch.signature;

          switch (action) {
            case "updateBatch":
              appendEvent({ signalR: notification });

              const finished = message.batch.finished;
              const final = message.final;
              const photos = message.photos;

              const batch = findBatchBySignature(signature, batches);

              if (batch !== false) {
                // Update compressed batches
                const copyBatches = batches.filter(
                  (item) => item.signature !== signature
                );

                const newBatches = [...copyBatches, { ...batch, finished }];
                setCompressedBatches(newBatches);

                // Update uploads
                let copyUploads = { ...uploads };

                // Update circuit batches
                copyUploads[selectedCircuit].support.compressed.batches =
                  newBatches;

                // Update circuit final
                copyUploads[selectedCircuit].support.compressed.final = final;

                // Update circuit photos
                copyUploads[selectedCircuit].support.photos = photos;

                setUploads(copyUploads);

                if (finished) setIsProcessing(false);

                // Update final file
                setFinalFile(final);
                if (photos?.missing) {
                  setMissingPhotos(photos.missing);
                }
              }
              break;
            case "deleteBatch":
              appendEvent({ signalR: notification });

              // Update compressed batches
              const copyBatches = batches.filter(
                (item) => item.signature !== signature
              );

              setCompressedBatches(copyBatches);

              // Update uploads
              let copyUploads = { ...uploads };

              // Update circuit batches
              copyUploads[selectedCircuit].support.compressed.batches =
                copyBatches;

              setUploads(copyUploads);

              setIsProcessing(false);

              showCustomModal({
                title: "File Process Error",
                message: `${message.reason}. Please check your email for more details.`,
                scope: "upload"
              });
              break;
            default:
            // Do nothing
          }
        });

        await conn.start().then(() => {
          setIsLoading(false);

          appendEvent({ signalR: { status: "connected" } });
        });

        setConnection(conn);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const closeConnection = async () => {
    if (connection) await connection.stop();
  };

  const appendEvent = (ev) => {
    setEvents([ev, ...events]);
  };

  // gets a list of circuits for the drop down
  const searchCircuits = async (e) => {
    e.preventDefault();

    setSelectedCircuit("");
    setUploads([]);
    setSelectedUploadObject(null);
    setCompressedBatches([]);
    setFinalFile(null);
    setMissingPhotos([]);
    setMissingCollapse(defaultMissingPhotosOpen);
    setEvents([]);
    closeConnection();

    const res = await getUploadsByTypeOwnerCompletion(
      type,
      user.org,
      photosComplete,
      isAdmin
    );

    const resetSupport = {
      compressed: {
        batches: [],
        final: null
      },
      photos: {
        list: [],
        count: 0,
        complete: false
      }
    };

    const uploadsByCircuit = {};

    // Assuming res.status === 200 OK
    res.response.forEach((upload) => {
      const support = upload.support || resetSupport;

      uploadsByCircuit[upload.circuit] = {
        ...upload,
        support
      };
    });

    setUploads(uploadsByCircuit);

    if (Object.keys(uploadsByCircuit).length === 0) {
      showCustomModal({
        title: "Search results",
        message: "No results were found following that criteria",
        scope: "no-results"
      });
    } else {
      // Close search card
      toggleSearch();
    }
  };

  const isValidFileSize = ({ size }) =>
    size / 1024 / 1024 / 1024 /** in Gb */ < maxFileSizeGb;

  // validate the file size
  // put the file in the state
  // request an upload url
  // and split the file into 99M chunks
  const handleFileChange = async (e) => {
    e.persist();

    const resetUploadForm = () => {
      // Reset signature
      setSignature("");

      // Reset upload form
      setFile(null);
      setIsLoading(false);

      e.target.value = null;
    };

    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const allowedContentTypes = [
        "application/zip",
        "application/x-zip-compressed"
      ];

      if (allowedContentTypes.includes(file.type)) {
        if (isValidFileSize(file)) {
          setIsLoading(true);

          // Check if file has been uploaded and finished processing
          const signature = await signFile(file);

          // Check if batch already exists
          const found = findBatchBySignature(signature, compressedBatches);

          // If batch is found and it's finished
          if (found !== false && found.finished) {
            // Batch exists and finished; nothing else to do with it
            showCustomModal({
              title: "Upload file validation",
              message: "File already uploaded and processed...",
              scope: "upload"
            });

            resetUploadForm();
          } else {
            const typePath = FileTypeAbbr[type];
            const resUploadUrl = await getUploadUrl(
              `input/${selectedCircuit}/${typePath}/${file.name}`
            );

            if (resUploadUrl.status === 200) {
              setUploadUrl(resUploadUrl.response);
              setFile(file);

              appendEvent({ resUploadUrl });

              splitFileIntoChunks(file);

              const resUrl = await getBlockListUrl(
                `input/${selectedCircuit}/${typePath}/${file.name}`
              );

              switch (resUrl.status) {
                case 200:
                  const uploadedChunks = await getUploadedBlocks(
                    resUrl.response
                  );

                  setUploadedChunks(uploadedChunks);

                  // Keep signature for further use
                  setSignature(signature);
                  break;
                default:
                  resetUploadForm();

                  showCustomModal({
                    title: "Upload file",
                    message:
                      "Unexpected error happened trying to get block list URL from Azure...",
                    scope: "upload"
                  });
              }
            } else {
              resetUploadForm();

              showCustomModal({
                title: "Failed request to Azure",
                message:
                  "Error requesting upload URL from Azure. Please try again...",
                scope: "upload"
              });
            }
          }
        } else {
          resetUploadForm();

          showCustomModal({
            title: "File size limits",
            message: `This file exceeds the maximum file size of ${maxFileSizeGb}GB`,
            scope: "upload"
          });
        }
      } else {
        resetUploadForm();

        showCustomModal({
          title: "File type validation",
          message: "The selected file is not a zip file",
          scope: "upload"
        });
      }
    } else {
      resetUploadForm();
    }

    setIsLoading(false);
  };

  const findBatchBySignature = (signature, batches) => {
    const res = batches.filter((item) => {
      if (item.signature === signature) return true;

      return false;
    });

    return res[0] ? res[0] : false;
  };

  // push each chunk to the file service
  // tell the file service to package all of the chunks together
  const uploadFile = async (e) => {
    e.preventDefault();
    setIsUploading(true);

    // Disconnect from Signal R
    closeConnection();

    let compressedBatchesUpdated = [...compressedBatches];

    const found = findBatchBySignature(signature, compressedBatches);
    if (found === false) {
      // Set new batch
      const batch = {
        fileName: file.name,
        contentType: "application/zip",
        size: file.size,
        finished: null,
        createdBy: userInfo,
        createdAt: getTimestamp(),
        signature
      };

      // Update upload batches in db
      await updateUploadBatches(selectedCircuit, type, [
        ...compressedBatchesUpdated,
        batch
      ]);

      // Set finished to false
      compressedBatchesUpdated = [
        ...compressedBatchesUpdated,
        { ...batch, finished: false }
      ];

      // Update batches in state
      setCompressedBatches(compressedBatchesUpdated);

      // Update uploads in state
      let copyUploads = { ...uploads };
      copyUploads[selectedCircuit].support.compressed.batches =
        compressedBatchesUpdated;
      setUploads(copyUploads);
    } else {
      if (found.finished === null) {
        if (compressedBatchesUpdated.length > 0) compressedBatchesUpdated.pop();

        // Set finished to false
        compressedBatchesUpdated = [
          ...compressedBatchesUpdated,
          { ...found, finished: false }
        ];

        // Update batches in state
        setCompressedBatches(compressedBatchesUpdated);

        // Update uploads in state
        let copyUploads = { ...uploads };
        copyUploads[selectedCircuit].support.compressed.batches =
          compressedBatchesUpdated;
        setUploads(copyUploads);
      }
    }

    // Initialize progress bar
    const totalChunks = chunks.length;
    setProgress(0);
    setProgressMax(totalChunks);

    const estimateCompletion = (chunk, benchmarkStart) => {
      const now = getTimestamp();
      const elapsed = now - benchmarkStart;
      const chunksLeft = totalChunks - chunk;
      const estimate = chunksLeft * elapsed + now;

      setBenchmarkEstimate(estimate);
    };

    // Loop through chunks and upload them
    for (let i = 0; i < totalChunks; i++) {
      // if this chunk was already uploaded and it has the same size as the chunk we're trying to upload
      const blockId = chunks[i].blockId;
      const chunkContents = chunks[i].contents;

      const isUploaded =
        uploadedChunks[blockId] &&
        uploadedChunks[blockId] - fileSizeDifference === chunkContents.size;

      if (!isUploaded) {
        const benchmarkStart = getTimestamp();

        await uploadZipChunk(uploadUrl, chunkContents, blockId);

        estimateCompletion(i + 1, benchmarkStart);

        // await delay(5000);
      }

      setProgress(i + 1);
    }

    // console.log("Finished at: ", new Date());

    // Finish up upload
    await commitUpload(
      uploadUrl,
      chunks.map((c) => c.blockId)
    );

    // Add metadata to uploaded file
    const md = {
      userId: user.id,
      userName: user.name,
      userEmail: user.email,
      circuit: selectedCircuit,
      type,
      signature
    };
    await postUploadMd(uploadUrl, md);

    // Update batch finished flag
    await updateBatchFinish(selectedCircuit, type, signature, false);

    // Finished uploading
    setIsUploading(false);

    // Reset progress bar
    setProgress(0);
    setProgressMax(null);
    setBenchmarkEstimate(null);

    // Signal a batch is being processed
    setIsProcessing(true);

    // Connect to Signal R
    joinHub(selectedCircuit, compressedBatchesUpdated);

    // Reset upload form
    setFile(null);
  };

  const splitFileIntoChunks = (file) => {
    // split the file into 99M chunks,
    // each chunk needs to have:
    // 1. base64 encoded block id
    // 2. chunk contents

    let chunks = [];
    if (file) {
      let fileSize = file.size;
      let chunkCount = Math.ceil(fileSize / fileChunkSize, fileChunkSize);
      let chunk = 0;
      while (chunk < chunkCount) {
        let offset = chunk * fileChunkSize;
        let fileChunk = file.slice(offset, offset + fileChunkSize);
        // each block id MUST be the same string length prior to base64 encoding. This is a requirement of the blob service
        chunks.push({
          blockId: btoa(
            `${file.name.slice(-42)}-${chunk.toString().padStart(5, "0")}`
          ),
          contents: fileChunk
        });
        chunk++;
      }
    }
    setChunks(chunks);
  };

  const handleType = (type) => {
    setType(type);

    // Reset rest of form
    setSelectedCircuit("");
    setPhotosComplete(false);
    setSelectedUploadObject(null);
    setCompressedBatches([]);
    setFinalFile(null);
    setMissingPhotos([]);
    setMissingCollapse(defaultMissingPhotosOpen);
    setUploads([]);
  };

  const checkIsProcessing = (batches) => {
    const res = batches.filter((item) => item.finished === false);

    return res.length > 0 ? true : false;
  };

  const handleSelectedCircuit = (circuit) => {
    // Disconnect from Signal R
    closeConnection();

    const upload = uploads[circuit];
    const batches = upload.support.compressed.batches;
    const final = upload.support.compressed.final;
    const missingPhotos = upload.support.photos?.missing;

    setSelectedCircuit(circuit);
    setSelectedUploadObject(upload);
    setCompressedBatches(batches);
    setFinalFile(final);
    setMissingPhotos(missingPhotos);
    setMissingCollapse(defaultMissingPhotosOpen);

    // Check for pending processing batch
    setIsProcessing(checkIsProcessing(batches));

    // Connect to Signal R
    if (batches.length > 0) joinHub(circuit, batches);
  };

  const handleIncompletePhotoUpload = (flag) => {
    setPhotosComplete(flag === "true");

    // Reset rest of form
    setSelectedCircuit("");
    setSelectedUploadObject(null);
    setCompressedBatches([]);
    setFinalFile(null);
    setMissingPhotos([])
    setMissingCollapse(defaultMissingPhotosOpen);
    setUploads([]);
  };

  const ProgressBadge = (props) => {
    const { finished, signature } = props;

    const label = finished
      ? "Succesful batch"
      : finished === null
      ? "Interrupted: try again..."
      : "Processing...";
    const labelColor = finished
      ? "success"
      : finished === null
      ? "danger"
      : "warning";

    const handleCancel = async () => {
      const batches = compressedBatches.filter(
        (item) => item.signature !== signature
      );

      // Update batches in db
      await updateUploadBatches(selectedCircuit, type, batches);

      // Update batches in state
      setCompressedBatches(batches);

      // Update uploads in state
      let copyUploads = { ...uploads };
      copyUploads[selectedCircuit].support.compressed.batches = batches;
      setUploads(copyUploads);
    };

    return (
      <div>
        <Badge color={labelColor} pill>
          {label}
        </Badge>{" "}
        {finished === false && (
          <small>
            <i>
              (Data Delivery will notify you via email when your upload has been
              processed.)
            </i>
          </small>
        )}
        {finished === null && (
          <>
            <hr />
            <Button color="link" outline size="sm" onClick={handleCancel}>
              <b>Cancel upload</b>
            </Button>
          </>
        )}
      </div>
    );
  };

  const ProgressBar = () => {
    const perc = progressMax ? (progress / progressMax) * 100 : 0;

    const fileName = file ? file.name : "noname";
    const progressLabel = Math.min(progressMax, progress + 1);

    return (
      <Card>
        <CardHeader tag="h6">Upload Progress</CardHeader>
        <CardBody>
          <div className="text-center">
            <b>
              Uploading chunk #{progressLabel} of {progressMax}...
            </b>
            <br />
            <small>
              <i>({fileName})</i>
            </small>
          </div>
          <Progress color="success" value={perc} />
          {benchmarkEstimate && (
            <div className="text-center">
              <small>
                <i>
                  Estimated completion at{" "}
                  <Moment format="lll">{benchmarkEstimate}</Moment>
                </i>
              </small>
            </div>
          )}
        </CardBody>
      </Card>
    );
  };

  const showOnly = (flag) => {
    return flag ? "Complete uploads" : "Incomplete uploads";
  };

  const ShowFinalFile = () => {
    const { fileName, size, createdAt } = finalFile;

    const [hrefCsv, setHrefCsv] = useState(null);
    const [csvSize, setCsvSize] = useState(null);

    const count = uploads[selectedCircuit].support.photos.count;
    const plural = count > 1 ? "s" : "";
    const downloadName = fileName.replace(
      /photos-\d.zip/,
      "list-of-photos.csv"
    );

    const handleDownload = async () => {
      const jsonData = await getListOfPhotos(selectedCircuit, type);

      // Transform response to array of strings
      const csvData = jsonData.response[0].support.photos.list.join("\n");

      // Create URL for CSV data
      const fileCsv = new Blob([`File Name\n${csvData}`], {
        type: "text/csv"
      });

      setHrefCsv(URL.createObjectURL(fileCsv));
      setCsvSize(fileCsv.size);
    };

    return (
      <Card className="mb-1rem">
        <CardHeader className="bg-success text-white" tag="h6">
          Complete Photo Upload
        </CardHeader>
        <CardBody>
          <b>{`${fileName} (${prettyBytes(size)})`}</b>{" "}
          <small>
            <i>
              ({count} photo{plural})
            </i>
          </small>
          <br />
          <small>
            By DDA @ <Moment format="lll">{createdAt}</Moment>
          </small>
          <hr />
          {!hrefCsv && (
            <Button color="link" outline size="sm" onClick={handleDownload}>
              <b>Download list of photos</b>
            </Button>
          )}
          {hrefCsv && (
            <>
              <CardText>
                Click on the link below to download the CSV file
              </CardText>
              <CardText>
                <a href={hrefCsv} download={downloadName}>
                  <FontAwesomeIcon icon={faDownload} /> List of photos (
                  {prettyBytes(csvSize ? csvSize : 0)})
                </a>
              </CardText>
            </>
          )}
        </CardBody>
      </Card>
    );
  };

  const ShowListOfBatches = () => {
    const [hrefCsv, setHrefCsv] = useState(null);
    const [csvSize, setCsvSize] = useState(null);

    const downloadName = `${selectedCircuit}_${FileTypeAbbr[type]}-list-of-photos.csv`;

    const handleDownload = async () => {
      const jsonData = await getListOfPhotos(selectedCircuit, type);

      // Transform response to array of strings
      const csvData = jsonData.response[0].support.photos.list.join("\n");

      // Create URL for CSV data
      const fileCsv = new Blob([`File Name\n${csvData}`], {
        type: "text/csv"
      });

      setHrefCsv(URL.createObjectURL(fileCsv));
      setCsvSize(fileCsv.size);
    };

    return (
      <Card className="mb-2">
        <CardHeader tag="h6">Upload batches</CardHeader>
        <CardBody>
          <ListGroup>
            {compressedBatches.map(
              (
                { fileName, size, finished, createdBy, createdAt, signature },
                i
              ) => (
                <ListGroupItem key={i}>
                  {`${fileName} (${prettyBytes(size)})`}
                  <br />
                  <small>
                    By {createdBy.name} @{" "}
                    <Moment format="lll">{createdAt}</Moment>
                  </small>
                  <ProgressBadge finished={finished} signature={signature} />
                </ListGroupItem>
              )
            )}
          </ListGroup>
          {!hrefCsv && !finalFile && (
            <>
              <hr />
              <Button color="link" outline size="sm" onClick={handleDownload}>
                <b>Download list of photos</b>
              </Button>
            </>
          )}
          {!finalFile && missingPhotos?.length &&  (
            <>
              <hr />
              <b>GLNs with missing photos</b>
              <Button className="collapse-button" color="default" onClick={toggleMissingCollapse} size="sm">
                <FontAwesomeIcon icon={missingCollapse ? faChevronUp : faChevronDown} />
              </Button>
              <Collapse isOpen={missingCollapse}>
                <div className="mt-1rem">
                  {missingPhotos.map(gln => (<span className="mr-05rem">{gln}</span>))}
                </div>
              </Collapse>
            </>
          )}
          {hrefCsv && !finalFile && (
            <>
              <hr />
              <CardText>
                Click on the link below to download the CSV file
              </CardText>
              <CardText>
                <a href={hrefCsv} download={downloadName}>
                  <FontAwesomeIcon icon={faDownload} /> List of photos (
                  {prettyBytes(csvSize ? csvSize : 0)})
                </a>
              </CardText>
            </>
          )}
        </CardBody>
      </Card>
    );
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = (scope = null) => {
    setOpenModal(!openModal);

    switch (scope) {
      case "search":
        toggleSearch();
        break;
      default:
      // Do nothing
    }
  };
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const showCustomModal = (params) => {
    const { title, message, scope = null } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal(scope);
  };

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <div className="UploaderZipDataFactory">
      <Card className="mt-1rem mb-1rem">
        <CardHeader tag="h6">
          Search{" "}
          {Object.keys(uploads).length > 0 && (
            <Button
              className="collapse-button"
              color="default"
              onClick={toggleSearch}
              size="sm"
            >
              <FontAwesomeIcon icon={collapseIcon} />
            </Button>
          )}
        </CardHeader>
        <Collapse
          isOpen={collapse}
          onEntering={onEntering}
          onExiting={onExiting}
        >
          <CardBody>
            <Form className="Filters" onSubmit={searchCircuits}>
              <h5 className="card-title">Upload pictures for</h5>
              <FormGroup tag="fieldset">
                {props.user.prefs.fileTypes.map((fileType, i) => (
                  <FormGroup check key={i}>
                    <Label className="file-type" check>
                      <Input
                        type="radio"
                        checked={type === fileType}
                        name="file-type"
                        onChange={(e) => handleType(e.target.value)}
                        value={fileType}
                        disabled={isLoading || isUploading}
                      />
                      {` ${startCase(fileType)}`}
                    </Label>
                  </FormGroup>
                ))}
              </FormGroup>
              <h5 className="card-title">Show only (optional)</h5>
              <FormGroup tag="fieldset">
                <FormGroup check>
                  <Label className="complete-photos" check>
                    <Input
                      type="radio"
                      checked={photosComplete === false}
                      name="complete-photos"
                      onChange={(e) =>
                        handleIncompletePhotoUpload(e.target.value)
                      }
                      value={false}
                      disabled={isLoading || isUploading}
                    />
                    Incomplete uploads
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label className="complete-photos" check>
                    <Input
                      type="radio"
                      checked={photosComplete === true}
                      name="complete-photos"
                      onChange={(e) =>
                        handleIncompletePhotoUpload(e.target.value)
                      }
                      value={true}
                      disabled={isLoading || isUploading}
                    />
                    Complete uploads
                  </Label>
                </FormGroup>
              </FormGroup>
              <Button
                color="primary"
                className="btn-lg mt-1rem"
                disabled={isLoading || isUploading}
                block
              >
                Search
              </Button>
            </Form>
          </CardBody>
        </Collapse>
      </Card>
      {Object.keys(uploads).length > 0 && (
        <Card>
          <CardHeader tag="h6">Results</CardHeader>
          <CardBody>
            <CardText>
              <b>Upload pictures for:</b> {phraseToProperCase(type)}
            </CardText>
            <CardText>
              <b>Show only:</b> {showOnly(photosComplete)}
            </CardText>
            <FormGroup>
              <CardText>
                <b>Select the circuit data you want to upload photos for:</b>
              </CardText>
              <Input
                type="select"
                value={selectedCircuit}
                onChange={(e) => handleSelectedCircuit(e.target.value)}
                disabled={isLoading || isUploading}
              >
                <option value="">Pick a circuit from the list</option>
                {Object.keys(uploads).map((circuit, i) => {
                  const isComplete =
                    uploads[circuit].support?.photos?.complete || false;

                  return (
                    <option value={circuit} key={i}>
                      {circuit} ({isComplete ? "Complete" : "Incomplete"})
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
            {selectedUploadObject && (
              <div>
                {finalFile && <ShowFinalFile />}
                {!isUploading && compressedBatches.length > 0 && (
                  <ShowListOfBatches />
                )}
                {!finalFile && !isProcessing && !isUploading && (
                  <Card>
                    <CardHeader tag="h6">Upload New</CardHeader>
                    <CardBody>
                      <Form onSubmit={uploadFile}>
                        <FormGroup>
                          <Input
                            type="file"
                            name="upload"
                            accept="application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip"
                            required
                            onChange={handleFileChange}
                          />
                          <FormText>
                            Pick a file up to {maxFileSizeGb} GBytes in size
                          </FormText>
                        </FormGroup>
                        <Card className="mb-1rem">
                          <CardHeader className="bg-warning">
                            <b>
                              <u>{phraseToProperCase(type)}</u> Photo File
                              Naming Convention
                            </b>
                          </CardHeader>
                          <CardBody>
                            <CardText>
                              <b>Format:</b>{" "}
                              {`${FileTypeAbbr[type]}-GLNX-GLNY-sequence number starting at 1.jpg`}
                            </CardText>
                            <ul>
                              <li>
                                Example of pole with one photo:
                                <br />
                                {`${FileTypeAbbr[type]}-150400-282707-1.jpg`}
                              </li>
                              <li>
                                Example of pole with two photos:
                                <br />
                                {`${FileTypeAbbr[type]}-150400-282707-1.jpg`}
                                <br />
                                {`${FileTypeAbbr[type]}-150400-282707-2.jpg`}
                              </li>
                            </ul>
                            <CardText>
                              <b>Photo removal conditions:</b>
                            </CardText>
                            <CardText>
                              Photos will be removed by the application if any
                              one of the following conditions are met.
                            </CardText>
                            <ul>
                              <li>
                                Filename does not follow photo naming convention
                                shown in "Format" section above
                              </li>
                              <li>
                                GLNX-GLNY in photo filename doesn't match a
                                record in the associated CSV file
                              </li>
                              {type === "poleInspection" && (
                                <>
                                  <li>
                                    AddRemoveUpdatePole_PIT value equals
                                    “Remove”
                                  </li>
                                  <li>
                                    ReasonPoleWasNotInspected_PIT value does not
                                    equal “Pole Was Inspected”
                                  </li>
                                  <li>
                                    InspectionTagType_PIT value equals “Pole Not
                                    Inspected“ (new)
                                  </li>
                                </>
                              )}
                              {type === "poleInspectionAudit" && (
                                <>
                                  <li>
                                    SkippedDueToAccess_PIA value equals “Y”
                                  </li>
                                </>
                              )}
                            </ul>
                          </CardBody>
                        </Card>
                        <Button
                          color="primary"
                          disabled={isLoading || isUploading || !file}
                        >
                          Upload{" "}
                          {(isLoading || isUploading) && (
                            <Spinner size="sm" color="light" />
                          )}
                        </Button>
                      </Form>
                    </CardBody>
                  </Card>
                )}
                {isUploading && <ProgressBar />}
              </div>
            )}
          </CardBody>
        </Card>
      )}
      {DEBUG_ON && (
        <Card className="mt-1rem">
          <CardHeader tag="h5">Events Log</CardHeader>
          <CardBody className="overflow-500">
            <Json
              data={{
                benchmarkEstimate,
                finalFile,
                events,
                compressedBatches,
                uploads
              }}
            />
          </CardBody>
        </Card>
      )}
      <ErrorModal />
    </div>
  );
};

export default UploaderZipDataFactory;
