import { bulkGet } from "./bulkServices";

const getUploadsBySignature = async (type, signature) => {
  const query = {
    collection: "Uploads",
    filter: {
      type,
      signature
    },
    sort: {},
    project: {
      _id: 0,
      uploadId: 1,
      fileName: 1,
      category: 1,
      circuit: 1,
      type: 1,
      contentType: 1,
      dataPoints: 1,
      createdBy: 1,
      createdAt: 1,
      status: 1
    },
    confirm: true
  };

  const res = await bulkGet(query);

  return res;
};

export { getUploadsBySignature };
