import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";
import genCsvTestData from "../libs/genCsvTestData";
import "./GenTestData.css";

const FileTypes = [
  {
    name: "filetype",
    value: "poleInspection",
    label: "Pole Inspection"
  },
  {
    name: "filetype",
    value: "poleInspectionAudit",
    label: "Pole Inspection Audit"
  },
  {
    name: "filetype",
    value: "poleReinforcement",
    label: "Pole Reinforcement"
  },
  {
    name: "filetype",
    value: "poleReinforcementAudit",
    label: "Pole Reinforcement Audit"
  },
  {
    name: "filetype",
    value: "poletopInspection",
    label: "Poletop Inspection"
  }
];

const GenTestData = props => {
  const [fileType, setFileType] = useState("poleInspection");
  const [count, setCount] = useState(10);
  const [testData, setTestData] = useState("");

  useEffect(() => {
    const pullTestData = async () => {
      return await genCsvTestData(fileType, count);
    };

    pullTestData().then(results => {
      setTestData(results);
    });
  }, [count, fileType]);

  return (
    <div className="GenTestData">
      <div className="container">
        <Card>
          <CardHeader tag="h5">Test Data</CardHeader>
          <CardBody>
            <FormGroup tag="fieldset">
              <legend>File Type</legend>
              {FileTypes.map((el, i) => (
                <FormGroup key={i} check>
                  <Label check>
                    <Input
                      type="radio"
                      name={el.name}
                      value={el.value}
                      checked={fileType === el.value ? "checked" : ""}
                      onChange={e => setFileType(e.target.value)}
                    />{" "}
                    {el.label}
                  </Label>
                </FormGroup>
              ))}
            </FormGroup>{" "}
            <FormGroup>
              <Label for="countSelect">Row count</Label>
              <Input
                type="select"
                name="select"
                id="countSelect"
                onChange={e => setCount(e.target.value)}
              >
                <option value={10}>10</option>
                <option value={100}>100</option>
                <option value={500}>500</option>
                <option value={1000}>1000</option>
                <option value={5000}>5000</option>
                <option value={10000}>10000</option>
              </Input>
            </FormGroup>{" "}
            <FormGroup>
              <Input
                type="textarea"
                name="text"
                id="test-data"
                rows={10}
                defaultValue={testData}
              />
            </FormGroup>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default GenTestData;
