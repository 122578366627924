import React from "react";
import SearchBox from "../components/SearchFileBox";
import "./Uploads.css";

const Uploads = (props) => {
  return (
    <div className="Uploads">
      <div className="container">
        <SearchBox {...props} />
      </div>
    </div>
  );
};

export default Uploads;
