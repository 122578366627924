import React from "react";
import CircuitAuditList from "../components/CircuitAuditList";
import "./CircuitAudits.css";

const CircuitAudits = (props) => {
  return (
    <div className="CircuitAudits">
      <div className="container">
        <CircuitAuditList {...props} />
      </div>
    </div>
  );
};

export default CircuitAudits;
