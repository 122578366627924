const roles = [
  { role: "admin", label: "Admin" },
  { role: "vendor", label: "Vendor" },
  { role: "analyst", label: "Analyst" },
  { role: "audit", label: "Auditor" },
  { role: "reinforcementInspector", label: "Reinforcement Inspector" },
  { role: "leadReinforcementInspector", label: "Lead Reinforcement Inspector" },
  { role: "dev", label: "Developer" },
  { role: "guest", label: "Guest" },
  { role: "photo-viewer", label: "Photo viewer"}
];

const getUserRoles = () => {
  return roles;
};

const getFieldsetPerRole = (role) => {
  let res = [];

  switch (role) {
    case "admin":
      res = [
        "poleInspection",
        "poleInspectionAudit",
        "poleReinforcement",
        "poleReinforcementAudit",
        "poletopInspection",
        "poleTopMaintenance"
      ];
      break;
    case "analyst":
      res = [
        "poleInspection",
        "poleInspectionAudit",
        "poleReinforcement",
        "poleReinforcementAudit",
        "poletopInspection",
        "poleTopMaintenance"
      ];
      break;
    case "vendor":
      res = [
        "poleInspection",
        "poleReinforcement",
        "poletopInspection",
        "poleTopMaintenance"
      ];
      break;
    case "audit":
      res = ["poleInspectionAudit"];
      break;
    case "reinforcementInspector":
      res = ["poleReinforcementAudit"];
      break;
    case "leadReinforcementInspector":
      res = ["poleReinforcementAudit"];
      break;
    case "dev":
      res = [
        "poleInspection",
        "poleInspectionAudit",
        "poleReinforcement",
        "poleReinforcementAudit",
        "poletopInspection",
        "poleTopMaintenance"
      ];
      break;
    default:
      res = [];
  }

  return res;
};

export { getUserRoles, getFieldsetPerRole };
