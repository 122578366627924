import React from "react";
import RunIntegrityChecks from "../components/RunIntegrityChecks";
import "./IntegrityChecks.css";

const IntegrityChecks = props => {
  return (
    <div className="IntegrityChecks">
      <div className="container">
        <RunIntegrityChecks {...props} />
      </div>
    </div>
  );
};

export default IntegrityChecks;
