import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Spinner
} from "reactstrap";
import { getLogEntry } from "../services/logService";
import Moment from "react-moment";
import "moment-timezone";
import { phraseToProperCase } from "../libs/case-utils";
import "./Release.css";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM-DD-YYYY HH:mm:ss";

// const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

const Release = (props) => {
  const { release, scope } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [showingUpdates, setShowingUpdates] = useState(false);
  const [showingChanges, setShowingChanges] = useState(false);
  const [updates, setUpdates] = useState(null);
  const [changes, setChanges] = useState(null);

  const isFieldDefChanges = scope === "fieldDefinitionChanges";
  const isFieldDefReleases = scope === "fieldDefinitionReleases";

  const CreatedByField = (props) => {
    const { by, at } = props;

    return (
      <>
        <b>Created by:</b>
        <br /> {by} {" @ "}
        <Moment>{at}</Moment>
      </>
    );
  };

  const UpdatedByField = (props) => {
    const { by, at } = props;

    return (
      <>
        <b>Updated by:</b>
        <br /> {by} {" @ "}
        <Moment>{at}</Moment>
      </>
    );
  };

  const ReleasedBadge = (props) => {
    const { option, action } = props;
    const color = option
      ? "success"
      : action !== "discard"
      ? "danger"
      : "secondary";
    const label = option ? "Yes" : "No";

    return (
      <Badge color={color} className="text-uppercase">
        {label}
      </Badge>
    );
  };

  const ShowFieldDefChanges = (props) => {
    const { buttonClass } = props;

    return (
      <>
        <CardText>
          <b>Field set:</b> {phraseToProperCase(release.object.fieldSet)}
        </CardText>
        <CardText>
          <b>Field key:</b> {release.object.fieldKey}
        </CardText>
        <CardText>
          <b>Release note:</b> {release.object.releaseNote}
        </CardText>
        <CardText>
          <b>Released to vendor:</b>{" "}
          <ReleasedBadge option={release.released} action={release.action} />
        </CardText>
        {!showingChanges && (
          <Button
            className="inspectButton"
            color={buttonClass}
            size="sm"
            onClick={() => handleInspectChanges([release.entryId])}
          >
            Inspect changes {isLoading && <Spinner size="sm" color="light" />}
          </Button>
        )}
        {showingChanges && <ShowChanges />}
      </>
    );
  };

  const ShowChanges = () => {
    return (
      <Card className="changes-container">
        <CardHeader>Changes</CardHeader>
        <CardBody className="changes-body">
          {changes.map((change, i) => {
            const { object } = change;
            const current = object.update.current;
            const next = object.update.next;
            const releaseNote = object.releaseNote;

            const curTrans = `[${current.transformation.join("], [")}]`;
            const nextTrans = `[${next.transformation.join("], [")}]`;

            return (
              <div key={i} className="margin-bottom-1rem">
                <CardTitle>
                  <b>Old options: </b>
                </CardTitle>
                <CardText>{current.options.join(",")}</CardText>
                <CardTitle>
                  <b>New options: </b>
                </CardTitle>
                <CardText>{next.options.join(",")}</CardText>

                <CardTitle>
                  <b>Old transformation: </b>
                </CardTitle>
                <CardText>
                  {current.useTransformation ? curTrans : "<empty list>"}
                </CardText>
                <CardTitle>
                  <b>New transformation: </b>
                </CardTitle>
                <CardText>
                  {next.useTransformation ? nextTrans : "<empty list>"}
                </CardText>

                {current.isOhPackage && (
                  <>
                    <CardTitle>
                      <b>Old Acceptable Value: </b>
                    </CardTitle>
                    <CardText>{current.acceptableValue}</CardText>
                    <CardTitle>
                      <b>New Acceptable Value: </b>
                    </CardTitle>
                    <CardText>{next.acceptableValue}</CardText>
                  </>
                )}
                <CardTitle>
                  <b>Release note: </b>
                </CardTitle>
                <CardText>{releaseNote}</CardText>
              </div>
            );
          })}
        </CardBody>
      </Card>
    );
  };

  const ShowUpdates = () => {
    return (
      <Card className="updates-container">
        <CardHeader>Fields Changed</CardHeader>
        <CardBody className="changes-body">
          {updates.map((update, i) => (
            <div key={i} className="margin-bottom-1rem">
              <CardTitle>
                <b>{update.object.fieldKey}</b>
              </CardTitle>
              <CardText>{update.object.releaseNote}</CardText>
            </div>
          ))}
        </CardBody>
      </Card>
    );
  };

  const handleInspectRelease = (ids) => {
    setIsLoading(true);

    const promises = ids.map((id) => {
      return getLogEntry(id);
    });

    Promise.all(promises).then((results) => {
      setUpdates(results);

      setShowingUpdates(true);
      setIsLoading(false);
    });
  };

  const handleInspectChanges = (ids) => {
    setIsLoading(true);

    const promises = ids.map((id) => {
      return getLogEntry(id);
    });

    Promise.all(promises).then((results) => {
      setChanges(results);

      setShowingChanges(true);
      setIsLoading(false);
    });
  };

  const ShowFieldDefReleases = () => {
    return (
      <>
        <CardText>
          <b>Field set:</b> {phraseToProperCase(release.object.fieldSet)}
        </CardText>
        <CardText>
          <b>Version:</b> {phraseToProperCase(release.object.version)}
        </CardText>
        {!showingUpdates && (
          <Button
            className="inspectButton"
            color="secondary"
            size="sm"
            onClick={() => handleInspectRelease(release.object.entryIds)}
          >
            Inspect release {isLoading && <Spinner size="sm" color="light" />}
          </Button>
        )}
        {showingUpdates && <ShowUpdates />}
      </>
    );
  };

  const getHeaderClass = () => {
    return release.released
      ? "card-header-success"
      : release.action !== "discard"
      ? "card-header-danger"
      : "";
  };

  const getButtonClass = () => {
    return release.released
      ? "success"
      : release.action !== "discard"
      ? "danger"
      : "secondary";
  };

  const getCardHeader = () => {
    const [field, source] = release.object.fieldKey.split("_", 2);

    return `${phraseToProperCase(field)} (${source})`;
  };

  return (
    <div className="Release">
      <Card className="MainCard">
        {isFieldDefChanges && (
          <CardHeader className={getHeaderClass()}>
            {getCardHeader()}
          </CardHeader>
        )}
        <CardBody>
          {/* <CardText>
            <b>Entry Id:</b> {release.entryId}
          </CardText> */}
          <CardText>
            <b>Scope:</b> {phraseToProperCase(release.scope)}
          </CardText>
          <CardText>
            <b>Action:</b> {phraseToProperCase(release.action)}
          </CardText>
          {isFieldDefChanges && (
            <ShowFieldDefChanges buttonClass={getButtonClass()} />
          )}
          {isFieldDefReleases && <ShowFieldDefReleases />}
          <Card body style={{ backgroundColor: "#f7f7f7" }}>
            {release.createdBy && (
              <CardText>
                <CreatedByField
                  by={release.createdBy.name}
                  at={release.createdAt}
                />
              </CardText>
            )}
            {release.updatedBy && (
              <CardText>
                <UpdatedByField
                  by={release.updatedBy.name}
                  at={release.updatedAt}
                />
              </CardText>
            )}
          </Card>
        </CardBody>
      </Card>
    </div>
  );
};

export default Release;
